import { useMemo } from "react";
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    MenuItem,
    Switch,
    Typography
  } from '@material-ui/core';
  import Alert from '@material-ui/lab/Alert';

const BackupBlock = (props) => {
    const backup = localStorage.getItem('certificateBackup');
    return useMemo(()=>{
        if (props.certificateId === 0 || props.certificateId === "new") {
            if (backup)    
                return <Box style={{marginBottom: "20px"}} color="primary">   
                <Card>
                <Alert severity="info">
                    <Grid container>
                        <Grid item xs={12}>Вы начали оформлять полис, но не закончили оформление. Мы восстановили этот незаконченый полис для вас. Если вы хотите начать оформление заново, нажмите на кнопку ниже.</Grid>
                        <Grid item xs={12}><Button onClick={props.resetBackup} style={{marginTop:"10px"}} variant="contained" color="default">Начать оформление заново</Button></Grid>
                    </Grid>
                </Alert>
                </Card>
            </Box>
        } 
        return null;
    }
    ,[props.certificateId]);
}

export default BackupBlock;
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialCertificate = {
    no_drivers_limit: 0,
    status_id: 5,
    owner_is_client: 0,
    excompany_id: 0,

    car_number: '',
    car_category_id: 'B',
    car_type_id: 1,
    car_brand_id: 0,
    car_model_id: 0,
    car_year: '',
    car_power: '',
    car_number_type: 1,
    car_body_number: '',
    car_vin_number: '',
    car_chass_number: '',
    car_task_id: 1,
    car_trailer: 0,
    car_seats: '',
    car_max_weight: '',

    car_document_type: 11,
    car_document_number: '',
    car_document_date: '',
    //car_diagnost_number: '',
    //car_diagnost_date: '',

    type_id: 0,
    period: 1, 
    season_months: 12,
    date_start: '',// (new Date()).setDate((new Date()).getDate() + 1),

    client_is_jur: 0,
    client_is_ip: 0,
    client_fio: '',
    client_gender: 0,
    client_birth_date: '',
    client_document_type: 1,
    client_document_series_number: '',
    client_document_date: '',
    client_document_place: '',
    client_document_code: '',
    client_country_id: 1,
    client_address: '',
    client_email: '',
    client_phone: '',

    client_name: '',
    client_inn: '',
    client_kpp: '',
    client_ogrn: '',
    client_form: null,
    client_cert_number: '',
    client_cert_date: '',

    owner_is_jur: 0,
    owner_is_ip: 0,
    owner_fio: '',
    owner_gender: 0,
    owner_birth_date: '',
    owner_document_type: 1,
    owner_document_series_number: '',
    owner_document_date: '',
    owner_document_place: '',
    owner_document_code: '',
    owner_country_id: 1,
    owner_address: '',
    owner_email: '',
    owner_phone: '',

    owner_name: '',
    owner_inn: '',
    owner_kpp: '',
    owner_ogrn: '',
    owner_form: null,
    owner_cert_number: '',
    owner_cert_date: '',

};

const initialState = {
    isLoading: false,
    isLoaded: false,
    loadError: false,
    certificate: initialCertificate,

    isSaving: false,
    savedId: false,
    saveError: null,
    errorFields: null,

    isCarInfoLoading: false,
    carInfoError: null,
    carInfoSuccess: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.CERTIFICATE_CAR_INFO_START: return updateState(state, {isCarInfoLoading: true , carInfoSuccess: false, carInfoError: false});
        case actionTypes.CERTIFICATE_CAR_INFO_FAIL: return updateState(state, {isCarInfoLoading: false, carInfoError: action.error });
        case actionTypes.CERTIFICATE_REDIRECT_TO_CALCULATE: return updateState(state, {savedId: null });

        case actionTypes.CERTIFICATE_CAR_INFO_SUCCESS: 
            const newCertificate = copyObject(state.certificate);
            let successText = "В базе Profins нет данных по этому авто. Укажите данные врунчую";
            if (action.carInfo.car_brand_id > 0) newCertificate.car_brand_id = action.carInfo.car_brand_id;
            if (action.carInfo.car_model_id > 0) newCertificate.car_model_id = action.carInfo.car_model_id;
            if (action.carInfo.car_category_id !== '') newCertificate.car_category_id = action.carInfo.car_category_id;
            if (action.carInfo.car_vin_number !== '') {
                successText = "Данные успешно загружены";
                newCertificate.car_number_type = 1;
                newCertificate.car_vin_number = action.carInfo.car_vin_number;
            }
            //if (action.carInfo.car_diagnost_date !== '') newCertificate.car_diagnost_date = action.carInfo.car_diagnost_date;

            return updateState(state, {isCarInfoLoading: false, carInfoSuccess: successText, certificate: newCertificate});

        case actionTypes.CERTIFICATE_INITIALIZE: 
            return updateState(state, {isLoading: false, isLoaded: true, certificate: initialCertificate, loadError: null, errorFields: null});

        case actionTypes.CERTIFICATE_UPDATE:
            const updatedCertificate = copyObject(state.certificate);
            for (const i in action.data)
                updatedCertificate[i] = action.data[i];
            return updateState(state, { certificate: updatedCertificate });

        case actionTypes.CERTIFICATE_SAVE_START: return updateState(state, {isSaving: true, saveError: null, errorFields: null});
        case actionTypes.CERTIFICATE_SAVE_SUCCESS:  // Проставляем айди у созданного полиса
            const certificateToMark = copyObject(state.certificate);
            certificateToMark.id = action.certificateId;
            return updateState(state, {isSaving: false, savedId: action.certificateId, certificate: certificateToMark });
        case actionTypes.CERTIFICATE_SAVE_FAIL: return updateState(state, {isSaving: false, saveError: action.error, errorFields: action.fields });

        case actionTypes.CERTIFICATE_LOAD_START: return updateState(state, {isLoading: true, isLoaded: false, loadError: null, errorFields: null});
        case actionTypes.CERTIFICATE_LOAD_SUCCESS: return updateState(state, {isLoading: false, isLoaded: true, certificate: action.certificate });
        case actionTypes.CERTIFICATE_LOAD_FAIL: return updateState(state, {isLoading: false, isLoaded: false, loadError: action.error });

        default: return state;
    }
}

export default reducer;
import {put} from 'redux-saga/effects';

import * as actions from '../actions/catalogs';
import * as generalActions from '../actions/general';
import * as authActions from '../actions/auth';

import tokenizedAxios from '../../utils/tokenizedAxios';

import { catalogUrls } from '../../settings/api_urls';

export function* loadCatalogSaga(action) 
{
    yield put(actions.loadStart(action.catalogType));

    const catalogUrlPath = catalogUrls[action.catalogType];
 
    try {

        let additionalData = {};
        if (action.catalogType === 'models')
            additionalData = { brand_id: action.subId }

        const response = yield tokenizedAxios(catalogUrlPath, 'get', additionalData);
        if (response.data.result === 'ok') {

            const changedCatalogs = {};

            if (action.catalogType === 'general') {

                const newCatalogs = {};
                for (let catalogName in response.data) 
                    if (catalogName !== 'result') {
                        newCatalogs[catalogName] = {};
                        for (let i in response.data[catalogName]) {
                            const companyElement = response.data[catalogName][i];
                            newCatalogs[catalogName][companyElement.id] = companyElement;
                        }
                    }
                
                changedCatalogs['general'] = newCatalogs;

            } else if (action.catalogType === 'companies') {

                changedCatalogs['companies'] = {};
                for (let i in response.data.companies) {
                    const companyElement = response.data.companies[i];
                    changedCatalogs['companies'][companyElement.id] = companyElement;
                }
                
            } else if (action.catalogType === 'excompanies') {

                changedCatalogs['excompanies'] = {};
                for (let i in response.data.companies) {
                    const companyElement = response.data.companies[i];
                    changedCatalogs['excompanies'][companyElement.id] = companyElement;
                }

            } else if (action.catalogType === 'brands') {

                changedCatalogs['brands'] = {};
                for (let i in response.data.brands) {
                    const element = response.data.brands[i];
                    changedCatalogs['brands'][element.id] = element;
                }
            } else if (action.catalogType === 'countries') {

                changedCatalogs['countries'] = {};
                for (let i in response.data.countries) {
                    const element = response.data.countries[i];
                    changedCatalogs['countries'][element.id] = element;
                }

            } else if (action.catalogType === 'models') {

                if (action.subId) {
                    if (!changedCatalogs['models']) changedCatalogs['models'] = {};
                    if (!changedCatalogs['models'][action.subId]) changedCatalogs['models'][action.subId] = {};
                    for (let i in response.data.models) {
                        const element = response.data.models[i];
                        changedCatalogs['models'][action.subId][element.id] = element;
                    }
                }

            } else if (action.catalogType === 'regions') {

                changedCatalogs['regions'] = {};
                for (let i in response.data.regions) {
                    const regionElement = response.data.regions[i];
                    changedCatalogs['regions'][regionElement.id] = regionElement;
                }

            }

            yield put(actions.loadSuccess(changedCatalogs));
        } else {
            if (response.data.not_authorized) yield put(authActions.logout());
            yield put(generalActions.generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalActions.generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
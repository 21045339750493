export const prepareReport = (report) => {

    const dataToSend = {};

    for (const fieldName in report) {
        switch (fieldName) {

            case "id": dataToSend['report_id'] = report.id; break;

            default: dataToSend[fieldName] = report[fieldName]; break;
        }
    }

    return dataToSend;
    
}
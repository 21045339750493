import React, { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  List,
  makeStyles,
  Typography,
} from '@material-ui/core';
import Page from '../../components/Page';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import Spinner from '../../components/Spinner';
import MessageElement from './MessageElement';
import MessageForm from './MessageForm';

import * as actions from '../../store/actions';
import parseUrl from '../../utils/parseUrl';
import { resetMessages } from '../../store/actions/messages';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    marginTop: '20px'
  },
  marginTop: {
    marginTop: '20px'
  },
}));

const Messages = (props) => {

  const messagesRefresher = useRef(null);

  const userId = parseUrl(3) ?? 0;
  const classes = useStyles();
  
  const onLoadMessages = props.onLoadMessages;
  const curUser = props.curUser;
  
  const deleteHandler = (messageId) => {
    props.onDeleteMessages(messageId);
  }

  const sendMessageHandler = (text) => {
    props.onSaveMessage([userId], text);
  }

  const filterHandler = useCallback((userId, page, perPage) => {
    onLoadMessages(userId, page, perPage);
  }, [onLoadMessages]);

  const onLoadUser = props.onLoadUser;
  const onResetMessages = props.onResetMessages;
  useEffect(() => {
    if (props.user.id*1 !== userId*1 && !props.isUserLoading && !props.userError) {
      onResetMessages();
      onLoadUser(userId);
    }
    else if (!props.messages) {
      filterHandler(userId, 1, props.perPage)
    }
  }, [onResetMessages, filterHandler, userId, onLoadUser, props.isUserLoading, props.messages, props.userError, props.user.id, props.perPage]);

  useEffect(() => {
    if (props.messages) {
      messagesRefresher.current = setInterval(()=>filterHandler(userId, 1, props.perPage), 5000);
      return () => {
        if (messagesRefresher.current!= null) clearInterval(messagesRefresher.current);
      }
    } 
  },[filterHandler, props.messages, props.perPage, userId]);
  
  let blocks = <Grid item lg={12} md={12} xs={12}><Spinner text="Загружаем сообщения" /></Grid>;
  let pages = 0;
  let dialogUser = props.user.id*1 === 0 ? props.curUser : props.user;
  if (props.messages) {
    blocks = [];
    pages = Math.ceil(props.total / props.perPage);
    for (const i in props.messages) {
      //if (!dialogUser) dialogUser = props.messages[i].user ? props.messages[i].user : props.messages[i].receiver;
      blocks.push(<MessageElement 
        isAdmin={curUser.access === 100} 
        divider={i < props.messages.length - 1}
        key={i} 
        message={props.messages[i]} 
        onDelete={deleteHandler}
        curUserId={props.curUser.id}
        headerTitle={props.messages[i].user_id*1===0 ? "Администрация" : (dialogUser.name_f + " " + dialogUser.name_i)}
      />);
    }
  }

  let title = null;
  if (dialogUser.id > 0)
    title = "Диалог с " + (dialogUser ? (dialogUser.name_f + " " + dialogUser.name_i + " (id"+dialogUser.id+")") : "Пользователь не найден")
  else 
    title = "Диалог с администрацией";

  return (

    <Page
      className={classes.root}
      title="Диалог"
    >
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title={title}
          />
          <Divider />
          <CardContent>
            <MessageForm 
              onSaveMessage={sendMessageHandler}
              isSaving={props.isSaving}
              saveError={props.saveError}
              savedId={props.savedId}
            />
          </CardContent>
          <Divider />
          <List>
            {blocks}
          </List>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            {props.page < pages ?
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={() => filterHandler(userId, props.page*1 + 1, props.perPage)}
            >
              Показать более старые
            </Button> : null }
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      isMessagesLoading: state.messages.isLoading,
      error: state.messages.error,
      messages: state.messages.messages,
      total: state.messages.total,
      userId: state.messages.userId,
      page: state.messages.page,
      perPage: state.messages.perPage,

      isUserLoading: state.user.isLoading,
      isUserLoaded: state.user.isLoaded,
      user: state.user.user,
      userError: state.user.loadError,

      isSaving: state.messages.isSaving, 
      saveError: state.messages.saveError,
      savedId: state.messages.savedId,

      curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadMessages: (userId, page, perPage) => dispatch(actions.loadMessages(userId, page, perPage)),
    onDeleteMessage: (messageId) => dispatch(actions.deleteMessage(messageId)),
    onLoadUser: (userId) => dispatch(actions.loadUser(userId)),
    onSaveMessage: (userId, text) => dispatch(actions.saveMessage(userId, text)),
    onResetMessages: () => dispatch(actions.resetMessages())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
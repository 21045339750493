import {
    Grid,
    TextField,
  } from '@material-ui/core';
//import MaskedInput from 'react-text-mask';
import InputMask from 'react-input-mask';
//import { TextField } from 'formik-material-ui';
//import { Formik, Form, Field } from 'formik';

const maskSymbol = /_/;
const removeMaskSymbols = (text) => {
    return text;
    //if (!text) return '';
    //return text.replace(maskSymbol,'').trim();
}

const ElkTextInput = (props) => {

    if (props.mask) {
        return <Grid item lg={props.width ?? 4} md={props.width ?? 4} xs={props.mobileWidth ?? 12} >
            <InputMask
            mask={props.mask}
            value={props.value ? props.value.toString() : ''}
            disabled={false}
            //maskChar=" "
            name={props.name}
            onBlur={props.handleBlur}
            onChange={(event) => { const str = removeMaskSymbols(event.target.value);  props.setFieldValue(props.name, str); props.onValueChange(str) }}
            >
                <TextField
                fullWidth
                multiline={props.multiline ?? false}
                error={props.error}
                helperText={props.helperText}
                type={props.type ?? 'text'}
                label={props.label}
                placeholder={props.placeholder}
                required={props.required}
                value={props.value ? props.value.toString() : ''}
                variant="outlined"
                />
            </InputMask>
        </Grid>
    } else {
        return <Grid item lg={props.width ?? 4} md={props.width ?? 4} xs={props.mobileWidth ?? 12} >
            <TextField
            fullWidth
            onBlur={props.handleBlur}
            error={props.error}
            multiline={props.multiline ?? false}
            helperText={props.helperText}
            type={props.type ?? 'text'}
            label={props.label}
            placeholder={props.placeholder}
            name={props.name}
            onChange={(event) => { props.setFieldValue(props.name, event.target.value); props.onValueChange(event.target.value) }}
            required={props.required}
            value={props.value}
            variant="outlined"
            />
            </Grid>
    }




    /*let inputProps = null;
    if (props.mask) {
        inputProps = {
            inputComponent: MaskedInput,
            inputProps: { 
            mask: props.mask,
            showMask: false,
            },
        }
    }

    return <Grid item lg={4} md={4} xs={12} >
        <TextField
        fullWidth
        onBlur={props.handleBlur}
        error={props.error}
        helperText={props.helperText}
        type={props.type ?? 'text'}
        label={props.label}
        placeholder={props.placeholder}
        name={props.name}
        onChange={(event) => { props.setFieldValue(props.name, event.target.value); props.onValueChange(event.target.value) }}
        required={props.required}
        value={props.value}
        variant="outlined"
        InputProps={inputProps}
        />
    </Grid>*/
}

export default ElkTextInput;

import React from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';

import tinkoffLogo from '../../assets/logos/1b.png';
import alfaLogo from '../../assets/logos/2b.png';
import ingosstrahLogo from '../../assets/logos/3b.png';
import vskLogo from '../../assets/logos/4b.png';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  stickToBottom: {
    //position: "fixed",
    //bottom: theme.spacing.unit * 2,
    //right: theme.spacing.unit * 3,
    maxWidth: '1000px', 
    margin: "0px auto",
    marginTop: "70px",
  }
}));

const LoginView = (props) => {

  //console.log("CURRENT URL", window.location.hostname);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isAuthenticated)
      navigate("/");
  },[props.isAuthenticated, navigate]);

  let apiErrorMessage = null;
  if (props.error) 
    apiErrorMessage = <Box mb={3}><Typography color="error" variant="body1" >{props.error}</Typography></Box>

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string().email('Укажите корректный E-mail').max(255).required('Пожалуйста, укажите ваш E-mail'),
              password: Yup.string().max(255).required('Пожалуйста, укажите ваш пароль')
            })}
            onSubmit={(values) => {
              props.onAuth(values.email, values.password, false);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Войти в Profins-a
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="E-mail"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Пароль"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  {apiErrorMessage}
                  <Button
                    color="primary"
                    disabled={props.loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {props.loading ? "Вхожу..." : "Войти"}
                  </Button>
                </Box>
                <Grid container>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Нет аккаунта?
                      {' '}
                      <Link
                        component={RouterLink}
                        to="/app/register"
                        variant="h6"
                      >
                        Зарегистрируйтесь!
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                      align="right"
                    >
                      <Link
                        component={RouterLink}
                        to="/app/reset"
                        variant="h6"
                      >
                        Восстановить пароль
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </Container>

        <Card className={classes.stickToBottom}>
        <CardContent  style={{paddingBottom: '16px'}}>
        <Grid container direction="row" justify="space-evenly" alignItems="center" >
          {/*<Grid item lg={12} md={12} xs={12}  align="center" style={{marginTop: "10px", marginBottom: "15px"}}>
            <Typography><b>Мы работаем с ведущими страховыми компаниями:</b></Typography>
            </Grid>*/}
          <Grid align="center" style={{padding:'15px', paddingLeft: '30px', paddingRight: '30px'}}>
            <a target="blank" href="https://acdn.tinkoffinsurance.ru/static/documents/417d017c-904c-4477-a106-e8bf32008054.pdf"><img className={classes.companyLogo} src={tinkoffLogo} width="137.5"  alt="Tinkoff" /></a>
          </Grid>
          <Grid  align="center" style={{padding:'15px', paddingLeft: '30px', paddingRight: '30px'}}>
            <a target="blank" href="https://www.alfastrah.ru/"><img className={classes.companyLogo} src={alfaLogo} width="137.5"  alt="Alfa" /></a>
          </Grid>
          <Grid  align="center" style={{padding:'15px', paddingLeft: '30px', paddingRight: '30px'}}>
            <img className={classes.companyLogo} src={ingosstrahLogo} width="137.5"  alt="Ingosstrah" />
          </Grid>
          <Grid align="center" style={{padding:'15px', paddingLeft: '30px', paddingRight: '30px'}}>
            <img className={classes.companyLogo} src={vskLogo} width="137.5"  alt="VSK" />
          </Grid>
        </Grid>
        </CardContent>
        </Card>

      </Box>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      loading: state.auth.loading,
      error: state.auth.error,
      isAuthenticated: state.auth.token != null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onAuth: (email, password, isSignUp) => dispatch(actions.auth(email, password, isSignUp))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
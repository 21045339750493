import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import AccountView from './views/account/AccountView';
import CustomerListView from './views/customer/CustomerListView';
import CertificateListView from './views/certificate/CertificateListView';
import CertificateForm from './views/certificate/CertificateForm';
import Profile from './views/profile/Profile';
import UserListView from './views/user/UserListView';
import UserCompany from './views/user/UserCompany';
import UserForm from './views/user/UserForm';
import UserLog from './views/user/UserLog';
import RateListView from './views/rate/RateListView';
import RateForm from './views/rate/RateForm';
import ReportListView from './views/report/ReportListView';
import CertificateCalculate from './views/certificate/CertificateCalculate';
import CertificatePreview from './views/certificate/CertificatePreview';
import CertificateProlong from './views/certificate/CertificateProlong';
import OldCertificateProlong from './views/certificate/OldCertificateProlong';
import DashboardView from './views/dashboard';
import LoginView from './views/auth/LoginView';
import ResetView from './views/auth/ResetView';
import LogoutAction from './views/auth/LogoutAction';
import NotFoundView from './views/errors/NotFoundView';
import ProductListView from './views/product/ProductListView';
import RegisterView from './views/auth/RegisterView';
import SettingsView from './views/settings/SettingsView';
import NewsListView from './views/news/NewsListView';
import NewsForm from './views/news/NewsForm';
import MessagesIndex from './views/messages';
import Messages from './views/messages/Messages';
import ReportForm from './views/report/ReportForm';

export const routesAuthenticated = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'logout', element: <LogoutAction /> },
      { path: '', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },

      { path: 'prolong', element: <CertificateProlong /> },

      { path: 'certificates', element: <CertificateListView /> },
      { path: 'certificates/:id/calculate', element: <CertificateCalculate /> },
      { path: 'certificates/:id/prolong', element: <OldCertificateProlong /> },
      { path: 'certificates/:id/preview', element: <CertificatePreview /> },
      { path: 'certificates/:id', element: <CertificateForm /> },
      { path: 'certificates/new', element: <CertificateForm /> },

      { path: 'users', element: <UserListView /> },
      { path: 'users_company', element: <UserCompany /> },
      { path: 'users/:id/history', element: <UserLog /> },
      { path: 'users/:id', element: <UserForm /> },
      { path: 'users/new', element: <UserForm /> },

      { path: 'profile', element: <Profile /> },

      { path: 'rates', element: <RateListView /> },
      { path: 'rates/:id', element: <RateForm /> },
      { path: 'rates/new', element: <RateForm /> },

      { path: 'news', element: <NewsListView /> },
      { path: 'news/:id', element: <NewsForm /> },
      { path: 'news/new', element: <NewsForm /> },

      { path: 'messages', element: <MessagesIndex /> },
      { path: 'messages/:id', element: <Messages /> },

      { path: 'reports', element: <ReportListView /> },
      { path: 'reports/:id', element: <ReportForm /> },
      //{ path: 'rates/new', element: <RateForm /> },

      { path: '*', element: <Navigate to="/app/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'app/login', element: <LoginView /> },
      { path: 'app/register', element: <RegisterView /> },
      { path: 'app/404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app" /> },
      { path: '*', element: <Navigate to="/app/404" /> }
    ]
  }
];

export const routesUnauthenticated = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'app/login', element: <LoginView /> },
      { path: 'app/register', element: <RegisterView /> },
      { path: 'app/reset', element: <ResetView /> },
      { path: 'app/404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/login" /> },
      { path: '*', element: <Navigate to="/app/login" /> },
    ]
  }
];
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import Page from '../../components/Page';
import 'moment/locale/ru';
import TotalPrice from './TotalPrice';
import TotalPaid from './TotalPaid';
import TotalCertificates from './TotalCertificates';
import TotalComission from './TotalComission';
import moment from 'moment';
import Header from '../../components/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Example = (props) => {
  const classes = useStyles();
  const counters = props.counters ?? {};
  const navigate = useNavigate();

  const handleGoToNewCertificatePage = () => {
    navigate("/app/certificates/new");
  }

  const currentDateTime = moment().format("DD.MM.YYYY");
  const newCertificateButton = <Button variant="contained" onClick={(event) => {handleGoToNewCertificatePage();}} color="primary">Оформить полис</Button>;

  let blocks;
  if (props.adminMode) {

    blocks = <>
      {props.adminStatistics}
      <Grid
        item
        lg={4}
        xl={4}
        md={12}
        xs={12}
      >
        {props.recentNews}
      </Grid>
      <Grid item lg={8} xl={8} md={12} xs={12}>
        {props.prolongationCertificates}
      </Grid>
    </>

  } else {

    blocks = <>
      <Grid item lg={7} xl={7} md={12} xs={12}>
        {props.prolongationCertificates}
      </Grid>
      {props.newCertificates}
      <Grid
        item
        lg={4}
        xl={4}
        md={12}
        xs={12}
      >
        {props.recentNews}
      </Grid>
      <Grid item lg={8} xl={8} md={12} xs={12}>
        {props.adminStatistics}
      </Grid>
    </>

  }

  return (
      <Page className={classes.root} title="Dashboard">
        <Container maxWidth={false}>
          <Grid container spacing={3} style={{marginBottom:"13px"}}>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Card>
                <CardContent>
                    <Typography style={{marginBottom:"12px", marginTop:"8px"}}>Уважаемые агенты, сегодня <b>{currentDateTime}</b>. Желаем вам хорошего дня и хороших продаж!</Typography>
                    {newCertificateButton}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Header style={{marginTop:"20px"}} title={"Статистика за " + moment().locale("ru").format("MMMM")} />
          <Grid container spacing={3}>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalPrice monthly={counters.monthly_total_price ?? 0} total={counters.total_price ?? 0} isLoading={props.countersLoading} error={props.countersError} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalPaid monthly={counters.monthly_total_paid_certificates ?? 0} total={counters.total_paid_certificates ?? 0} isLoading={props.countersLoading} error={props.countersError} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalCertificates monthly={counters.monthly_total_certificates ?? 0} total={counters.total_certificates ?? 0} isLoading={props.countersLoading} error={props.countersError} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <TotalComission monthly={counters.monthly_total_comission ?? 0} total={counters.total_comission ?? 0} isLoading={props.countersLoading} error={props.countersError} />
            </Grid>
            {blocks}
          </Grid>
        </Container>
      </Page>
    );
};

export default (Example);
import * as actionTypes from './actionTypes';

export const resetMessages = () => { return { type: actionTypes.MESSAGES_RESET }}

export const loadMessages = (userId, page, perPage) => {
    return {
        userId: userId,
        page : page,
        perPage : perPage,
        type: actionTypes.MESSAGES_LOAD_INIT,
    }
}

export const loadStart = (userId, page, perPage) => {
    return {
        userId: userId,
        page: page,
        perPage: perPage,
        type: actionTypes.MESSAGES_LOAD_START,
    }
}

export const loadSuccess = (messages, total) => {
    return {
        messages: messages,
        total: total,
        type: actionTypes.MESSAGES_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        type: actionTypes.MESSAGES_LOAD_FAIL,
    }
}

export const deleteMessage = (messageId) => ({ messageId: messageId, type: actionTypes.MESSAGES_DELETE })
export const deleteMessageSuccess = (messageId) => ({ messageId: messageId, type: actionTypes.MESSAGES_DELETE_SUCCESS })
export const deleteMessageFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })

export const saveMessage = (userIds, text) => {
    return {
        text: text,
        userIds: userIds,
        type: actionTypes.MESSAGES_SAVE,
    }
}

export const saveMessageStart = () => {
    return {
        type: actionTypes.MESSAGES_SAVE_START,
    }
}

export const saveMessageSuccess = () => {
    return {
        type: actionTypes.MESSAGES_SAVE_SUCCESS,
    }
}

export const saveMessageFail = (error, fields) => {
    return {
        error: error,
        fields: fields,
        type: actionTypes.MESSAGES_SAVE_FAIL,
    }
}

export const addMassMessageUsers = (userData) => {
    return {
        userData: userData,
        type: actionTypes.MESSAGES_MASS_ADD,
    }
}

export const removeMassMessageUsers = (userIds) => {
    return {
        userIds: userIds,
        type: actionTypes.MESSAGES_MASS_REMOVE,
    }
}
import * as actionTypes from './actionTypes';

export const loadCounters = () => ({ type: actionTypes.DASHBOARD_COUNTERS })
export const loadCountersStart = () => ({ type: actionTypes.DASHBOARD_COUNTERS_START })
export const loadCountersSuccess = (counters) => ({ counters: counters, type: actionTypes.DASHBOARD_COUNTERS_SUCCESS })
export const loadCountersFail = (errorText) => ({ text: errorText, type: actionTypes.DASHBOARD_COUNTERS_FAIL })

export const loadProlongationCertificates = () => ({ type: actionTypes.DASHBOARD_PROLONG_CERTIFICATES })
export const loadProlongationCertificatesStart = () => ({ type: actionTypes.DASHBOARD_PROLONG_CERTIFICATES_START })
export const loadProlongationCertificatesSuccess = (certificates) => ({ certificates: certificates, type: actionTypes.DASHBOARD_PROLONG_CERTIFICATES_SUCCESS })
export const loadProlongationCertificatesFail = (errorText) => ({ text: errorText, type: actionTypes.DASHBOARD_PROLONG_CERTIFICATES_FAIL })

export const loadAdminStatistics = () => ({ type: actionTypes.DASHBOARD_STATISTICS })
export const loadAdminStatisticsStart = () => ({ type: actionTypes.DASHBOARD_STATISTICS_START })
export const loadAdminStatisticsSuccess = (statistics) => ({ statistics: statistics, type: actionTypes.DASHBOARD_STATISTICS_SUCCESS })
export const loadAdminStatisticsFail = (errorText) => ({ text: errorText, type: actionTypes.DASHBOARD_STATISTICS_FAIL })

export const loadCreatedCertificates = () => ({ type: actionTypes.DASHBOARD_CREATED_CERTIFICATES })
export const loadCreatedCertificatesStart = () => ({ type: actionTypes.DASHBOARD_CREATED_CERTIFICATES_START })
export const loadCreatedCertificatesSuccess = (certificates) => ({ certificates: certificates, type: actionTypes.DASHBOARD_CREATED_CERTIFICATES_SUCCESS })
export const loadCreatedCertificatesFail = (errorText) => ({ text: errorText, type: actionTypes.DASHBOARD_CREATED_CERTIFICATES_FAIL })

export const loadNewCertificates = () => ({ type: actionTypes.DASHBOARD_NEW_CERTIFICATES })
export const loadNewCertificatesStart = () => ({ type: actionTypes.DASHBOARD_NEW_CERTIFICATES_START })
export const loadNewCertificatesSuccess = (certificates) => ({ certificates: certificates, type: actionTypes.DASHBOARD_NEW_CERTIFICATES_SUCCESS })
export const loadNewCertificatesFail = (errorText) => ({ text: errorText, type: actionTypes.DASHBOARD_NEW_CERTIFICATES_FAIL })
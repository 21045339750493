import * as actionTypes from './actionTypes';


export const updateRate = (newRateObject) => {
    return {
        data: newRateObject,
        type: actionTypes.RATE_UPDATE,
    }
}

export const saveRate = (rateObject) => {
    return {
        data: rateObject,
        type: actionTypes.RATE_SAVE,
    }
}

export const saveRateStart = () => {
    return {
        type: actionTypes.RATE_SAVE_START,
    }
}

export const saveRateSuccess = (rateId) => {
    return {
        rateId: rateId,        
        type: actionTypes.RATE_SAVE_SUCCESS,
    }
}

export const saveRateFail = (error, fields) => {
    return {
        error: error,
        fields: fields,
        type: actionTypes.RATE_SAVE_FAIL,
    }
}



export const initializeRate = () => {
    return {
        type: actionTypes.RATE_INITIALIZE,
    }
}

export const loadRate = (rateId, mode = 'base') => {
    if (mode === 'base')
        return {
            rateId : rateId,
            mode: mode,
            type: actionTypes.RATE_LOAD,
        }
    else if (mode === 'parent')
        return {
            rateId : rateId,
            mode: mode,
            type: actionTypes.RATE_LOAD,
        }
    else alert('wrong type: ' + mode);
}

export const loadRateStart = (rateId, mode = 'base') => {
    if (mode === 'base')
        return {
            rateId : rateId,
            type: actionTypes.RATE_LOAD_START,
        }
    else if (mode === 'parent')
        return {
            type: actionTypes.PARENT_RATE_START,
        }
    else alert('wrong type:: ' + mode);
}

export const loadRateSuccess = (data, mode = 'base') => {
    if (mode === 'base')
        return {
            rate: data.rate,        
            type: actionTypes.RATE_LOAD_SUCCESS,
        }
    else if (mode === 'parent')
        return {
            rate: data.rate,        
            type: actionTypes.PARENT_RATE_SUCCESS,
        }
    else alert('wrong type::: ' + mode);
}


export const loadRateFail = (error, mode = false) => {
    if (mode === 'base')
        return {
            error: error,
            type: actionTypes.RATE_LOAD_FAIL,
        }
    else if (mode === 'parent')
        return {
            error: error,
            type: actionTypes.PARENT_RATE_FAIL,
        }
    else alert('wrong type:::: ' + mode);
}

export const loadRateInfo = (rateId, mode = 'base') => {
    if (mode === 'current')
        return {
            rateId : rateId,
            mode: mode,
            type: actionTypes.RATE_INFO_LOAD,
        }
    else alert('wrong type! ' + mode);
}

export const loadRateInfoStart = (rateId, mode = 'base') => {
    if (mode === 'current')
        return {
            type: actionTypes.USER_RATE_START,
        }
    else alert('wrong type!! ' + mode);
}

export const loadRateInfoSuccess = (data, mode = 'base') => {
    if (mode === 'current')
        return {
            rate: data.rate,        
            type: actionTypes.USER_RATE_SUCCESS,
        }
    else alert('wrong type!!! ' + mode);
}


export const loadRateInfoFail = (error, mode = false) => {
    if (mode === 'current')
        return {
            error: error,
            type: actionTypes.USER_RATE_FAIL,
        }
    else alert('wrong type!!!! ' + mode);
}
import {put} from 'redux-saga/effects';

import * as actions from '../actions/prolong';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { certificateProlongUrl } from '../../settings/api_urls';

export function* prolongCertificateSaga(action) 
{
    yield put(actions.prolongCertificateStart());

    try {

        const response = yield tokenizedAxios(certificateProlongUrl, 'post', action.prolongData);

        if (response.data.result === 'ok') {
            const certificateId = response.data.certificate_id;
            yield put(actions.prolongCertificateSuccess(certificateId));
        } else {
            yield put(actions.prolongCertificateFail(response.data.errorstring));
        }

    } catch (error) {
        yield put(actions.prolongCertificateFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", null));
    };
}
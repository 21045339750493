import moment from 'moment'

export default function parseServerDate(dateString) {
    if (dateString) {
        if (typeof dateString == "string") {
            const formatedDate = moment(dateString, "YYYY-MM-DD").format("DD.MM.YYYY");
            //console.log("PARSING DATE " + dateString +": "+ formatedDate);
            if (formatedDate !== "Invalid date") return formatedDate;
        }
    }
    return null;
}
import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { useEffect, useState } from 'react'
import { useRoutes } from 'react-router-dom';
import { LinearProgress, Snackbar, ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import './mixins/chartjs';
import themeCreator from './theme';
import { routesAuthenticated, routesUnauthenticated} from './routes';
import ErrorBoundary from './utils/ErrorBoundary';

import { connect } from 'react-redux';

import * as actions from './store/actions';
import useScript from './hooks/useScript';

import SimpleSnackbar from './components/Snackbar';
import Spinner from './components/Spinner';
import copyArray from './utils/copyArray';

const App = (props) => {

  useScript('//code-ya.jivosite.com/widget/yULZAw6EgC');

  const [colors, setColors] = useState({primary:null, secondary: null, background: null});

  const theme = themeCreator(colors);

  const authCheckState = props.authCheckState;
  useEffect(() => {
    authCheckState();
  },[authCheckState]);

  let finalRoutes = null;
  if (props.isAuthenticated) finalRoutes = routesAuthenticated;
  else if (!props.isAuthenticated) finalRoutes = routesUnauthenticated
  const routing = useRoutes(finalRoutes);

  if (props.isLocalTokenChecked) {

    
    let general = null;
    if (props.isGeneralLoading) {
      general = <LinearProgress style={{position:'absolute', zIndex:5000, width:"100%"}} />;
    } else if (props.generalErrorText) {
      general = <SimpleSnackbar type="error" text={props.generalErrorText} />
    } else if (props.generalSuccessText) {
      general = <SimpleSnackbar type="success" text={props.generalSuccessText} />
    }
    
    let colorsBlock = null;
    /*if (props.user && props.user.access > 50) colorsBlock = <div style={{textAlign:'center'}}>
      <input type="text" onChange={(event)=>setColors({primary: event.target.value, secondary: colors.secondary, background: colors.background})} value={colors.primary} />
      <input type="text" onChange={(event)=>setColors({secondary: event.target.value, primary: colors.primary, background: colors.background})} value={colors.secondary} />
      <input type="text" onChange={(event)=>setColors({background: event.target.value, primary: colors.primary, secondary: colors.secondary})} value={colors.background} />
    </div>*/

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
          <ErrorBoundary>
            {general}
            {routing}
            {colorsBlock}
          </ErrorBoundary>
      </ThemeProvider>
    );

  } else {

    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Spinner text="Проверка доступа" />
      </ThemeProvider>
    );

  }
};

const mapStateToProps = (state) => {
  return {
    isGeneralLoading: state.general.isGeneralActive,
    generalErrorText: state.general.generalErrorText,
    generalSuccessText: state.general.generalSuccessText,
    //generalMessages: state.general.generalMessages,
    isAuthenticated: state.auth.token != null,
    isLocalTokenChecked: state.auth.isLocalTokenChecked,
    user: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    authCheckState: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ruRU } from '@material-ui/core/locale';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,

  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,

} from '@material-ui/core';
import { DateRangePicker } from "materialui-daterange-picker";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Search as SearchIcon, Calendar as CalendarIcon, HelpCircle as HelpIcon, X as CloseIcon } from 'react-feather';
import DeleteIcon from '@material-ui/icons/Delete';
import { X as ClearIcon } from 'react-feather';
import Autocomplete from '@material-ui/lab/Autocomplete';

import MenuButton from './MenuButton';
import Spinner from '../Spinner';
import ErrorBlock from '../ErrorBlock';
import copyObject from '../../utils/copyObject';

import ElkServerSearchInput from '../FormElements/ElkServerSearchInput';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  hover: {cursor: 'pointer'},
  verticalMiddle: {verticalAlign: 'middle'},
  formControl: { width: '100%' },
  smallCell: {
    width: '10px'
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  customTableContainer: {
    overflowX: "initial",
    maxHeight: "880px",
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const SmartTable = (props) => {

  const [datePickerRangeOpenField, setDatePickerRangeOpen] = React.useState(null);

  const setDateRange = (range) => {
    const rangeStart = new Date(range.startDate); // Date.parse(range.startDate);
    const rangeEnd = new Date(range.endDate); // Date.parse(range.endDate);
    const startString = rangeStart.getDate() + '.' + (rangeStart.getMonth()+1) +'.' + rangeStart.getFullYear();
    const endString = rangeEnd.getDate() + '.' + (rangeEnd.getMonth()+1) +'.' + rangeEnd.getFullYear();
    handleFilterChange('created_at', {start: startString, end: endString}); 
  }

  let dateRangePickerValue = {};
  const openDatePickerRange = (filterName) => {
    if (filterName !== null) {
      const currentFilterValue = props.filter[filterName] ?? null;
      if (currentFilterValue !== null)
        dateRangePickerValue = {startDate: new Date(currentFilterValue.start), endDate: new Date(currentFilterValue.end) };
    }
    setDatePickerRangeOpen(filterName)
  }

  const classes = useStyles();
  const [selectedIds, setSelectedIds] = useState([]);
  const [delayedSearch, setDelayedSearch] = useState([]);
  
  const executeSetSelectedIds = (ids) => {
    const tableName = props.tableName ?? ('t' + Math.floor(Math.random() * 10000));
    localStorage.setItem('smartTableSelectedIds_' + tableName, ids);
    setSelectedIds(ids);
    if (props.onChangeSelectedIds) props.onChangeSelectedIds(ids);
  }

  const handleSelectAll = (event) => {
    let newSelectedIds;

    if (event.target.checked) {
      newSelectedIds = props.data.map((element) => element.id);
    } else {
      newSelectedIds = [];
    }

    executeSetSelectedIds(newSelectedIds);
  };

  const throttledSearch = throttle((request, callback, filterElement) => {
    filterElement.searchFunc(request, callback)
  }, 500);

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedIds.indexOf(id);
    let newSelectedIds = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(selectedIds, id);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(1));
    } else if (selectedIndex === selectedIds.length - 1) {
      newSelectedIds = newSelectedIds.concat(selectedIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        selectedIds.slice(0, selectedIndex),
        selectedIds.slice(selectedIndex + 1)
      );
    }

    executeSetSelectedIds(newSelectedIds);
  };

  const handleLimitChange = (event) => {
    props.onFilter(props.filter, 1, event.target.value, props.order);
  };

  const handlePageChange = (event, newPage) => {
    props.onFilter(props.filter, newPage + 1, props.perPage, props.order);
  };

  const handleSort = (field) => {
    let order = props.order ?? {};
    if (!order) {
      order = {};
      order[field] = 'DESC';
    } else {
      if (!order[field]) {
        order = {};
        order[field] = 'DESC';
      }
      else 
        order[field] =order[field] === "DESC" ? "ASC" : "DESC";
    }
    props.onFilter(props.filter, 1, props.perPage, order);
  }

  const handleFilterChange = (key, value ) => {
    const newFilter = {...props.filter};
    newFilter[key] = value;
    props.onFilter(newFilter, props.page, props.perPage, props.order);
  }

  const handleMultiselectChange = (filterName, selectedItem) => {
    const currentFieldValue = selectedItem;
    handleFilterChange(filterName, currentFieldValue);
  }

  const handleMultiselectFilterChange = (filterName, valuesList) => {
    const currentFieldValue = [];
    for (const i in valuesList)
      currentFieldValue.push(valuesList[i].id);
    handleFilterChange(filterName, currentFieldValue);
  }

  let table = <Spinner />;
  let pagination = null;

  if (props.error) {
    table = <ErrorBlock error={props.error} />
  }
  else if (!props.isLoading) {

    if (props.data && props.data.length > 0) {

      let tableHeaders = [];

      if (props.buttonsData) 
        tableHeaders.push(<TableCell className={classes.smallCell} key="-3"></TableCell>);

      if (props.massButtons || props.infoBlock) 
        tableHeaders.push(<TableCell key="-1" padding="checkbox">
          <Checkbox
            checked={selectedIds.length === props.data.length}
            color="primary"
            indeterminate={
              selectedIds.length > 0
              && selectedIds.length < props.data.length
            }
            onChange={handleSelectAll}
          />
        </TableCell>);

      for (let i in props.tableheaders) { 
        let sortIcon = null;
        if (props.order && props.order[i] !== undefined) 
          sortIcon = props.order[i] === "DESC" ? <ArrowDownwardIcon fontSize="inherit" className={classes.verticalMiddle} /> : <ArrowUpwardIcon fontSize="inherit" className={classes.verticalMiddle} />;
        tableHeaders.push(<TableCell key={i}><Link className={classes.hover} onClick={(event) => handleSort(i)}>{props.tableheaders[i]}</Link> {sortIcon}</TableCell>)
      }

      if (props.tooltipData) 
        tableHeaders.push(<TableCell className={classes.smallCell} key="-2"></TableCell>);

      table = <PerfectScrollbar>
        <Box minWidth={props.minWidth ?? "1050"}>
          <TableContainer classes={props.isScrollable ? {root: classes.customTableContainer} : null}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {tableHeaders}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.map((element) => {

                  const tableRow = [];

                  if (props.buttonsData) {
                    tableRow.push(<TableCell key={-3}><MenuButton buttonsData={props.buttonsData(element)} /></TableCell>);
                  }

                  if ((props.massButtons && props.massButtons.length > 0) || props.infoBlock)
                    tableRow.push(
                      <TableCell key={-1} padding="checkbox" >
                        <Checkbox
                          checked={selectedIds.indexOf(element.id) !== -1}
                          onChange={(event) => { handleSelectOne(event, element.id) }}
                          value="true"
                        />
                      </TableCell>
                    );

                  const rowData = props.rowCreator(element);
                  for (let i in rowData)
                    tableRow.push(<TableCell onClick={(event) => { if (props.onRowClick) props.onRowClick(element.id) }} className={classes.hover} key={i}>{rowData[i]}</TableCell>);

                  if (props.tooltipData)
                    tableRow.push(<TableCell onClick={(event) => { if (props.onRowClick) props.onRowClick(element.id)}} className={classes.hover} key={-2}><HtmlTooltip title={props.tooltipData(element)} ><SvgIcon fontSize="small" color="action" ><HelpIcon /></SvgIcon></HtmlTooltip></TableCell>);

                  return <TableRow hover key={element.id} selected={selectedIds.indexOf(element.id) !== -1} >{tableRow}</TableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PerfectScrollbar>;

      pagination = null;
      if (props.total > props.perPage)
        pagination = <TablePagination
          component="div"
          count={props.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleLimitChange}
          page={props.page - 1}
          rowsPerPage={props.perPage}
          rowsPerPageOptions={[20, 50, 100, 500]}
        />

    } else {

      table = <Box my={2} ml={2}><Typography color="textSecondary" gutterBottom variant="body2">Нет записей</Typography></Box>

    }
  }

  const startDelayedSearch = (field, value) => {

    const newDelayedSearchData = copyObject(delayedSearch);
    if (delayedSearch[field] !== undefined && delayedSearch[field] !== null) {
      clearTimeout(delayedSearch[field].timeout);
    }
    
    newDelayedSearchData[field] = {
      value: value,
      timeout: setTimeout(()=>handleFilterChange(field, value), 1000)
    }

    setDelayedSearch(newDelayedSearchData);
  }

  let filters = [];
  let filterBlock = null;
  for (let filterName in props.filters) {

    let inputBlock = null;
    const filterElement = props.filters[filterName];
    const filterValue = props.filter[filterName] ?? null;

    switch (filterElement.type) {

      case 'search':
        inputBlock = <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action" ><SearchIcon /></SvgIcon>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <SvgIcon fontSize="small" style={{cursor:'pointer'}} color="action" onClick={() => { startDelayedSearch(filterName, ''); }} ><CloseIcon /></SvgIcon>
              </InputAdornment>
            )
          }}
          name={filterName}
          value={delayedSearch[filterName] ? delayedSearch[filterName].value : ''}
          label={filterElement.name}
          placeholder={filterElement.placeholder ?? ''}
          variant="outlined"
          onChange={(event) => { startDelayedSearch(filterName, event.target.value); }}
        />
        break;

      case 'dateRange':
        let endAdornment = null;
        if (filterValue !== null && filterValue !== '') {
          endAdornment = (
            <InputAdornment position="start" onClick={(event) => handleFilterChange(filterName, null)} className={classes.hover}>
              <SvgIcon fontSize="small" color="action" >
                <ClearIcon />
              </SvgIcon>
            </InputAdornment>
          );
        }
        inputBlock = <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action" >
                  <CalendarIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: endAdornment,
          }}
          placeholder="Нажмите для выбора"
          variant="outlined"
          name={filterName}
          label={filterElement.name}
          value={filterValue ? (filterValue.start + " - " + filterValue.end) : ""}
          onFocus={(event) => {openDatePickerRange(filterName)}}
        />
        break;

      case 'multiselect':
        const optionsList = [];
        for (const i in filterElement.options) {
          const optionVariant = filterElement.options[i];
          optionsList.push(<MenuItem key={optionVariant.id} value={optionVariant.id} >{optionVariant.name}</MenuItem>)
        }
        inputBlock = <TextField
          classes={{ root: classes.formControl }}
          select
          name={filterName}
          variant="outlined"
          label={filterElement.name}
          SelectProps={{
            multiple: true,
            value: filterValue ?? [],
            onChange: (event, index, values) => { handleMultiselectChange(filterName, event.target.value) }
          }}
        >
          {optionsList}
        </TextField>
        break;

      case 'select':
        const options = [<MenuItem key={-1} value={null} >Все варианты</MenuItem>];
        for (const i in filterElement.options) {
          const optionVariant = filterElement.options[i];
          options.push(<MenuItem key={optionVariant.id} value={optionVariant.id} >{optionVariant.name}</MenuItem>)
        }
        inputBlock = <TextField
          classes={{ root: classes.formControl }}
          select
          name={filterName}
          variant="outlined"
          label={filterElement.name}
          SelectProps={{
            multiple: false,
            value: filterValue ?? '',
            onChange: (event, index, values) => { handleFilterChange(filterName, event.target.value) }
          }}
        >
          {options}
        </TextField>
        break;

      case 'multiselectSearch':

        const filterOptionsArray = [];
        for (const i in filterElement.options ?? [])
          filterOptionsArray.push({id: i, name: filterElement.options[i].name});

        inputBlock = <Autocomplete
          multiple
          options={ filterOptionsArray }
          getOptionLabel={(option) => option.name}
          defaultValue={[]}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label={filterElement.name}
              //placeholder="Favorites"
            />
          )}
          onChange={(event, value, reason) => { handleMultiselectFilterChange(filterName, value)}}
        />
        break;

      case 'multiselectServerSearch':
        inputBlock = <ElkServerSearchInput
        fullWidth
        width={12}
        value={filterValue ?? ""}
        label={filterElement.name}
        searchFunc={(request, callback) => throttledSearch(request,callback,filterElement) }
        onValueChange={(value) => { handleFilterChange(filterName, value) }}
        />
        break;

      default:
        break;
    }

    if (inputBlock !== null)
      filters.push(<Grid key={filterName} item md={3 * (filterElement.width ?? 1)} xs={12} >{inputBlock}</Grid>);
  }

  if (filters.length > 0)
    filterBlock = <Box my={3}>
      <Card>
        <CardContent>
          <Box>
            <Grid container spacing={2} >
              {filters}
              <Grid item >
                <Button variant="contained" color="default" startIcon={<DeleteIcon />} onClick={() => props.onFilter({}, 1, props.perPage, props.order)} >Очистить фильтр</Button>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>

  let bottomMenu = null;
  if (selectedIds.length > 0) {
    const massBtns = [];
    if (props.massButtons && props.massButtons.length > 0) {
      for (const i in props.massButtons) {
        massBtns.push(<Button key={i} style={{marginLeft:"20px", marginTop:"10px", marginBottom: "10px"}} variant="contained" color="default" startIcon={props.massButtons[i].icon ?? null} onClick={() => { props.massButtons[i].onClick(selectedIds); executeSetSelectedIds([]) }} >{props.massButtons[i].name}</Button>);
      }
    }
    
    bottomMenu = <AppBar color="primary" className={classes.appBar}>
      {props.infoBlock ? props.infoBlock : null}
      <Toolbar>
        <Grid container justify="center" alignItems="center" >
          {massBtns}
        </Grid>
      </Toolbar>
    </AppBar>
  }

  return (
    <>
    <Box>
      <DateRangePicker
        open={datePickerRangeOpenField != null ? true : false}
        toggle={() => openDatePickerRange(null)}
        locale={ruRU}
        onChange={(range) => { setDateRange(range); openDatePickerRange(null); }}
        initialDateRange = {dateRangePickerValue}
      />
    </Box>
    {filterBlock}
    <Card className={clsx(classes.root, props.className)} style={{marginBottom: bottomMenu ? "64px" : "0px"}} >
      {table}
      {pagination}
      {bottomMenu}
    </Card>
    
    </>
  );
};

SmartTable.propTypes = {
  className: PropTypes.string,
  //data: PropTypes.array.isRequired
};



export default SmartTable;
import {put} from 'redux-saga/effects';
import * as actions from '../actions/dialogs';
import * as authActions from '../actions/auth';
import * as generalActions from '../actions/general';
import tokenizedAxios from '../../utils/tokenizedAxios';
import { dialogsUrl, newMessagesUrl } from '../../settings/api_urls';

export function* loadDialogsSaga(action) 
{
    yield put(actions.loadStart(action.filter, action.page, action.perPage));

    const getData = {
        filter: action.filter ?? {},
        page: action.page ?? 1,
        perpage: action.perPage ?? 20
    }

    try {
        const response = yield tokenizedAxios(dialogsUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const dialogs = response.data.dialogs;
            const total = response.data.total;
            yield put(actions.loadSuccess(dialogs, total));
        } else {
            yield put(actions.loadError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* loadNewMessagesSaga(action) 
{
    yield put(actions.loadNewMessagesStart());

    const getData = {}

    try {
        const response = yield tokenizedAxios(newMessagesUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const newMessagesCount = response.data.new_messages_count;
            yield put(actions.loadNewMessagesSuccess(newMessagesCount));
        } else {
            if (response.data.not_authorized) yield put(authActions.logout());
            yield put(generalActions.generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalActions.generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
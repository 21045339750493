import * as actionTypes from './actionTypes';

export const loadCarInfo = (carNumber) => {
    return {
        carNumber : carNumber,
        type: actionTypes.CERTIFICATE_CAR_INFO,
    }
}

export const loadCarInfoStart = (carNumber) => {
    return {
        carNumber : carNumber,
        type: actionTypes.CERTIFICATE_CAR_INFO_START,
    }
}

export const loadCarInfoSuccess = (carInfo) => {
    return {
        carInfo: carInfo,        
        type: actionTypes.CERTIFICATE_CAR_INFO_SUCCESS,
    }
}

export const loadCarInfoFail = (error) => {
    return {
        error: error,
        type: actionTypes.CERTIFICATE_CAR_INFO_FAIL,
    }
}

export const redirectToCalculate = () => {
    return {
        type: actionTypes.CERTIFICATE_REDIRECT_TO_CALCULATE,
    }
}

export const updateCertificate = (newCertificateObject) => {
    return {
        data: newCertificateObject,
        type: actionTypes.CERTIFICATE_UPDATE,
    }
}

export const saveCertificate = (certificateObject) => {
    return {
        data: certificateObject,
        type: actionTypes.CERTIFICATE_SAVE,
    }
}

export const saveCertificateStart = (carNumber) => {
    return {
        type: actionTypes.CERTIFICATE_SAVE_START,
    }
}

export const saveCertificateSuccess = (certificateId) => {
    return {
        certificateId: certificateId,        
        type: actionTypes.CERTIFICATE_SAVE_SUCCESS,
    }
}

export const saveCertificateFail = (error, fields) => {
    return {
        error: error,
        fields: fields,
        type: actionTypes.CERTIFICATE_SAVE_FAIL,
    }
}



export const initializeCertificate = () => {
    return {
        type: actionTypes.CERTIFICATE_INITIALIZE,
    }
}

export const loadCertificate = (certificateId, prolongMode) => {
    return {
        certificateId : certificateId,
        prolongMode: prolongMode,
        type: actionTypes.CERTIFICATE_LOAD,
    }
}

export const loadCertificateStart = (certificateId) => {
    return {
        certificateId : certificateId,
        type: actionTypes.CERTIFICATE_LOAD_START,
    }
}

export const loadCertificateSuccess = (data) => {
    return {
        certificate: data.certificate,        
        type: actionTypes.CERTIFICATE_LOAD_SUCCESS,
    }
}

export const loadCertificateFail = (error) => {
    return {
        error: error,
        type: actionTypes.CERTIFICATE_LOAD_FAIL,
    }
}
import {put} from 'redux-saga/effects';

import * as actions from '../actions/rate';
import * as ratesActions from '../actions/rates';

import tokenizedAxios from '../../utils/tokenizedAxios';

import { rateSaveUrl, rateLoadUrl, rateInfoUrl } from '../../settings/api_urls';

import parseServerRate from './sagasUtils/parseServerRate';

export function* loadRateSaga(action) 
{
    const mode = action.mode ?? "base";

    if (action.rateId === 0 || action.rateId === 'new') { // Новое КВ
        yield put(actions.initializeRate());
    } else {
        yield put(actions.loadRateStart(action.rateId, mode));
        try {
            const response = yield tokenizedAxios(rateLoadUrl, 'get', { rate_id: action.rateId, combine_fields: 0 });
            if (response.data.result === 'ok') {
                const rate = response.data.rate;
                const finalRate = parseServerRate(rate);
                yield put(actions.loadRateSuccess({rate: finalRate }, mode));
            } else {
                yield put(actions.loadRateFail(response.data.errorstring, mode));
            }
        } catch (error) {
            console.log("ERROR RATE LOADING",error);
            yield put(actions.loadRateFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз.", mode));
        };
    }
}

export function* saveRateSaga(action) 
{
    //console.log("SAVING");
    yield put(actions.saveRateStart());

    try {
        const dataToSend = action.data;
        dataToSend.new_api = 1; //New API for backend
        const response = yield tokenizedAxios(rateSaveUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            const rateId = response.data.id;
            yield put(actions.saveRateSuccess({
                id: rateId,
            }));
            yield put(ratesActions.resetRates());
        } else {
            yield put(actions.saveRateFail(response.data.errorstring, response.data.fields));
        }

    } catch (error) {
        //console.log("ERROR", error.message);
        yield put(actions.saveRateFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", null));
    };
}

export function* loadRateInfoSaga(action) 
{
    const mode = action.mode ?? "base";

    yield put(actions.loadRateInfoStart(action.rateId, mode));
    try {
        const response = yield tokenizedAxios(rateInfoUrl, 'get', { rate_id: action.rateId });
        if (response.data.result === 'ok') {
            const rate = response.data.rate;
            const finalRate = parseServerRate(rate, true);
            yield put(actions.loadRateInfoSuccess({rate: finalRate }, mode));
        } else {
            yield put(actions.loadRateInfoFail(response.data.errorstring, mode));
        }
    } catch (error) {
        console.log("ERROR RATE INFO",error);
        yield put(actions.loadRateInfoFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз.", mode));
    };
}
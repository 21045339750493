import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';

const initialState = {
    isActive: false,
    documents: null,
    isLoading: false,
    error: null,
    isLoaded: false,
}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.DOCUMENTS_LOAD_START:   
            const finalNewState = {isLoading: true, documents:null, error: null, isLoaded: false };
            return updateState(state, finalNewState);

        case actionTypes.DOCUMENTS_LOAD_SUCCESS: return updateState(state, {isLoading: false, documents: action.documents, isLoaded: true});
        case actionTypes.DOCUMENTS_LOAD_FAIL:    return updateState(state, {isLoading: false, documents: null, isLoaded: false, error: action.error });

        case actionTypes.DOCUMENTS_OPEN: return updateState(state, {isActive: true, certificateId: action.certificateId });
        case actionTypes.DOCUMENTS_CLOSE: return updateState(state, {isActive: false, certificateId: null, isLoading: false, documents:null, error: null, isLoaded: false});

        default: return state;
    }
}

export default reducer;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import * as actions from '../../../store/actions';
import Spinner from '../../../components/Spinner';
import ReactToPrint from 'react-to-print';
import PreviewElement from './PreviewElement';

const useStyles = makeStyles(() => ({
  root: {},
  certificateBox: {
    marginTop: "20px",
    width: "100%"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  marginLeft: {
    marginLeft: "10px"
  },
  marginRight: {
    marginRight: "20px"
  },
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  error: {
    color: "#f44336"
  },
  hr: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  hrTop: {
    marginTop: "30px",
    marginBottom: "10px",
  },
  subText: {
    fontSize: '10px',
    color: 'gray',
    marginTop: '1px',
  },
}));

const PreviewForm = ({ className, certificate, urlCertificateId, ...rest }) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const componentRef = useRef();

  const onLoadCatalog = rest.onLoadCatalog;
  const onLoadCertificate = rest.onLoadCertificate;
  const catalogsGeneral = rest.catalogs['general'] ?? false;
  const catalogsBrands = rest.catalogs['brands'] ?? false;
  const catalogsModels = rest.catalogs['models'] ?? false;

  const returnToCertificate = () => {
    navigate("/app/certificates/" + certificate.id);
  }
  const proceedToCalculate = () => {
    navigate("/app/certificates/" + certificate.id + "/calculate");
  }
  
  useEffect(() => {
    const anchor = document.querySelector('#back-to-top-anchor');
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!certificate.id || urlCertificateId*1 !== certificate.id*1) {
      if (!rest.isCertificateLoading) {
        onLoadCertificate(urlCertificateId);
      }
    }
    else if (!catalogsBrands) {
      onLoadCatalog('brands')
    }
    else if (!catalogsModels) {
      onLoadCatalog('models', certificate.car_brand_id)
    }
  }, [catalogsGeneral, catalogsBrands, rest.user, onLoadCatalog, certificate, urlCertificateId, onLoadCertificate, rest.isCertificateLoading]);

  let previewElement = <Spinner />
  let printButton = "";
  if (catalogsBrands && catalogsModels && catalogsGeneral && certificate.id) {
    previewElement = <PreviewElement ref={componentRef} certificate={certificate} catalogsGeneral={catalogsGeneral} catalogsBrands={catalogsBrands} catalogsModels={catalogsModels} />
    printButton = <ReactToPrint
      trigger={() => <Button variant="contained" color="default" onClick={proceedToCalculate} className={classes.marginRight}>Распечатать</Button>}
      content={() => componentRef.current}
    />
  }

  return (
    <>
    <Box className={classes.certificateBox}>
      <Card>
        {/*<CardHeader 
          title="Предпросмотр полиса"
          id="back-to-top-anchor"
        />
        <Divider />*/}
        <CardContent id="back-to-top-anchor">{previewElement}</CardContent>
      </Card>
    </Box>
    <Box className={classes.certificateBox}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12} className={classes.center} >
              <Button variant="contained" color="default" onClick={returnToCertificate} className={classes.marginRight}>Вернуться к редактированию полиса</Button>
              {printButton}
              <Button variant="contained" color="primary" onClick={proceedToCalculate}>Рассчитать полис</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    certificate: state.certificate.certificate,
    isCertificateLoading: state.certificate.isLoading,
    error: state.certificate.error,
    
    isCatalogsLoading: state.catalogs.isLoading,
    catalogs: state.catalogs.catalogs,

    user: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCatalog: (catalogName, subId=null) => dispatch(actions.loadCatalog(catalogName, subId)),
      onLoadCertificate: (certificateId) => dispatch(actions.loadCertificate(certificateId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreviewForm);


import * as Yup from 'yup';

import onlyDigitsAndLetters from '../onlyDigitsAndLetters';
import onlyDigitsAndLatinLetters from '../onlyDigitsAndLatinLetters';

//Комплексная валидцаия айди авто (Vin, кузова, шасси в зависимости от выбранного типа номера)
const carIdValidation = (type, required=true) => {

    let checkerObject = {};

    if (required) {
        checkerObject = {
            //car_number_type: Yup.string().required("Выберите тип идентификации авто").oneOf([1,2,3],"Выберите тип идентификации авто"),
        }
        if (type === 1) checkerObject.car_vin_number = Yup.string().required('Пожалуйста, укажите VIN').test('full', 'Номер должен состоять из 17 цифр и латинских букв', function(value) {
            return value && (onlyDigitsAndLatinLetters(value).length === 17);
        });
        if (type === 2) checkerObject.car_body_number = Yup.string().required('Пожалуйста, укажите номер кузова').test('full', 'Номер должен состоять не более чем из 20 символов', function(value) {
            return value && (onlyDigitsAndLetters(value).length < 20);
        });
        if (type === 3) checkerObject.car_chass_number = Yup.string().required('Пожалуйста, укажите номер шасси').test('full', 'Номер должен состоять не более чем из 20 символов', function(value) {
            return value && (onlyDigitsAndLetters(value).length < 20);
        });
    }

    return checkerObject;
}

export default carIdValidation;
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TabletIcon from '@material-ui/icons/Description';

const Percentage = ({ className, ...rest }) => {
  const theme = useTheme();

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const data = generateData(rest.statistics);
  const total = data.datasets[0].data[0] + data.datasets[0].data[1] + data.datasets[0].data[2]
  const devices = [
    {
      title: data.labels[0],
      value: Math.round(data.datasets[0].data[0] / total * 100),
      icon: LaptopMacIcon,
      color: colors.indigo[500]
    },
    {
      title: data.labels[1],
      value: Math.round(data.datasets[0].data[1] / total * 100),
      icon: TabletIcon,
      color: colors.red[600]
    },
    {
      title: data.labels[2],
      value: Math.round(data.datasets[0].data[2] / total * 100),
      icon: AttachMoneyIcon,
      color: colors.orange[600]
    }
  ];

  return (
    <Card
      className={clsx(className)}
      {...rest}
    >
      <CardContent>
        <Box
          height={300}
          position="relative"
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          mt={2}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              p={1}
              textAlign="center"
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
                %
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

const generateData = (statistics) => {

  let newCertificates = 0;
  let paid = 0;
  let created = 0;
  for (const i in statistics) {
    const el = statistics[i];
    newCertificates += parseInt(el.new_count)
    created += parseInt(el.created_count)
    paid += parseInt(el.paid_count)
  }

  console.log(newCertificates, created, paid)

  const data = {
    datasets: [
      {
        data: [newCertificates, created, paid],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.orange[600]
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: ['Предрасчёт', 'Оформлены', 'Оплачены']
  };

  return data;
}

Percentage.propTypes = {
  className: PropTypes.string
};

export default Percentage;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Spinner from '../../../components/Spinner';
import isCompanyVisible from '../../../utils/isCompanyVisible';
import { subrateDrivers, subrateTrailer, subrateJuridical, subrateProlong } from '../../../settings/profins';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3)
  },
  marginTop: {
    marginTop: '20px'
  },
  chip: {
    marginRight: '2px', marginBottom: '4px'
  },
  tooltipLink: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  }
}));

const RateInfo = (props) => {

  const classes = useStyles();
  const curRate = props.infoRate;

  const catalogsCompanies = props.catalogs['companies'] ?? false;
  const catalogsRegions = props.catalogs['regions'] ?? false;
  const catalogsGeneral = props.catalogs['general'] ?? false;

  let curRateBlock = <Grid item lg={12} md={12} xs={12} ><Spinner text="Загружаем информацию о комиссионном вознаграждении"></Spinner></Grid>

  if (curRate) {

    let subrates = null;
    //let otherText = " стоимости полиса идёт вам";
    if (curRate.rates) {

      const curRates = {};
      for (const i in curRate.rates) 
        curRates[curRate.rates[i].id] = curRate.rates[i];

      if (!curRate.parent_rates) curRate.parent_rates = curRates;

      //otherText = " в остальных случаях";
      const specialTariffs = [];
      for (const companyId in catalogsCompanies) if (isCompanyVisible(props.curUser, companyId)) {

        specialTariffs.push(<Box key={"st" + companyId} style={{marginTop:"20px", marginBottom:"5px"}}><Typography variant="h4">{catalogsCompanies[companyId].name}</Typography></Box>);

        for (const i in curRate.parent_rates) if (companyId === curRate.parent_rates[i].company_id) {
          const parentRate = curRate.parent_rates[i];
          const regionTariffs = [];
          let allRegions = false;
          //console.log("REGION INFO", parentRate.region_id);
          for (const j in parentRate.region_id) {
            const regionId = parentRate.region_id[j];
            //console.log("REGION ID", regionId);
            if (regionId > 0) {     
              regionTariffs.push(catalogsRegions[regionId] ? (
              <Chip className={classes.chip} key={"j"+regionId} label={catalogsRegions[regionId].name} />
              ) : null);
            } else { 
              allRegions = true;
              regionTariffs.push(<Chip className={classes.chip} key={"j"+j} label="Все регионы" />);
            }
          }

          const categoryTariffs = [];
          for (const j in parentRate.category) {
            const categoryId = parentRate.category[j];
            if (categoryId !== "0") {     
              categoryTariffs.push(catalogsGeneral['car_categories'][categoryId] ? <Chip className={classes.chip} key={"f"+catalogsGeneral['car_categories'][categoryId].id} label={catalogsGeneral['car_categories'][categoryId].name} /> : null);
            } else { 
              categoryTariffs.push(<Chip className={classes.chip} key={"f"+j} label="Все категории" />);
            }
          }

          const taskTariffs = [];
          for (const j in parentRate.task) {
            const taskId = parentRate.task[j];
            if (taskId !== "0") {     
              taskTariffs.push(catalogsGeneral['car_tasks'][taskId] ? <Chip className={classes.chip} key={"g"+catalogsGeneral['car_tasks'][taskId].id} label={catalogsGeneral['car_tasks'][taskId].name} /> : null);
            } else { 
              taskTariffs.push(<Chip className={classes.chip} key={"g"+j} label="Все цели использования" />);
            }
          }

          const prolongTariffs = [];
          for (const j in parentRate.prolong) {
            const prolongId = parentRate.prolong[j];
            if (prolongId !== "0") {     
              prolongTariffs.push(catalogsGeneral['prolong'][prolongId] ? <Chip className={classes.chip} key={"g"+catalogsGeneral['prolong'][prolongId].id} label={catalogsGeneral['prolong'][prolongId].name} /> : null);
            } else { 
              prolongTariffs.push(<Chip className={classes.chip} key={"g"+j} label="Все варианты" />);
            }
          }

          const otherTariffs = [];
          if (parentRate.trailer > 0) otherTariffs.push(<Chip className={classes.chip} key={"a"+parentRate.trailer} label={subrateTrailer[parentRate.trailer].name} />);
          if (parentRate.juridical > 0) otherTariffs.push(<Chip className={classes.chip} key={"b"+parentRate.juridical} label={subrateJuridical[parentRate.juridical].name} />);
          //if (curRate.rates[i].prolong > 0) otherTariffs.push(<Chip className={classes.chip} key={"c"+parentRate.prolong} label={subrateProlong[parentRate.prolong].name} />);
          if (parentRate.drivers > 0) otherTariffs.push(<Chip className={classes.chip} key={"d"+parentRate.drivers} label={subrateDrivers[parentRate.drivers].name} />);
          if (parentRate.power > 0) otherTariffs.push(<Chip className={classes.chip} key={"e"+parentRate.power} label={"Мощность от " + parentRate.power} />);
          if (parentRate.kbm > 0) otherTariffs.push(<Chip className={classes.chip} key={"f"+parentRate.kbm} label={"КБМ до " + parentRate.kbm} />);

          let tariffText = '';
          let specialBlock = null;

          if (!props.readOnly) {

            let finalPercent = props.editedRate.parent_rates[parentRate.id].percent*1 - (props.editedRate ? props.editedRate.percent*1 : 0);
            if (props.specialSettings) {
              const specialTariffs = props.specialSettings.filter((element)=>element.subrate_id*1===parentRate.id*1);
              let specialTariffPercent = 0;
              if (specialTariffs.length > 0) specialTariffPercent = specialTariffs[0].percent;
              specialBlock = <input onChange={(event)=>props.changeSpecialSubrate(parentRate.id, event.target.value)} style={{width:"30px"}} value={specialTariffPercent !==0 ? specialTariffPercent : ""}  />
              finalPercent += specialTariffPercent*1;
            }
            tariffText += (curRates[i] ? curRates[i].percent : 0) + "% ("+finalPercent+"%)";
            
          } else {

            tariffText += Math.max(0,(curRates[i] ? curRates[i].percent : 0)) + "%";

          }

          tariffText += " в " + (allRegions ? "любом регионе" : (parentRate.region_id.length + (parentRate.region_id.length === 1 ? " регионе" : " регионах")));

          specialTariffs.push(
            <Accordion key={i}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{specialBlock} {tariffText}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{display: 'block'}}>
                {regionTariffs}
                {categoryTariffs}
                {taskTariffs}
                {prolongTariffs}
                {otherTariffs}
              </AccordionDetails>
            </Accordion>);
        }
      }

      subrates = <Grid item lg={12} md={12} xs={12} >{specialTariffs}</Grid>
    }
    curRateBlock = <>
      {/*<Grid item lg={12} md={12} xs={12} >
        <Typography variant="h4" component="p">Комиссионное вознаграждение "{curRate.name}"</Typography>
      </Grid>*/}
      {subrates}
      {/*<Grid item lg={12} md={12} xs={12} >
        <Typography variant="body1" component="p">{curRate.percent}% {otherText}</Typography>
      </Grid>*/}
    </>
  }

  return curRateBlock;
};

const mapStateToProps = state => {
  return {
      curUser: state.auth.user,
      catalogs: state.catalogs.catalogs,
  }
}

export default connect(mapStateToProps)(RateInfo);
const createMaskFromString = (initialMask) => {
    const mask = [];
    for (let i=0; i<initialMask.length; i++) {
        switch (initialMask[i]) {
            case "d": mask.push(/\d/); break; 
            case "r": mask.push(/[а-я0-9]/i); break; 
            default: mask.push(initialMask[i]); break;
        }
    }
    //console.log("MASKS", initialMask, initialMask.length, mask);
    return mask;
}

export default createMaskFromString;
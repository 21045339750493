import {put} from 'redux-saga/effects';

import * as actions from '../actions/files';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { fileUploadUrl } from '../../settings/api_urls';

export function* uploadFileSaga(action) 
{
    yield put(actions.uploadFileStart(action.fileName, action.additional));

    try {
        const dataToSend = new FormData();
        dataToSend.append('file', action.file);
        dataToSend.append('new_api', 1);
        dataToSend.append('name', action.fileName);
        dataToSend.append('additional', JSON.stringify(action.additional));
        dataToSend.append('token', localStorage.getItem('token'));

        //const dataToSend = {aaa: 'gowno'};
        //dataToSend.new_api = 1;

        const response = yield tokenizedAxios(fileUploadUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            yield put(actions.uploadFileSuccess(action.fileName, action.additional));
        } else {
            yield put(actions.uploadFileFail(action.fileName, response.data.errorstring, action.additional));
        }

    } catch (error) {
        yield put(actions.uploadFileFail(action.fileName, "Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", action.additional));
    };
}
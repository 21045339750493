import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as actions from '../../store/actions/index';

const LogoutAction = (props) => {

    const onLogout = props.onLogout;
    const navigate = useNavigate();

    useEffect(()=>{
        if (props.isAuthenticated)
            onLogout();
        else 
            navigate("/");
    }, [onLogout, props.isAuthenticated, navigate])

    return <p>Выхожу...</p>
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token != null,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LogoutAction);
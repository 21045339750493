import * as actionTypes from './actionTypes';


export const saveReport = (reportObject) => {
    return {
        data: reportObject,
        type: actionTypes.REPORT_SAVE,
    }
}

export const updateReport = (newReportObject) => {
    return {
        data: newReportObject,
        type: actionTypes.REPORT_UPDATE,
    }
}

export const saveReportStart = () => {
    return {
        type: actionTypes.REPORT_SAVE_START,
    }
}

export const saveReportSuccess = (reportId) => {
    return {
        reportId: reportId,  
        type: actionTypes.REPORT_SAVE_SUCCESS,
    }
}

export const saveReportFail = (error, fields) => {
    return {
        error: error,
        fields: fields,
        type: actionTypes.REPORT_SAVE_FAIL,
    }
}



export const initializeReport = () => {
    return {
        type: actionTypes.REPORT_INITIALIZE,
    }
}

export const loadReport = (reportId, isFull) => {
    return {
        reportId : reportId,
        isFull: isFull,
        type: actionTypes.REPORT_LOAD,
    }
}

export const loadReportStart = (reportId) => {
    return {
        reportId : reportId,
        type: actionTypes.REPORT_LOAD_START,
    }
}

export const loadReportSuccess = (data) => {
    return {
        report: data.report,
        type: actionTypes.REPORT_LOAD_SUCCESS,
    }
}

export const loadReportFail = (error) => {
    return {
        error: error,
        type: actionTypes.REPORT_LOAD_FAIL,
    }
}


export const downloadReport = (reportId) => {
    return {
        reportId : reportId,
        type: actionTypes.REPORT_DOWNLOAD,
    }
}

export const downloadReportStart = (reportId) => {
    return {
        reportId : reportId,
        type: actionTypes.REPORT_DOWNLOAD_START,
    }
}

export const downloadReportSuccess = (data) => {
    return {
        report: data.report,
        type: actionTypes.REPORT_DOWNLOAD_SUCCESS,
    }
}

export const downloadReportFail = (error) => {
    return {
        error: error,
        type: actionTypes.REPORT_DOWNLOAD_FAIL,
    }
}



export const changeReportStatus = (reportId, newStatusId) => {
    return {
        reportId : reportId,
        newStatusId: newStatusId,
        type: actionTypes.REPORT_CHANGE_STATUS,
    }
}

export const changeReportStatusSuccess = (newStatusId) => {
    return {
        newStatusId: newStatusId,
        type: actionTypes.REPORT_CHANGE_STATUS_SUCCESS,
    }
}



export const deleteCertificateFromReport = (reportId, certificateId) => {
    return {
        reportId : reportId,
        certificateId: certificateId,
        type: actionTypes.DELETE_CERTIFICATE_FROM_REPORT,
    }
}

export const deleteCertificateFromReportStart = (reportId, certificateId) => {
    return {
        reportId : reportId,
        certificateId: certificateId,
        type: actionTypes.DELETE_CERTIFICATE_FROM_REPORT_START,
    }
}

export const deleteCertificateFromReportSuccess = (certificateId) => {
    return {
        certificateId: certificateId,
        type: actionTypes.DELETE_CERTIFICATE_FROM_REPORT_SUCCESS,
    }
}

export const deleteCertificateFromReportFail = (error) => {
    return {
        error: error,
        type: actionTypes.DELETE_CERTIFICATE_FROM_REPORT_FAIL,
    }
}
import React, { useEffect, useState, useRef } from 'react';
import {useLocation, Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  ShoppingBag as ShoppingBagIcon,
  User as UserIcon,
  LogOut as LogOutIcon,
  Users as UsersIcon,
  Briefcase as BriefcaseIcon,
  PlusCircle as PlusCircleIcon,
  RefreshCw as RefreshIcon,
  Mail as MailIcon,
} from 'react-feather';
import NavItem from './NavItem';
import MessagesBadge from './MessagesBadge';
import ReportsBadge from './ReportsBadge';

import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import backgroundImage from '../../../assets/backgrounds/left_menu.jpg';

/*const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};*/

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    height: '100%'
    //top: 64,
    //height: 'calc(100% - 64px)'
  },
  info: {
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "20px",
    //padding: "10px",
    fontFamily: "Roboto, Arial",
    fontSize: "14px",
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  white: {
    color: 'white',
  },
  leftMenu: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    backgroundColor: "#2a5b7a",
    color: 'white',
  }
}));

const NavBar = ({ onMobileClose, openMobile, user, newMessages, newReports, getNewMessagesCount, getNewReportsCount }) => {
  
  const newMessagesRefresher = useRef(null);

  useEffect(() => {
      if (user) {
          getNewMessagesCount();
          newMessagesRefresher.current = setInterval(getNewMessagesCount, 15000);
          getNewReportsCount();
      }  
      return () => {
        if (newMessagesRefresher.current!= null) clearInterval(newMessagesRefresher.current);
      } 
  },[user, getNewMessagesCount, getNewReportsCount]);

  //console.log("CHECK", newReports);

  const items = [

    {
      href: '/app/certificates/new',
      icon: PlusCircleIcon,
      title: 'Оформить ОСАГО'
    },
  
    {
      href: '/app/prolong',
      icon: RefreshIcon,
      title: 'Пролонгация ОСАГО'
    },
  
    {
      href: '/app/messages',
      icon: MailIcon,
      title: <MessagesBadge newMessages={newMessages} />
    },
  
    {
      href: '/app/certificates',
      icon: BriefcaseIcon,
      title: 'Полисы',
    },
  
    {
      href: '/app/users',
      icon: UsersIcon,
      title: 'Агенты',
    },
  
    {
      href: '/app/rates',
      icon: ShoppingBagIcon,
      title: 'КВ',
    },

    {
      href: '/app/reports',
      icon: BriefcaseIcon,
      title: <ReportsBadge newReports={newReports} />,
    },
    
  
  
  
    /*{
      href: '/app/dashboard',
      icon: BarChartIcon,
      title: '*** Dashboard'
    },
    {
      href: '/app/customers',
      icon: UsersIcon,
      title: '*** Customers'
    },
    {
      href: '/app/products',
      icon: ShoppingBagIcon,
      title: '*** Products'
    },
    {
      href: '/app/account',
      icon: UserIcon,
      title: '*** Account'
    },
    {
      href: '/app/settings',
      icon: SettingsIcon,
      title: '*** Settings'
    },
    {
      href: '/login',
      icon: LockIcon,
      title: '*** Login'
    },
    {
      href: '/register',
      icon: UserPlusIcon,
      title: '*** Register'
    },
    {
      href: '/404',
      icon: AlertCircleIcon,
      title: '*** Error'
    }*/
  ];

  const classes = useStyles();
  const location = useLocation();

  //console.log("USER ID", user.id);
  //console.log("USER NAME", user.name_f);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const adminBlocks = [];
  if (user && user.access === 100) {
    adminBlocks.push(<NavItem
      key="mainkv"
      href='rates/1'
      title='Основное КВ'
      icon={SettingsIcon}
    />);
    adminBlocks.push(<NavItem
      key="news"
      href='news'
      title='Новости'
      icon={SettingsIcon}
    />);
    adminBlocks.push(<NavItem
      key="agent_company"
      href='users_company'
      title='Компании агентов'
      icon={UsersIcon}
    />);

  }

  let helloMessage = <Box flexGrow={1}></Box>;
  if (!user || user.access < 100)
    helloMessage = <Box flexGrow={1}>
      <div className={classes.info}>
        <p style={{marginBottom:"10px"}}>Уважаемые коллеги!</p>
        <p style={{marginBottom:"10px"}}>В случае возникновения какой-либо ошибки при заполнении или расчёте полиса, просьба отправлять скриншот либо фото на почту rusinsur@bk.ru или в Whatsapp вашему куратору</p>
        <p>Так вопрос будет решён максимально оперативно</p></div>
    </Box>

  const content = (
    <Box
      height="100%"
      className={classes.leftMenu}
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        {/*<Avatar
          className={classes.avatar}
          component={RouterLink}
          src={"/static/images/avatars/avatar_6.png"}
          to="/app/account"
        />*/}
        <Typography className={classes.name} variant="h2">
          <RouterLink className={classes.white} to="/app">Profins-a</RouterLink>
        </Typography>
        <Typography
          className={classes.name}
          //color="textPrimary"
          variant="h5"
        >
          {user.name_f + " " + user.name_i}
        </Typography>
        <Typography
          //color="textSecondary"
          variant="body2"
        >
          {"ID " + user.id}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Box>
      {/*helloMessage*/}
      <Box flexGrow={1}></Box>
      <Box
        p={2}
      >
        {adminBlocks}
        <NavItem
            href='profile'
            title='Профиль'
            icon={UserIcon}
        />
        <NavItem
            href='logout'
            title='Выйти'
            icon={LogOutIcon}
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};



const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    newMessages: state.dialogs.newMessages,
    newReports: state.report.newReports,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getNewMessagesCount: () => dispatch(actions.loadNewMessages()),
    getNewReportsCount: () => dispatch(actions.loadNewReports()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);


import {put} from 'redux-saga/effects';

import * as actions from '../actions/users';
import { generalError, generalSuccess, generalStart } from '../actions/general';
import { changeCompanyDataStart, changeCompanyDataFail, changeCompanyDataSuccess } from '../actions/users';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { usersUrl, usersDeleteUrl, companyUpdateUrl } from '../../settings/api_urls';

export function* loadUsersSaga(action) 
{
    yield put(actions.loadStart(action.filter, action.page, action.perPage, action.order, action.additionalData));

    const getData = {
        ...action.filter,
        format: 1,
        fill_agent_data: 1,
        page: action.page ?? 1,
        perpage: action.perPage ?? 20,
        order: action.order ?? { "id": "DESC" },
        additional_data: action.additionalData,
    }

    try {
        const response = yield tokenizedAxios(usersUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const users = response.data.users;
            const total = response.data.total;
            yield put(actions.loadSuccess(users, total));
        } else {
            yield put(actions.loadError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* deleteUserSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(usersDeleteUrl, 'post', {id: action.userId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Агент " + action.userId + " успешно удалён"));
            yield put(actions.deleteUserSuccess(action.userId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* changeCompanySaga(action)
{
    yield put(changeCompanyDataStart(action.companyId, action.policyType, action.userIds));

    try {
        const response = yield tokenizedAxios(companyUpdateUrl, 'post', { user_ids: action.userIds, new_value: action.newValue, company_id: action.companyId, policy_type: action.policyType });
        //console.log("RESULT", response.data, action.userIds);
        if (response.data.result === 'ok') {
            yield put(changeCompanyDataSuccess(action.companyId, action.policyType, action.userIds, action.newValue));
        } else {
            yield put(changeCompanyDataFail(action.companyId, action.policyType, action.userIds));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
        yield put(changeCompanyDataFail(action.companyId, action.policyType, action.userIds));
    };
}
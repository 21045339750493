import React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import onlyDigits from '../../utils/onlyDigits';
import * as actions from '../../store/actions/register';
import { yupLocale } from '../../settings/localization';

import ErrorBlock from '../../components/ErrorBlock';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

Yup.setLocale(yupLocale);

const RegisterView = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isAuthenticated)
      navigate("/");
  },[props.isAuthenticated, navigate]);

  let apiErrorMessage = null;
  if (props.error) 
    apiErrorMessage = <ErrorBlock error={props.error} />

  return (
    <Page
      className={classes.root}
      title="Register"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              phone: '7',
              name: '',
              surname: '',
              password: '',
              rePassword: '',
              policy: false
            }}
            validationSchema={
              Yup.object().shape({
                email: Yup.string().email('Укажите корректный E-mail').max(255).required('Пожалуйста, укажите ваш E-mail'),
                phone: Yup.string().required('Пожалуйста, укажите ваш телефон').test('phone-match', 'Неверно указан телефон', function(value) {
                  return onlyDigits(value).length === 11;
                }),
                name: Yup.string().max(255).required('Пожалуйста, укажите ваше имя'),
                surname: Yup.string().max(255).required('Пожалуйста, укажите вашу фамилию'),
                password: Yup.string().max(255).min(6).required('Пожалуйста, укажите ваш пароль'),
                rePassword: Yup.string().max(255).required('Пожалуйста, повторите пароль').test('passwords-match', 'Пароль и подтверждение пароля должны совпадать', function(value) {
                  return this.parent.password === value;
                }),
                policy: Yup.boolean().oneOf([true], 'Вы должны согласиться с правилами работы на сервисе')
              })
            }
            onSubmit={(values) => {
              props.onRegister(values.name, values.surname, values.email, values.phone, values.password);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => { 
              
              //console.log("TOUCHED ERRORS", touched, errors);

              return (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Создание новой учётной записи
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Заполните поля ниже для создания учётной записи Profisn-a.ru
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Имя"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.surname && errors.surname)}
                  fullWidth
                  helperText={touched.surname && errors.surname}
                  label="Фамилия"
                  margin="normal"
                  name="surname"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.surname}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="E-mail"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  helperText={touched.phone && errors.phone}
                  label="Телефон"
                  margin="normal"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="phone"
                  value={values.phone}
                  variant="outlined"
                  placeholder="7 (999) 999-9999"

                  InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { 
                      mask: ['7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
                      showMask: false,
                    },
                  }}

                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Пароль"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.rePassword && errors.rePassword)}
                  fullWidth
                  helperText={touched.rePassword && errors.rePassword}
                  label="Повторите пароль"
                  margin="normal"
                  name="rePassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.rePassword}
                  variant="outlined"
                />
                <Box
                  alignItems="center"
                  display="flex"
                  ml={-1}
                >
                  <Checkbox
                    checked={values.policy}
                    name="policy"
                    onChange={handleChange}
                  />
                  <Typography
                    color="textSecondary"
                    variant="body1"
                  >
                    Я прочитал и согласен
                    {' '}
                    <Link
                      color="primary"
                      component={RouterLink}
                      to="#"
                      underline="always"
                      variant="h6"
                    >
                      с условиями работы
                    </Link>
                  </Typography>
                </Box>
                {Boolean(touched.policy && errors.policy) && (
                  <FormHelperText error>
                    {errors.policy}
                  </FormHelperText>
                )}
                <Box my={2}>
                  {apiErrorMessage}
                  <Button
                    color="primary"
                    disabled={props.loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Зарегистрироваться
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Уже есть учётная запись?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/app/login"
                    variant="h6"
                  >
                    Перейти к странице входа
                  </Link>
                </Typography>
              </form>
            )}
          }
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      loading: state.register.loading,
      error: state.register.error,
      isAuthenticated: state.auth.token != null,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onRegister: (name, surname, email, phone, password) => dispatch(actions.register(name, surname, email, phone, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterView);
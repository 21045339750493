import React from 'react';
import {
  makeStyles,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Card,
} from '@material-ui/core';
import Spinner from '../../../components/Spinner';

const useStyles = makeStyles(() => ({
  center: {
    textAlign: "center",
  },
  right: {
    textAlign: "right",
  },
  hr: {
    marginTop: "10px",
    marginBottom: "10px",
  },
  hrTop: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  printForm: {
    padding: "20px",
  },
  marginTop: {
    marginTop: "10px",
  }
}));

const PreviewElement = React.forwardRef((props, ref) => {

  const classes = useStyles();

  const certificate = props.certificate;
  const catalogsBrands = props.catalogsBrands;
  const catalogsModels = props.catalogsModels;
  const catalogsGeneral = props.catalogsGeneral;

  let carName = "";
  if (catalogsBrands && catalogsModels) {
    const carBrandName = catalogsBrands[certificate.car_brand_id] ? catalogsBrands[certificate.car_brand_id].name : "";
    const carModelName = catalogsModels[certificate.car_brand_id][certificate.car_model_id] ? catalogsModels[certificate.car_brand_id][certificate.car_model_id].name : "";
    carName = carBrandName + " " + carModelName;
  }

  let numberType = "VIN";
  let number = certificate.car_vin_number;
  if (!number && certificate.car_body_number) {
    numberType = "Кузов";
    number = certificate.car_body_number;
  }
  if (!number && certificate.car_chass_number) {
    numberType = "Шасси";
    number = certificate.car_chass_number;
  }

  let preview = <Spinner />
  if (catalogsBrands && catalogsModels && catalogsGeneral && certificate.id) {

    let carAdditionalInfo = "";
    if (certificate.car_category_id == "C") {
      carAdditionalInfo = "<b>Максимально разрешённая масса:</b> " + certificate.car_max_weight + " тонн";
    }
    if (certificate.car_category_id == "D") {
      carAdditionalInfo = "<b>Количество мест:</b> " + certificate.car_seats;
    }

    let clientData = <>
    <Typography className={classes.hr}><big><b>{certificate.client_fio}</b></big> {certificate.client_birth_date} г.р.</Typography>
    <Typography><b>Документ:</b> {catalogsGeneral['document_types'][certificate.client_document_type].name} {certificate.client_document_series_number} выдан {certificate.client_document_date} {certificate.client_document_code} {certificate.client_document_place}</Typography>
    <Typography><b>Адрес:</b> {certificate.client_address}</Typography>
    <Typography><b>Email:</b> {certificate.client_email}</Typography>
    <Typography><b>Телефон:</b> {certificate.client_phone}</Typography>
    </>
    if (certificate.client_is_jur) {
      clientData = <>
      <Typography className={classes.hr}>{catalogsGeneral['jur_forms'][certificate.client_form].name} <big><b>{certificate.client_name}</b></big> {certificate.client_birth_date} г.р.</Typography>
      <Typography><b>ИНН:</b> {certificate.client_inn}, <b>КПП:</b> {certificate.client_kpp}, <b>ОГРН:</b> {certificate.client_ogrn}</Typography>
      <Typography><b>Документ о регистрации:</b> {certificate.client_document_series_number} выдан {certificate.client_document_date}</Typography>
      <Typography><b>Адрес:</b> {certificate.client_address}</Typography>
      <Typography><b>Email:</b> {certificate.client_email}</Typography>
      <Typography><b>Телефон:</b> {certificate.client_phone}</Typography>
      </>
    }

    let ownerData = <>
    <Typography className={classes.hr}><big><b>{certificate.owner_fio}</b></big> {certificate.owner_birth_date} г.р.</Typography>
    <Typography><b>Документ:</b> {catalogsGeneral['document_types'][certificate.owner_document_type].name} {certificate.owner_document_series_number} выдан {certificate.owner_document_date} {certificate.owner_document_code} {certificate.owner_document_place}</Typography>
    <Typography><b>Адрес:</b> {certificate.owner_address}</Typography>
    <Typography><b>Email:</b> {certificate.owner_email}</Typography>
    <Typography><b>Телефон:</b> {certificate.owner_phone}</Typography>
    </>
    if (certificate.owner_is_jur) {
      ownerData = <>
      <Typography className={classes.hr}>{catalogsGeneral['jur_forms'][certificate.owner_form].name} <big><b>{certificate.owner_name}</b></big> {certificate.owner_birth_date} г.р.</Typography>
      <Typography><b>ИНН:</b> {certificate.owner_inn}, <b>КПП:</b> {certificate.owner_kpp}, <b>ОГРН:</b> {certificate.owner_ogrn}</Typography>
      <Typography><b>Документ о регистрации:</b> {certificate.owner_document_series_number} выдан {certificate.owner_document_date}</Typography>
      <Typography><b>Адрес:</b> {certificate.owner_address}</Typography>
      <Typography><b>Email:</b> {certificate.owner_email}</Typography>
      <Typography><b>Телефон:</b> {certificate.owner_phone}</Typography>
      </>
    }

    let driversTable = <Typography><b>Неограниченное количество лиц, допущенных к управлению транспортным средством</b></Typography>;
    if (certificate.no_drivers_limit == 0) {
      driversTable = <Table border="1" size="small" className={classes.marginTop}>
        <TableHead>
          <TableRow>
            <TableCell>Лица, допущенные к управлению транспортным средством</TableCell>
            <TableCell>Документ</TableCell>
            <TableCell>Стаж от</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {certificate.drivers.map((driver) => (
            <TableRow key={driver.fio}>
              <TableCell>
                <Typography>{driver.fio} {driver.birth_date} г.р.</Typography>
                {(driver.prev_fio && driver.prev_fio.trim()) != "" ? <Typography>Предыдущее ФИО: {driver.prev_fio}</Typography> : ""}
              </TableCell>
              <TableCell>
                <Typography>{catalogsGeneral['document_types'][driver.document_type].name}{driver.country_code != "" ? (" (" + driver.country_code + ")") : ""} {driver.document_series_number} от {driver.document_from}</Typography>
                {(driver.prev_document_series_number && driver.prev_document_series_number.trim() != "") ? <Typography>Предыдущий документ: {driver.prev_document_series_number}</Typography> : ""}
              </TableCell>
              <TableCell>
                <Typography>
                  {driver.experience_from}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    }

    preview = <Card ref={ref} className={classes.printForm}>
    <Typography variant="h2" className={classes.center}>Предпросмотр полиса</Typography>
    {/*<Typography variant="h4" className={classes.center}>ABC № 1234567890</Typography>*/}
    <Typography className={classes.right}>Срок страхования с <b>{certificate.date_start}</b> на <b>{catalogsGeneral['periods_options'][certificate.season_months].name}</b></Typography>
    <hr className={classes.hrTop} />
    <Typography variant='h4'>Страхователь:</Typography>
    <hr className={classes.hr}/>
    {clientData}
    <hr className={classes.hrTop}/>
    <Typography variant='h4'>Собственник транспортного средства:</Typography>
    <hr className={classes.hr}/>
    {ownerData}
    <hr className={classes.hrTop}/>
    <Typography variant='h4'>Данные по автомобилю:</Typography>
    <hr className={classes.hr}/>
    <Typography className={classes.hr}><big><b>{carName}</b></big> {certificate.car_year} года выпуска, {catalogsGeneral['car_categories'][certificate.car_category_id].name} ({catalogsGeneral['car_types'][certificate.car_type_id].name})</Typography>
    <Typography><b>{numberType}</b> {number}</Typography>
    <Typography><b>Госномер:</b> {certificate.car_number}</Typography>
    <Typography><b>Мощность:</b> {certificate.car_power} л.с.</Typography>
    <Typography>{carAdditionalInfo}</Typography>
    <Typography><b>Использование:</b> {catalogsGeneral['car_tasks'][certificate.car_task_id].name} ({certificate.trailer ? "с прицепом" : "без прицепа"})</Typography>
    <Typography><b>Документ:</b> {catalogsGeneral['document_types'][certificate.car_document_type].name} {certificate.car_document_number} от {certificate.car_document_date}</Typography>
    {/*<hr className={classes.hrTop}/>
    <Typography variant='h4'>Договор заключен в отношении:</Typography>
    <hr className={classes.hr}/>*/}
    {driversTable}
    </Card>
  }

  return preview;
});

export default PreviewElement;
import {put} from 'redux-saga/effects';

import * as actions from '../actions/user';
import * as rateActions from '../actions/rates';
import * as usersActions from '../actions/users';

import tokenizedAxios from '../../utils/tokenizedAxios';

import { userSaveUrl, userLoadUrl, userLogUrl, changePasswordUrl } from '../../settings/api_urls';

import parseServerUser from './sagasUtils/parseServerUser';

export function* loadUserSaga(action) 
{
    if (action.userId === 0 || action.userId === 'new') { // Новый пользователь
        yield put(actions.initializeUser());
    } else {
        yield put(actions.loadUserStart(action.userId));
        try {
            const response = yield tokenizedAxios(userLoadUrl, 'get', { user_id: action.userId, combine_fields: 0 });
            if (response.data.result === 'ok') {
                const user = response.data.user;
                const finalUser = parseServerUser(user);
                yield put(actions.loadUserSuccess({
                    user: finalUser,
                }));
                yield put(rateActions.resetRates());
            } else {
                yield put(actions.loadUserFail(response.data.errorstring));
            }
        } catch (error) {
            yield put(actions.loadUserFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или введите данные по авто вручную."));
        };
    }
}

export function* saveUserSaga(action) 
{
    //console.log("SAVING");
    yield put(actions.saveUserStart());

    try {
        const dataToSend = action.data;
        dataToSend.create_linked_rate = action.createLinkedRate ? 1 : 0;
        dataToSend.new_api = 1; //New API for backend
        const response = yield tokenizedAxios(userSaveUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            const userId = response.data.id;
            const savedRateId = response.data.rate_id;
            yield put(actions.saveUserSuccess(userId, savedRateId));
            yield put(usersActions.resetUsers());
        } else {
            yield put(actions.saveUserFail(response.data.errorstring, response.data.fields));
        }

    } catch (error) {
        //console.log("ERROR", error.message);
        yield put(actions.saveUserFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", null));
    };
}

export function* loadUserLogSaga(action) 
{
    yield put(actions.loadUserLogStart(action.userId, action.page));

    const getData = {
        user_id: action.userId,
        page: action.page ?? 1,
    }

    try {
        const response = yield tokenizedAxios(userLogUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const log = response.data.log;
            const total = response.data.total;
            const pages = response.data.pages;
            yield put(actions.loadUserLogSuccess(log, total, pages));
        } else {
            yield put(actions.loadUserLogError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadUserLogError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* changePasswordSaga(action) 
{
    yield put(actions.changePasswordStart());

    try {
        const dataToSend = {};
        dataToSend.new_api = 1; //New API for backend
        dataToSend.user_id = action.userId;
        dataToSend.old_password = action.oldPassword ?? '';
        if (!isNaN(action.oldPassword)) dataToSend.code = action.oldPassword;
        dataToSend.new_password = action.newPassword;
        dataToSend.re_new_password = action.reNewPassword;
        const response = yield tokenizedAxios(changePasswordUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            yield put(actions.changePasswordSuccess());
        } else {
            yield put(actions.changePasswordFail(response.data.errorstring));
        }

    } catch (error) {
        yield put(actions.changePasswordFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку"));
    };
}
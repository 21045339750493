import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Fade,
    Grid,
    Modal,
    TextField,
    makeStyles,
    Paper,
    Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';

import Spinner from '../../../components/Spinner';

import * as actions from '../../../store/actions';

import { certificateDocumentUrl } from '../../../settings/api_urls';
import changeDocumentName from '../../../utils/changeDocumentName';


const useStyles = makeStyles(() => ({
    root: {},
    certificateBox: {
        marginTop: "20px",
        width: "100%"
    },
    marginBottom: {
        marginBottom: "10px"
    },
    documentButton: {
        width: "250px"
    },
    marginLeft: {
        marginLeft: "10px"
    },
    center: {
        textAlign: "center",
    },
    saveSpinner: {
        marginRight: "10px"
    },
    error: {
        color: "#f44336"
    },
    finishCard: {
        width: "400px",
        textAlign: "center",
    },
    finishButton: {
        width: "210px",
    },
    companyLogo: {
        height: '50px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

// certificateId - айди полиса по которому печатаем. Может быть передано через props или получено из redux
const CertificateDocuments = ({isActive, onLoadDocuments, certificateId, documents, isDocumentsLoading, isLoaded, error, ...props}) => {

    const classes = useStyles();

    useEffect(() => {
        //console.log("LOADING DOCUMENTS", isLoaded);
        if (!isLoaded) {
            onLoadDocuments(certificateId);
        }
    }, [certificateId, onLoadDocuments, isLoaded]);

    const printDocument = (document) => {
        if (document.link) {
            window.open(document.link, '_blank');
        } else {
            const token = localStorage.getItem('token');
            window.open('/certificate/document?certificate_id='+certificateId+'&document_id='+document.id+'&token='+token, '_blank');
        }
    }

    let documentButtonsBlock = null;
    if (isDocumentsLoading) documentButtonsBlock = <Grid item xs={12} lg={12} md={12}><Spinner text="Загружаем доступные документы"></Spinner></Grid>;
    else if (error !== null) documentButtonsBlock = <Grid item xs={12} lg={12} md={12}><Alert severity="error">{error}</Alert></Grid>;
    else if (documents !== null) {
        if (documents.length === 0) documentButtonsBlock = <Alert severity="info">Нет доступных для печати документов</Alert>;
        else {
            documentButtonsBlock = [];
            for (const i in documents) {
                const doc = documents[i];
                documentButtonsBlock.push(<Grid key={i} item xs={12} lg={12} md={12}><Button size="small" className={classes.documentButton} variant="contained" color="primary" onClick={() => printDocument(doc)}>{ changeDocumentName(doc.name) }</Button></Grid>)
            }
        }
    }

    if (!isActive) return null;
    else return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        onClose={() => props.onCloseWindow()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
    >
        <Fade in={true}>
        <Card className={classes.root} >
        <CardContent className={classes.finishCard}>
            <Box alignItems="center" display="flex" flexDirection="column" >
            <Grid container spacing={1} >
                <Grid item xs={12} lg={12} md={12}>
                <Typography color="textPrimary" gutterBottom variant="h3" >
                    Печать документов полиса #{certificateId}
                </Typography>
                </Grid>
                {documentButtonsBlock}
                <Grid item xs={12} lg={12} md={12}><Button className={classes.documentButton} variant="contained" color="default" onClick={() => props.onCloseWindow()}>Закрыть окно</Button></Grid>
            </Grid>
            </Box>
        </CardContent>
        </Card>
        </Fade>
    </Modal>

}


const mapStateToProps = state => {
    return {
        isActive: state.documents.isActive,  
        documents: state.documents.documents,
        isDocumentsLoading: state.documents.isLoading,
        error: state.documents.error,
        isLoaded: state.documents.isLoaded,
        isDirect: state.documents.direct,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onLoadDocuments: (certificateId) => dispatch(actions.loadDocuments(certificateId)),
        //onPrintDocument: (certificateId, documentId) => dispatch(actions.loadDocument(certificateId, documentId)),
        onCloseWindow: () => dispatch(actions.closeDocumentsWindow()),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(CertificateDocuments);
import React, { useState } from 'react';
import moment from 'moment';

import {
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import createMenuItems from '../../../utils/createMenuItems';
import makeFirstLetterCapital from '../../../utils/makeFirstLetterCapital'
import getFormError from '../../../utils/getFormError'
import createMaskFromString from '../../../utils/createMaskFromString'

import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import ElkDadataFio from '../../../components/FormElements/ElkDadataFio';
import ElkDateSelector from '../../../components/FormElements/ElkDateSelector';
import ElkSelectInput from '../../../components/FormElements/ElkSelectInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  right: {
    textAlign: "right",
  }
}));

const CertificateFormPeriod = (props) => {

  const classes = useStyles();

  const periodMonths = {
    3: {id:3, name:"3 месяца"},
    4: {id:4, name:"4 месяца"},
    5: {id:5, name:"5 месяцев"},
    6: {id:6, name:"6 месяцев"},
    7: {id:7, name:"7 месяцев"},
    8: {id:8, name:"8 месяцев"},
    9: {id:9, name:"9 месяцев"},
    10: {id:10, name:"10 месяцев"},
    11: {id:11, name:"11 месяцев"},
    12: {id:12, name:"12 месяцев"},
  }
  
  let periodsOptionsList = [];
  periodsOptionsList = createMenuItems(periodMonths);

  let periodText = "Новый период";
  if (props.period.start !== "" && props.period.months > 0)
    periodText = <Typography>Период от <b>{props.period.start}</b> до <b>{moment(props.period.start,'DD.MM.YYYY').add(props.period.months,'M').format('DD.MM.YYYY')}</b></Typography>;

  return ( 
    <Grid item lg={12} md={12} xs={12} >
      <Paper elevation={5} className={classes.paper} style={{width:"100%"}}>
        <Grid container spacing={2} style={{width:"100%"}}>

          <Grid item lg={9} md={8} xs={5} >
            <Typography variant="h5" gutterBottom>{periodText}</Typography>
          </Grid>
          <Grid item lg={3} md={4} xs={7} className={classes.right} >
            <Typography gutterBottom><Button startIcon={<DeleteIcon />} onClick={() => props.removePeriod(props.periodId)}>Удалить</Button></Typography>
          </Grid>

          <ElkDateSelector 
            {...getFormError(["start" ], props.touched, props.errors, props.periodErrors)}
            label="Начало периода" 
            name={"start_"+props.periodId}
            onValueChange={(newValue) => { props.changePeriodData(props.periodId, { start: newValue }) }} 
            value={props.period.start}
            setFieldValue={props.setFieldValue}
          />

          <ElkSelectInput 
            {...getFormError(["months"], props.touched, props.errors, props.periodErrors)}
            label="Кол-во месяцев"
            name={"months_"+props.periodId}
            onValueChange={(newValue) => { props.changePeriodData(props.periodId, { months: newValue }) }} 
            setFieldValue={props.setFieldValue}
            value={props.period.months}
            options={ periodsOptionsList }
          /> 

        </Grid>
      </Paper> 
    </Grid>
  );
};


export default CertificateFormPeriod;

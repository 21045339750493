import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    Fade,
    FormControlLabel,
    Grid,
    Modal,
    TextField,
    makeStyles,
    MenuItem,
    Switch,
    Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import Spinner from '../../../components/Spinner';

import * as actions from '../../../store/actions';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    root: {},
    certificateBox: {
        marginTop: "20px",
        width: "100%"
    },
    marginBottom: {
        marginBottom: "10px"
    },
    documentButton: {
        width: "250px"
    },
    marginLeft: {
        marginLeft: "10px"
    },
    center: {
        textAlign: "center",
    },
    saveSpinner: {
        marginRight: "10px"
    },
    error: {
        color: "#f44336"
    },
    finishCard: {
        width: "400px",
        textAlign: "center",
    },
    finishButton: {
        width: "210px",
    },
    companyLogo: {
        height: '50px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

const ReportsWindow = ({onLoadReports, certificateId, reports, isReportsLoading, isLoaded, error, ...props}) => {

    const classes = useStyles();
    const [selectedReport, setSelectedReport] = useState(0);
    const [newReportType, setNewReportType] = useState( props.curUser.access >= 50 ? 0 : 1 );

    const getNameByReportType = (reportType) => {
        return (reportType === 0 ? "Общий отчёт" : "[Админ] Отчёт " + props.curUser.name_f + " " + props.curUser.name_i) + ' от ' + moment().format("DD.MM.YYYY");
    }

    const [newReportName, setNewReportName] = useState( getNameByReportType(newReportType) );

    useEffect(() => {
        if (!isLoaded) {
            onLoadReports(certificateId);
        }
    }, [certificateId, onLoadReports, isLoaded]);

    const changeReportType = (newReportType) => {
        setNewReportType(newReportType);
        setNewReportName(getNameByReportType(newReportType) );
    }

    /*const printDocument = (document) => {
        if (document.link) {
            window.open(document.link, '_blank');
        } else {
            //alert("Printing " + document.id);
            const token = localStorage.getItem('token');
            window.open('/certificate/document?certificate_id='+certificateId+'&document_id='+document.id+'&token='+token, '_blank');
        }
    }*/

    let reportChoseBlock = null;
    if (isReportsLoading) reportChoseBlock = <Grid item xs={12} lg={12} md={12}><Spinner text="Загружаем доступные отчёты"></Spinner></Grid>;
    else if (error !== null) reportChoseBlock = <Grid item xs={12} lg={12} md={12}><Alert severity="error">{error}</Alert></Grid>;
    else if (reports !== null) {
        const reportsOptions = [<MenuItem key={0} value={0} >Создать новый отчёт</MenuItem>];
        for (const i in reports) {
            const doc = reports[i];
            reportsOptions.push(<MenuItem key={doc.id} value={doc.id} >{doc.name}</MenuItem>);
        }        
        reportChoseBlock = <TextField
            fullWidth
            select
            //name={props.name}
            variant="outlined"
            label="Выберите отчёт куда поместить полисы"
            SelectProps={{
                multiple: false,
                value: selectedReport ?? 0,
                onChange: (event, index, values) => { setSelectedReport(event.target.value); }
            }}
        >
        {reportsOptions}
        </TextField> 
    }

    let agentCheckbox = null;
    if (props.curUser.access >= 50) agentCheckbox = <Grid item xs={12} lg={12} md={12}>
        <FormControlLabel
            style={{marginLeft:0}}
            control={<Switch checked={newReportType === 1} onChange={(event, newValue) => { changeReportType(newValue ? 1 : 0) }} name="new_report_type" />}
            label="Отчёт от лица агента"
        />
    </Grid>;

    let nameEnterBlock = null;
    if (selectedReport === 0) 
        nameEnterBlock = <>
        <Grid item xs={12} lg={12} md={12}>
            <TextField
                fullWidth
                type="text"
                label="Название нового отчёта"
                name="new_report_name"
                onChange={(event) => { setNewReportName(event.target.value) }}
                value={newReportName}
                variant="outlined"
            />
        </Grid>
        {agentCheckbox}
        </>

    //if (!isActive) return null;
    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={true}
        onClose={() => props.onCloseWindow()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
        timeout: 500,
        }}
    >
        <Fade in={true}>
        <Card className={classes.root} >
        <CardContent className={classes.finishCard}>
            <Box alignItems="center" display="flex" flexDirection="column" >
            <Grid container spacing={1} >
                <Grid item xs={12} lg={12} md={12}>
                    <Typography color="textPrimary" gutterBottom variant="h3" >
                        Добавление полисов в отчёт
                    </Typography>
                    <Typography>Полисы для добавления: {props.certificateIds.join(', ')}</Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12}>{reportChoseBlock}</Grid>
                {nameEnterBlock}
                <Grid item xs={12} lg={12} md={12}><Button className={classes.documentButton} variant="contained" color="primary" onClick={() => props.onAddCertificates(props.certificateIds, selectedReport, newReportName, newReportType, true)}>Добавить и перейти в отчёт</Button></Grid>
                <Grid item xs={12} lg={12} md={12}><Button className={classes.documentButton} variant="contained" color="primary" onClick={() => props.onAddCertificates(props.certificateIds, selectedReport, newReportName, newReportType, false)}>Добавить и вернуться в полисы</Button></Grid>
                <Grid item xs={12} lg={12} md={12}><Button className={classes.documentButton} variant="contained" color="default" onClick={() => props.onCloseWindow()}>Отмена</Button></Grid>
            </Grid>
            </Box>
        </CardContent>
        </Card>
        </Fade>
    </Modal>

}


const mapStateToProps = state => {
    return {
        //isActive: state.reports.isActive,  
        reports: state.reports.reports,
        isReportsLoading: state.reports.isLoading,
        error: state.reports.error,
        isLoaded: state.reports.isLoaded,

        curUser: state.auth.user,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onLoadReports: () => dispatch(actions.loadReports({}, 1, 100, {'created_at':'desc'})),
        onAddCertificates: (certificateIds, reportId, newReportName, newReportType, goToReportId) => dispatch(actions.addReport(certificateIds, reportId, newReportName, newReportType, goToReportId)),
        onCloseWindow: () => dispatch(actions.closeReportsWindow()),
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ReportsWindow);
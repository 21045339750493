import {put} from 'redux-saga/effects';

import * as actions from '../actions/new';
import * as newsActions from '../actions/news';

import tokenizedAxios from '../../utils/tokenizedAxios';

import { newsSaveUrl, newsLoadUrl } from '../../settings/api_urls';

export function* loadNewsElementSaga(action) 
{
    if (action.newsId === 0 || action.newsId === 'new') { // Новая новость
        yield put(actions.initializeNews());
    } else {
        yield put(actions.loadNewsStart(action.newsId));
        try {
            const response = yield tokenizedAxios(newsLoadUrl, 'get', { news_id: action.newsId, combine_fields: 0 });
            if (response.data.result === 'ok') {
                const news = response.data.news;
                yield put(actions.loadNewsSuccess({news: news }));
            } else {
                yield put(actions.loadNewsFail(response.data.errorstring));
            }
        } catch (error) {
            yield put(actions.loadNewsFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз."));
        };
    }
}

export function* saveNewsSaga(action) 
{
    yield put(actions.saveNewsStart());

    try {
        const dataToSend = action.data;
        dataToSend.new_api = 1; //New API for backend
        dataToSend.news_id = action.data.id;
        const response = yield tokenizedAxios(newsSaveUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            const newsId = response.data.id;
            yield put(actions.saveNewsSuccess({
                id: newsId,
            }));
            yield put(newsActions.resetNews());
        } else {
            yield put(actions.saveNewsFail(response.data.errorstring, response.data.fields));
        }

    } catch (error) {
        yield put(actions.saveNewsFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", null));
    };
}
//Прошитое скрытие 
export default function isCompanyVisible(user, companyId, type = null) {

    if (!user) return false;
    if (user && user.access > 50) return true; //Админ всё видит

    const accessData = user.data_company;
    //console.log("ACCESS DATA", user);
    if (accessData[companyId]) {
      const companyInfo = accessData[companyId];
      if (type === null) return companyInfo['bso']*1 + companyInfo['eosago']*1 + companyInfo['ebso']*1 > 0;
      else return companyInfo[type]*1 === 1;
    }

    return false;
}
import {put} from 'redux-saga/effects';
import {delay} from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/auth';
import { apiUrl } from '../../settings/base';
import { loginUrl, loginCheckUrl } from '../../settings/api_urls';

export function* logoutSaga(action) 
{
    yield localStorage.removeItem('token');
    yield localStorage.removeItem('user');

    yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action)
{
    yield delay(action.expirationTime * 1000);
    yield put(actions.logout());
}

export function* authUserSaga(action)
{
    yield put(actions.authStart());

    const authData = {
        email: action.email,
        password: action.password,
        returnSecureToken: true
    }

    try {
        const response = yield axios.post(apiUrl + loginUrl,authData);
        if (response.data.result === 'ok') {
            yield localStorage.setItem('token', response.data.token);
            yield localStorage.setItem('user', JSON.stringify(response.data.user));
            yield put(actions.authSuccess(response.data));
        } else {
            yield put(actions.authError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.authError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* authCheckStateSaga(action)
{
    const token = yield localStorage.getItem('token');
    if (token) {
        try {
            const response = yield axios.post(apiUrl + loginCheckUrl, {token: token});
            if (response.data.result === 'ok') {
                yield put(actions.authSuccess({token: token, user: response.data.user}));
            } else {
                yield put(actions.logout());
            }
        } catch {
            yield put(actions.logout());
        }
    } else {
        yield put(actions.logout());
    }
}
export const loginUrl = '/login';
export const loginCheckUrl = '/login/check';
export const registerUrl = '/register';
export const resetUrl = '/reset';

export const certificatesUrl = '/certificate';
export const certificatesDeleteUrl = '/certificate/delete';
export const certificatesCancelUrl = '/certificate/cancel';
export const certificatesProlongUrl = '/certificate/prolong';

export const carSearchUrl = '/catalog/search-car';
export const certificateSaveUrl = '/certificate/one';
export const certificateLoadUrl = '/certificate/one';
export const certificateDocumentsUrl = '/certificate/documents';
export const certificateDocumentUrl = '/certificate/document';

export const usersUrl = '/user';
export const usersAutocompleteUrl = '/user/autocomplete';
export const usersDeleteUrl = '/user/delete';
export const userSaveUrl = '/user/one';
export const userLoadUrl = '/user/one';
export const userLogUrl = '/user/history';
export const changePasswordUrl = '/user/change-password';
export const companyUpdateUrl = '/user/update-company-data';

export const ratesUrl = '/rate';
export const ratesDeleteUrl = '/rate/delete';
export const rateSaveUrl = '/rate/one';
export const rateLoadUrl = '/rate/one';
export const rateInfoUrl = '/rate/info';

export const newsUrl = '/news';
export const newsDeleteUrl = '/news/delete';
export const newsSaveUrl = '/news/one';
export const newsLoadUrl = '/news/one';

export const dialogsUrl = '/messages/dialogs';
export const messagesUrl = '/messages';
export const messagesDeleteUrl = '/messages/delete';
export const messageSaveUrl = '/messages/save';
export const newMessagesUrl = '/messages/new';

export const reportsUrl = '/report';
export const reportsDeleteUrl = '/report/delete';
export const reportSaveUrl = '/report/one';
export const reportLoadUrl = '/report/one';
export const reportDownloadUrl = '/report/download';
export const newReportsUrl = '/report/new';
export const deleteCertificateFromReportUrl = '/report/delete-certificate';
export const changeCertificateStatusUrl = '/report/change-status';

export const fileUploadUrl = '/file/upload-picture';

export const certificateCalculateUrl = '/certificate/calculate';
export const certificateProcessUrl = '/certificate/process';
export const certificateFinishUrl = '/certificate/finish';
export const certificateProlongUrl = '/certificate/prolong-initiate';

export const countersUrl = '/dashboard/counters';
export const prolongationCertificatesUrl = '/dashboard/certificates-for-prolongation';
export const newCertificatesUrl = '/dashboard/new-certificates';
export const createdCertificatesUrl = '/dashboard/created-certificates';
export const statisticsUrl = '/dashboard/statistics';

export const catalogUrls = {
    'general': '/catalog/get-dictionaries',
    'companies': '/catalog/company',
    'excompanies': '/catalog/excompany',
    'regions': '/catalog/region',
    'brands': '/catalog/brand',
    'models': '/catalog/model',
    'countries': '/catalog/country',
};
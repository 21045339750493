
import * as Yup from 'yup';

const oneOfValidation = (list, required=true, field='id', errorText="Выберите значение из списка") => {

    var checker = Yup.string().nullable();

    if (required) checker = checker.required("Поле обязательно для заполнения");

    checker = checker.test('oneOf', errorText, function(value) {
        //console.log("CHECKING", value, list, errorText);
        for (const i in list) {
            if (typeof list[i][field] === 'undefined') console.log("UNDEFINED CHECKING", value, list, field, errorText);
            if (list[i][field].toString() === value ? value.toString() : '') return true;
        }
        return false;
    });

    return checker;
}

export default oneOfValidation;
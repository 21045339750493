import React from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import UserForm from '../user/UserForm/UserForm';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Profile = (props) => {
  const classes = useStyles();

  const userId = props.curUser ? props.curUser.id : "new";

  return (
    <Page
      className={classes.root}
      title="Управление пользователем"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <UserForm userId={userId} />
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    curUser: state.auth.user,
  }
}

export default connect(mapStateToProps)(Profile);

import React from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import Alert from '@material-ui/lab/Alert';

import ErrorBlock from '../../components/ErrorBlock';

import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResetView = (props) => {

  //console.log("CURRENT URL", window.location.hostname);

  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isAuthenticated)
      navigate("/");
  },[props.isAuthenticated, navigate]);

  let apiErrorMessage = null;
  if (props.error) 
    apiErrorMessage = <Box mb={3}><Typography color="error" variant="body1" >{props.error}</Typography></Box>

  const generalBlock = <Grid container>
    <Grid item lg={6} md={6} xs={12}>
      <Typography color="textSecondary" variant="body1" >
        Нет аккаунта?
        {' '}
        <Link component={RouterLink} to="/app/register" variant="h6" >
          Зарегистрируйтесь!
        </Link>
      </Typography>
    </Grid>
    <Grid item lg={6} md={6} xs={12}>
      <Typography color="textSecondary" variant="body1" align="right" >
        <Link component={RouterLink} to="/app/login" variant="h6" >
          Назад к вводу пароля
        </Link>
      </Typography>
    </Grid>
  </Grid>

  let form = null;
  if (props.successUserId) {

    form = form = <Formik
      initialValues={{
        code: '',
        password: '',
        rePassword: ''
      }}
      validationSchema={Yup.object().shape({
        code: Yup.string().max(255).required('Пожалуйста, укажите ваш код из письма'),
        password: Yup.string().max(255).min(6).required('Пожалуйста, укажите новый пароль'),
        rePassword: Yup.string().max(255).required('Пожалуйста, повторите пароль').test('passwords-match', 'Пароль и подтверждение пароля должны совпадать', function(value) {
          return this.parent.password === value;
        }),
      })}
      onSubmit={(values) => {
        props.onChangePassword(props.successUserId, values.code, values.password, values.rePassword);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => { 
        
        let successBlock = null;
    if (props.passwordChangeSuccess) {
      successBlock = <Box mb={3}><Alert severity="success">Пароль успешно изменён! Вы можете войти с ним на сайте!</Alert></Box>
    } else {
      successBlock = <>
          <Box mb={1}>
            <Typography color="textPrimary" >
              На указанный вами E-mail было отправлено письмо с кодом для восстановления пароля. Укажите этот код в поле ниже а также новый пароль.
            </Typography>
          </Box>
          <TextField
            error={Boolean(touched.code && errors.code)}
            fullWidth
            helperText={touched.code && errors.code}
            label="Код из письма"
            margin="normal"
            name="code"
            onBlur={handleBlur}
            onChange={handleChange}
            type="text"
            value={values.code}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Пароль"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.rePassword && errors.rePassword)}
            fullWidth
            helperText={touched.rePassword && errors.rePassword}
            label="Повторите пароль"
            margin="normal"
            name="rePassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.rePassword}
            variant="outlined"
          />
          
          <Box my={2}>
            {apiErrorMessage}
            <Button
              color="primary"
              disabled={props.loading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {props.loading ? "Изменяю..." : "Сменить пароль"}
            </Button>
          </Box>
        </>
    }
        
        return (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography color="textPrimary" variant="h2" >
              Ввод нового пароля
            </Typography>
          </Box>
          {successBlock}
          {generalBlock}
        </form>
      )}}
    </Formik>

  } else {

    form = <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email('Укажите корректный E-mail').max(255).required('Пожалуйста, укажите ваш E-mail'),
      })}
      onSubmit={(values) => {
        props.onReset(values.email);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box mb={3}>
            <Typography
              color="textPrimary"
              variant="h2"
            >
              Сброс пароля
            </Typography>
          </Box>
          <Box mb={1}>
            <Typography
              color="textPrimary"
              //variant="p"
            >
              Укажите E-mail вашего аккаунта - на него придёт информация о смене пароля
            </Typography>
          </Box>
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="E-mail"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          
          <Box my={2}>
            {apiErrorMessage}
            <Button
              color="primary"
              disabled={props.loading}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {props.loading ? "Отправляю письмо..." : "Далее"}
            </Button>
          </Box>
          {generalBlock}
        </form>
      )}
    </Formik>

  }

  return (
    <Page
      className={classes.root}
      title="Reset"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          {form}
        </Container>
      </Box>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      loading: state.reset.loading,
      error: state.reset.error || state.user.passwordChangeError,
      successUserId: state.reset.success,
      passwordChangeSuccess: state.user.passwordChangeSuccess,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onReset: (email) => dispatch(actions.reset(email)),
      onChangePassword: (userId, code, password,rePassword) => dispatch(actions.changePassword(userId, code, password,rePassword)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetView);
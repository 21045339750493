import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import ErrorBlock from '../../components/ErrorBlock';
import Spinner from '../../components/Spinner';
import roubles from '../../utils/roubles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    fontFamily: "Arial"
  },
  avatar: {
    backgroundColor: colors.indigo[600],
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.green[900]
  },
  differenceValue: {
    color: colors.green[900],
    marginRight: theme.spacing(1)
  }
}));

const TotalComission = ({ className, ...rest }) => {
  const classes = useStyles();

  let monthlyCountersBlock = <Spinner />;
  if (rest.error) {
    monthlyCountersBlock = <ErrorBlock error={rest.error} />
  } else if (rest.monthly != null) {
    monthlyCountersBlock = roubles(rest.monthly);
  }

  let totalCountersBlock = null;
  if (rest.error) {
    totalCountersBlock = <ErrorBlock error={rest.error} />
  } else if (rest.total != null) {
    totalCountersBlock = roubles(rest.total);
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              Комиссия
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
            >
              {monthlyCountersBlock}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        {/* <Box
          mt={2}
          display="flex"
          alignItems="center"
        >
          <Typography color="textSecondary">{totalCountersBlock} за всё время</Typography>
        </Box> */}
      </CardContent>
    </Card>
  );
};

TotalComission.propTypes = {
  className: PropTypes.string
};

export default TotalComission;

import {put} from 'redux-saga/effects';

import * as actions from '../actions/calculations';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { certificateCalculateUrl, certificateProcessUrl, certificateFinishUrl } from '../../settings/api_urls';

export function* calculateCertificateSaga(action) 
{
    yield put(actions.calculateCertificateStart(action.certificateId, action.companyId));

    const data = {
        certificate_id: action.certificateId,
        company_id: action.companyId,
        fixed_model_id: action.fixedModelId,
    }

    try {
        const response = yield tokenizedAxios(certificateCalculateUrl, 'post', data);
        if (response.data.result === 'ok') {
            const result = response.data;
            yield put(actions.calculateCertificateSuccess(action.companyId, result));
        } else {
            //console.log("FAIL RES", response.data);
            yield put(actions.calculateCertificateFail(action.companyId, response.data.errorstring, response.data.mode, response.data.errors ?? false, response.data.models ?? null));
        }
    } catch (error) {
        yield put(actions.calculateCertificateFail(action.companyId, "Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* processCertificateSaga(action) 
{
    yield put(actions.processCertificateStart(action.certificateId, action.companyId, action.typeId, action.bsoNumber, action.bsoCheque));

    const data = {
        certificate_id: action.certificateId,
        company_id: action.companyId,
        mode: action.typeId,
        bso_number: action.bsoNumber,
        bso_document: action.bsoCheque,
        calc_data: action.calcData,
    }

    try {
        const response = yield tokenizedAxios(certificateProcessUrl, 'post', data);
        if (response.data.result === 'ok') {
            yield put(actions.processCertificateSuccess(action.companyId, response.data));
        } else {
            //console.log("FAIL RES", response.data);
            yield put(actions.processCertificateFail(action.companyId, response.data.errorstring, response.data.errors ?? false));
        }
    } catch (error) {
        yield put(actions.processCertificateFail(action.companyId, "Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку.", null));
    };
}

export function* finishCertificateSaga(action) 
{
    yield put(actions.finishCertificateStart(action.certificateId));

    const data = {
        certificate_id: action.certificateId,
        company_id: action.companyId,
        calc_data: action.calcData,
    }

    try {
        const response = yield tokenizedAxios(certificateFinishUrl, 'post', data);
        if (response.data.result === 'ok') {
            yield put(actions.finishCertificateSuccess(action.companyId, response.data));
        } else {
            //console.log("FAIL RES", response.data);
            yield put(actions.finishCertificateFail(action.companyId, response.data.errorstring, response.data.errors ?? false));
        }
    } catch (error) {
        yield put(actions.finishCertificateFail(action.companyId, "Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку.", null));
    };
}
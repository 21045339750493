import * as actionTypes from '../actions/actionTypes';

import { updateState } from '../../shared/utility';

const initialState = {
    isGeneralActive: false,
    generalErrorText: null,
    generalSuccessText: null,
    //generalMessages: [],
    time: null, // Чтобы при каждом обновлении всплывал текст
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GENERAL_START: return updateState(state, {isGeneralActive:  true, generalErrorText: null, generalSuccessText: null});
        case actionTypes.GENERAL_SUCCESS: return updateState(state, {isGeneralActive:  false, generalSuccessText: action.text, generalErrorText: null, time: Date.now()});
        case actionTypes.GENERAL_ERROR: return updateState(state, {isGeneralActive: false, generalErrorText: action.error, generalSuccessText: null, time: Date.now()});

        /*case actionTypes.GENERAL_START: return updateState(state, {isGeneralActive:  true});
        case actionTypes.GENERAL_SUCCESS: return updateState(state, {isGeneralActive:  false, generalMessages: addGeneralMessage(state, "success", action.text)});
        case actionTypes.GENERAL_ERROR: return updateState(state, {isGeneralActive: false, generalMessages: addGeneralMessage(state, "error", action.error)});*/

        default: return state;
    }
}

/*const addGeneralMessage = (state, type, message) => {
    const newGeneralMessages = [];
    for (const i in state.generalMessages)
        if (state.generalMessages[i].time + 3000 >= Date.now())
            newGeneralMessages.push(state.generalMessages[i]);
    newGeneralMessages.push({type:type, message:message, time: Date.now()});
    return newGeneralMessages;
}*/

export default reducer;
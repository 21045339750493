import {
    Chip,
    ListItem,
    ListItemText,
  } from '@material-ui/core';

const DialogElement = (props) => {

  const dialog = props.dialog;

  let text = dialog.text;
  if (text.length > 197) {
    text = text.substring(0,197) + "...";
  }

  let dialogUser;
  if (dialog.user) dialogUser = dialog.user;
  else dialogUser = dialog.receiver;

  let badge = null;
  if (dialog.new && dialog.new > 0) badge = <Chip
    label={dialog.new + " новых"}
    clickable
    color="primary"
    size="small"
  />;

  return <ListItem
    divider={props.divider ?? false}
    onClick={()=>props.onClickHandler(dialogUser.id)}
    style={{cursor:"pointer"}}
  >
    <ListItemText
      primary={<>
        {dialogUser ? (dialogUser.name_f + " " + dialogUser.name_i + " (id"+dialogUser.id+")") : "Пользователь не найден"}&nbsp;&nbsp;{badge}
      </>}
      secondary={text}
    />
    {/*<IconButton
      edge="end"
      size="small"
    >
      <MoreVertIcon />
    </IconButton>*/}
  </ListItem>
}

export default DialogElement;
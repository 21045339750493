import {put} from 'redux-saga/effects';

import * as actions from '../actions/documents';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { certificateDocumentsUrl } from '../../settings/api_urls';

export function* loadDocumentsSaga(action) 
{
    yield put(actions.loadStart(action.certificateId));

    try {
        const response = yield tokenizedAxios(certificateDocumentsUrl, 'get', { certificate_id: action.certificateId });
        if (response.data.result === 'ok') {
            const documents = response.data.documents;
            yield put(actions.loadSuccess(documents));
        } else {
            yield put(actions.loadError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyArray from '../../utils/copyArray';
import copyObject from '../../utils/copyObject';

const initialState = {
    isLoading: false,
    messages: null,
    total: null,
    error: null,

    userId: 0,
    page: 1,
    perPage: 50,

    isSaving: false, 
    saveError: null,
    savedId: null,

    massMessageIds: {},

}

const reducer = (state = initialState, action) => {

    let newMassMessageIds;

    switch (action.type) {

        case actionTypes.MESSAGES_RESET:
        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.MESSAGES_LOAD_START:
            const finalNewState = {isLoading: true, messages: state.messages};
            if (action.userId !== null) finalNewState.userId = action.userId;
            if (action.page !== null) finalNewState.page = action.page;
            if (action.perPage !== null) finalNewState.perPage = action.perPage;
            return updateState(state, finalNewState);

        case actionTypes.MESSAGES_LOAD_SUCCESS: 
            let newMessages;
            if (state.messages && state.page > 1) newMessages = copyArray(state.messages);
            else newMessages = [];
            for (const i in action.messages)
                newMessages.push(action.messages[i]);        
        return updateState(state, {isLoading: false, messages: newMessages, total: action.total * 1});

        case actionTypes.MESSAGES_LOAD_FAIL:    return updateState(state, {isLoading: false, messages: null, total: null, error: action.error });

        case actionTypes.MESSAGES_DELETE_SUCCESS: 
            const newData = [];
            for (const i in state.messages)
                if (state.messages[i].id !== action.messageId)
                newData.push(state.messages[i]);

            return updateState(state, {isLoading: false, messages: newData, total: state.total - 1});

        case actionTypes.MESSAGES_SAVE_START: return updateState(state, {isSaving: true, saveError: null});
        case actionTypes.MESSAGES_SAVE_SUCCESS: return updateState(state, {isSaving: false, savedId: true, massMessageIds: {}, messages: null, total: null, error: null });
        case actionTypes.MESSAGES_SAVE_FAIL: return updateState(state, {isSaving: false, saveError: action.error });
    
        case actionTypes.MESSAGES_MASS_ADD: 
            newMassMessageIds = copyObject(state.massMessageIds);
            for (const i in action.userData)
                newMassMessageIds[i] = action.userData[i];
            return updateState(state, {massMessageIds: newMassMessageIds, savedId: null, error: null}); 

        case actionTypes.MESSAGES_MASS_REMOVE: 
            newMassMessageIds = copyObject(state.massMessageIds);
            action.userIds.forEach((item, index) => {
                if (newMassMessageIds[item]) delete(newMassMessageIds[item]);
            });
            return updateState(state, {massMessageIds: newMassMessageIds}); 

        default: return state;
    }
}

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';

const initialState = {
    isLoading: false,
    rates: null,
    total: null,
    error: null,

    filter: {},
    page: 1,
    perPage: 1000,
    order: {id: 'DESC'},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.RATES_RESET:
        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.RATES_LOAD_START:   
            const finalNewState = {isLoading: true};
            if (action.filter !== null) finalNewState.filter = action.filter;
            if (action.page !== null) finalNewState.page = action.page;
            if (action.perPage !== null) finalNewState.perPage = action.perPage;
            if (action.order !== null) finalNewState.order = action.order;
            return updateState(state, finalNewState);

        case actionTypes.RATES_LOAD_SUCCESS: return updateState(state, {isLoading: false, rates: action.rates, total: action.total * 1});
        case actionTypes.RATES_LOAD_FAIL:    return updateState(state, {isLoading: false, rates: null, total: null, error: action.error });

        case actionTypes.RATES_DELETE_SUCCESS: 
            const newRates = [];
            for (const i in state.rates)
                if (state.rates[i].id !== action.rateId)
                newRates.push(state.rates[i]);

            return updateState(state, {isLoading: false, rates: newRates, total: state.total - 1});

        default: return state;
    }
}

export default reducer;
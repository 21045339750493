import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Spinner from '../../../components/Spinner';
import CertificateDocuments from '../CertificateDocuments';

import * as actions from '../../../store/actions';

import roubles from '../../../utils/roubles';
import copyObject from '../../../utils/copyObject';

import { companiesLinkedProlong } from '../../../settings/companies';

import ElkSearchInput from '../../../components/FormElements/ElkSearchInput';
import isCompanyVisible from '../../../utils/isCompanyVisible';

import FinishBlock from './FinishBlock';
import getLogoByCompanyId from '../../../utils/getCompanyLogo';

const useStyles = makeStyles(() => ({
  root: {},
  certificateBox: {
    marginTop: "20px",
    width: "100%"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  marginLeft: {
    marginLeft: "10px"
  },
  calculateBtn: {
  },
  center: {
    textAlign: "center",
  },
  error: {
    color: "#f44336"
  },
  companyLogo: {
    //height: '50px'
  },
  subText: {
    fontSize: '10px',
    color: 'gray',
    marginTop: '1px',
  },
}));

// urlCertificateId - айди сертификата в урл строке - надо убедиться что в редаксе тот же самый сертификат и перегрузить его если не он
// finishCertificateId - айди сертификата, который оформляется в данный момент
// certificate - полис, который сейчас в редаксе. его айди должен совпасть с urlCertificateId и из него берётся finishCertificateId в момент оформления полиса
const CalculateForm = ({ className, certificate, calculations, finishCertificateId, urlCertificateId, ...rest }) => {

  //console.log("CALC FORM CERTIFICATE", certificate);

  const [fixModels, setFixModels] = useState({});
  const [infoOpened, setInfoOpened] = useState({});

  const classes = useStyles();
  const navigate = useNavigate();

  const onLoadCatalog = rest.onLoadCatalog;
  const onLoadCertificate = rest.onLoadCertificate;
  const onCalculateCertificate = rest.onCalculateCertificate;
  const catalogsGeneral = rest.catalogs['general'] ?? false;
  const catalogsCompanies = rest.catalogs['companies'] ?? false;

  const calculateCertificate = useCallback((companyId, fixedModelId) => {
    if (!certificate) alert("Расчёт без загруженного полиса");
    onCalculateCertificate(certificate.id, companyId, fixedModelId);
  }, [onCalculateCertificate, certificate]);

  const finishWindow = (companyId) => {
    rest.onSetFinishInfo(companyId, null, '', '', '');
  }

  

  const returnToCertificate = () => {
    navigate("/app/certificates/" + certificate.id);
  }
  
  const checkProlongValidity = (certificate, companyId) => {
    return certificate.prolong_company_id*1 > 0 && certificate.prolong_company_id*1 !== companyId*1 
    && (!companiesLinkedProlong.includes(certificate.prolong_company_id*1) || !companiesLinkedProlong.includes(companyId*1));
  }

  useEffect(() => {
    const anchor = document.querySelector('#back-to-top-anchor');
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, []);

  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsCompanies) onLoadCatalog('companies')
    else if (!certificate.id || urlCertificateId*1 !== certificate.id*1) {
      if (!rest.isCertificateLoading) {
        onLoadCertificate(urlCertificateId);
      }
    } else if (!calculations && urlCertificateId*1 === certificate.id*1) {
      for (const companyId in catalogsCompanies) if (isCompanyVisible(rest.user, companyId)) { //TODO Костыль для скрытия сравни у неадмина
        if ( !checkProlongValidity(certificate, companyId) || certificate.client_document_place !== "")
          calculateCertificate(companyId);
      }
    }
  }, [catalogsGeneral, rest.user, onLoadCatalog, certificate, urlCertificateId, onLoadCertificate, catalogsCompanies, calculations, calculateCertificate, rest.isCertificateLoading]);

  const getPolicyTypesByCompanyId = (companyId) => {
    const platesArray = [];
    if (isCompanyVisible(rest.user, companyId, 'eosago')) platesArray.push(<div><Chip style={{marginBottom: "3px"}} label="ЕОСАГО" size="small" variant="outlined" /></div>);
    if (isCompanyVisible(rest.user, companyId, 'bso')) platesArray.push(<div><Chip style={{marginBottom: "3px"}} label="БСО" size="small" variant="outlined" /></div>);
    if (isCompanyVisible(rest.user, companyId, 'ebso')) platesArray.push(<div><Chip style={{marginBottom: "3px"}} label="ЕБСО" size="small" variant="outlined" /></div>);
    return platesArray;
  }

  const changeFixedModel = (companyId, newFixedModelId) => {
    const newFixedModels = copyObject(fixModels);
    newFixedModels[companyId] = newFixedModelId;
    setFixModels(newFixedModels);
  }

  const toggleCalcInfo = (companyId) => {
    const newInfoOpened = copyObject(infoOpened);
    if (!newInfoOpened[companyId] || newInfoOpened[companyId] === 0) newInfoOpened[companyId] = 1;
    else newInfoOpened[companyId] = 0;
    setInfoOpened(newInfoOpened);
  }

  const companiesCalculations = [];
  for (const companyId in catalogsCompanies) if (isCompanyVisible(rest.user, companyId)) { //TODO Костыль для скрытия сравни у неадмина

    const company = catalogsCompanies[companyId];

    let companyButtons = <Spinner text="Идёт расчёт премии"></Spinner>;
    if (checkProlongValidity(certificate, companyId) && certificate.client_document_place === "")
      companyButtons = <Alert severity="info">Для расчёта премии заполните все поля в форме полиса</Alert>

    const calculation = (calculations && calculations[companyId]);

    let calculationResult = {};
    if (calculation && !calculation.isLoading) {
      if (calculation.calculationError) {
        if (calculation.models) {
          companyButtons = <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12}>
              <Alert severity="info">
                {calculation.calculationError}
              </Alert>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <ElkSearchInput 
                width={12}
                label="Укажите модель авто"
                name={"fix_model_" + company.id}
                onValueChange={(newValue) => { changeFixedModel(company.id, newValue); }} 
                setFieldValue={ ()=>null }
                value={ fixModels[company.id] }
                options={ calculation.models }
              />  
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Button variant="contained" color="default" onClick={() => calculateCertificate(company.id, fixModels[company.id] ?? 0)}>Подтвердить модель и рассчитать заново</Button>
            </Grid>
          </Grid>
        } else {
          let calculationErrors = null;
          if (calculation.calculationErrors && Array.isArray(calculation.calculationErrors) && calculation.calculationErrors.length > 0) {
            calculationErrors = [];
            for (const i in calculation.calculationErrors)
              calculationErrors.push(<p key={i}>{calculation.calculationErrors[i]}</p>);
            //calculationErrors = {calculationErrors}
          }

          companyButtons = <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12}>
              <Alert severity={ calculation.calculationMode ? calculation.calculationMode : "error" }>
                {calculation.calculationError}
                {calculationErrors}
              </Alert>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Button variant="contained" color="default" onClick={() => calculateCertificate(company.id)}>Рассчитать заново</Button>
            </Grid>
          </Grid>
        }
      }
      else if (calculation.calculationResult) {
        calculationResult = calculation.calculationResult;

        const continueButtons = [];
       
        let koeffString = [];
        if (calculationResult.koeffs && Object.keys(calculationResult.koeffs).length > 0) {
          let first = true;
          let i = 0;
          if (infoOpened[companyId] && infoOpened[companyId]===1)
            for (const kf in calculationResult.koeffs) {
              i++;
              koeffString.push(<span key={i}>{first?"":", "}<b>{kf}</b>:&nbsp;{calculationResult.koeffs[kf]}</span>);
              first = false;
            }
            continueButtons.push(<Grid key={0} item><Button className={classes.calculateBtn} variant="contained" color="default" onClick={()=>{toggleCalcInfo(companyId); return false;}}>Подробнее</Button></Grid>);
        }
        if (koeffString.length > 0)
          koeffString = <Grid item lg={12} md={12} xs={12}>
            <Alert severity="info" align="left">{koeffString}</Alert>
          </Grid>

        continueButtons.push(<Grid key={1} item><Button className={classes.calculateBtn} variant="contained" color="default" onClick={() => calculateCertificate(company.id)}>Рассчитать заново</Button></Grid>);
        if(calculationResult.cost*1 === 0){
          continueButtons.push(<Grid key={2} item><Button className={classes.calculateBtn} variant="contained" disabled color="primary" onClick={() => finishWindow(company.id)}>Оформить</Button></Grid>);
        }else{
          continueButtons.push(<Grid key={2} item><Button className={classes.calculateBtn} variant="contained" color="primary" onClick={() => finishWindow(company.id)}>Оформить</Button></Grid>);
        }
        let notice=null;
        if (calculationResult.notice && calculationResult.notice !== "")
          notice = <Alert severity="warning" style={{marginTop:"5px"}}>{calculationResult.notice}</Alert>

        companyButtons = <Grid container spacing={1}>
          <Grid item lg={12} md={12} xs={12}>
            <Alert severity="success">{certificate.id + ": Расчёт произведён"}</Alert>
            {notice}
          </Grid>
          {koeffString}
          <Grid item lg={12} md={12} xs={12}>
            <Grid container spacing={1} justify="flex-end">
              {continueButtons}
            </Grid>
          </Grid>
        </Grid>
      }
    }

    let calcualtionBonusBlock = roubles(calculationResult.bonus);
    if (calculationResult.reinsurance_pool === 1) {
      calcualtionBonusBlock=<><Alert severity="warning">0&nbsp;р</Alert><div className={classes.subText}>Перестрахование</div></>
    }
    else if (calculationResult.bonus*1 === 0) calcualtionBonusBlock=<Alert severity="warning">0&nbsp;р</Alert>
    else {
      if (calculationResult.bonus) {
        const percent = Math.round((calculationResult.bonus / calculationResult.cost) * 1000);
        calcualtionBonusBlock = <><div>{calcualtionBonusBlock}</div><div className={classes.subText}>{percent / 10}%</div></>
      } else 
        calcualtionBonusBlock = null;
    }

    companiesCalculations.push(
      <TableRow key={company.id}>
        <TableCell component="th" scope="row">
          {getLogoByCompanyId(company.id)}
        </TableCell>
        <TableCell align="left">{getPolicyTypesByCompanyId(company.id)}</TableCell>
        <TableCell align="right">{roubles(calculationResult.cost)}</TableCell>
        <TableCell align="right">{calcualtionBonusBlock}</TableCell>
        <TableCell align="right">{calculationResult.KBM ?? ""}</TableCell>
        <TableCell align="right">{companyButtons}</TableCell>
      </TableRow>
    )
  }

  let documentsPopup = null;
  if (rest.isDocumentsWindowActive && certificate.id > 0) documentsPopup = <CertificateDocuments certificateId={certificate.id} />

  let trailerWarning = null;
  if (certificate.car_trailer) trailerWarning = <Alert severity="warning" style={{marginBottom:"15px"}}>Внимание! В полисе указано что авто используется с прицепом. Если это не так, вернитесь на редактирование полиса и уберите пометку "с прицепом".</Alert>

  return (
    <>
    <Box className={classes.certificateBox}>
      <Card>
        <CardHeader 
          //subheader="Каждая страховая расчитывается отдельно."
          title="Расчёт премии"
          id="back-to-top-anchor"
        />
        <Divider />
        <CardContent>
        {trailerWarning}
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Страховая компания</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">Цена</TableCell>
                <TableCell align="right">Комиссия</TableCell>
                <TableCell align="right">КБМ</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companiesCalculations}
            </TableBody>
          </Table>
        </TableContainer>
        </CardContent>
      </Card>
    </Box>
    <Box className={classes.certificateBox}>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} xs={12} className={classes.center} >
              <Button variant="contained" color="default" onClick={returnToCertificate}>Вернуться к редактированию полиса</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
    <FinishBlock
      finishWindow = {finishWindow}
      getLogoByCompanyId = {getLogoByCompanyId}
      catalogsCompanies = {catalogsCompanies}
      certificate = {certificate}
      calculations = {calculations}
      companyId = {rest.companyId}
    />
    {documentsPopup}
    </>
  );
};

const mapStateToProps = state => {
  return {
    certificate: state.certificate.certificate,
    isCertificateLoading: state.certificate.isLoading,
    error: state.certificate.error,
    
    isCatalogsLoading: state.catalogs.isLoading,
    catalogs: state.catalogs.catalogs,

    calculations: state.calculations.calculations,
    finishCertificateId: state.calculations.certificateId,

    companyId: state.calculations.companyId, 
    isDocumentsWindowActive: state.documents.isActive,

    user: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCatalog: (catalogName, subId=null) => dispatch(actions.loadCatalog(catalogName, subId)),
      onCalculateCertificate: (certificateId, companyId, fixedModelId) => dispatch(actions.calculateCertificate(certificateId, companyId, fixedModelId)),
      onLoadCertificate: (certificateId) => dispatch(actions.loadCertificate(certificateId)),
      onSetFinishInfo: (companyId, typeId, bsoNumber, bsoCheque, formError) => dispatch(actions.setFinishInfo(companyId, typeId, bsoNumber, bsoCheque, formError)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CalculateForm);

import {
    Grid,
    makeStyles,
    TextField,
  } from '@material-ui/core';
import InputMask from 'react-input-mask';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import onlyDigitsAndLetters from '../../utils/onlyDigitsAndLetters';

import { latinLettersAndDigits as nll } from '../../settings/masks';

const useStyles = makeStyles((theme) => ({
    smallTop: {marginTop: '4px'},
}));

const ProfinsCarNumber = (props) => {

    const classes = useStyles();

    let carNumberBlock = null;
    switch (props.car_number_type) {
        case 2:

            carNumberBlock = <InputMask
            //mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            value={props.car_body_number ?? ''}
            disabled={false}
            name="car_body_number"
            onBlur={props.handleBlur}
            onChange={(event) => { const newVal = onlyDigitsAndLetters(event.target.value.toUpperCase()); props.setFieldValue("car_body_number", newVal); props.onValueChange({ car_body_number: newVal })}}
            >
                <TextField
                fullWidth
                error={props.errorText ? true : false}
                helperText={props.errorText}
                type={props.type ?? 'text'}
                label="Номер кузова"
                //required={true}
                placeholder="12345678901234567"
                required={props.required}
                value={props.car_body_number ?? ''}
                variant="outlined"
                />
            </InputMask>

            break;
        case 3:

            carNumberBlock = <InputMask
            //mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            value={props.car_chass_number ?? ''}
            disabled={false}
            name="car_chass_number"
            onBlur={props.handleBlur}
            onChange={(event) => { const newVal = onlyDigitsAndLetters(event.target.value.toUpperCase()); props.setFieldValue("car_chass_number", newVal); props.onValueChange({ car_chass_number: newVal })}}
            >
                <TextField
                fullWidth
                error={props.errorText ? true : false}
                helperText={props.errorText}
                type={props.type ?? 'text'}
                label="Номер шасси"
                //required={true}
                placeholder="12345678901234567"
                required={props.required}
                value={props.car_chass_number ?? ''}
                variant="outlined"
                />
            </InputMask>

            break;
        default:

            carNumberBlock = <InputMask
            mask={[nll, nll, nll, nll, nll, nll, nll, nll, nll, nll, nll, nll, nll, nll, nll, nll, nll]}
            value={props.car_vin_number ?? ''}
            disabled={false}
            name="car_vin_number"
            onBlur={props.handleBlur}
            onChange={(event) => { props.setFieldValue("car_vin_number", event.target.value.toUpperCase()); props.onValueChange({ car_vin_number: event.target.value.toUpperCase() })}}
            >
                <TextField
                fullWidth
                error={props.errorText ? true : false}
                helperText={props.errorText}
                type={props.type ?? 'text'}
                label="VIN"
                //required={true}
                placeholder="Z94CE55DBFR367750"
                required={props.required}
                value={props.car_vin_number ?? ''}
                variant="outlined"
                />
            </InputMask>

            break;
    }

    return <Grid item lg={8} md={12} xs={12} >
            <Grid container spacing={1} >
                <Grid item lg={6} md={6} xs={12} >
                    <ToggleButtonGroup
                        value={props.car_number_type}
                        exclusive
                        className={classes.smallTop}
                        label="Идентификатор ТС"
                        onChange={(event, newValue) => { props.onValueChange({ car_number_type: newValue }) }}
                    >
                        <ToggleButton value={1}>VIN</ToggleButton>
                        <ToggleButton value={2}>Номер кузова</ToggleButton>
                        <ToggleButton value={3}>Номер шасси</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item lg={6} md={6} xs={12} >
                    {carNumberBlock}
                </Grid>
            </Grid>
        </Grid>
}

export default ProfinsCarNumber;

export const ADD_INGREDIENT = 'ADD_INGREDIENT';
export const REMOVE_INGREDIENT = 'REMOVE_INGREDIENT';
export const SET_INGREDIENTS = 'SET_INGREDIENTS';
export const FETCH_INGREDIENTS_FAILED = 'FETCH_INGREDIENTS_FAILED';
export const INIT_INGREDIENTS_START = 'INIT_INGREDIENTS_START';

export const PURCHASE_BURGER_START = 'PURCHASE_BURGER_START';
export const PURCHASE_BURGER_SUCCESS = 'PURCHASE_BURGER_SUCCESS';
export const PURCHASE_BURGER_FAIL = 'PURCHASE_BURGER_FAIL';
export const PURCHASE_START = 'PURCHASE_START';
export const PURCHASE_INIT = 'PURCHASE_INIT';

export const FETCH_ORDERS_INIT = 'FETCH_ORDERS_INIT';
export const FETCH_ORDERS_START = 'FETCH_ORDERS_START';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL';

////////////////////////////////

export const GENERAL_START = 'GENERAL_START';
export const GENERAL_SUCCESS = 'GENERAL_SUCCESS';
export const GENERAL_ERROR = 'GENERAL_ERROR';

export const CERTIFICATES_RESET = 'CERTIFICATES_RESET';
export const CERTIFICATES_LOAD_INIT = 'CERTIFICATES_LOAD_INIT';
export const CERTIFICATES_LOAD_START = 'CERTIFICATES_LOAD_START';
export const CERTIFICATES_LOAD_SUCCESS = 'CERTIFICATES_LOAD_SUCCESS';
export const CERTIFICATES_LOAD_FAIL = 'CERTIFICATES_LOAD_FAIL';

export const CERTIFICATES_DELETE = 'CERTIFICATES_DELETE';
export const CERTIFICATES_DELETE_START = 'CERTIFICATES_DELETE_START';
export const CERTIFICATES_DELETE_SUCCESS = 'CERTIFICATES_DELETE_SUCCESS';
export const CERTIFICATES_DELETE_FAIL = 'CERTIFICATES_DELETE_FAIL';

export const RESET_PROLONG_DATA = 'RESET_PROLONG_DATA'
export const UPDATE_PROLONG_DATA = 'UPDATE_PROLONG_DATA'
export const CERTIFICATES_PROLONG = 'CERTIFICATES_PROLONG';
export const CERTIFICATES_PROLONG_START = 'CERTIFICATES_PROLONG_START';
export const CERTIFICATES_PROLONG_SUCCESS = 'CERTIFICATES_PROLONG_SUCCESS';
export const CERTIFICATES_PROLONG_FAIL = 'CERTIFICATES_PROLONG_FAIL';

export const CERTIFICATES_CANCEL = 'CERTIFICATES_CANCEL';
export const CERTIFICATES_CANCEL_START = 'CERTIFICATES_CANCEL_START';
export const CERTIFICATES_CANCEL_SUCCESS = 'CERTIFICATES_CANCEL_SUCCESS';
export const CERTIFICATES_CANCEL_FAIL = 'CERTIFICATES_CANCEL_FAIL';

export const CERTIFICATE_UPDATE = 'CERTIFICATE_UPDATE';
export const CERTIFICATE_SAVE = 'CERTIFICATE_SAVE';
export const CERTIFICATE_SAVE_START = 'CERTIFICATE_SAVE_START';
export const CERTIFICATE_SAVE_SUCCESS = 'CERTIFICATE_SAVE_SUCCESS';
export const CERTIFICATE_SAVE_FAIL = 'CERTIFICATE_SAVE_FAIL';
export const CERTIFICATE_REDIRECT_TO_CALCULATE = 'CERTIFICATE_REDIRECT_TO_CALCULATE';

export const CERTIFICATE_CAR_INFO = 'CERTIFICATE_CAR_INFO';
export const CERTIFICATE_CAR_INFO_START = 'CERTIFICATE_CAR_INFO_START';
export const CERTIFICATE_CAR_INFO_SUCCESS = 'CERTIFICATE_CAR_INFO_SUCCESS';
export const CERTIFICATE_CAR_INFO_FAIL = 'CERTIFICATE_CAR_INFO_FAIL';

export const CERTIFICATE_INITIALIZE = 'CERTIFICATE_INITIALIZE';
export const CERTIFICATE_LOAD = 'CERTIFICATE_LOAD';
export const CERTIFICATE_LOAD_START = 'CERTIFICATE_LOAD_START';
export const CERTIFICATE_LOAD_SUCCESS = 'CERTIFICATE_LOAD_SUCCESS';
export const CERTIFICATE_LOAD_FAIL = 'CERTIFICATE_LOAD_FAIL';

export const CERTIFICATE_CALCULATE = 'CERTIFICATE_CALCULATE';
export const CERTIFICATE_CALCULATE_CLEAR = 'CERTIFICATE_CALCULATE_CLEAR';
export const CERTIFICATE_CALCULATE_START = 'CERTIFICATE_CALCULATE_START';
export const CERTIFICATE_CALCULATE_SUCCESS = 'CERTIFICATE_CALCULATE_SUCCESS';
export const CERTIFICATE_CALCULATE_FAIL = 'CERTIFICATE_CALCULATE_FAIL';
export const CERTIFICATE_RESET_CALCULATIONS = 'CERTIFICATE_RESET_CALCULATIONS';

export const CERTIFICATE_SET_PROCESS_INFO = 'CERTIFICATE_SET_PROCESS_INFO';
export const CERTIFICATE_PROCESS = 'CERTIFICATE_PROCESS';
export const CERTIFICATE_PROCESS_START = 'CERTIFICATE_PROCESS_START';
export const CERTIFICATE_PROCESS_SUCCESS = 'CERTIFICATE_PROCESS_SUCCESS';
export const CERTIFICATE_PROCESS_FAIL = 'CERTIFICATE_PROCESS_FAIL';

export const CERTIFICATE_SET_FINISH_INFO = 'CERTIFICATE_SET_FINISH_INFO';
export const CERTIFICATE_FINISH = 'CERTIFICATE_FINISH';
export const CERTIFICATE_FINISH_START = 'CERTIFICATE_FINISH_START';
export const CERTIFICATE_FINISH_SUCCESS = 'CERTIFICATE_FINISH_SUCCESS';
export const CERTIFICATE_FINISH_FAIL = 'CERTIFICATE_FINISH_FAIL';

export const CATALOG_LOAD_INIT = 'CATALOG_LOAD_INIT';
export const CATALOG_LOAD_START = 'CATALOG_LOAD_START';
export const CATALOG_LOAD_SUCCESS = 'CATALOG_LOAD_SUCCESS';
export const CATALOG_LOAD_FAIL = 'CATALOG_LOAD_FAIL';

export const AUTH_START = 'AUTH_START';
export const AUTH_USER = 'AUTH_USER';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECK = 'AUTH_CHECK';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_CHECK_TIMEOUT = 'AUTH_CHECK_TIMEOUT';
export const AUTH_SET_REDIRECT_PATH = 'AUTH_SET_REDIRECT_PATH';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';

export const AUTH_RESET = 'AUTH_RESET'
export const AUTH_RESET_START = 'AUTH_RESET_START';
export const AUTH_RESET_FAIL = 'AUTH_RESET_FAIL';
export const AUTH_RESET_SUCCESS = 'AUTH_RESET_SUCCESS';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';

////////////////////////// USERS ///////////////////////////////

export const USERS_RESET = 'USERS_RESET';
export const USERS_LOAD_INIT = 'USERS_LOAD_INIT';
export const USERS_LOAD_START = 'USERS_LOAD_START';
export const USERS_LOAD_SUCCESS = 'USERS_LOAD_SUCCESS';
export const USERS_LOAD_FAIL = 'USERS_LOAD_FAIL';

export const USERS_COMPANY_UPDATE = 'USERS_COMPANY_UPDATE';
export const USERS_COMPANY_UPDATE_START = 'USERS_COMPANY_UPDATE_START';
export const USERS_COMPANY_UPDATE_SUCCESS = 'USERS_COMPANY_UPDATE_SUCCESS';
export const USERS_COMPANY_UPDATE_FAIL = 'USERS_COMPANY_UPDATE_FAIL';

export const USER_INITIALIZE = 'USER_INITIALIZE';
export const USER_LOAD = 'USER_LOAD';
export const USER_LOAD_START = 'USER_LOAD_START';
export const USER_LOAD_SUCCESS = 'USER_LOAD_SUCCESS';
export const USER_LOAD_FAIL = 'USER_LOAD_FAIL';

export const USER_UPDATE = 'USER_UPDATE';
export const USER_SAVE = 'USER_SAVE';
export const USER_SAVE_START = 'USER_SAVE_START';
export const USER_SAVE_SUCCESS = 'USER_SAVE_SUCCESS';
export const USER_SAVE_FAIL = 'USER_SAVE_FAIL';

export const USERS_DELETE = 'USERS_DELETE';
export const USERS_DELETE_START = 'USERS_DELETE_START';
export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS';
export const USERS_DELETE_FAIL = 'USERS_DELETE_FAIL';

export const USER_RATE = 'USER_RATE';
export const USER_RATE_START = 'USER_RATE_START';
export const USER_RATE_SUCCESS = 'USER_RATE_SUCCESS';
export const USER_RATE_FAIL = 'USER_RATE_FAIL';

export const PASSWORD_CHANGE = 'PASSWORD_CHANGE';
export const PASSWORD_CHANGE_START = 'PASSWORD_CHANGE_START';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';
export const PASSWORD_CHANGE_FAIL = 'PASSWORD_CHANGE_FAIL';

export const USER_LOAD_LOG = 'USER_LOAD_LOG';
export const USER_LOAD_LOG_START = 'USER_LOAD_LOG_START';
export const USER_LOAD_LOG_SUCCESS = 'USER_LOAD_LOG_SUCCESS';
export const USER_LOAD_LOG_FAIL = 'USER_LOAD_LOG_FAIL';

export const USER_PANEL_LOG = 'USER_PANEL_LOG';

////////////////////////// NEWS ///////////////////////////////

export const NEWS_RESET = 'NEWS_RESET';
export const NEWS_LOAD_INIT = 'NEWS_LOAD_INIT';
export const NEWS_LOAD_START = 'NEWS_LOAD_START';
export const NEWS_LOAD_SUCCESS = 'NEWS_LOAD_SUCCESS';
export const NEWS_LOAD_FAIL = 'NEWS_LOAD_FAIL';

export const NEWS_ELEMENT_INITIALIZE = 'NEWS_ELEMENT_INITIALIZE';
export const NEWS_ELEMENT_LOAD = 'NEWS_ELEMENT_LOAD';
export const NEWS_ELEMENT_LOAD_START = 'NEWS_ELEMENT_LOAD_START';
export const NEWS_ELEMENT_LOAD_SUCCESS = 'NEWS_ELEMENT_LOAD_SUCCESS';
export const NEWS_ELEMENT_LOAD_FAIL = 'NEWS_ELEMENT_LOAD_FAIL';

export const NEWS_ELEMENT_UPDATE = 'NEWS_ELEMENT_UPDATE';
export const NEWS_ELEMENT_SAVE = 'NEWS_ELEMENT_SAVE';
export const NEWS_ELEMENT_SAVE_START = 'NEWS_ELEMENT_SAVE_START';
export const NEWS_ELEMENT_SAVE_SUCCESS = 'NEWS_ELEMENT_SAVE_SUCCESS';
export const NEWS_ELEMENT_SAVE_FAIL = 'NEWS_ELEMENT_SAVE_FAIL';

export const NEWS_DELETE = 'NEWS_DELETE';
export const NEWS_DELETE_START = 'NEWS_DELETE_START';
export const NEWS_DELETE_SUCCESS = 'NEWS_DELETE_SUCCESS';
export const NEWS_DELETE_FAIL = 'NEWS_DELETE_FAIL';

////////////////////////// DIALOGS ///////////////////////////////

export const DIALOGS_RESET = 'DIALOGS_RESET';
export const DIALOGS_LOAD_INIT = 'DIALOGS_LOAD_INIT';
export const DIALOGS_LOAD_START = 'DIALOGS_LOAD_START';
export const DIALOGS_LOAD_SUCCESS = 'DIALOGS_LOAD_SUCCESS';
export const DIALOGS_LOAD_FAIL = 'DIALOGS_LOAD_FAIL';

export const NEW_MESSAGES_LOAD_INIT = 'NEW_MESSAGES_LOAD_INIT';
export const NEW_MESSAGES_LOAD_START = 'NEW_MESSAGES_LOAD_START';
export const NEW_MESSAGES_LOAD_SUCCESS = 'NEW_MESSAGES_LOAD_SUCCESS';
export const NEW_MESSAGES_REDUCE = 'NEW_MESSAGES_REDUCE';

////////////////////////// MESSAGES ///////////////////////////////

export const MESSAGES_RESET = 'MESSAGES_RESET';
export const MESSAGES_LOAD_INIT = 'MESSAGES_LOAD_INIT';
export const MESSAGES_LOAD_START = 'MESSAGES_LOAD_START';
export const MESSAGES_LOAD_SUCCESS = 'MESSAGES_LOAD_SUCCESS';
export const MESSAGES_LOAD_FAIL = 'MESSAGES_LOAD_FAIL';

export const MESSAGES_DELETE = 'MESSAGES_DELETE';
export const MESSAGES_DELETE_START = 'MESSAGES_DELETE_START';
export const MESSAGES_DELETE_SUCCESS = 'MESSAGES_DELETE_SUCCESS';
export const MESSAGES_DELETE_FAIL = 'MESSAGES_DELETE_FAIL';

export const MESSAGES_SAVE = 'MESSAGES_SAVE';
export const MESSAGES_SAVE_START = 'MESSAGES_SAVE_START';
export const MESSAGES_SAVE_SUCCESS = 'MESSAGES_SAVE_SUCCESS';
export const MESSAGES_SAVE_FAIL = 'MESSAGES_SAVE_FAIL';

export const MESSAGES_MASS_ADD = 'MESSAGES_MASS_ADD';
export const MESSAGES_MASS_REMOVE = 'MESSAGES_MASS_REMOVE';

////////////////////////// RATES ///////////////////////////////

export const RATES_RESET = 'RATES_RESET';
export const RATES_LOAD_INIT = 'RATES_LOAD_INIT';
export const RATES_LOAD_START = 'RATES_LOAD_START';
export const RATES_LOAD_SUCCESS = 'RATES_LOAD_SUCCESS';
export const RATES_LOAD_FAIL = 'RATES_LOAD_FAIL';

export const RATE_INITIALIZE = 'RATE_INITIALIZE';
export const RATE_LOAD = 'RATE_LOAD';
export const RATE_LOAD_START = 'RATE_LOAD_START';
export const RATE_LOAD_SUCCESS = 'RATE_LOAD_SUCCESS';
export const RATE_LOAD_FAIL = 'RATE_LOAD_FAIL';

export const RATE_INFO_INITIALIZE = 'RATE_INFO_INITIALIZE';
export const RATE_INFO_LOAD = 'RATE_INFO_LOAD';
export const RATE_INFO_LOAD_START = 'RATE_INFO_LOAD_START';
export const RATE_INFO_LOAD_SUCCESS = 'RATE_INFO_LOAD_SUCCESS';
export const RATE_INFO_LOAD_FAIL = 'RATE_INFO_LOAD_FAIL';

export const RATE_UPDATE = 'RATE_UPDATE';
export const RATE_SAVE = 'RATE_SAVE';
export const RATE_SAVE_START = 'RATE_SAVE_START';
export const RATE_SAVE_SUCCESS = 'RATE_SAVE_SUCCESS';
export const RATE_SAVE_FAIL = 'RATE_SAVE_FAIL';

export const RATES_DELETE = 'RATES_DELETE';
export const RATES_DELETE_START = 'RATES_DELETE_START';
export const RATES_DELETE_SUCCESS = 'RATES_DELETE_SUCCESS';
export const RATES_DELETE_FAIL = 'RATES_DELETE_FAIL';

export const PARENT_RATE_START = 'PARENT_RATE_START';
export const PARENT_RATE_SUCCESS = 'PARENT_RATE_SUCCESS';
export const PARENT_RATE_FAIL = 'PARENT_RATE_FAIL';

export const CHILD_RATE_INIT = 'CHILD_RATE_INIT';
export const CHILD_RATE_START = 'CHILD_RATE_START';
export const CHILD_RATE_SUCCESS = 'CHILD_RATE_SUCCESS';
export const CHILD_RATE_FAIL = 'CHILD_RATE_FAIL';

////////////////////////// REPORTS ///////////////////////////////

export const REPORTS_RESET = 'REPORTS_RESET';
export const REPORTS_LOAD_INIT = 'REPORTS_LOAD_INIT';
export const REPORTS_LOAD_START = 'REPORTS_LOAD_START';
export const REPORTS_LOAD_SUCCESS = 'REPORTS_LOAD_SUCCESS';
export const REPORTS_LOAD_FAIL = 'REPORTS_LOAD_FAIL';

export const REPORTS_DELETE = 'REPORTS_DELETE';
export const REPORTS_DELETE_START = 'REPORTS_DELETE_START';
export const REPORTS_DELETE_SUCCESS = 'REPORTS_DELETE_SUCCESS';
export const REPORTS_DELETE_FAIL = 'REPORTS_DELETE_FAIL';

export const REPORTS_ADD = 'REPORTS_ADD';
export const REPORTS_ADD_START = 'REPORTS_ADD_START';
export const REPORTS_ADD_SUCCESS = 'REPORTS_ADD_SUCCESS';
export const REPORTS_ADD_FAIL = 'REPORTS_ADD_FAIL';

export const REPORTS_OPEN = 'REPORTS_OPEN';
export const REPORTS_REDIRECT = 'REPORTS_REDIRECT';
export const REPORTS_CLOSE = 'REPORTS_CLOSE';

export const REPORT_INITIALIZE = 'REPORT_INITIALIZE';
export const REPORT_LOAD = 'REPORT_LOAD';
export const REPORT_LOAD_START = 'REPORT_LOAD_START';
export const REPORT_LOAD_SUCCESS = 'REPORT_LOAD_SUCCESS';
export const REPORT_LOAD_FAIL = 'REPORT_LOAD_FAIL';

export const REPORT_UPDATE = 'REPORT_UPDATE';
export const REPORT_SAVE = 'REPORT_SAVE';
export const REPORT_SAVE_START = 'REPORT_SAVE_START';
export const REPORT_SAVE_SUCCESS = 'REPORT_SAVE_SUCCESS';
export const REPORT_SAVE_FAIL = 'REPORT_SAVE_FAIL';

export const REPORT_DOWNLOAD = 'REPORT_DOWNLOAD';
export const REPORT_DOWNLOAD_START = 'REPORT_DOWNLOAD_START';
export const REPORT_DOWNLOAD_SUCCESS = 'REPORT_DOWNLOAD_SUCCESS';
export const REPORT_DOWNLOAD_FAIL = 'REPORT_DOWNLOAD_FAIL';

export const REPORT_CHANGE_STATUS = 'REPORT_CHANGE_STATUS';
export const REPORT_CHANGE_STATUS_START = 'REPORT_CHANGE_STATUS_START';
export const REPORT_CHANGE_STATUS_SUCCESS = 'REPORT_CHANGE_STATUS_SUCCESS';
export const REPORT_CHANGE_STATUS_FAIL = 'REPORT_CHANGE_STATUS_FAIL';

export const DELETE_CERTIFICATE_FROM_REPORT = 'DELETE_CERTIFICATE_FROM_REPORT';
export const DELETE_CERTIFICATE_FROM_REPORT_START = 'DELETE_CERTIFICATE_FROM_REPORT_START';
export const DELETE_CERTIFICATE_FROM_REPORT_SUCCESS = 'DELETE_CERTIFICATE_FROM_REPORT_SUCCESS';
export const DELETE_CERTIFICATE_FROM_REPORT_FAIL = 'DELETE_CERTIFICATE_FROM_REPORT_FAIL';

export const NEW_REPORTS_LOAD_INIT = 'NEW_REPORTS_LOAD_INIT';
export const NEW_REPORTS_LOAD_START = 'NEW_REPORTS_LOAD_START';
export const NEW_REPORTS_LOAD_SUCCESS = 'NEW_REPORTS_LOAD_SUCCESS';
export const NEW_REPORTS_REDUCE = 'NEW_REPORTS_REDUCE';

////////////////////////// FILES ///////////////////////////////

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

////////////////////////// DOCUMENTS ///////////////////////////////

export const DOCUMENTS_LOAD_INIT = 'DOCUMENTS_LOAD_INIT';
export const DOCUMENTS_LOAD_START = 'DOCUMENTS_LOAD_START';
export const DOCUMENTS_LOAD_SUCCESS = 'DOCUMENTS_LOAD_SUCCESS';
export const DOCUMENTS_LOAD_FAIL = 'DOCUMENTS_LOAD_FAIL';
export const DOCUMENTS_OPEN = 'DOCUMENTS_OPEN';
export const DOCUMENTS_CLOSE = 'DOCUMENTS_CLOSE';

////////////////////////// DASHBOARD ///////////////////////////////

export const DASHBOARD_COUNTERS = 'DASHBOARD_COUNTERS';
export const DASHBOARD_COUNTERS_START = 'DASHBOARD_COUNTERS_START';
export const DASHBOARD_COUNTERS_SUCCESS = 'DASHBOARD_COUNTERS_SUCCESS';
export const DASHBOARD_COUNTERS_FAIL = 'DASHBOARD_COUNTERS_FAIL';
export const DASHBOARD_PROLONG_CERTIFICATES = 'DASHBOARD_PROLONG_CERTIFICATES';
export const DASHBOARD_PROLONG_CERTIFICATES_START = 'DASHBOARD_PROLONG_CERTIFICATES_START';
export const DASHBOARD_PROLONG_CERTIFICATES_SUCCESS = 'DASHBOARD_PROLONG_CERTIFICATES_SUCCESS';
export const DASHBOARD_PROLONG_CERTIFICATES_FAIL = 'DASHBOARD_PROLONG_CERTIFICATES_FAIL';
export const DASHBOARD_STATISTICS = 'DASHBOARD_STATISTICS';
export const DASHBOARD_STATISTICS_START = 'DASHBOARD_STATISTICS_START';
export const DASHBOARD_STATISTICS_SUCCESS = 'DASHBOARD_STATISTICS_SUCCESS';
export const DASHBOARD_STATISTICS_FAIL = 'DASHBOARD_STATISTICS_FAIL';
export const DASHBOARD_CREATED_CERTIFICATES = 'DASHBOARD_CREATED_CERTIFICATES';
export const DASHBOARD_CREATED_CERTIFICATES_START = 'DASHBOARD_CREATED_CERTIFICATES_START';
export const DASHBOARD_CREATED_CERTIFICATES_SUCCESS = 'DASHBOARD_CREATED_CERTIFICATES_SUCCESS';
export const DASHBOARD_CREATED_CERTIFICATES_FAIL = 'DASHBOARD_CREATED_CERTIFICATES_FAIL';
export const DASHBOARD_NEW_CERTIFICATES = 'DASHBOARD_NEW_CERTIFICATES';
export const DASHBOARD_NEW_CERTIFICATES_START = 'DASHBOARD_NEW_CERTIFICATES_START';
export const DASHBOARD_NEW_CERTIFICATES_SUCCESS = 'DASHBOARD_NEW_CERTIFICATES_SUCCESS';
export const DASHBOARD_NEW_CERTIFICATES_FAIL = 'DASHBOARD_NEW_CERTIFICATES_FAIL';
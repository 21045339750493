import * as actionTypes from './actionTypes';


export const updateUser = (newUserObject) => {
    return {
        data: newUserObject,
        type: actionTypes.USER_UPDATE,
    }
}


export const loadUserLog = (userId, page) => {
    return {
        userId : userId,
        page : page,
        type: actionTypes.USER_LOAD_LOG,
    }
}
export const loadUserLogStart = (userId, page) => {
    return {
        userId : userId,
        page : page,
        type: actionTypes.USER_LOAD_LOG_START,
    }
}
export const loadUserLogSuccess = (log, total, pages) => {
    return {
        log: log,
        total: total,
        pages: pages,
        type: actionTypes.USER_LOAD_LOG_SUCCESS,
    }
}
export const loadUserLogError = (error) => {
    return {
        error: error,
        type: actionTypes.USER_LOAD_LOG_FAIL,
    }
}


export const changePassword = (userId, oldPassword, newPassword, reNewPassword) => {
    return {
        userId: userId,
        oldPassword: oldPassword,
        newPassword: newPassword,
        reNewPassword: reNewPassword,
        type: actionTypes.PASSWORD_CHANGE,
    }
}
export const changePasswordStart = (userId, oldPassword, newPassword, reNewPassword) => {
    return {
        userId: userId,
        oldPassword: oldPassword,
        newPassword: newPassword,
        reNewPassword: reNewPassword,
        type: actionTypes.PASSWORD_CHANGE_START,
    }
}
export const changePasswordSuccess = () => {
    return {      
        type: actionTypes.PASSWORD_CHANGE_SUCCESS,
    }
}
export const changePasswordFail = (error) => {
    return {
        error: error,
        type: actionTypes.PASSWORD_CHANGE_FAIL,
    }
}


export const saveUser = (userObject, createLinkedRate) => {
    return {
        data: userObject,
        createLinkedRate: createLinkedRate,
        type: actionTypes.USER_SAVE,
    }
}

export const saveUserStart = (carNumber) => {
    return {
        type: actionTypes.USER_SAVE_START,
    }
}

export const saveUserSuccess = (userId, savedRateId) => {
    return {
        userId: userId,  
        savedRateId: savedRateId,      
        type: actionTypes.USER_SAVE_SUCCESS,
    }
}

export const saveUserFail = (error, fields) => {
    return {
        error: error,
        fields: fields,
        type: actionTypes.USER_SAVE_FAIL,
    }
}



export const initializeUser = () => {
    return {
        type: actionTypes.USER_INITIALIZE,
    }
}

export const loadUser = (userId) => {
    return {
        userId : userId,
        type: actionTypes.USER_LOAD,
    }
}

export const loadUserStart = (userId) => {
    return {
        userId : userId,
        type: actionTypes.USER_LOAD_START,
    }
}

export const loadUserSuccess = (data) => {
    return {
        user: data.user,        
        type: actionTypes.USER_LOAD_SUCCESS,
    }
}

export const loadUserFail = (error) => {
    return {
        error: error,
        type: actionTypes.USER_LOAD_FAIL,
    }
}
import React from 'react';
import axios from 'axios';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    Container,
    Divider,
    Grid,
    makeStyles,
    List,
    Typography,
  } from '@material-ui/core';
  import Page from '../components/Page';
  import Alert from '@material-ui/lab/Alert';

  import { apiUrl } from '../settings/base';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
          error: null, 
          errorInfo: null,
          isSent: false,
          finishBlock: <Alert severity="warning">Мы отправляем сообщение об ошибке администрации! Они сделают так чтобы она больше не повторилась</Alert> 
        };
    }
    

    componentDidUpdate() {
        //console.log("STATE IS", this.state);
        if (this.state.error && !this.state.isSent) {
            const data = [
                this.state.error && this.state.error.toString(),
                this.state.errorInfo.componentStack
            ];
        
            axios.post(apiUrl + "/error/frontend", data)
                .then((response)=> {
                    if (response.result==='ok' && response.mail_result)
                        this.setState({isSent: true, finishBlock:<Alert severity="success">Сообщение администрации успешно отправлено, спасибо!</Alert>});
                    else
                        this.setState({isSent: true, finishBlock:<Alert severity="error">Ошибка отправки сообщения администрации :( Пожалуйста, напишите нам и пришлите лог ошибки (отображён ниже)</Alert>})
                })
                .catch(()=>this.setState({isSent: true, finishBlock:<Alert severity="error">Ошибка отправки сообщения администрации :( Пожалуйста, напишите нам и пришлите лог ошибки (отображён ниже)</Alert>}));
        }

     }
     
    
    componentDidCatch(error, errorInfo) {
      // Catch errors in any components below and re-render with error message
      this.setState({
        error: error,
        errorInfo: errorInfo
      })
      // You can also log error messages to an error reporting service here
    }
    
    render() {
      if (this.state.errorInfo) {
        // Error path
        return (

            <Page
                style={{marginTop:"20px"}}
                title="У нас произошла ошибка :("
            >
                <Container maxWidth={false}>
                    <Card>
                    <CardHeader
                        title="У нас произошла ошибка :("
                    />
                    <Divider />

                    <Grid container spacing={3} >
                        <Grid item xs={12}>{this.state.finishBlock}</Grid>
                        <Grid item xs={12}>
                            <Typography>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo.componentStack}
                            </Typography>
                        </Grid>
                    </Grid>
                    </Card>
                </Container>
            </Page>

        );
      }
      // Normally, just render children
      return this.props.children;
    }  
  }

  export default ErrorBoundary;
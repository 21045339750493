import {
    MenuItem,
  } from '@material-ui/core';

const createMenuItems = (data, preItem = null) => {
    const menuItemsList = [];
    if (preItem) menuItemsList.push(preItem);
    for (const i in data) {
        const optionVariant = data[i];
        menuItemsList.push(<MenuItem key={optionVariant.id} value={optionVariant.id} >{optionVariant.name}</MenuItem>)
    }
    return menuItemsList;
}

export default createMenuItems;
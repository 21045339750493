import * as actionTypes from './actionTypes';

export const loadDocuments = (certificateId) => {
    return {
        certificateId : certificateId,
        type: actionTypes.DOCUMENTS_LOAD_INIT,
    }
}

export const loadStart = (certificateId) => {
    return {
        certificateId: certificateId,
        type: actionTypes.DOCUMENTS_LOAD_START,
    }
}

export const loadSuccess = (documents) => {
    return {
        documents: documents,
        type: actionTypes.DOCUMENTS_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        type: actionTypes.DOCUMENTS_LOAD_FAIL,
    }
}

export const loadDocument = (certificateId, documentId) => {
    return {
        certificateId : certificateId,
        documentId : documentId,
        type: actionTypes.DOCUMENTS_LOAD_INIT,
    }
}

export const openDocumentsWindow = (certificateId) => ({ certificateId: certificateId, type: actionTypes.DOCUMENTS_OPEN })
export const closeDocumentsWindow = () => ({ type: actionTypes.DOCUMENTS_CLOSE })
import {
    Grid,
    TextField,
  } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ElkSearchInput = (props) => {

    const getOptionById = (id) => {
        for (const i in props.options)
            if (props.options[i].id+"" === id+"")
                return props.options[i];
        return null;
    }

    const getOptionLabelById = (id) => {
        const option = getOptionById(id);
        return option ? option.name : "";
    }

    return <Grid item lg={props.width ?? 4} md={props.width ?? 4} xs={12} >
        <Autocomplete
            options={ props.options ?? [] }
            value={getOptionById(props.value)}
            //getOptionSelected={(option) => { return option.id+"" === props.value+"" }}
            getOptionLabel={(option) => option.name ?? ''}
            //defaultValue={props.defaultValue}
            
            renderInput={(params) => (
                <TextField
                {...params}
                value={getOptionLabelById(props.value)}
                variant="outlined"
                label={props.label}
                error={props.error ?? false}
                helperText={props.helperText ?? ""}
                name={props.name}
                required={props.required}
                />
            )}
            onChange={(event, value, reason) => { props.setFieldValue(props.name, value ? value.id : null); props.onValueChange(value ? value.id : null) }}
        />
    </Grid>
}

export default ElkSearchInput;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ruLocale from "date-fns/locale/ru";
import * as Yup from 'yup';
import { Formik } from 'formik';
import Alert from '@material-ui/lab/Alert';

import { subrateDrivers, subrateTrailer, subrateJuridical } from '../../../settings/profins';

import copyObject from '../../../utils/copyObject';
import createMenuItems from '../../../utils/createMenuItems';

import ElkTextInput from '../../../components/FormElements/ElkTextInput';

import Spinner from '../../../components/Spinner';
import RateInfo from '../RateForm/RateInfo';

import { prepareRate } from './RateController';
import Subrate from './Subrate';

import * as actions from '../../../store/actions';
import getFormError from '../../../utils/getFormError';
import copyArray from '../../../utils/copyArray';
import ElkSelectInput from '../../../components/FormElements/ElkSelectInput';

const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: "100%"
  },
  userBox: {
    marginTop: "20px"
  },
  marginTop: {
    marginTop: "10px"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  center: {
    textAlign: "center",
  },
  saveSpinner: {
    marginRight: "10px"
  },
  error: {
    color: "#f44336"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const RateForm = ({ className, rate, rateId, ...rest }) => {

  const [companyOptionsList, setCompanyOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [regionOptionsList, setRegionOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [categoriesOptionsList, setCategoriesOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [tasksOptionsList, setTasksOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [prolongOptionsList, setProlongOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [regionId, setRegionId] = useState(0);

  const rateErrors = {};
  if (rest.errorFields) {
    for (const i in rest.errorFields) 
    rateErrors[i] = rest.errorFields[i];
  }

  const classes = useStyles();
  //const navigate = useNavigate();

  const curUser = rest.curUser;
  const onLoadCatalog = rest.onLoadCatalog;
  const onLoadRate = rest.onLoadRate;
  const onLoadRates = rest.onLoadRates;
  const catalogsGeneral = rest.catalogs['general'] ?? false;
  const catalogsRegions = rest.catalogs['regions'] ?? false;
  const catalogsCompanies = rest.catalogs['companies'] ?? false;
  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsCompanies) onLoadCatalog('companies')
    else if (!catalogsRegions) onLoadCatalog('regions')
    else if (rate.id*1 !== rateId*1 && !rest.isRateLoading && !rest.rateError) onLoadRate(rateId);
    else if (rate.id*1 === rateId*1 && rate.parent_id>0 && !rest.parentRate && !rest.parentError) onLoadRate(rate.parent_id, "parent");
    else if (rate.id*1 === rateId*1 && !rest.childRatesLoaded && !rest.isChildRatesLoading && !rest.childRatesError) { onLoadRates(rateId); }

    if (catalogsCompanies) {
      let newCompanyOptionsList = [];
      newCompanyOptionsList = createMenuItems(catalogsCompanies, <MenuItem key={0} value={0} >Все компании</MenuItem>);
      setCompanyOptionsList(newCompanyOptionsList);
    }

    if (catalogsRegions) {
      let newRegionOptionsList = [];
      newRegionOptionsList = createMenuItems(catalogsRegions, <MenuItem key={0} value={0} >Все регионы</MenuItem>);
      setRegionOptionsList(newRegionOptionsList);
    }

    if (catalogsGeneral) {
      let newCategoryOptionsList = [];
      let newTaskOptionsList = [];
      let newProlongOptionsList = [];
      newCategoryOptionsList = createMenuItems(catalogsGeneral['car_categories'], <MenuItem key={0} value={0} >Все категории</MenuItem>);
      newTaskOptionsList = createMenuItems(catalogsGeneral['car_tasks'], <MenuItem key={0} value={0} >Все цели использования</MenuItem>);
      newProlongOptionsList = createMenuItems(catalogsGeneral['prolong'], <MenuItem key={0} value={0} >Все варианты</MenuItem>);
      setCategoriesOptionsList(newCategoryOptionsList);
      setTasksOptionsList(newTaskOptionsList);
      setProlongOptionsList(newProlongOptionsList)
    }

  }, [catalogsGeneral, rate.parent_id, rest.isParentLoading, rest.rateError, rest.parentRate, rest.parentError, catalogsCompanies, catalogsRegions, onLoadCatalog, rate.id, rateId, 
      onLoadRate, rest.isRateLoading, curUser.rate_id, rest.childRatesLoaded, rest.isChildRatesLoading, rest.childRatesError, onLoadRates]);

  const updateRateData = (updatedFields) => {
    let newRateData = copyObject(rate);
    for (const i in updatedFields)
    newRateData[i] = updatedFields[i];
    rest.onUpdateRate(newRateData);
  }

  const saveRate = () => {
    const dataToSend = prepareRate(rate);
    rest.onSaveRate(dataToSend);
  }

  const addSubrate = () => {
    //console.log("OLD RATES", rate.rates);
    const newRates = copyArray(rate.rates);
    newRates.push({id: 0, company_id: 0, region_id:[0], drivers:0, trailer:0, juridical:0, task:[0], category:[0], percent:'', power: "", kbm: "", prolong: [0]});
    //console.log("OLD RATES", newRates);
    updateRateData({ rates: newRates });
  }

  const deleteSubrate = (subrateToDelete) => {
    const newRates = copyArray(rate.rates);
    newRates.splice(subrateToDelete, 1);
    updateRateData({ rates: newRates });
  }

  const changeTariff = (key, data) => {
    for (const i in data) {
      if (["region_id", "category", "task", "prolong"].includes(i)) {
        if (data[i].length > 1) data[i] = data[i].filter(item => item !== 0); //Убираем "Все XXX" если выбрано более 1 XXX
        if (data[i].length === 0) data[i] = [0]; //Добавляем "Все XXX" если все XXX убраны
      }
      rate.rates[key][i] = data[i];
    }
    updateRateData( { rates: rate.rates });
  }

  const filterRatesByRegion = (newRegionId) => {
    setRegionId(newRegionId);
  }

  const changeSpecialTariff = (key, percent) => {
    let newRates = copyArray(rate.rates);
    //Удаляем этот подтариф
    newRates = newRates.filter(function( obj ) {
        return obj.subrate_id*1 !== key*1;
    });
    //Пишем новый подтариф вместо удалённого
    newRates.push({ rate_id:rate.id, subrate_id: key*1, percent: percent});
    updateRateData( { rates: newRates });
  }

  const yupValidationShape = React.useMemo(() => {
    let yupValidationShape = {
      name: Yup.string().max(255).required('Укажите название комиссионного вознаграждения'),
      percent: Yup.number().max(255).required('Укажите ставку комиссионного вознаграждения'),
    }
    return yupValidationShape;
  }, []);

  const subrateDriversOptions = React.useMemo(() => { return createMenuItems(subrateDrivers, <MenuItem key={0} value={0} >Все варианты</MenuItem>); }, []);
  const subrateTrailerOptions = React.useMemo(() => { return createMenuItems(subrateTrailer, <MenuItem key={0} value={0} >Все варианты</MenuItem>); }, []);
  const subrateJuridicalOptions = React.useMemo(() => { return createMenuItems(subrateJuridical, <MenuItem key={0} value={0} >Все варианты</MenuItem>); }, []);
  //const subrateProlongOptions = React.useMemo(() => { return createMenuItems(subrateProlong, <MenuItem key={0} value={0} >Все варианты</MenuItem>); }, []);

  let backdrop = null;
  //if (rest.isCatalogsLoading) return <Spinner text="Загружаем каталоги"></Spinner>;
  if (rest.isRateLoading) backdrop = <Backdrop className={classes.backdrop} open={true}><Spinner text="Загружаем данные комиссионного вознаграждения" /></Backdrop>

  //if ((/*!rest.isCatalogsLoading &&*/ !rest.isRateLoading))
  if (!rest.rateError && !rest.parentError) {
    return <>
      <Formik
        className={clsx(classes.root, className)}
        initialValues={rate}
        //enableReinitialize={rest.isRateLoaded}
        enableReinitialize={true}
        onSubmit={ (values) => { saveRate();  } }
        validationSchema={ Yup.object().shape(yupValidationShape) }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => { 
          
          let saveButton = <Button variant="contained" color="primary" type="submit">Сохранить</Button>
          if (rest.isSaving) saveButton = <Button variant="contained" color="primary" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Сохраняю</Button>

          let saveError = null;
          if (rest.saveError) saveError = <Alert severity="error" className={classes.marginBottom}>{rest.saveError}</Alert>
          else if (rest.savedId) saveError =<Alert severity="success" className={classes.marginBottom}>КВ успешно сохранёно</Alert>
          else if (touched && errors && Object.keys(errors).length > 0) {
            for (const error in errors) if (touched[error]) {
              saveError = <Alert severity="error" className={classes.marginBottom}>Ошибки в заполнении данных комиссионного вознаграждения</Alert>
              break;
            }
          }

          let specialTariffs = null;
          if (rate.rates.length > 0) {
            specialTariffs = [];
            const companiesArrayWithNew = Object.keys(catalogsCompanies);
            companiesArrayWithNew.push(0);
            //console.log("COMP",companiesArrayWithNew);
            for (const j in companiesArrayWithNew) {

              if (companiesArrayWithNew[j] > 0) {
                //console.log("RD", j, Object.keys(catalogsCompanies));
                specialTariffs.push(<Grid key={"st" + j} item lg={12} md={12} xs={12} style={{marginTop:"20px", marginBottom:"5px"}}><Typography variant="h4">{catalogsCompanies[companiesArrayWithNew[j]].name}</Typography></Grid>);
              }

              for (const i in rate.rates) if (rate.rates[i].company_id*1 === companiesArrayWithNew[j]*1) {
                //console.log(rate.rates[i].region_id);
                if (!regionId || rate.rates[i].region_id.includes(regionId))
                  specialTariffs.push(<Subrate 
                    key={i} 
                    pos={i} 
                    setFieldValue={setFieldValue} 
                    handleBlur={handleBlur} 
                    data={rate.rates[i]} 
                    changeTariff={changeTariff} 
                    companyOptionsList={companyOptionsList} 
                    regionOptionsList={regionOptionsList} 
                    catalogsRegions={catalogsRegions}
                    catalogsCategories={catalogsGeneral['car_categories'] ?? []}
                    catalogsTasks={catalogsGeneral['car_tasks'] ?? []}
                    catalogsProlong={catalogsGeneral['prolong'] ?? []}
                    deleteSubrate={deleteSubrate}
                    categoriesOptionsList={categoriesOptionsList}
                    tasksOptionsList={tasksOptionsList}
                    prolongOptionsList={prolongOptionsList}
                    subrateDriversOptions={subrateDriversOptions}
                    subrateTrailerOptions={subrateTrailerOptions}
                    subrateJuridicalOptions={subrateJuridicalOptions}
                    //subrateProlongOptions={subrateProlongOptions}
                    />);
              }
            }
          } else
            specialTariffs = <Grid item lg={12} md={12} xs={12} ><Alert severity="info">Пока не добавлено ни одной ставки</Alert></Grid>

          let subratesError = null;
          if (rateErrors.subrate) subratesError = <Grid item lg={12} md={12} xs={12} ><Alert severity="error">{rateErrors.subrate}</Alert></Grid>
          
          //console.log("SPECIAL TARIFFS", rate.rates);

          let parentRateData = <Alert severity="info">Это основное КВ</Alert>
          if (rate && rate.parent_id > 0) parentRateData = <Box className={classes.userBox}>
            <Card>
              <CardHeader
                subheader="Указывайте положительное число чтобы дать агенту БОНУС и отрицательное чтобы наложить на агента ШТРАФ при оформлении полиса на данном подтарифе. Бонус не должен превышать ставку по тарифу."
                title="Бонусы для подтарифов"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3} ><RateInfo editedRate={rate} changeSpecialSubrate={changeSpecialTariff} infoRate={rest.parentRate} specialSettings={rate.rates} /></Grid>
              </CardContent>
            </Card>
          </Box>

          let childRatesBlock = null;
          if (rest.childRatesError) childRatesBlock = <Alert severity="error">{rest.childRatesError}</Alert>
          else if (rest.childRatesLoaded && Object.keys(rest.childRates).length > 0) { 
          }

          let mainPercentBlock = null;
          if (rate.id !== 1) mainPercentBlock = <Box className={classes.userBox}>
            <Card>
              <CardHeader
                subheader="Укажите какой процент от стоимости полиса вы заберёте себе."
                title="Процентная ставка"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3} >
                  <ElkTextInput
                    {...getFormError('percent', touched, errors, rateErrors)}
                    label="Ваш комиссионный процент"
                    name="percent" 
                    placeholder="92.5"
                    type="text"
                    onValueChange={(newValue) => { updateRateData({ percent: newValue }) }} 
                    //required={true} 
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    value={rate.percent ?? ''}
                  />
                </Grid>
              </CardContent>
            </Card>
          </Box>

          let subratesBlock = null;
          if (rate.id === 1) subratesBlock = <>
            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  title="Фильтр"
                />
                <Divider />
                <CardContent>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                        select
                        fullWidth
                        name="region_id"
                        variant="outlined"
                        label="Регион"
                        SelectProps={{
                            multiple: false,
                            value: regionId, 
                            onChange: (event, index, values) => { filterRatesByRegion(event.target.value) }
                        }}
                    >
                    { regionOptionsList }
                    </TextField>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  subheader="Здесь вы можете указать ставки, отличающиеся от ставки по-умолчанию"
                  title="Специальные ставки для компаний и регионов"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={1} >
                    {specialTariffs}
                    {subratesError}
                    <Grid item lg={12} md={12} xs={12} style={{marginTop:"20px"}}><Button variant="contained" color="primary" onClick={addSubrate}>Добавить ставку</Button></Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </>

          return (<form onSubmit={handleSubmit} className={classes.fullWidth}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>

            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  //subheader="Введите госномер авто и часть данных будет заполнена автоматически"
                  title="Основные данные"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >

                    <ElkTextInput
                      {...getFormError('name', touched, errors, rateErrors)}
                      label="Название"
                      name="name" 
                      placeholder="Выгодный"
                      type="text"
                      onValueChange={(newValue) => { updateRateData({ name: newValue }) }} 
                      //required={true} 
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={rate.name ?? ''}
                    />

                  </Grid>
                </CardContent>
              </Card>
            </Box>

            {mainPercentBlock}

            {parentRateData}

            {subratesBlock}

            {childRatesBlock}

            <Box className={classes.userBox}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} xs={12} className={classes.center} >
                      {saveError}
                      {saveButton}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>

            </MuiPickersUtilsProvider>
          </form>
        ); }}
      </Formik>
      {backdrop}
    </>
  } else {
    if (rest.rateError) return <Box style={{width:'100%'}} className={classes.userBox}><Alert style={{width:'100%'}} severity="error">{rest.rateError}</Alert></Box>;
    else if (rest.parentError) return <Box style={{width:'100%'}} className={classes.userBox}><Alert style={{width:'100%'}} severity="error">{rest.parentError}</Alert></Box>;
  }
  /*else {
    //if (rest.isCatalogsLoading) return <Spinner text="Загружаем каталоги"></Spinner>;
    if (rest.isRateLoading) return <Spinner text="Загружаем данные полиса"></Spinner>;
    return <Spinner></Spinner>;
  }*/
};

RateForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    isRateLoading: state.rate.isLoading,
    isRateLoaded: state.rate.isLoaded,
    rateError: state.rate.loadError,
    isRateSaving: state.rate.isSaving,
    rate: state.rate.rate,
    saveError: state.rate.saveError,
    errorFields: state.rate.errorFields,
    isSaving: state.rate.isSaving,
    savedId: state.rate.savedId,
    curUser: state.auth.user,

    parentRate: state.rate.parentRate,
    isParentLoading: state.rate.isParentLoading,
    parentError: state.rate.parentError,

    childRates: state.rate.childRates,
    isChildRatesLoading: state.rate.isChildRatesLoading,
    childRatesError: state.rate.childRatesError,
    childRatesLoaded: state.rate.childRatesLoaded,

    isCatalogsLoading: state.catalogs.isLoading,
    catalogs: state.catalogs.catalogs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCatalog: (catalogName, subId=null) => dispatch(actions.loadCatalog(catalogName, subId)),
      onUpdateRate: (newData) => dispatch(actions.updateRate(newData)),
      onSaveRate: (rateToSave) => dispatch(actions.saveRate(rateToSave)),
      onLoadRate: (rateId, mode) => dispatch(actions.loadRate(rateId, mode)),
      onLoadRates: (rateId) => dispatch(actions.loadRates({parent_id: rateId, linked_id: 0}, 1, 100, {name: 'asc'}, "child"))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RateForm);

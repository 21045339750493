import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import * as actions from '../../store/actions';

import Example from './Example';
import RecentNews from './RecentNews';
import CertificateListView from '../certificate/CertificateListView';
import ProlongationCertificates from './ProlongationCertificates';
import CreatedCertificates from './CreatedCertificates';
import NewCertificates from './NewCertificates';
import AdminStatistics from './AdminStatistics';
import { Grid } from '@material-ui/core';

const Dashboard = (props) => {

  const curUser = props.curUser;

  const onLoadNews = props.onLoadNews;
  const onLoadCounters = props.onLoadCounters;
  useEffect(() => {
    onLoadNews();
    onLoadCounters();
  }, [onLoadNews, onLoadCounters]);

  const recentNews = <RecentNews style={{width:"100%"}} news={props.news} isLoading={props.isNewsLoading} error={props.newsError} />
  const prolongationCertificates = <ProlongationCertificates />

  let createdCertificates;
  let newCertificates;
  const adminStatistics = <AdminStatistics />;
  if (curUser.access < 100) {
    createdCertificates = <Grid item lg={7} xl={7} md={12} xs={12}><CreatedCertificates /></Grid>
    newCertificates = <Grid item lg={5} xl={5} md={12} xs={12}><NewCertificates /></Grid>
  }

  return <Example 
    adminMode={curUser.access >= 100}
    counters={props.counters} 
    countersLoading={props.countersLoading} 
    countersError={props.countersError} 
    recentNews={recentNews} 
    prolongationCertificates={prolongationCertificates} 
    createdCertificates={createdCertificates} 
    newCertificates={newCertificates} 
    adminStatistics={adminStatistics}
  />;
};

const mapStateToProps = state => {
  return {
    isNewsLoading: state.news.isLoading,
    isNewsLoaded: state.news.isLoaded,
    newsError: state.news.loadError,
    news: state.news.news,

    counters: state.dashboard.counters,
    countersLoading: state.dashboard.countersLoading,
    countersError: state.dashboard.countersError,

    curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadNews: () => dispatch(actions.loadNews({"active":1}, 1, 5, null)),
    onLoadCounters: () => dispatch(actions.loadCounters()),
    onLoadAdminStatistics: () => dispatch(actions.loadAdminStatistics()),
    onLoadCreatedCertificates: () => dispatch(actions.loadCreatedCertificates()),
    onLoadNewCertificates: () => dispatch(actions.loadNewCertificates()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
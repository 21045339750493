import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  makeStyles,
  colors
} from '@material-ui/core';

const DailyStatistics = ({ className, ...rest }) => {
  const theme = useTheme();

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 5,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.default,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  const bar = <Bar data={generateData(rest.statistics)} options={options}/>

  return (
    <Card
      className={clsx(className)}
      {...rest}
    >
      <CardContent>
        <Box
          height={400}
          width="100%"
          position="relative"
        >
         {bar} 
        </Box>
      </CardContent>
    </Card>
  );
};

const generateData = (statistics) => {

  const values = {
    'new': [],
    'paid': [],
    'created': []
  };
  const labels = [];

  for (const i in statistics) {
    if (i>29) {
      break;
    }
    const el = statistics[i];
    labels.push(el.date);
    values.new.push(parseInt(el.new_count) + parseInt(el.created_count) + parseInt(el.paid_count))
    values.created.push(parseInt(el.created_count) + parseInt(el.paid_count))
    values.paid.push(parseInt(el.paid_count))
  }

  const data = {
    datasets: [
      {
        backgroundColor: colors.grey[500],
        data: values.new,
        label: 'Создано'
      },
      {
        backgroundColor: colors.indigo[200],
        data: values.created,
        label: 'Оформлено'
      },
      {
        backgroundColor: colors.orange[200],
        data: values.paid,
        label: 'Оплачено'
      }
    ],
    labels: labels
  };

  return data;
}

DailyStatistics.propTypes = {
  className: PropTypes.string
};

export default DailyStatistics;

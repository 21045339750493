import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialRate = {
    id: 0,
    name: '',
    percent: 0,
    user_id: 0,
    parent_id: 0,
    rates: [],
};

const initialState = {
    isLoading: false,
    isLoaded: false,
    loadError: false,
    rate: initialRate,

    isParentLoading: false,
    parentRate: null,
    parentError: null,

    childRates: null,
    isChildRatesLoading: false,
    childRatesError: null,
    childRatesLoaded: false,

    isSaving: false,
    savedId: false,
    saveError: null,
    errorFields: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.RATE_INITIALIZE: 
            return updateState(state, {
                isLoading: false, isLoaded: false, rate: initialRate, loadError: null, 
                errorFields: null, savedId: null, 
                parentRate: null, isParentLoading: false, parentError: null,
                childRates:null, childRatesLoaded: false, childRatesError: null, isChildRatesLoading: false
            });

        case actionTypes.RATE_UPDATE:
            const updatedRate = copyObject(state.rate);
            for (const i in action.data)
            updatedRate[i] = action.data[i];
            return updateState(state, { rate: updatedRate });

        case actionTypes.RATE_SAVE_START: return updateState(state, {isSaving: true, saveError: null, errorFields: null, savedId: null});
        case actionTypes.RATE_SAVE_SUCCESS: return updateState(state, {isSaving: false, savedId: action.rateId });
        case actionTypes.RATE_SAVE_FAIL: return updateState(state, {isSaving: false, saveError: action.error, errorFields: action.fields });

        case actionTypes.RATE_LOAD_START: return updateState(state, {isLoading: true, isLoaded: false, loadError: null, errorFields: null, savedId: null});
        case actionTypes.RATE_LOAD_SUCCESS: return updateState(state, {isLoading: false, isLoaded: true, rate: action.rate, parentRate: null, isParentLoading: false, parentError: null, childRates:null, childRatesLoaded: false, childRatesError: null, isChildRatesLoading: false });
        case actionTypes.RATE_LOAD_FAIL: return updateState(state, {isLoading: false, isLoaded: false, loadError: action.error });

        case actionTypes.PARENT_RATE_START: return updateState(state, {isParentLoading: false, parentRate: null, parentError: null });
        case actionTypes.PARENT_RATE_SUCCESS: return updateState(state, {isParentLoading: false, parentRate: action.rate, parentError: null });
        case actionTypes.PARENT_RATE_FAIL: return updateState(state, {isParentLoading: false, parentRate: null, parentError: action.error });

        case actionTypes.CHILD_RATE_START: return updateState(state, {isChildRatesLoading: false, childRates: null, childRatesError: null, childRatesLoaded: false });
        case actionTypes.CHILD_RATE_SUCCESS: return updateState(state, {isChildRatesLoading: false, childRates: action.rates, childRatesError: null, childRatesLoaded: true });
        case actionTypes.CHILD_RATE_FAIL: return updateState(state, {isChildRatesLoading: false, childRates: null, childRatesError: action.error, childRatesLoaded: false });

        default: return state;
    }
}

export default reducer;
import React from 'react';
import { connect } from 'react-redux';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Divider,
  Grid,
  makeStyles
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import * as actions from '../../../store/actions';
import MessageForm from '../../messages/MessageForm';

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  chip: {
    marginRight: '4px', marginBottom: '4px'
  },
  marginTop: {
    marginTop: "10px"
  }
}));

const MassMessageBlock = (props) => {
  const classes = useStyles();

  if (props.massMessageData && Object.keys(props.massMessageData).length > 0) {

    const recepicients = [];
    for (const i in props.massMessageData)
      recepicients.push(<Chip onClick={(event)=>props.removeUserHandler(i)} className={classes.chip} key={i} label={props.massMessageData[i]} />);

    return (
      <Box mt={3}>
          <Box className={classes.userBox}>
            <Card>
              <CardHeader
                subheader="Сообщение будет отправлено данному списку агентов. Нажмите на плашку агента чтобы удалить его из получателей"
                title="Массовая рассылка сообщения"
              />
              <Divider />
              <CardContent>
                <Box>
                  <Grid container spacing={3} >
                    <Grid item lg={12} md={12} xs={12} className={classes.center} >
                      <Box style={{marginBottom:"10px"}}>
                        {recepicients}
                      </Box>
                      <MessageForm 
                      onSaveMessage={(text)=>props.onSaveMessage(Object.keys(props.massMessageData), text)}
                      isSaving={props.isSaving}
                      saveError={props.saveError}
                      savedId={props.savedId}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Box>
    );

  } else if (props.savedId) {

    return (
      <Box mt={3}>
          <Box className={classes.userBox}>
            <Card>
              <CardContent>
                <Alert severity="success" className={classes.marginTop}>Сообщения успешно отправлены</Alert>
              </CardContent>
            </Card>
          </Box>
        </Box>
    );

  } else return null;

  
};

const mapStateToProps = state => {
  return {
      isSaving: state.messages.isSaving, 
      saveError: state.messages.saveError,
      savedId: state.messages.savedId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSaveMessage: (userIds, text) => dispatch(actions.saveMessage(userIds, text)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MassMessageBlock);
import * as actionTypes from '../actions/actionTypes';

import { updateState } from '../../shared/utility';

const initialState = {
    loading: false,
    error: null,
    success: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_RESET_START: return updateState(state, {error:null, loading: true, success: false});
        case actionTypes.AUTH_RESET_FAIL: return updateState(state, {loading: false, error: action.error});
        case actionTypes.AUTH_RESET_SUCCESS: return updateState(state, {loading: false, success: action.userId});
        default: return state;
    }
}

export default reducer;
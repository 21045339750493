const parseServerRate = (serverData, infoRate = false) => {

    //console.log("SERVER DATA", serverData);

    const data = {};

    for (const fieldName in serverData) {
        
        switch (fieldName) {

            case "rates": 

                const rates = [];
                for (const i in serverData[fieldName]) {
                    const newRate = serverData[fieldName][i];
                    if (serverData.id === 1 || infoRate) {
                        newRate.region_id = JSON.parse(newRate.region_id);
                        newRate.category = JSON.parse(newRate.category);
                        newRate.task = JSON.parse(newRate.task);
                        newRate.prolong = JSON.parse(newRate.prolong);
                    }
                    rates.push(newRate);
                }
                
                data.rates = rates;
            break;

            case "parent_rates": 

                const parent_rates = {};
                for (const i in serverData[fieldName]) {
                    const newParentRate = serverData[fieldName][i];
                    //console.log("PARENT RATE", serverData.id, infoRate);
                    //if (serverData.id === 1 || infoRate) {
                        newParentRate.region_id = JSON.parse(newParentRate.region_id);
                        newParentRate.category = JSON.parse(newParentRate.category);
                        newParentRate.task = JSON.parse(newParentRate.task);
                        newParentRate.prolong = JSON.parse(newParentRate.prolong);
                        //console.log("NEW PARENT RATE", newParentRate);
                    //}
                    parent_rates[i] = newParentRate;
                }

                //console.log("FINAL PARENT RATES", parent_rates);
                
                data.parent_rates = parent_rates;
            break;

            default: data[fieldName] = serverData[fieldName];
        }

    }

    //console.log("FINAL DATA", data);

    return data;
}

export default parseServerRate;
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from '../../../components/Page';
import SmartTable from '../../../components/SmartTable';
import Toolbar from './Toolbar';

import Spinner from '../../../components/Spinner';
import tokenizedAxios from '../../../utils/tokenizedAxios';
import ErrorBlock from '../../../components/ErrorBlock';
import { reportDeleteableStauses } from '../../../settings/profins';
import { usersAutocompleteUrl } from '../../../settings/api_urls';

import * as actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3)
  },
  marginTop: {
    marginTop: '20px'
  },
  tooltipLink: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  }
}));

const smartTableHeaders = { 
  'id':'Номер', 
  'name':'Название', 
  'status_id':'Статус',
  'user_id':'Автор', 
  'sum':'Сумма',
  'income':'Комиссия',
  'cash':'На руках',
  'difference':'К сдаче',
  'certificates':'Полисов',
  'created_at':'Создан',  
}

const ReportListView = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const onLoadReports = props.onLoadReports;
  const onLoadCatalog = props.onLoadCatalog;
  const currentFilter = props.filter;

  const filterHandler = useCallback((filter, page, perPage, order) => {
    //console.log("LOAD REPORTS", filter);
    onLoadReports(filter, page, perPage, order);
  }, [onLoadReports]);

  const clickHandler = (reportId) => {
    navigate("/app/reports/" + reportId);
  }

  const deleteHandler = (reportId) => {
    props.onDeleteReport(reportId);
  }

  const catalogsGeneral = props.catalogs['general'] ?? false;
  const catalogsRegions = props.catalogs['regions'] ?? false;

  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsRegions) onLoadCatalog('regions')
    else if (props.reports === null) filterHandler(currentFilter, 1, 20, {id: "DESC"})
  }, [filterHandler, onLoadCatalog, catalogsGeneral, catalogsRegions, currentFilter, props.reports]);

  const smartTableColumnCreator = (record) => {
    return {
      'id': record.id,
      'name': record.name,
      'status_id': catalogsGeneral['report_statuses'][record.status_id] ? <Chip color={catalogsGeneral['report_statuses'][record.status_id].color} label={catalogsGeneral['report_statuses'][record.status_id].name} /> : <ErrorBlock error="НЕИЗВЕСТНЫЙ СТАТУС" />,
      'user_id': (parseInt(record.user_id) === 0 ? "" : (record.user ? (record.user.name_f + ' ' + record.user.name_i) + (parseInt(record.is_created_by_admin) === 1 ? " [Админ]" : "") : <ErrorBlock error="НЕИЗВЕСТНЫЙ АВТОР" />)),
      'sum': record.sum,
      'income': record.income,
      'cash': record.cash,
      'difference': record.cash - record.income,
      'certificates': record.certificates,
      'created_at': new Date(record.created_at).toLocaleDateString(),
    };
  };

  async function searchAgentFunc (request, callback) {
    const response = await tokenizedAxios(usersAutocompleteUrl, 'get', {search: request});
    const optionsData = [];
    let error = null;
    if (response.data.result === "ok") {
        const users = response.data.users;
        for (const i in response.data.users) 
            optionsData.push({id: users[i].id, value: users[i].name_f + " " + users[i].name_i});
    } else {
        error = response.errorstring;
    }

    callback(optionsData, error);
  }

  const smartTableFilters = {
    //'search': { type: 'search', width: 2, name: "Поиск агента", placeholder: "Поиск по ФИО агента" },
    'created_at': { type: 'dateRange', name: "Дата создания" },
    //'rate_id': { type: 'multiselect', name: "Тариф", options: [] },
    'status_id': {type: 'multiselect', name: "Статус", options: catalogsGeneral ? catalogsGeneral['report_statuses'] : null},
    'user_id': {type: 'multiselectServerSearch', name: "Агент", searchFunc: (request, callback) => searchAgentFunc(request,callback)},
    //'region_id': {type: 'multiselectSearch', name: "Регион", options: catalogsRegions},
  }

  const smartTableButtonsData = (record) => {
    const links = [];
    links.push(<MenuItem key={1} onClick={(event)=>clickHandler(record.id)}>Редактировать</MenuItem>);
    if (reportDeleteableStauses.includes(record.status_id)) links.push(<MenuItem key={3} onClick={(event)=>deleteHandler(record.id)}>Удалить</MenuItem>);
    return links;
  }

  let table = <Spinner></Spinner>;
  if (props.isCatalogsLoading) table = <Spinner text="Загружаем каталоги"></Spinner>
  else
    table = <SmartTable 
      isLoading={props.isReportsLoading}
      tableheaders={smartTableHeaders} 
      rowCreator={smartTableColumnCreator}
      tooltipData={null}
      buttonsData={smartTableButtonsData}
      filters={smartTableFilters}
      data={props.reports} 
      total={props.total}
      onFilter={filterHandler} 
      onRowClick={clickHandler}
      error={props.error} 
      filter={props.filter}
      order={props.order}
      page={props.page}
      perPage={props.perPage}
    />;

  const totalBox = <Box className={classes.userBox} mt={3}>
    <Card>
      <CardHeader
        title="Информация по отчётам"
      />
      <Divider />
      <CardContent>
        <Typography>{parseInt(props.totalNew) > 0 ? 'Новых отчётов: ' + props.totalNew : 'Нет новых отчётов'}</Typography>
      </CardContent>
    </Card>
  </Box>

  return (
    <Page
      className={classes.root}
      title="Reports"
    >
      <Container maxWidth={false}>
        <Toolbar className={classes.marginTop} />
        {totalBox}
        <Box mt={3}>
          {table}
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      isReportsLoading: state.reports.isLoading,
      error: state.reports.error,
      reports: state.reports.reports,
      total: state.reports.total,
      totalNew: state.reports.totalNew,
      page: state.reports.page,
      perPage: state.reports.perPage,
      filter: state.reports.filter,
      order: state.reports.order,

      isCatalogsLoading: state.catalogs.isLoading,
      catalogs: state.catalogs.catalogs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadReports: (filter, page, perPage, order) => dispatch(actions.loadReports(filter, page, perPage, order)),
      onLoadCatalog: (catalogName) => dispatch(actions.loadCatalog(catalogName)),
      onDeleteReport: (reportId) => dispatch(actions.deleteReport(reportId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportListView);
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';

const initialState = {
    isLoading: false,
    certificates: null,
    total: null,
    error: null,

    filter: {},
    page: 1,
    perPage: 20,
    order: {certificate_processed_at: 'DESC'},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CERTIFICATES_RESET:
        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.CERTIFICATES_LOAD_START:   
            const finalNewState = {isLoading: true};
            if (action.filter !== null) finalNewState.filter = action.filter;
            if (action.page !== null) finalNewState.page = action.page;
            if (action.perPage !== null) finalNewState.perPage = action.perPage;
            if (action.order !== null) finalNewState.order = action.order;
            return updateState(state, finalNewState);

        case actionTypes.CERTIFICATES_LOAD_SUCCESS: return updateState(state, {isLoading: false, certificates: action.certificates, total: action.total * 1});
        case actionTypes.CERTIFICATES_LOAD_FAIL:    return updateState(state, {isLoading: false, certificates: null, total: null, error: action.error });

        case actionTypes.CERTIFICATES_DELETE_SUCCESS: 
            const newCertificates = [];
            for (const i in state.certificates)
                if (state.certificates[i].id !== action.certificateId)
                newCertificates.push(state.certificates[i]);
            return updateState(state, {isLoading: false, certificates: newCertificates, total: state.total - 1});

        case actionTypes.CERTIFICATES_CANCEL_SUCCESS: 
            const finalCertificates = [];
            for (const i in state.certificates) {
                if (state.certificates[i].id === action.certificateId)
                    state.certificates[i].status_id = action.newStatus;
                finalCertificates.push(state.certificates[i]);
            }
            return updateState(state, {isLoading: false, certificates: finalCertificates});

        default: return state;
    }
}

export default reducer;
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  List,
  TextField,
  SvgIcon,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Page from '../../components/Page';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Search as SearchIcon } from 'react-feather';

import Spinner from '../../components/Spinner';
import DialogElement from './DialogElement';

import * as actions from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    marginTop: '20px'
  },
  marginTop: {
    marginTop: '20px'
  },
}));

const Dialogs = (props) => {

  const dialogRefresher = useRef(null);
  const [searchString, setSearchString] = useState("");
  const [delayedSearch, setDelayedSearch] = useState();

  const classes = useStyles();
  const navigate = useNavigate();

  const onLoadDialogs = props.onLoadDialogs;
  
  const filterHandler = useCallback((filter, page, perPage) => {
    onLoadDialogs(filter, page, perPage);
  }, [onLoadDialogs]);

  const openDialogPage = (userId) => {
    navigate("/app/messages/" + userId);
  }

  const startDelayedSearch = (value) => {
    if (delayedSearch !== undefined && delayedSearch !== null) {
      clearTimeout(delayedSearch.timeout);
    }
    const newDelayedSearch = {
      value: value,
      timeout: setTimeout(()=>filterHandler({search: value}, 1, 10000), 1000)
    }

    setDelayedSearch(newDelayedSearch);
  }

  useEffect(() => {
    filterHandler({}, 1, props.perPage)
  },[filterHandler, props.perPage]);

  useEffect(() => {
    if (dialogRefresher.current) clearInterval(dialogRefresher.current);
    dialogRefresher.current = setInterval(()=>filterHandler({search: searchString}, 1, props.perPage), 10000);
    return () => {
      clearInterval(dialogRefresher.current);
    }
  },[filterHandler, props.perPage, searchString]);

  let blocks = <Grid item lg={12} md={12} xs={12}><Spinner text="Загружаем диалоги" /></Grid>;
  let pages = 0;
  if (props.error) {
    blocks = <Alert severity="error" align="left">{props.error}</Alert>
  } else if (props.dialogs) {
    blocks = [];
    pages = Math.ceil(props.total / props.perPage);
    for (const i in props.dialogs)
      blocks.push(<DialogElement 
        key={i} 
        divider={i < props.dialogs.length - 1}
        dialog={props.dialogs[i]} 
        onClickHandler={openDialogPage}
      />);
  }

  return (

    <Page
      className={classes.root}
      title="Диалоги"
    >
      <Container maxWidth={false}>
        <Card>
          <CardHeader
            title="Диалоги"
          />
          <Divider />
          <CardContent>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action" ><SearchIcon /></SvgIcon>
                  </InputAdornment>
                )
              }}
              name="search"
              value={searchString}
              label="Поиск по диалогам"
              placeholder="Игнатьева Наталья"
              helperText="Начните вводить фамилию, имя, email или телефон собеседника"
              variant="outlined"
              onChange={(event) => { startDelayedSearch(event.target.value); setSearchString(event.target.value); }}
            />
          </CardContent>
          <List>
            {blocks}
          </List>
          <Divider />
          <Box
            display="flex"
            justifyContent="flex-end"
            p={2}
          >
            {props.page < pages ?
            <Button
              color="primary"
              endIcon={<ArrowRightIcon />}
              size="small"
              variant="text"
              onClick={() => filterHandler({}, props.page*1 + 1, props.perPage)}
            >
              Показать более старые
            </Button> : null }
          </Box>
        </Card>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      isDialogsLoading: state.dialogs.isLoading,
      error: state.dialogs.error,
      dialogs: state.dialogs.dialogs,
      total: state.dialogs.total,
      filter: state.dialogs.filter,
      page: state.dialogs.page,
      perPage: state.dialogs.perPage,

      curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadDialogs: (filter, page, perPage) => dispatch(actions.loadDialogs(filter, page, perPage)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dialogs);
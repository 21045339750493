import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from '../../../components/Page';
import SmartTable from '../../../components/SmartTable';
import Toolbar from './Toolbar';

import {UserPlus} from 'react-feather';

import Spinner from '../../../components/Spinner';
import ErrorBlock from '../../../components/ErrorBlock';
import getFio from '../../../utils/getFio';
import { userDeleteableStauses } from '../../../settings/profins';

import * as actions from '../../../store/actions';
import MassMessageBlock from './MassMessageBlock';
import ConfirmDialog from '../../../components/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3)
  },
  marginTop: {
    marginTop: '20px'
  },
  tooltipLink: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  }
}));

const smartTableHeaders = { 
  'id':'Номер', 
  'name':'Агент', 
  'region_id':'Регион',
  'status_id':'Статус', 
  'rate_id':'Ком. вознаграждение',  
  'parent_id':'Автор',
  'logged_at':'Посл. вход',
}

const UserListView = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const [confirmBlock, setConfirmBlock] = useState(null);

  const onLoadUsers = props.onLoadUsers;
  const onLoadCatalog = props.onLoadCatalog;
  const onLoadRates = props.onLoadRates;
  const currentFilter = props.filter;

  const filterHandler = useCallback((filter, page, perPage, order) => {
    onLoadUsers(filter, page, perPage, order);
  }, [onLoadUsers]);

  const clickHandler = (userId) => {
    navigate("/app/users/" + userId);
  }

  const deleteHandler = (userId) => {
    props.onDeleteUser(userId);
  }

  const catalogsGeneral = props.catalogs['general'] ?? false;
  const catalogsRegions = props.catalogs['regions'] ?? false;
  const rates = props.rates;

  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsRegions) onLoadCatalog('regions')
    else if (!props.isRatesLoading && !props.ratesError && !rates) onLoadRates({}, 1, 100, {'name':'asc'});
    else if (props.users === null) filterHandler(currentFilter, 1, 20, {id: "DESC"})
  }, [filterHandler, onLoadCatalog, catalogsGeneral, catalogsRegions, currentFilter, props.users, onLoadRates, rates, props.isRatesLoading, props.ratesError]);

  const smartTableColumnCreator = (record) => {
    return {
      'id': record.id,
      'name': record.name_f + " " + record.name_i,
      'region_id': (catalogsRegions ? (catalogsRegions[record.region_id] ? catalogsRegions[record.region_id].name : '') : ''),
      'status_id': catalogsGeneral['user_status'][record.status_id] ? catalogsGeneral['user_status'][record.status_id].name : <ErrorBlock error="НЕИЗВЕСТНЫЙ СТАТУС" />,
      'rate_id': (record.rate_name ?? <ErrorBlock error="НЕИЗВЕСТНОЕ КВ" />),
      'parent_id': (record.author_name),
      'logged_at': record.logged_at !== "0000-00-00 00:00:00" ? moment(record.logged_at).format("DD.MM.YY HH:mm") : "",
    };
  };

  const addToMassMessageHandler = (selectedIds) => {
    const dataToAdd = {};
    selectedIds.forEach( (item, index) => {
      for (const i in props.users) {
        if (props.users[i].id*1 === item*1)
          dataToAdd[item] = props.users[i].name_f + " " + props.users[i].name_i;
      }
    });
    props.addMassMessageUsers(dataToAdd);
  }

  const openConfirmBlock = (onContinue, header, text) => {
    setConfirmBlock({onContinue: onContinue, onCancel: ()=>setConfirmBlock(null), header: header, text: text});
  }

  const smartTableFilters = {
    'search': { type: 'search', width: 2, name: "Поиск агента", placeholder: "Поиск по ФИО агента" },
    'created_at': { type: 'dateRange', name: "Дата создания" },
    'rate_id': { type: 'multiselect', name: "Ком. вознаграждение", options: rates },
    'status_id': {type: 'multiselect', name: "Статус", options: catalogsGeneral ? catalogsGeneral['user_status'] : null},
    'region_id': {type: 'multiselectSearch', name: "Регион", options: catalogsRegions},
  }

  /*const smartTableTooltipData = (record) => {
    return <Box padding={3}>
      <Grid container spacing={2} >
        <Grid item md={4} xs={12} ><b>Агент</b></Grid>
        <Grid item md={8} xs={12} >{record.agent ? getFio(record.agent) : 'Неизвестно'}</Grid>
        <Grid item md={4} xs={12} ><b>Регион</b></Grid>
        <Grid item md={8} xs={12} >{props.catalogs['regions'] ? (props.catalogs['regions'][record.client_region_id] ? props.catalogs['regions'][record.client_region_id].name : 'Регион не найден') : 'Неизветно'}</Grid>
        <Grid item md={12} xs={12} className={classes.marginTop}><b>Контакты страхователя</b></Grid>
        <Grid item md={4} xs={12} ><b>Телефон</b></Grid>
        <Grid item md={8} xs={12} >{record.client_phone ?? 'Не указан'}</Grid>
        <Grid item md={4} xs={12} ><b>E-mail</b></Grid>
        <Grid item md={8} xs={12} >{record.client_email ?? 'Не указан'}</Grid>
      </Grid>
    </Box>
  }*/

  const smartTableButtonsData = (record) => {

    const links = [];
    links.push(<MenuItem key={1} onClick={(event)=>clickHandler(record.id)}>Редактировать</MenuItem>);
    //if (certificateContinueableStauses.includes(record.status_id)) links.push(<MenuItem key={2} onClick={(event)=>calculateHandler(record.id)}>Рассчитать</MenuItem>);
    //if (userDeleteableStauses.includes(record.status_id)) links.push(<MenuItem key={3} onClick={(event)=>deleteHandler(record.id)}>Удалить</MenuItem>);
    if (props.curUser.access >= 50 && record.id*1 !== props.curUser.id*1) links.push(<MenuItem key={2} onClick={(event)=>openConfirmBlock(()=>deleteHandler(record.id), 'Удаление агента '+record.name_f+' '+record.name_i, 'Вы действительно хотите удалить этого агента?')}>Удалить</MenuItem>);
    if (props.curUser.access >= 50 && record.id*1 !== props.curUser.id*1) links.push(<MenuItem key={3} onClick={(event)=>navigate('/app/messages/'+record.id)}>Отправить сообщение</MenuItem>);

    return links;
  }

  const deleteRecepicient = (userId) => {
    props.removeMassMessageUsers([userId]);
  }

  let smartTableMassButtons = null;
  let massMessageBlock = null;
  let table = <Spinner></Spinner>;
  if (props.isCatalogsLoading) table = <Spinner text="Загружаем каталоги"></Spinner>
  else {

    if (props.curUser.access >= 50) {
      smartTableMassButtons = [
        {
          name: "Добавить в массовую рассылку", 
          onClick: (selectedIds)=>addToMassMessageHandler(selectedIds),
          icon: <UserPlus />
        }
      ];
      massMessageBlock = <MassMessageBlock removeUserHandler={deleteRecepicient} massMessageData={props.massMessageIds} />
    }

    table = <SmartTable 
      isLoading={props.isUsersLoading}
      tableheaders={smartTableHeaders} 
      rowCreator={smartTableColumnCreator}
      tooltipData={null}
      buttonsData={smartTableButtonsData}
      filters={smartTableFilters}
      data={props.users} 
      total={props.total}
      onFilter={filterHandler} 
      onRowClick={clickHandler}
      massButtons={smartTableMassButtons}
      error={props.error} 
      filter={props.filter}
      order={props.order}
      page={props.page}
      perPage={props.perPage}
    />;
  }

  let confirmDialog = null;
  if (confirmBlock !== null)
    confirmDialog = <ConfirmDialog 
      dialogHeader={confirmBlock.header} 
      dialogText={confirmBlock.text} 
      cancelHandler={confirmBlock.onCancel} 
      continueHandler={confirmBlock.onContinue} 
    />;

  return (
    <Page
      className={classes.root}
      title="Users"
    >
      <Container maxWidth={false}>
        {massMessageBlock}
        <Toolbar className={classes.marginTop} />
        <Box mt={3}>
          {table}
          {confirmDialog}
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      isUsersLoading: state.users.isLoading,
      error: state.users.error,
      users: state.users.users,
      total: state.users.total,
      page: state.users.page,
      perPage: state.users.perPage,
      filter: state.users.filter,
      order: state.users.order,

      massMessageIds: state.messages.massMessageIds,

      rates: state.rates.rates,
      isRatesLoading: state.rates.isLoading,
      ratesError: state.rates.error,

      isCatalogsLoading: state.catalogs.isLoading,
      catalogs: state.catalogs.catalogs,

      curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadUsers: (filter, page, perPage, order) => dispatch(actions.loadUsers(filter, page, perPage, order)),
      onLoadRates: (filter, page, perPage, order) => dispatch(actions.loadRates(filter, page, perPage, order)),
      onLoadCatalog: (catalogName) => dispatch(actions.loadCatalog(catalogName)),
      onDeleteUser: (userId) => dispatch(actions.deleteUser(userId)),

      addMassMessageUsers: (userIds) => dispatch(actions.addMassMessageUsers(userIds)),
      removeMassMessageUsers: (userIds) => dispatch(actions.removeMassMessageUsers(userIds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListView);
import {put} from 'redux-saga/effects';

import * as actions from '../actions/news';
import { generalError, generalSuccess, generalStart } from '../actions/general';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { newsUrl, newsDeleteUrl } from '../../settings/api_urls';

export function* loadNewsSaga(action) 
{
    yield put(actions.loadStart(action.filter, action.page, action.perPage, action.order));

    const getData = {
        ...action.filter,
        page: action.page ?? 1,
        perPage: action.perPage ?? 20,
        order: action.order ?? { "id": "DESC" },
    }

    try {
        const response = yield tokenizedAxios(newsUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const news = response.data.news;
            const total = response.data.total;
            yield put(actions.loadSuccess(news, total));
        } else {
            yield put(actions.loadError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* deleteNewsSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(newsDeleteUrl, 'post', {news_id: action.newsId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Новость " + action.newsId + " успешно удалена"));
            yield put(actions.deleteNewsSuccess(action.newsId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
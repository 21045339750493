import {
    Grid,
  } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import onlyDigits from '../../utils/onlyDigits';

const ElkDateSelector = (props) => {

    let curDate = props.value ? ( onlyDigits(props.value).length === 8 ? moment(props.value, "DD.MM.YYYY") : props.value ) : null;
    
    return <Grid item lg={props.width ?? 4} md={props.width ?? 4} xs={12} >
        <KeyboardDatePicker
        fullWidth
        onBlur={props.handleBlur}
        label={props.label}
        name={props.name}
        error={props.error}
        helperText={props.helperText}
        format="dd.MM.yyyy"
        inputVariant="outlined"
        placeholder="30.12.2021"
        required={props.required}
        value={curDate}
        onChange={(event, value) => { props.setFieldValue(props.name, value); props.onValueChange(value) }}
        KeyboardButtonProps={{
            'aria-label': 'change date',
        }}
        />
    </Grid>
}

export default ElkDateSelector;
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialState = {
    files: {}
}

const reducer = (state = initialState, action) => {

    let files = null;

    switch (action.type) {

        case actionTypes.UPLOAD_FILE_START: 
            files = copyObject(state.files);
            files[action.fileName] = { loading: true, ...action.additional }
            return updateState(state, { files: files });

        case actionTypes.UPLOAD_FILE_SUCCESS: 
            files = copyObject(state.files);
            files[action.fileName] = { loading: false, success: true, ...action.additional }
            return updateState(state, { files: files });

        case actionTypes.UPLOAD_FILE_FAIL: 
            files = copyObject(state.files);
            files[action.fileName] = { loading: false, error: action.error, ...action.additional }
            return updateState(state, { files: files });

        default: return state;
    }
}

export default reducer;
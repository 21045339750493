import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';

const initialState = {
    isLoading: false,
    isLoaded: false,
    reports: null,
    total: null,
    totalNew: null,
    error: null,

    goToReportId: 0,
    isReportsWindowOpened: false,
    reportWindowCertificateIds: [],

    filter: {},
    page: 1,
    perPage: 20,
    order: {id: 'DESC'},

    isNewReportsLoading: false,
    newReports: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.REPORTS_RESET:
        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.REPORTS_LOAD_START:   
            const finalNewState = {isLoading: true, isLoaded: false};
            if (action.filter !== null) finalNewState.filter = action.filter;
            if (action.page !== null) finalNewState.page = action.page;
            if (action.perPage !== null) finalNewState.perPage = action.perPage;
            if (action.order !== null) finalNewState.order = action.order;
            return updateState(state, finalNewState);

        case actionTypes.REPORTS_LOAD_SUCCESS: return updateState(state, {isLoading: false, isLoaded: true, reports: action.reports, total: action.total * 1, totalNew: action.totalNew * 1});
        case actionTypes.REPORTS_LOAD_FAIL:    return updateState(state, {isLoading: false, isLoaded: false, reports: null, total: null, error: action.error });

        case actionTypes.REPORTS_DELETE_SUCCESS: 
            const newReports = [];
            for (const i in state.reports)
                if (state.reports[i].id !== action.reportId)
                newReports.push(state.reports[i]);

            return updateState(state, {isLoading: false, reports: newReports, total: state.total - 1});

        case actionTypes.REPORTS_OPEN:
            return updateState(state, {isReportsWindowOpened: true, reportWindowCertificateIds: action.certificateIds, goToReportId: 0 });
        case actionTypes.REPORTS_REDIRECT: 
            return updateState(state, {isReportsWindowOpened: true, reportWindowCertificateIds: null, isLoading: false, reports:null, error: null, isLoaded: false, goToReportId: action.goToReportId});
        case actionTypes.REPORTS_CLOSE: 
            return updateState(state, {isReportsWindowOpened: false, reportWindowCertificateIds: null, isLoading: false, reports:null, error: null, isLoaded: false, goToReportId: 0});

        default: return state;
    }
}

export default reducer;
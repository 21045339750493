import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Backdrop,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Spinner from '../../../components/Spinner';
import * as actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: { marginTop: "20px" },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const UserLog = (props) => {

  const classes = useStyles();

  const onLoadCatalog = props.onLoadCatalog;
  const onLoadUserLog = props.onLoadUserLog;
  const onLoadRates = props.onLoadRates;
  const catalogsGeneral = props.catalogs['general'] ?? false;
  const rates = props.rates ?? false;
  const userLog = props.userLog ?? false;
  const page = props.page ?? 1;
  const userId = props.userId;
  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!rates) onLoadRates({}, 1, 1000, {})
  }, [catalogsGeneral, onLoadCatalog, onLoadRates, rates]);

  useEffect(() => {
    if (!userLog) {
      onLoadUserLog(userId, 1);
    }
  }, [onLoadUserLog, userLog, userId]);

  const changePage = (event) => {
    onLoadUserLog(userId, event.target.value);
  }

  let backdrop = null;
  if (props.isLogLoading) backdrop = <Backdrop className={classes.backdrop} open={true}><Spinner text="Загружаем историю изменений" /></Backdrop>

  let table = null;
  let pagination = null;
  if (!props.logError) {
    if (userLog && Object.keys(userLog).length > 0) {

      let tableHeaders = <>
        <TableCell>Дата</TableCell>
        <TableCell>Автор</TableCell>
        <TableCell>Изменения</TableCell>
      </>

      table = <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(userLog).map((element) => {

                const changes = [];
                const changesJSON = JSON.parse(element.changes);
                for (const i in changesJSON) {
                  changes.push(
                    <TableRow>
                      <TableCell>{i}</TableCell>
                      <TableCell>{changesJSON[i].o}</TableCell>
                      <TableCell>{changesJSON[i].n}</TableCell>
                    </TableRow>
                  )
                }

                const changesBlock = <Table>
                  <TableHead>
                    {/*<TableRow>
                      <TableCell>Поле</TableCell>
                      <TableCell>Было</TableCell>
                      <TableCell>Стало</TableCell>
                    </TableRow>*/}
                  </TableHead>
                  <TableBody>
                    {changes}
                  </TableBody>
                </Table>

                return <TableRow hover key={element.id} >
                  <TableCell>{moment(element.created_at).format("DD.MM.YYYY")}<br/>{moment(element.created_at).format("HH:mm")}</TableCell>
                  <TableCell>{element.name ?? '???'}</TableCell>
                  <TableCell>{changesBlock}</TableCell>
                </TableRow>
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>;

      pagination = <TablePagination
        component="div"
        count={props.total}
        onChangePage={changePage}
        //onChangeRowsPerPage={handleLimitChange}
        page={props.page*1 - 1}
        rowsPerPage={50}
        //rowsPerPageOptions={[20, 50, 100]}
      />

    } else {
      table = <Box minWidth={1050}><Alert severity="info">Нет записей</Alert></Box>
    }
  } else 
    table = <Box minWidth={1050}><Alert severity="error">{props.logError}</Alert></Box>

  return <Card className={clsx(classes.root, props.className)} >
    {table}
    {pagination}
    {backdrop}
  </Card>

};

const mapStateToProps = state => {
  return {
    isLogLoading: state.user.isLogLoading,
    isLogLoaded: state.user.isLogLoaded,
    userLog: state.user.log,
    logError: state.user.logError,
    total: state.user.logTotal,
    page: state.user.logPage,
    rates: state.rates.rates,
    isCatalogsLoading: state.catalogs.isLoading,
    catalogs: state.catalogs.catalogs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCatalog: (catalogName, subId=null) => dispatch(actions.loadCatalog(catalogName, subId)),
      onLoadRates: (filter, page, perPage, order) => dispatch(actions.loadRates(filter, page, perPage, order)),
      onLoadUserLog: (userId, page) => dispatch(actions.loadUserLog(userId, page)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLog);

import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from '../../../components/Page';
import SmartTable from '../../../components/SmartTable';
import Toolbar from './Toolbar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Alert from '@material-ui/lab/Alert';

import RateInfo from '../RateForm/RateInfo';
import Spinner from '../../../components/Spinner';

import * as actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3)
  },
  marginTop: {
    marginTop: '20px'
  },
  tooltipLink: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  }
}));

const smartTableHeaders = { 
  'id':'Номер', 
  'name':'Название', 
  'percent':'Процент', 
}

const RateListView = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const onLoadRates = props.onLoadRates;
  const onLoadRateInfo = props.onLoadRateInfo;
  const onLoadCatalog = props.onLoadCatalog;
  const currentFilter = props.filter;
  const curUser = props.curUser;
  const curRate = props.curRate;

  const filterHandler = useCallback((filter, page, perPage, order) => {
    onLoadRates(filter, page, perPage, order);
  }, [onLoadRates]);

  const clickHandler = (rateId) => {
    navigate("/app/rates/" + rateId);
  }

  const deleteHandler = (rateId) => {
    props.onDeleteRate(rateId);
  }

  const catalogsGeneral = props.catalogs['general'] ?? false;
  const catalogsCompanies = props.catalogs['companies'] ?? false;
  const catalogsRegions = props.catalogs['regions'] ?? false;

  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsCompanies) onLoadCatalog('companies')
    else if (!catalogsRegions) onLoadCatalog('regions')
    else if (!curRate && curUser.rate_id > 1) onLoadRateInfo(curUser.rate_id, 'current');
    else filterHandler(currentFilter, 1, 1000, {id: "DESC"})
  }, [filterHandler, onLoadCatalog, catalogsGeneral, catalogsCompanies, catalogsRegions, currentFilter, curUser.rate_id, curRate, onLoadRateInfo]);

  const smartTableColumnCreator = (record) => {
    return {
      'id': record.id,
      'name': record.name,
      'percent': record.percent,
    };
  };

  const smartTableFilters = {
    'search': { type: 'search', width: 2, name: "Поиск", placeholder: "Поиск по названию комиссионного вознаграждения" },
    'region_id': {type: 'select', name: "Регион", options: props.catalogs['regions']},
    //'created_at': { type: 'dateRange', name: "Дата создания" },
  }

  const smartTableTooltipData = null;
  /*const smartTableTooltipData = (record) => {
    return <Box padding={3}>
      <Grid container spacing={2} >
        <Grid item md={4} xs={12} ><b>Агент</b></Grid>
        <Grid item md={8} xs={12} >{record.agent ? getFio(record.agent) : 'Неизвестно'}</Grid>
        <Grid item md={4} xs={12} ><b>Регион</b></Grid>
        <Grid item md={8} xs={12} >{props.catalogs['regions'] ? (props.catalogs['regions'][record.client_region_id] ? props.catalogs['regions'][record.client_region_id].name : 'Регион не найден') : 'Неизветно'}</Grid>
        <Grid item md={12} xs={12} className={classes.marginTop}><b>Контакты страхователя</b></Grid>
        <Grid item md={4} xs={12} ><b>Телефон</b></Grid>
        <Grid item md={8} xs={12} >{record.client_phone ?? 'Не указан'}</Grid>
        <Grid item md={4} xs={12} ><b>E-mail</b></Grid>
        <Grid item md={8} xs={12} >{record.client_email ?? 'Не указан'}</Grid>
      </Grid>
    </Box>
  }*/

  const smartTableButtonsData = (record) => {

    const links = [];
    links.push(<MenuItem key={1} onClick={(event)=>clickHandler(record.id)}>Редактировать</MenuItem>);
    links.push(<MenuItem key={3} onClick={(event)=>deleteHandler(record.id)}>Удалить</MenuItem>);

    return links;
  }

  //console.log("SMART TABLE", );

  //let table = null;
  //if (curUser.access === 100) {
    let table = <Spinner></Spinner>;
    if (props.isCatalogsLoading) table = <Spinner text="Загружаем каталоги"></Spinner>
    //else if (props.isCertificatesLoading) table = <Spinner text="Загружаем полисы"></Spinner>
    else //if (props.certificates)
      table = <SmartTable 
        isLoading={props.isRatesLoading}
        tableheaders={smartTableHeaders} 
        rowCreator={smartTableColumnCreator}
        tooltipData={smartTableTooltipData}
        buttonsData={smartTableButtonsData}
        filters={smartTableFilters}
        data={props.rates ? Object.values(props.rates) : null} 
        total={props.total}
        onFilter={filterHandler} 
        onRowClick={clickHandler}
        error={props.error} 
        filter={props.filter}
        order={props.order}
        page={props.page}
        perPage={props.perPage}
      />;
  //}

  //console.log("DATA", curRate);

  let curRateContainer = null;
  if (curUser.rate_id > 1 && curUser.access < 50) {
    let curRateBlock = <Box mt={1} style={{textAlign:"center", width:"100%"}}><Spinner text="Загружаем данные о тарифе "/></Box>;
    if (props.curRateError) curRateBlock = <Alert severity="error" style={{width: "100%"}}>{ props.curRateError }</Alert>
    else if (curRate) curRateBlock = <RateInfo infoRate={curRate} readOnly={true} rates={curRate.rates} />
    curRateContainer = <Box className={classes.userBox}>
      <Card>
        <CardHeader
          subheader="Здесь представлены данные по вашему комиссионному вознаграждению, на котором работаете Вы"
          title="Ваше текущее комиссионное вознаграждение"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} >
            { curRateBlock }
          </Grid>
        </CardContent>
      </Card>
    </Box>
  }

  return (
    <Page
      className={classes.root}
      title="Customers"
    >
      <Container maxWidth={false}>
        {/*curUser.access === 100 ? <Toolbar className={classes.marginTop} /> : null*/}
        <Toolbar className={classes.marginTop} />
        <Box mt={3}>
          {curRateContainer}
          {table}
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      isRatesLoading: state.rates.isLoading,
      error: state.rates.error,
      rates: state.rates.rates,
      total: state.rates.total,
      page: state.rates.page,
      perPage: state.rates.perPage,
      filter: state.rates.filter,
      order: state.rates.order,

      curUser: state.auth.user,
      curRate: state.auth.rate,
      curRateLoading: state.auth.rateLoading,
      curRateError: state.auth.rateError,

      isCatalogsLoading: state.catalogs.isLoading,
      catalogs: state.catalogs.catalogs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadRateInfo: (rateId, mode) => dispatch(actions.loadRateInfo(rateId, mode)),
    onLoadRates: (filter, page, perPage, order) => dispatch(actions.loadRates(filter, page, perPage, order)),
    onLoadCatalog: (catalogName) => dispatch(actions.loadCatalog(catalogName)),
    onDeleteRate: (rateId) => dispatch(actions.deleteRate(rateId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RateListView);
import {put} from 'redux-saga/effects';

import * as actions from '../actions/dashboard';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { countersUrl, prolongationCertificatesUrl, newCertificatesUrl, createdCertificatesUrl, statisticsUrl } from '../../settings/api_urls';

export function* loadCountersSaga(action) 
{
    yield put(actions.loadCountersStart());

    try {
        const response = yield tokenizedAxios(countersUrl, 'get', {});
        if (response.data.result === 'ok') {
            const counters = response.data.counters;
            yield put(actions.loadCountersSuccess(counters));
        } else {
            yield put(actions.loadCountersFail(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadCountersFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* loadProlongationCertificatesSaga(action) 
{
    yield put(actions.loadProlongationCertificatesStart());

    try {
        const response = yield tokenizedAxios(prolongationCertificatesUrl, 'get', {});
        if (response.data.result === 'ok') {
            const certificates = response.data.certificates;
            yield put(actions.loadProlongationCertificatesSuccess(certificates));
        } else {
            yield put(actions.loadProlongationCertificatesFail(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadProlongationCertificatesFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* loadCreatedCertificatesSaga(action) 
{
    yield put(actions.loadCreatedCertificatesStart());

    try {
        const response = yield tokenizedAxios(createdCertificatesUrl, 'get', {});
        if (response.data.result === 'ok') {
            const certificates = response.data.certificates;
            yield put(actions.loadCreatedCertificatesSuccess(certificates));
        } else {
            yield put(actions.loadCreatedCertificatesFail(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadCreatedCertificatesFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* loadNewCertificatesSaga(action) 
{
    yield put(actions.loadNewCertificatesStart());

    try {
        const response = yield tokenizedAxios(newCertificatesUrl, 'get', {});
        if (response.data.result === 'ok') {
            const certificates = response.data.certificates;
            yield put(actions.loadNewCertificatesSuccess(certificates));
        } else {
            yield put(actions.loadNewCertificatesFail(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadNewCertificatesFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* loadAdminStatisticsSaga(action) 
{
    yield put(actions.loadAdminStatisticsStart());

    try {
        const response = yield tokenizedAxios(statisticsUrl, 'get', {});
        if (response.data.result === 'ok') {
            const statistics = response.data.stats;
            yield put(actions.loadAdminStatisticsSuccess(statistics));
        } else {
            yield put(actions.loadAdminStatisticsFail(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadAdminStatisticsFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
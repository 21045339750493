import * as actionTypes from './actionTypes';


export const updateNews = (newNewsObject) => {
    return {
        data: newNewsObject,
        type: actionTypes.NEWS_ELEMENT_UPDATE,
    }
}

export const saveNews = (newsObject) => {
    return {
        data: newsObject,
        type: actionTypes.NEWS_ELEMENT_SAVE,
    }
}

export const saveNewsStart = () => {
    return {
        type: actionTypes.NEWS_ELEMENT_SAVE_START,
    }
}

export const saveNewsSuccess = (newsId) => {
    return {
        newsId: newsId,  
        type: actionTypes.NEWS_ELEMENT_SAVE_SUCCESS,
    }
}

export const saveNewsFail = (error, fields) => {
    return {
        error: error,
        fields: fields,
        type: actionTypes.NEWS_ELEMENT_SAVE_FAIL,
    }
}



export const initializeNews = () => {
    return {
        type: actionTypes.NEWS_ELEMENT_INITIALIZE,
    }
}

export const loadNewsElement = (newsId) => {
    return {
        newsId : newsId,
        type: actionTypes.NEWS_ELEMENT_LOAD,
    }
}

export const loadNewsStart = (newsId) => {
    return {
        newsId : newsId,
        type: actionTypes.NEWS_ELEMENT_LOAD_START,
    }
}

export const loadNewsSuccess = (data) => {
    return {
        news: data.news,
        type: actionTypes.NEWS_ELEMENT_LOAD_SUCCESS,
    }
}

export const loadNewsFail = (error) => {
    return {
        error: error,
        type: actionTypes.NEWS_ELEMENT_LOAD_FAIL,
    }
}
import { isObject } from "formik";

import explodeSeriesNumber from '../../../utils/explodeSeriesNumber';
import copyObject from '../../../utils/copyObject';
import includeAddressData from "../../../utils/includeAddressData";
import { fillFioFields } from "../../../shared/utility";

const parsePerson = (personData, fioField) => {
    let finalData = {};
    if (personData) {
        if (personData.name) finalData.name_i = personData.name;
        if (personData.surname) finalData.name_f = personData.surname;
        if (personData.patronymic) finalData.name_o = personData.patronymic;
    } else {
        finalData = fillFioFields(finalData, fioField);
    }
    return finalData;
}

export const prepareUser = (initialUser) => {

    // Сначала пробуем дадату, и, если там данных нет, просто разбиваем фио по пробелу
    const includePersonData = (personData, fioField, dataToSend) => {
        const person = parsePerson(personData, fioField);
        if (person.name_i) dataToSend["name_i"] = person.name_i;
        if (person.name_f) dataToSend["name_f"] = person.name_f;
        if (person.name_o) dataToSend["name_o"] = person.name_o;
    }

    /*const includeAddressData = (addressData, dataToSend) => {

        console.log("ADDRESS DATA", addressData);

        if (addressData) {
            if (addressData.apt) dataToSend["register_apt"] = addressData.apt;
            if (addressData.city) dataToSend["register_city"] = addressData.city;
            if (addressData.city_id) dataToSend["register_city_id"] = addressData.city_id;
            
            if (addressData.house) dataToSend["register_house"] = addressData.house;
            if (addressData.house_id) dataToSend["register_house_id"] = addressData.house_id;
            if (addressData.postal_code) dataToSend["register_index"] = addressData.postal_code;

            if (addressData.street) {
                if (addressData.street) dataToSend["register_street"] = addressData.street;
                if (addressData.street_id) dataToSend["register_street_id"] = addressData.street_id;
            } else if (addressData.settlement) {
                if (addressData.settlement) dataToSend["register_street"] = addressData.settlement;
                if (addressData.settlement_id) dataToSend["register_street_id"] = addressData.settlement_id;
            } else if (addressData.area) {
                if (addressData.area) dataToSend["register_street"] = addressData.area;
                if (addressData.area_id) dataToSend["register_street_id"] = addressData.area_id;
            }
        }

        //console.log("AFTER HOUSE FINAL", addressData.house_id, dataToSend["register_house_id"] );

        //console.log("Including address data RESULT", dataToSend.register_house_id);
    }*/

    const user = copyObject(initialUser);

    const fieldsToIgnore = ['pasport_photo1', 'pasport_photo2', 'register_address_data', 'data', 'fio'];

    if (!user || !isObject(user)) return {result: "error", errorString: "Вы не указали данные пользователя"};

    //Создаём массив данных для отправки на сервер. Это все поля формы за исключением некоторых - их обрабатываем отдельно и не включаем в результирующий массив
    const dataToSend = {};

    let exploded = null;
    for (const fieldName in user) {
        switch (fieldName) {

            case "id": dataToSend['user_id'] = user.id; break;

            case "address_data":
                if (user[fieldName].city_id) dataToSend.city_id = user[fieldName].city_id;
                break;

            case "passport_series_number": 
                exploded=explodeSeriesNumber(user[fieldName]); 
                dataToSend['passport_series']=exploded.series; 
                dataToSend['passport_number']=exploded.number;  
                break;

            default:
                if (!fieldsToIgnore.includes(fieldName))
                    dataToSend[fieldName] = user[fieldName];
                break;
        }
    }

    if (user.register_address_data) includeAddressData('register', user.register_address_data, dataToSend, true);
    if (user.data || user.fio !== "") includePersonData(user.data, user.fio, dataToSend);
    if (user.address_data) dataToSend.city_id = user.address_data.city_id;

    return dataToSend;
}
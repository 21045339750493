import * as actionTypes from '../actions/actionTypes';

import { updateState } from '../../shared/utility';

const initialState = {
    token: null,
    user: null,
    error: null,
    loading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.REGISTER_START: return updateState(state, {error:null, loading: true});
        case actionTypes.REGISTER_FAIL: return updateState(state, {loading: false, error: action.error});
        case actionTypes.REGISTER_SUCCESS: return updateState(state, {loading: false, token: action.token, user: action.user});
        default: return state;
    }
}

export default reducer;
import moment from 'moment'

const parseServerUser = (serverData) => {

    const data = {};

    for (const fieldName in serverData) {
        
        switch (fieldName) {

            case "birth_date": 
            case "passport_date": 
                data[fieldName] = moment(serverData[fieldName], "YYYY-MM-DD").format("DD.MM.YYYY"); break;

            default: data[fieldName] = serverData[fieldName];
        }

    }

    data.fio = serverData.name_f;
    data.fio += (data.fio !== "" ? " " : "") + serverData.name_i;
    data.fio += (data.fio !== "" ? " " : "") + serverData.name_o;

    data.passport_series_number = serverData.passport_series + (serverData.passport_number ? (" " + serverData.passport_number) : "");

    data.city = serverData.city;
    data.password = '';
    data.repassword = '';

    //data.register_address = 

    return data;
}

export default parseServerUser;
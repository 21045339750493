import moment from 'moment';
import parseServerDate from '../../../utils/parseServerDate';

const parseServerCertificate = (serverCertificate) => {

    const certificate = {};

    for (const fieldName in serverCertificate) {
        
        switch (fieldName) {
            case "car_vin_number":   certificate[fieldName] = serverCertificate[fieldName]; if (certificate[fieldName] !== '') certificate.car_number_type = 1; break;
            case "car_body_number":  certificate[fieldName] = serverCertificate[fieldName]; if (certificate[fieldName] !== '') certificate.car_number_type = 2; break;
            case "car_chass_number": certificate[fieldName] = serverCertificate[fieldName]; if (certificate[fieldName] !== '') certificate.car_number_type = 3; break;

            case "drivers":
                const drivers = [];
                for (const i in serverCertificate[fieldName]) {
                    const driver = serverCertificate[fieldName][i];
                    driver.fio = driver.name_f + " " + driver.name_i + (driver.name_o !== "" ? (" " + driver.name_o) : "");
                    driver.document_series_number = driver.document_series + " " + driver.document_number;
                    driver.birth_date = parseServerDate(driver.birth_date);
                    driver.document_from = parseServerDate(driver.document_from);
                    driver.experience_from = parseServerDate(driver.experience_from);
                    driver.country_code = driver.country_code ?? "";

                    driver.prev_document_series_number = driver.prev_document_series + " " + driver.prev_document_number;
                    driver.prev_fio = driver.prev_name_f + " " + driver.prev_name_i + " " + driver.prev_name_o;

                    drivers.push(driver);
                }
                certificate[fieldName] = drivers;
                break;

            case "periods":
                const periods = [];
                for (const i in serverCertificate[fieldName]) {
                    const period = serverCertificate[fieldName][i];
                    period.start = parseServerDate(period.start);
                    period.end = parseServerDate(period.end);
                    period.months = moment(period.end, "DD.MM.YYYY").diff(moment(period.start, "DD.MM.YYYY"), 'months', true);
                    periods.push(period);
                }
                certificate[fieldName] = periods;
                break;

            case "date_start": 
            case "owner_birth_date": 
            case "client_birth_date": 
            case "car_document_date": 
            case "certificate_from": 
            case "client_cert_date": 
            case "owner_cert_date": 
            case "client_document_date": 
            case "owner_document_date": 
                certificate[fieldName] = parseServerDate(serverCertificate[fieldName]); break;

            default: certificate[fieldName] = serverCertificate[fieldName];
        }

    }

    certificate.client_fio = serverCertificate.client_name_f + " " + serverCertificate.client_name_i + (serverCertificate.client_name_o !== "" ? (" " + serverCertificate.client_name_o) : "");
    certificate.owner_fio  = serverCertificate.owner_name_f  + " " + serverCertificate.owner_name_i  + (serverCertificate.owner_name_o  !== "" ? (" " + serverCertificate.owner_name_o)  : "");

    certificate.client_document_series_number = serverCertificate.client_document_series + (serverCertificate.client_document_number ? (" " + serverCertificate.client_document_number) : "");
    certificate.owner_document_series_number  = serverCertificate.owner_document_series  + (serverCertificate.owner_document_number  ? (" " + serverCertificate.owner_document_number)  : "");

    //Если совпадает фио + паспорт ставим "совпадает"
    if (certificate.client_fio === certificate.owner_fio && certificate.client_document_series === certificate.owner_document_series && certificate.client_document_number === certificate.owner_document_number)
        certificate.owner_is_client = true;
    else 
        certificate.owner_is_client = false;

    //if (isPersonsSame(certificate)) certificate

    return certificate;
}

export default parseServerCertificate;
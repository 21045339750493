import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';


import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import {watchAll} from './store/sagas/index';

import certificatesReducer from './store/reducers/certificates';
import certificateReducer from './store/reducers/certificate';
import usersReducer from './store/reducers/users';
import userReducer from './store/reducers/user';
import ratesReducer from './store/reducers/rates';
import rateReducer from './store/reducers/rate';
import reportsReducer from './store/reducers/reports';
import reportReducer from './store/reducers/report';
import documentsReducer from './store/reducers/documents';
import authReducer from './store/reducers/auth';
import filesReducer from './store/reducers/files';
import registerReducer from './store/reducers/register';
import resetReducer from './store/reducers/reset';
import catalogsReducer from './store/reducers/catalogs';
import generalReducer from './store/reducers/general';
import calculationsReducer from './store/reducers/calculations';
import prolongReducer from './store/reducers/prolong';
import newsReducer from './store/reducers/news';
import newReducer from './store/reducers/new';
import dialogsReducer from './store/reducers/dialogs';
import messagesReducer from './store/reducers/messages';
import dashboardReducer from './store/reducers/dashboard';

const rootReducer = combineReducers({
  catalogs: catalogsReducer,
  auth: authReducer,
  register: registerReducer,
  certificates: certificatesReducer,
  certificate: certificateReducer,
  users: usersReducer,
  user: userReducer,
  rates: ratesReducer,
  documents: documentsReducer,
  reports: reportsReducer,
  report: reportReducer,
  rate: rateReducer,
  files: filesReducer,
  calculations: calculationsReducer,
  general: generalReducer,
  prolong: prolongReducer,
  reset: resetReducer,
  news: newsReducer,
  new: newReducer,
  dialogs: dialogsReducer,
  messages: messagesReducer,
  dashboard: dashboardReducer
})

const composeEnhancers = (process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(thunk, sagaMiddleware)
));

sagaMiddleware.run(watchAll);


ReactDOM.render((
  <React.StrictMode>
    <BrowserRouter><Provider store={store}><App /></Provider></BrowserRouter>
  </React.StrictMode>
), document.getElementById('root'));

serviceWorker.unregister();

const getFormError = (field, touched, errors, certificateErrors = {}, initialHelperText='') => {

    if (touched && (errors || certificateErrors)) {
        if (Array.isArray(field)) 
        {
            for (const i in field) {
                const fieldName = field[i];
                if (Boolean((touched[fieldName] && errors[fieldName]) || certificateErrors[fieldName]))
                    return {
                        error: true,
                        helperText: (touched[fieldName] && errors[fieldName]) || certificateErrors[fieldName]
                    }
            }
            return {
                error: false,
                helperText: initialHelperText,
            }
        } 
        else 
        {

            //console.log("GET FORM ERROR", touched, errors, certificateErrors);

            return {
                error: Boolean((touched[field] && errors[field]) || certificateErrors[field]),
                helperText: (touched[field] && errors[field]) || certificateErrors[field] || initialHelperText
            }
        }
    }
}

export default getFormError;
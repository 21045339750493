import * as actionTypes from './actionTypes';

export const resetNews = () => { return { type: actionTypes.NEWS_RESET }}

export const loadNews = (filter, page, perPage, order) => {
    return {
        filter : filter,
        page : page,
        perPage : perPage,
        order: order,

        type: actionTypes.NEWS_LOAD_INIT,
    }
}

export const loadStart = (filter, page, perPage, order) => {
    return {
        filter: filter,
        page: page,
        perPage: perPage,
        order: order,

        type: actionTypes.NEWS_LOAD_START,
    }
}

export const loadSuccess = (news, total) => {
    return {
        news: news,
        total: total,
        type: actionTypes.NEWS_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        type: actionTypes.NEWS_LOAD_FAIL,
    }
}


export const deleteNews = (newsId) => ({ newsId: newsId, type: actionTypes.NEWS_DELETE })
export const deleteNewsSuccess = (newsId) => ({ newsId: newsId, type: actionTypes.NEWS_DELETE_SUCCESS })
export const deleteNewsFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialState = {
    isLoading: false,
    catalogs: {},
    error: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.CATALOG_LOAD_START:   return updateState(state, {isLoading: true});
        case actionTypes.CATALOG_LOAD_FAIL:    return updateState(state, {isLoading: false, error: action.error });

        case actionTypes.CATALOG_LOAD_SUCCESS: 
            const currentCatalogs = copyObject(state.catalogs);
            for (let catalogName in action.changedCatalogs) currentCatalogs[catalogName] = action.changedCatalogs[catalogName];
        return updateState(state, {isLoading: false, catalogs: currentCatalogs, error: null});

        default: return state;
    }
}

export default reducer;
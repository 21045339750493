import tinkoffLogo from '../assets/logos/1b.png';
import alfaLogo from '../assets/logos/2b.png';
import ingosstrahLogo from '../assets/logos/3b.png';
import ingosstrahLogo2 from '../assets/logos/8b.png';
import ingosstrahLogo3 from '../assets/logos/10b.png';
import vskLogo from '../assets/logos/4b.png';
import vskLogo2 from '../assets/logos/11b.png';
import alfaLogo2 from '../assets/logos/5b.png';
import alfaLogo3 from '../assets/logos/7b.png';
import alfaLogo4 from '../assets/logos/9b.png';
import sberLogo from '../assets/logos/12b.png';

import sravniAbsolutLogo from '../assets/logos/absolut.svg';
import sravniAskoLogo from '../assets/logos/asko.svg';
import sravniAstroLogo from '../assets/logos/astro.svg';
import sravniIngosstrahLogo from '../assets/logos/ingosstrah.svg';
import sravniMafinLogo from '../assets/logos/mafin.svg';
import sravniMaksLogo from '../assets/logos/maks.svg';
import sravniOskLogo from '../assets/logos/osk.svg';
import sravniRenessansLogo from '../assets/logos/renessans.svg';
import sravniRosgosstrahLogo from '../assets/logos/rosgorstrah.svg';
import sravniSogazLogo from '../assets/logos/sogaz.svg';
import sravniSoglasieLogo from '../assets/logos/soglasie.svg';
import sravniSovkombankLogo from '../assets/logos/sovkombank.png';
import sravniVskLogo from '../assets/logos/vsk.svg';
import sravniZettaLogo from '../assets/logos/zetta.svg';

const getLogoByCompanyId = (companyId) => {
    let companyLogo = null;
    switch (companyId*1) {
      case 1: companyLogo = <img src={tinkoffLogo} width="137.5"  alt="Tinkoff" />; break;
      case 2: companyLogo = <img src={alfaLogo} width="137.5" alt="Альфа-страхование" />; break;
      case 3: companyLogo = <img src={ingosstrahLogo} width="137.5"  alt="Ингосстрах" />; break;
      case 4: companyLogo = <img src={vskLogo} width="137.5"  alt="ВСК" />; break;
      case 5: companyLogo = <img src={alfaLogo2} alt="Альфа-страхование 2" width="137.5"  />; break;
      case 7: companyLogo = <img src={alfaLogo3} width="137.5" alt="Альфа-страхование" />; break;
      case 8: companyLogo = <img src={ingosstrahLogo2} width="137.5"  alt="Ингосстрах 2" />; break;
      case 9: companyLogo = <img src={alfaLogo4} width="137.5" alt="Альфа-страхование 4" />; break;
      case 10: companyLogo = <img src={ingosstrahLogo3} width="137.5"  alt="Ингосстрах 3" />; break;
      case 11: companyLogo = <img src={vskLogo2} width="137.5"  alt="ВСК 2" />; break;
      case 12: companyLogo = <img src={sberLogo} width="137.5"  alt="Sber" />; break;

      case 8105: companyLogo = <img src={sravniRosgosstrahLogo} width="137.5" alt="Logo" />; break;
      case 7468: companyLogo = <img src={"https://f.sravni.ru/logotypes/ic/biglogo_" + companyId + ".svg"} width="137.5" alt="Logo" />; break;
      case 7930: companyLogo = <img src={sravniSogazLogo} width="137.5" alt="Logo" />; break;
      case 7809: companyLogo = <img src={sravniSovkombankLogo} width="137.5" alt="Logo" />; break;
      case 7932: companyLogo = <img src={sravniSoglasieLogo} width="137.5" alt="Logo" />; break;
      case 7470: companyLogo = <img src={sravniMaksLogo} width="137.5" alt="Logo" />; break;
      case 7659: companyLogo = <img src={sravniIngosstrahLogo} width="137.5" alt="Logo" />; break;
      case 7469: companyLogo = <img src={sravniRenessansLogo} width="137.5" alt="Logo" />; break;
      case 209089: companyLogo = <img src={sravniMafinLogo} width="137.5" alt="Logo" />; break;
      case 77459: companyLogo = <img src={sravniAbsolutLogo} width="137.5" alt="Logo" />; break;
      case 126810: companyLogo = <img src={"https://f.sravni.ru/logotypes/ic/biglogo_" + companyId + ".svg"} width="137.5" alt="Logo" />; break;
      case 147329: companyLogo = <img src={sravniOskLogo} width="137.5" alt="Logo" />; break;
      case 152951: companyLogo = <img src={sravniAskoLogo} width="137.5" alt="Logo" />; break;
      case 7962: companyLogo = <img src={sravniZettaLogo} width="137.5" alt="Logo" />; break;
      case 7763: companyLogo = <img src={sravniVskLogo} width="137.5" alt="Logo" />; break;
      case 152855: companyLogo = <img src={sravniAstroLogo} width="137.5" alt="Logo" />; break;	  
      default: break;
    }
    return companyLogo;
  }

export default getLogoByCompanyId;
import {put} from 'redux-saga/effects';

import * as actions from '../actions/certificate';
import * as calculateActions from '../actions/calculations';
import * as certificatesActions from '../actions/certificates';

//import { generalError, generalSuccess, generalStart } from '../actions/general';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { carSearchUrl, certificateSaveUrl, certificateLoadUrl } from '../../settings/api_urls';

import parseServerCertificate from './sagasUtils/parseServerCertificate';

export function* loadCarInfoSaga(action) 
{
    yield put(actions.loadCarInfoStart(action.carNumber));

    try {
        const response = yield tokenizedAxios(carSearchUrl, 'get', { number: action.carNumber });

        if (response.data.result === 'ok') {
            const carInfo = response.data.car;
            yield put(actions.loadCarInfoSuccess({
                car_brand_id: carInfo.brand_id,
                car_model_id: carInfo.model_id,
                car_category_id: carInfo.category_id,
                car_vin_number: carInfo.vin,
                //car_diagnost_date: carInfo.diagnost_date,
            }));
        } else {
            yield put(actions.loadCarInfoFail(response.data.errorstring));
        }

    } catch (error) {
        yield put(actions.loadCarInfoFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или введите данные по авто вручную."));
    };
}

export function* loadCertificateSaga(action) 
{
    if (action.certificateId === 0 || action.certificateId === 'new') { // Новый полис

        const certificateBackup = yield localStorage.getItem('certificateBackup');
        if (certificateBackup)
            yield put(actions.loadCertificateSuccess({
                certificate: JSON.parse(certificateBackup),
            }));
        else 
            yield put(actions.initializeCertificate());
            
    } else {
        yield put(actions.loadCertificateStart(action.certificateId));
        yield put(calculateActions.calculateCertificateClear(action.certificateId));
        try {
            const response = yield tokenizedAxios(certificateLoadUrl, 'get', { certificate_id: action.certificateId, combine_fields: 0 });
            if (response.data.result === 'ok') {
                const certificate = response.data.certificate;
                const finalCertificate = parseServerCertificate(certificate);
                if (action.prolongMode) {
                    finalCertificate.date_start = "";
                    finalCertificate.periods = [];
                }
                yield put(actions.loadCertificateSuccess({
                    certificate: finalCertificate,
                }));
            } else {
                yield put(actions.loadCertificateFail(response.data.errorstring));
            }
        } catch (error) {
            yield put(actions.loadCarInfoFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или введите данные по авто вручную."));
        };
    }
}

export function* saveCertificateSaga(action) 
{
    yield put(actions.saveCertificateStart());

    try {
        const dataToSend = action.data;
        dataToSend.new_api = 1; //New API for backend
        const response = yield tokenizedAxios(certificateSaveUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            const certificateId = response.data.id;
            yield put(actions.saveCertificateSuccess({
                id: certificateId,
            }));
            yield put(calculateActions.resetCalculations());
            yield put(certificatesActions.resetCertificates());
        } else {
            yield put(actions.saveCertificateFail(response.data.errorstring, response.data.fields));
        }

    } catch (error) {
        //console.log("ERROR", error.message);
        yield put(actions.saveCertificateFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", null));
    };
}
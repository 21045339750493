import * as actionTypes from './actionTypes';

export const resetDialogs = () => { return { type: actionTypes.DIALOGS_RESET }}

export const loadDialogs = (filter, page, perPage) => {
    return {
        filter: filter,
        page : page,
        perPage : perPage,
        type: actionTypes.DIALOGS_LOAD_INIT,
    }
}

export const loadStart = (filter, page, perPage) => {
    return {
        filter: filter,
        page: page,
        perPage: perPage,
        type: actionTypes.DIALOGS_LOAD_START,
    }
}

export const loadSuccess = (dialogs, total) => {
    return {
        dialogs: dialogs,
        total: total,
        type: actionTypes.DIALOGS_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        type: actionTypes.DIALOGS_LOAD_FAIL,
    }
}


export const loadNewMessages = () => {
    return {
        type: actionTypes.NEW_MESSAGES_LOAD_INIT,
    }
}

export const loadNewMessagesStart = () => {
    return {
        type: actionTypes.NEW_MESSAGES_LOAD_START,
    }
}

export const loadNewMessagesSuccess = (newMessagesCount) => {
    return {
        newMessages: newMessagesCount,
        type: actionTypes.NEW_MESSAGES_LOAD_SUCCESS,
    }
}

export const newMessagesReduce = (amount) => {
    return {
        amount: amount,
        type: actionTypes.NEW_MESSAGES_REDUCE,
    }
}
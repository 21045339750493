import {
    CircularProgress,
    Box,
    Typography,
  } from '@material-ui/core';

const Spinner = (props) => {
  let text = null;
  if (props.text !== null && props.text !== '') text = <Typography>{props.text}</Typography>
  return <Box display="flex" textAlign="center"><Box m="auto" my={2}><CircularProgress />{text}</Box></Box>;
}
export default Spinner;
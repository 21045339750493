import {put} from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/register';
import { authSuccess } from '../actions/auth';

import { apiUrl } from '../../settings/base';
import { registerUrl } from '../../settings/api_urls';

export function* registerUserSaga(action)
{
    yield put(actions.registerStart());

    const registerData = {
        name_i: action.name,
        name_f: action.surname,
        email: action.email,
        phone: action.phone,
        password: action.password
    }

    try {
        const response = yield axios.post(apiUrl + registerUrl, registerData);
        if (response.data.result === 'ok') {
            yield localStorage.setItem('token', response.data.token);
            yield localStorage.setItem('user', JSON.stringify(response.data.user));
            yield put(actions.registerSuccess(response.data));
            const authInfo = {
                token: response.data.token,
                user: response.data.user,
            }
            yield put(authSuccess(authInfo));
        } else {
            yield put(actions.registerError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.registerError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
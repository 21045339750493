import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from '../../../components/Page';
import Toolbar from './Toolbar';

import Spinner from '../../../components/Spinner';
import NewsElement from './NewsElement';

import * as actions from '../../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  marginTop: {
    marginTop: '20px'
  },
}));

const NewsListView = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const onLoadNews = props.onLoadNews;
  const curUser = props.curUser;
  const currentFilter = props.filter;

  const clickHandler = (newsId) => {
    navigate("/app/news/" + newsId);
  }

  const deleteHandler = (newsId) => {
    props.onDeleteNews(newsId);
  }

  //const toggleHideHandler = (newsId) => {
    //props.onDeleteNews(newsId);
  //}

  const filterHandler = useCallback((filter, page, perPage, order) => {
    onLoadNews(filter, page, perPage, order);
  }, [onLoadNews]);

  useEffect(() => {
    filterHandler(currentFilter, 1, 20, {id: "DESC"})
  }, [filterHandler, currentFilter]);
  
  /*const smartTableButtonsData = (record) => {
    const links = [];
    links.push(<MenuItem key={1} onClick={(event)=>clickHandler(record.id)}>Редактировать</MenuItem>);
    links.push(<MenuItem key={3} onClick={(event)=>deleteHandler(record.id)}>Удалить</MenuItem>);
    return links;
  }*/

  let blocks = <Grid item lg={12} md={12} xs={12}><Spinner text="Загружаем новости" /></Grid>;
  if (props.news) {
    blocks = [];
    for (const i in props.news)
      blocks.push(<NewsElement 
        isAdmin={curUser.access === 100} 
        key={i} 
        newsElement={props.news[i]} 
        onDelete={deleteHandler}
        onClick={clickHandler}
        //onToggleHide={toggleHideHandler} 
      />);
  }

  return (

    <Page
      className={classes.root}
      title="News"
    >
      <Container maxWidth={false}>
        {curUser.access === 100 ? <Toolbar className={classes.marginTop} /> : null}
        <Box mt={3}>
        <Grid container spacing={2}>
          {blocks}
        </Grid>
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      isNewsLoading: state.news.isLoading,
      error: state.news.error,
      news: state.news.news,
      total: state.news.total,
      filter: state.news.filter,
      page: state.news.page,
      perPage: state.news.perPage,
      order: state.news.order,

      curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadNews: (page, perPage) => dispatch(actions.loadNews(page, perPage)),
    onDeleteNews: (newsId) => dispatch(actions.deleteNews(newsId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsListView);
import React from 'react';
import { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ElkServerSearchInput({searchFunc, ...props}) {

    const [options, setOptions] = useState([]);

    const prepareOptions = ( results, error) => {

        let newOptions = [];
        
        if (results) {
            for (const i in results) {
                newOptions.push(results[i]);
            }
        }

        setOptions(newOptions);
    };

  return (
    <Grid item lg={props.fullWidth ? 12 : 8} md={props.fullWidth ? 12 : 8} xs={12} >
        <Autocomplete
            fullWidth
        
            options={options}
            getOptionLabel={(option) => option.value ?? ""}
            renderOption={(option) => option.value}
            onChange={(event, value) => { props.onValueChange(value) }}
            value={props.value ?? ""}
            renderInput={(params) => (
            <TextField
                {...params}
                onBlur={props.handleBlur}
                name={props.name}
                error={props.error}
                helperText={props.helperText}
                label={props.label}
                variant="outlined"
                required={props.required}
                value={props.value ?? ""}
                onChange={(event) => { searchFunc(event.target.value, prepareOptions); }}
                InputProps={{ ...params.InputProps, type: 'search' }}
            />
            )}
        />
    </Grid>
  );
}
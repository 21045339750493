export const certificateContinueableStauses = [5, 10];
export const certificateDeleteableStauses = [5, 10];
export const certificateCancelableStatuses = [15, 20];
export const certificateProlongableStatuses = [20, 30, 40];
export const certificatePrintableStatuses = [20, 30, 40];
export const certificateReportableStatuses = [20];
export const certificateDisplayPaymentTypeStatuses = [20, 30, 40];

export const userDeleteableStauses = [10, 60];
export const reportDeleteableStauses = [10, 60];

export const subrateDrivers = {
    1: {id:1, name:"Без ограничения"},
    2: {id:2, name:"С ограничением"},
  }
export const subrateTrailer = {
    1: {id:1, name:"С прицепом"},
    2: {id:2, name:"Без прицепа"},
  }
export const subrateJuridical = {
    1: {id:1, name:"Юридическое лицо"},
    2: {id:2, name:"Физическое лицо"},
}
export const subrateProlong = {
  1: {id:1, name:"Пролонгация"},
  2: {id:2, name:"Зелёный коридор"},
  3: {id:3, name:"Новый полис"},
}

import {
    Accordion,
    AccordionSummary,
    Button,
    Chip,
    Grid,
    makeStyles,
    Paper,
    TextField,
    Typography
  } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Spinner from '../../../components/Spinner';
import ElkSelectInput from '../../../components/FormElements/ElkSelectInput';
import ElkTextInput from '../../../components/FormElements/ElkTextInput';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    deleteButton: {
        marginTop: "10px"
    },
    paper: {
        padding: theme.spacing(2),
    },
    marginTop: {
        marginTop: "20px"
    }
}));

const Subrate = (props) => {

    const classes = useStyles();

    if (props.companyOptionsList && props.regionOptionsList) {

        let tariffText = props.data.percent + "%";
        let allRegions = false;
        for (const i in props.data.region_id) {
            if (parseInt(props.data.region_id[i]) === 0) {
                allRegions = true;
                break;
            }
        }
        tariffText += " в " + (allRegions ? "любом регионе" : (props.data.region_id.length + (props.data.region_id.length === 1 ? " регионе" : " регионах")));

        return <Grid item lg={12} md={12} xs={12} >
            <Accordion key={props.key}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{ tariffText }</Typography>
            </AccordionSummary>
            <Paper elevation={5} className={classes.paper} style={{width:"100%"}}>
            <Grid container spacing={3} >
            <ElkSelectInput 
                width={4}
                label="Компания"
                name={"company_" + props.pos}
                onValueChange={(newValue) => { props.changeTariff(props.pos, {company_id: newValue}) }} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                //required={true} 
                value={props.data.company_id}
                options={ props.companyOptionsList }
            /> 
            <ElkSelectInput 
                width={4}
                label="Использование с прицепом"
                name={"trailer_" + props.pos}
                onValueChange={(newValue) => { props.changeTariff(props.pos, {trailer: newValue}) }} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                value={props.data.trailer}
                options={ props.subrateTrailerOptions }
            /> 
            {/*<ElkSelectInput 
                width={4}
                label="Пролонгация"
                name={"prolong_" + props.pos}
                onValueChange={(newValue) => { props.changeTariff(props.pos, {prolong: newValue}) }} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                value={props.data.prolong}
                options={ props.subrateProlongOptions }
            />*/}
            <ElkSelectInput 
                width={4}
                label="Ограничение по типу страхователя"
                name={"juridical_" + props.pos}
                onValueChange={(newValue) => { props.changeTariff(props.pos, {juridical: newValue}) }} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                value={props.data.juridical}
                options={ props.subrateJuridicalOptions }
            /> 
            <ElkTextInput
                width={4}
                mobileWidth={12}
                label="КБМ до"
                name={"kbm_" + props.pos} 
                placeholder="90"
                type="text"
                onValueChange={(newValue) => { props.changeTariff(props.pos, { kbm: newValue }) }} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                value={ props.data.kbm }
            />
            <ElkSelectInput 
                width={4}
                label="Ограничение по водителям"
                name={"drivers_" + props.pos}
                onValueChange={(newValue) => { props.changeTariff(props.pos, {drivers: newValue}) }} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                value={props.data.drivers}
                options={ props.subrateDriversOptions }
            /> 
            <Grid item lg={6} md={6} xs={12} >
                <TextField
                    select
                    fullWidth
                    name={'region_' + props.pos}
                    variant="outlined"
                    label="Регион"
                    handleBlur={props.handleBlur}
                    setFieldValue={props.setFieldValue}
                    SelectProps={{
                        multiple: true,
                        value: props.data.region_id,
                        onChange: (event, index, values) => { props.changeTariff(props.pos, { region_id: event.target.value}) },
                        renderValue: (selected) => (
                        <div>
                            {selected.map((value) => {
                                if (value > 0) {     
                                    return props.catalogsRegions[value] ? (
                                    <Chip style={{marginRight:"2px"}} key={value} label={props.catalogsRegions[value].name} />
                                    ) : null 
                                } else 
                                    return <Chip style={{marginRight:"2px"}} key={0} label="Все регионы" />
                            })}
                        </div>
                        )
                    }}
                    >
                    {props.regionOptionsList}
                </TextField>
            </Grid>
            <Grid item lg={6} md={6} xs={12} >
                <TextField
                    select
                    fullWidth
                    name={'category_' + props.pos}
                    variant="outlined"
                    label="Категория"
                    handleBlur={props.handleBlur}
                    setFieldValue={props.setFieldValue}
                    SelectProps={{
                        multiple: true,
                        value: props.data.category,
                        onChange: (event, index, values) => { props.changeTariff(props.pos, { category: event.target.value}) },
                        renderValue: (selected) => (
                        <div>
                            {selected.map((value) => {
                                if (value !== 0) {     
                                    return props.catalogsCategories[value] ? (
                                    <Chip style={{marginRight:"2px"}} key={value} label={props.catalogsCategories[value].name} />
                                    ) : null 
                                } else 
                                    return <Chip style={{marginRight:"2px"}} key={0} label="Все категории" />
                            })}
                        </div>
                        )
                    }}
                    >
                    {props.categoriesOptionsList}
                </TextField>
            </Grid>
            <Grid item lg={6} md={6} xs={12} >
                <TextField
                    select
                    fullWidth
                    name={'task_' + props.pos}
                    variant="outlined"
                    label="Цель использования"
                    handleBlur={props.handleBlur}
                    setFieldValue={props.setFieldValue}
                    SelectProps={{
                        multiple: true,
                        value: props.data.task,
                        onChange: (event, index, values) => { props.changeTariff(props.pos, { task: event.target.value}) },
                        renderValue: (selected) => (
                        <div>
                            {selected.map((value) => {
                                if (value !== 0) {     
                                    return props.catalogsTasks[value] ? (
                                    <Chip style={{marginRight:"2px"}} key={value} label={props.catalogsTasks[value].name} />
                                    ) : null 
                                } else 
                                    return <Chip style={{marginRight:"2px"}} key={0} label="Все цели использования" />
                            })}
                        </div>
                        )
                    }}
                    >
                    {props.tasksOptionsList}
                </TextField>
            </Grid>
            <Grid item lg={6} md={6} xs={12} >
                <TextField
                    select
                    fullWidth
                    name={'prolong_' + props.pos}
                    variant="outlined"
                    label="Пролонгация"
                    handleBlur={props.handleBlur}
                    setFieldValue={props.setFieldValue}
                    SelectProps={{
                        multiple: true,
                        value: props.data.prolong,
                        onChange: (event, index, values) => { props.changeTariff(props.pos, { prolong: event.target.value}) },
                        renderValue: (selected) => (
                        <div>
                            {selected.map((value) => {
                                if (value !== 0) {     
                                    return props.catalogsProlong[value] ? (
                                    <Chip style={{marginRight:"2px"}} key={value} label={props.catalogsProlong[value].name} />
                                    ) : null 
                                } else 
                                    return <Chip style={{marginRight:"2px"}} key={0} label="Все варианты" />
                            })}
                        </div>
                        )
                    }}
                    >
                    {props.prolongOptionsList}
                </TextField>
            </Grid>
            <ElkTextInput
                width={3}
                mobileWidth={6}
                label="Мощность от"
                name={"power_" + props.pos} 
                placeholder="90"
                type="text"
                onValueChange={(newValue) => { props.changeTariff(props.pos, { power: newValue }) }} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                value={ props.data.power }
            />
            <ElkTextInput
                width={3}
                mobileWidth={6}
                label="Процент"
                name={"percent_" + props.pos} 
                placeholder="89.9"
                type="text"
                onValueChange={(newValue) => { props.changeTariff(props.pos, { percent: newValue }) }} 
                //required={true} 
                handleBlur={props.handleBlur}
                setFieldValue={props.setFieldValue}
                value={ props.data.percent }
            />
            <Grid item lg={1} md={1} xs={2} >
                <Button className={classes.deleteButton} color="default" onClick={ (event) => props.deleteSubrate(props.pos) }><DeleteIcon /></Button>
            </Grid>
        </Grid>
        </Paper>
        </Accordion>
        </Grid>
    } else 
        return <Spinner />
}

export default Subrate;
import * as actionTypes from './actionTypes';

export const resetReports = () => { return { type: actionTypes.REPORTS_RESET }}

export const loadReports = (filter, page, perPage, order) => {
    return {
        filter : filter,
        page : page,
        perPage : perPage,
        order: order,

        type: actionTypes.REPORTS_LOAD_INIT,
    }
}

export const loadStart = (filter, page, perPage, order) => {
    return {
        filter: filter,
        page: page,
        perPage: perPage,
        order: order,

        type: actionTypes.REPORTS_LOAD_START,
    }
}

export const loadSuccess = (reports, total, totalNew) => {
    return {
        reports: reports,
        total: total,
        totalNew: totalNew,
        
        type: actionTypes.REPORTS_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        
        type: actionTypes.REPORTS_LOAD_FAIL,
    }
}

export const openReportsWindow = (certificateIds) => ({ certificateIds: certificateIds, type: actionTypes.REPORTS_OPEN })
export const closeReportsWindow = () => ({ type: actionTypes.REPORTS_CLOSE })
export const redirectFromReportsWindow = (goToReportId) => ({ type: actionTypes.REPORTS_REDIRECT, goToReportId: goToReportId })

export const deleteReport = (reportId) => ({ reportId: reportId, type: actionTypes.REPORTS_DELETE })
export const deleteReportSuccess = (reportId) => ({ reportId: reportId, type: actionTypes.REPORTS_DELETE_SUCCESS })
export const deleteReportFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })

export const addReport = (certificateIds, reportId, newReportName, newReportType, goToReportId) => ({ certificateIds: certificateIds, reportId: reportId, newReportName: newReportName, newReportType: newReportType, goToReportId: goToReportId, type: actionTypes.REPORTS_ADD })
export const addReportStart = (certificateIds, reportId, newReportName, newReportType) => ({ certificateIds: certificateIds, reportId: reportId, newReportName: newReportName, newReportType: newReportType, type: actionTypes.REPORTS_ADD_START })
export const addReportSuccess = (reportId) => ({ reportId: reportId, type: actionTypes.REPORTS_ADD_SUCCESS })
export const addReportFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })

export const loadNewReports = () => ({ type: actionTypes.NEW_REPORTS_LOAD_INIT })
export const loadNewReportsStart = () => ({ type: actionTypes.NEW_REPORTS_LOAD_START })
export const loadNewReportsSuccess = (newReportsCount) => ({ newReports: newReportsCount, type: actionTypes.NEW_REPORTS_LOAD_SUCCESS, })
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
} from '@material-ui/core';

import * as actions from '../../store/actions';
import Spinner from '../../components/Spinner';
import Header from '../../components/Header';
import Percentage from './Percentage';
import DailyStatistics from './DailyStatistics';

const AdminStatistics = (props) => {

  const onLoadAdminStatistics = props.onLoadAdminStatistics;
  const onLoadCatalog = props.onLoadCatalog;

  const catalogsGeneral = props.catalogs['general'] ?? false;

  useEffect(() => {
    if (catalogsGeneral && props.statistics === null) {
      if (!props.isStatisticsLoading) {
        onLoadAdminStatistics()
      }
    }
  }, [onLoadAdminStatistics, onLoadCatalog, catalogsGeneral, props.statistics]);

  let block;
  if (props.isStatisticsLoading || props.isCatalogsLoading) {
    block = <Grid item lg={12} md={12} xl={12} xs={12}><Spinner></Spinner></Grid>
  }
  else {

    if (props.curUser.access >= 100) {
      block = <>
        <Grid item lg={8} md={12} xl={9} xs={12}><Header title="Оформление по дням" /><DailyStatistics statistics={props.statistics} /></Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}><Header title="Отношение статусов" /><Percentage statistics={props.statistics} /></Grid>
      </>;
    } else {
      block = <><Header title="Оформление по дням" /><DailyStatistics statistics={props.statistics} /></>
    }
  }

  return <>
    {block}
  </>
};

const mapStateToProps = state => {
  return {
      isStatisticsLoading: state.dashboard.statisticsLoading,
      error: state.dashboard.statisticsError,
      statistics: state.dashboard.statistics,

      isCatalogsLoading: state.catalogs.isLoading,
      catalogs: state.catalogs.catalogs,

      curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadAdminStatistics: () => dispatch(actions.loadAdminStatistics()),
    onLoadCatalog: (catalogName) => dispatch(actions.loadCatalog(catalogName)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminStatistics);
import {put} from 'redux-saga/effects';

import * as actions from '../actions/certificates';
import { generalError, generalSuccess, generalStart } from '../actions/general';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { certificatesUrl, certificatesDeleteUrl, certificatesCancelUrl, certificatesProlongUrl } from '../../settings/api_urls';

export function* loadCertificatesSaga(action) 
{
    yield put(actions.loadStart(action.filter, action.page, action.perPage, action.order));

    const getData = {
        ...action.filter,
        format: 1,
        fill_agent_data: 1,
        page: action.page ?? 1,
        perpage: action.perPage ?? 20,
        order: action.order ?? { "certificate_processed_at": "DESC" },
    }

    try {
        const response = yield tokenizedAxios(certificatesUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const certificates = response.data.certificates;
            const total = response.data.total;
            yield put(actions.loadSuccess(certificates, total));
        } else {
            yield put(actions.loadError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* deleteCertificateSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(certificatesDeleteUrl, 'post', {id: action.certificateId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Полис " + action.certificateId + " успешно удалён"));
            yield put(actions.deleteCertificateSuccess(action.certificateId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* cancelCertificateSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(certificatesCancelUrl, 'post', {id: action.certificateId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Полис " + action.certificateId + " успешно аннулирован"));
            yield put(actions.cancelCertificateSuccess(action.certificateId, response.data.newStatus));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* prolongCertificateSaga(action)
{
    alert('Не реализовано');
    return;

    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(certificatesProlongUrl, 'post', {id: action.certificateId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Полис " + action.certificateId + " успешно пролонгирован"));
            yield put(actions.prolongCertificateSuccess(action.certificateId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
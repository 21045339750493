import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ruLocale from "date-fns/locale/ru";
import * as Yup from 'yup';
import { Formik } from 'formik';
import Alert from '@material-ui/lab/Alert';

import copyObject from '../../../utils/copyObject';
import onlyDigits from '../../../utils/onlyDigits';
import dateValidation from '../../../utils/yup/dateValidation';
import carIdValidation from '../../../utils/yup/carIdValidation';
import makeFirstLetterCapital from '../../../utils/makeFirstLetterCapital'

import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import ElkDateSelector from '../../../components/FormElements/ElkDateSelector';
import ElkDadataFio from '../../../components/FormElements/ElkDadataFio';
import ProfinsCarNumber from '../../../components/FormElements/ProfinsCarNumber';

import isCompanyVisible from '../../../utils/isCompanyVisible';

import * as actions from '../../../store/actions';
import getFormError from '../../../utils/getFormError';
import { russianLetters } from '../../../settings/masks';
import getLogoByCompanyId from '../../../utils/getCompanyLogo';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: "100%"
  },
  certificateBox: {
    //marginTop: "20px"
  },
  marginTop: {
    marginTop: "20px"
  },
  newProlongButton: {
    marginLeft: "10px",
    marginTop: "20px"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  center: {
    textAlign: "center",
  },
  saveSpinner: {
    marginRight: "10px"
  },
  error: {
    color: "#f44336"
  }
}));

const ProlongForm = ( props ) => {

  const prolongData = props.prolongData;
  const className = props.className;

  const classes = useStyles();

  const resetProlongCertificate = (resetForm) => {
    resetForm();
    props.onResetProlongData();
  }

  const updateProlongDataFio = (setFieldValue, newValue) => {
    let genderValue = prolongData.gender;
    if (newValue && newValue.data && newValue.data.gender) {
      genderValue = newValue.data.gender === 'FEMALE' ? 2 : 1;
    } else if (newValue && newValue.gender)
      genderValue = newValue.gender;
    const fioValue = newValue.fio ? makeFirstLetterCapital(newValue.fio) : prolongData.fio;
    setFieldValue("gender", genderValue);
    setFieldValue("fio", fioValue);
    updateProlongData({ fio: fioValue, data: newValue.data ?? null, gender: genderValue });
  }

  const updateProlongData = (updatedFields) => {
    let newProlongData = copyObject(prolongData);
    for (const i in updatedFields)
      newProlongData[i] = updatedFields[i];
    props.onUpdateProlongData(newProlongData);
  }

  const prolongCertificate = () => {
    props.onProlongCertificate(prolongData);
  }

  let yupValidationShape = {
    prev_number: Yup.string().max(255).required('Пожалуйста, укажите номер предыдущего договора')
  }
  if (prolongData.typeId === 1) yupValidationShape.inn = Yup.string().required('Пожалуйста, укажите ИНН страхователя').test('full', 'ИНН должен состоять из 10 цифр', function(value) {
    return value && (onlyDigits(value).length === 10);
  });
  if (prolongData.company_id === 2) {
    if (prolongData.typeId === 0) yupValidationShape.birth_date = dateValidation(true, 'past', 100, 18, "Пожалуйста, укажите дату рождения");
  }
  else if (prolongData.company_id === 3) {
    yupValidationShape = {...yupValidationShape, ...carIdValidation(prolongData.car_number_type)}
    if (prolongData.typeId === 0) { 
      yupValidationShape.fio = Yup.string().max(255).required('Пожалуйста, укажите фио страхователя').test('2 parts', 'Укажите ФИО страхователя', function(value) {
        return value && (value.trim().split(" ").length >= 2);
      });
    }
  }

  return (
    <Formik
      initialValues={prolongData}
      onSubmit={ (values) => { prolongCertificate(); } }
      validationSchema={
        Yup.object().shape(yupValidationShape)
      }
      //validateOnBlur={true}
      //validator={() => ({})}
    >
      {({
        errors,
        handleBlur,
        touched,
        values,
        setFieldValue,
        handleSubmit,
        resetForm
      }) => { 
        
        let companyRelatedFields = null;
        let typedFields = null;
        let carBlock = null;

        //console.log("TOUCHED ERRORS", touched, errors, values, prolongData);

        if (props.prolongResult) {

          const prolongContainer = <>
            <Alert severity="success">Полис {props.prolongResult} успешно загружен и готов к пролонгации</Alert>
            <Link to={"/app/certificates/"+props.prolongResult}><Button className={classes.marginTop} variant="contained" color="primary">Продолжить пролонгацию</Button></Link>
            <Button className={classes.newProlongButton} variant="contained" color="default" onClick={() => resetProlongCertificate(resetForm)}>Новая пролонгация</Button>
          </>

          return <form className={classes.fullWidth}>
            <Box className={classes.certificateBox}>
              <Card>
                <CardHeader
                  subheader="Полис успешно пролонгирован"
                  title="Пролонгация ОСАГО"
                />
                <Divider />
                <CardContent>
                  {prolongContainer}
                </CardContent>
              </Card>
              {companyRelatedFields}
            </Box>
          </form>

        } else {

          //let numberHint = null;
          let mask = null;
          let helperText = null;

          if (prolongData.company_id === 2) {

            helperText = "Серия указывается РУССКИМИ буквами, например, МММ 1234567890";
            mask = [russianLetters, russianLetters, russianLetters, ' ',/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]

            if (prolongData.typeId === 0) {
              typedFields = 
                <ElkDateSelector 
                  width={12}
                  {...getFormError("birth_date", touched, errors)}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  label="Дата рождения страхователя" 
                  name={"birth_date"}
                  onValueChange={(newValue) => { updateProlongData({ birth_date: newValue }) }} 
                  required={true} 
                  value={prolongData.birth_date}
                />
              
            } else if (prolongData.typeId === 1) {
              typedFields = 
                <ElkTextInput
                  {...getFormError("inn", touched, errors)}
                  width={12}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  label="ИНН страхователя"
                  name={"inn"}
                  placeholder="1234567890"
                  onValueChange={(newValue) => { updateProlongData({ inn: newValue }) }} 
                  required={true} 
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  value={prolongData.inn ?? ''}
                />
              
            }

          } else if ([3,8,10].includes(prolongData.company_id)) {

            helperText = "Например, CL123456789";
            mask = ["C", "L", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]

            if (prolongData.typeId === 0) {

              typedFields = <>
                <ElkDadataFio
                  width={12}
                  {...getFormError("fio", touched, errors)}
                  handleBlur={handleBlur}
                  label="ФИО страхователя" 
                  name={"fio"}
                  onValueChange={(newValue) => { updateProlongDataFio(setFieldValue, newValue) }}
                  required={true} 
                  value={prolongData.fio ?? ''}
                  genderValue={prolongData.gender ?? ''}
                />
                {/*<ElkTextInput
                  width={12}
                  {...getFormError("document", touched, errors)}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  label="Серия и номер документа через пробел"
                  name={"document"}
                  placeholder="3406 807311"
                  onValueChange={(newValue) => { updateProlongData({ document: newValue }) }} 
                  required={true} 
                  value={prolongData.document ?? ''}
                />*/}
              </>
            } else if (prolongData.typeId === 1) {
              typedFields = 
                <ElkTextInput
                  width={12}
                  {...getFormError("inn", touched, errors)}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  label="ИНН страхователя"
                  name={"inn"}
                  placeholder="1234567890"
                  onValueChange={(newValue) => { updateProlongData({ inn: newValue }) }} 
                  required={true} 
                  mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                  value={prolongData.inn ?? ''}
                />
            }

            carBlock = <ProfinsCarNumber 
              errorText={(prolongData.car_number_type===1 && touched.car_vin_number && errors.car_vin_number) || (prolongData.car_number_type===2 && touched.car_body_number && errors.car_body_number) || (prolongData.car_number_type===3 && touched.car_chass_number && errors.car_chass_number)}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              onValueChange={(newValues) => { updateProlongData(newValues) }} 
              car_body_number={prolongData.car_body_number}
              car_vin_number={prolongData.car_vin_number}
              car_chass_number={prolongData.car_chass_number}
              car_number_type={prolongData.car_number_type}
            />
          }

          if (prolongData.company_id !== null) {

            companyRelatedFields = <Card className={classes.certificateBox}>
              <CardHeader
                //subheader=""
                title="Данные по старому полису"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3} >
                    <ElkTextInput
                      width={12}
                      {...getFormError('prev_number', touched, errors)}
                      label="Номер прошлого полиса АИС"
                      name="prev_number"
                      //placeholder={numberHint}
                      helperText={helperText}
                      onValueChange={(newValue) => { updateProlongData({ prev_number: newValue }) }} 
                      required={true} 
                      mask={mask}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={prolongData.prev_number ?? ''}
                    />
                  {carBlock}
                </Grid>
              </CardContent>
              <Divider />
              <CardHeader
                subheader="Данные по предыдущему страхователю"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3} >
                  <Grid item lg={12} md={12} xs={12} >
                    <ToggleButtonGroup
                        value={prolongData.typeId ?? null}
                        exclusive
                        label="Юрлицо"
                        onChange={(event, newValue) => { updateProlongData({ typeId: newValue })}}
                    >
                      <ToggleButton value={0}>Физическое лицо</ToggleButton>
                      <ToggleButton value={1}>Юридическое лицо</ToggleButton>
                    </ToggleButtonGroup>
                    
                  </Grid>
                  {typedFields}
                </Grid>
              </CardContent>
            </Card>;

          }

          let prolongButton = <Grid container spacing={3} >
            <Grid item lg={6} md={6} xs={12} ><Button variant="contained" color="default" onClick={() => resetProlongCertificate(resetForm)}>Сбросить данные формы</Button></Grid>
            <Grid item lg={6} md={6} xs={12} ><Button variant="contained" color="primary" type="submit">Пролонгировать</Button></Grid>
          </Grid>
          if (props.isProlonging) prolongButton = <Button variant="contained" color="primary" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Пролонгирую полис</Button>
          /*else if (props.prolongResult) prolongButton = <>
            <Alert severity="success">Полис успешно загружен и готов к пролонгации</Alert>
            <Link to={"/app/certificates/"+props.prolongResult}><Button className={classes.marginTop} variant="contained" color="primary">Продолжить пролонгацию</Button></Link>
          </>*/

          let prolongError = null;
          if (props.prolongError) prolongError = <Alert severity="error" className={classes.marginBottom}>{props.prolongError}</Alert>

          let bottomBlock = null;
          if (prolongData.company_id !== null) bottomBlock = <Box className={classes.certificateBox}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item lg={12} md={12} xs={12} className={classes.center} >
                    {prolongError}
                    {prolongButton}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>;

          let ingosstrah2Toggle = null;
          if (isCompanyVisible(props.user, 8))
            ingosstrah2Toggle = <ToggleButton value={8} aria-label="Ингосстрах 2">{ getLogoByCompanyId(8) }</ToggleButton>

          let ingosstrah3Toggle = null;
          if (isCompanyVisible(props.user, 10))
            ingosstrah3Toggle = <ToggleButton value={10} aria-label="Ингосстрах 3">{ getLogoByCompanyId(10) }</ToggleButton>

          return <form className={classes.fullWidth} onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <Box className={classes.certificateBox}>
              <Card>
                <CardHeader
                  subheader="Выберите страховую компанию полис которой хотите продлить"
                  title="Пролонгация ОСАГО"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <Grid item lg={12} md={12} xs={12} >
                      <ToggleButtonGroup
                        value={prolongData.company_id}
                        exclusive
                        label="Страховая компания"
                        onChange={(event, newValue) => { updateProlongData({ company_id: newValue })}}
                        aria-label="Страховая компания"
                      >
                        <ToggleButton value={2} aria-label="Альфа-страхование">{ getLogoByCompanyId(2) }</ToggleButton>
                        { isCompanyVisible(props.user, 3) ? <ToggleButton value={3} aria-label="Ингосстрах">{ getLogoByCompanyId(3) }</ToggleButton> : null }
                        { ingosstrah2Toggle }
                        { ingosstrah3Toggle }
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              
              {companyRelatedFields}
    
            </Box>

            {bottomBlock}

            </MuiPickersUtilsProvider>
          </form>

        }
      }}
    </Formik>
  );
};

ProlongForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    prolongData: state.prolong.prolongData,
    isProlonging: state.prolong.isProlonging,
    prolongError: state.prolong.prolongError,
    prolongResult: state.prolong.prolongResult,

    user: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateProlongData: (newProlongData) => dispatch(actions.updateProlongData(newProlongData)),
    onProlongCertificate: (prolongData) => dispatch(actions.prolongCertificate(prolongData)),
    onResetProlongData: () => dispatch(actions.resetProlongData()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProlongForm);

import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';
import copyArray from '../../utils/copyArray';

const initialReportElement = {
    id: 0,
    name: '',
    certificates: {},
};

const initialState = {
    isLoading: false,
    isLoaded: false,
    loadError: false,
    report: initialReportElement,

    isSaving: false,
    savedId: false,
    saveError: null,

    newReports: null,
    isNewReportsLoading: false,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.REPORT_INITIALIZE: 
            return updateState(state, {
                isLoading: false, 
                isLoaded: false, 
                report: initialReportElement, 
                loadError: null, 
                isSaving: false,
                savedId: false,
                saveError: null,
            });

        /*case actionTypes.REPORT_UPDATE:
            const updatedReportElement = copyObject(state.report);
            for (const i in action.data)
            updatedReportElement[i] = action.data[i];
            return updateState(state, { report: updatedReportElement });*/

        case actionTypes.REPORT_SAVE_START: return updateState(state, {isSaving: true, saveError: null, errorFields: null});
        case actionTypes.REPORT_SAVE_SUCCESS: return updateState(state, {isSaving: false, savedId: action.reportId });
        case actionTypes.REPORT_SAVE_FAIL: return updateState(state, {isSaving: false, saveError: action.error, errorFields: action.fields });

        case actionTypes.REPORT_LOAD_START: return updateState(state, {isLoading: true, isLoaded: false, loadError: null, errorFields: null, isSaving: false, savedId: false, saveError: null });
        case actionTypes.REPORT_LOAD_SUCCESS: return updateState(state, {isLoading: false, isLoaded: true, report: action.report });
        case actionTypes.REPORT_LOAD_FAIL: return updateState(state, {isLoading: false, isLoaded: false, loadError: action.error });

        case actionTypes.REPORT_CHANGE_STATUS_SUCCESS: 
            const updatedReportData = copyObject(state.report);
            updatedReportData.status_id = action.newStatusId;
            return updateState(state, { report: updatedReportData });

        case actionTypes.REPORT_UPDATE:
            const updatedReport = copyObject(state.report);
            for (const i in action.data)
            updatedReport[i] = action.data[i];
            return updateState(state, { report: updatedReport });

        case actionTypes.DELETE_CERTIFICATE_FROM_REPORT_SUCCESS: 
            const newReport = copyObject(state.report);
            const currentCertificates = copyArray(state.report.certificates);
            const newCertificates = [];
            for (const i in currentCertificates)
                if (currentCertificates[i].id !== action.certificateId)
                    newCertificates.push(currentCertificates[i]);
            newReport.certificates = newCertificates;
            return updateState(state, { report: newReport });

        case actionTypes.NEW_REPORTS_LOAD_START: return updateState(state, {isNewReportsLoading: true});
        case actionTypes.NEW_REPORTS_LOAD_SUCCESS: return updateState(state, {isNewReportsLoading: false, newReports: action.newReports});
        case actionTypes.NEW_REPORTS_REDUCE: return updateState(state, {newReports: state.newReports - action.amount*1});

        default: return state;
    }
}

export default reducer;
import {put} from 'redux-saga/effects';

import * as actions from '../actions/reports';
import * as authActions from '../actions/auth';
import * as generalActions from '../actions/general';
import * as reportActions from '../actions/report';
import { generalError, generalSuccess, generalStart } from '../actions/general';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { reportsUrl, reportsDeleteUrl, reportSaveUrl, newReportsUrl } from '../../settings/api_urls';

export function* loadReportsSaga(action) 
{
    yield put(actions.loadStart(action.filter, action.page, action.perPage, action.order));

    const getData = {
        ...action.filter,
        format: 1,
        fill_agent_data: 1,
        page: action.page ?? 1,
        perPage: action.perPage ?? 20,
        order: action.order ?? { "id": "DESC" },
    }

    try {
        //console.log("GO AXIOS", reportsUrl, getData);
        const response = yield tokenizedAxios(reportsUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const reports = response.data.reports;
            const total = response.data.total;
            const totalNew = response.data.total_new;
            yield put(actions.loadSuccess(reports, total, totalNew));
        } else {
            yield put(actions.loadError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* deleteReportSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(reportsDeleteUrl, 'post', {id: action.reportId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Отчёт " + action.reportId + " успешно удалён"));
            yield put(actions.deleteReportSuccess(action.reportId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* addReportSaga(action)
{
    yield put(actions.addReportStart(action.certificateIds, action.reportId, action.newReportName, action.newReportType));
    yield put (generalStart());

    try {
        const response = yield tokenizedAxios(reportSaveUrl, 'post', {certificate_ids: action.certificateIds, report_id: action.reportId, name: action.newReportName, type: action.newReportType});
        //console.log("RESPONSE", action.goToReport, response);
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Полисы добавлены в отчёт #" + response.data.report_id));
            if (action.goToReportId) {
                yield put(reportActions.initializeReport());
                yield put(actions.redirectFromReportsWindow(action.goToReportId ? response.data.report_id : 0));
            }
            else
                yield put(actions.closeReportsWindow());
        } else {
            //console.log("GNEERAL ERROR", response.data);
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* loadNewReportsSaga(action) 
{
    yield put(actions.loadNewReportsStart());

    const getData = {
        //fill_agent_data: 1
    }

    try {
        const response = yield tokenizedAxios(newReportsUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const newReportsCount = response.data.new_reports_count;
            yield put(actions.loadNewReportsSuccess(newReportsCount));
        } else {
            if (response.data.not_authorized) yield put(authActions.logout());
            yield put(generalActions.generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalActions.generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
import * as actionTypes from './actionTypes';

export const registerStart = () => {
    return { type: actionTypes.REGISTER_START }
}

export const registerSuccess = (registerData) => {
    return {
        type: actionTypes.REGISTER_SUCCESS,
        token: registerData.token,
        user: registerData.user,
    }
}

export const registerError = (error) => {
    return {
        type: actionTypes.REGISTER_FAIL,
        error: error,
    }
}

export const register = (name, surname, email, phone, password) => {
    return {
        type: actionTypes.REGISTER_USER,
        name: name,
        surname: surname,
        email: email,
        phone: phone,
        password: password,
    }
}
const roubles = (sum) => {
    if (!isNaN(sum)) {
        return numberWithSpaces(Math.round(sum)) + "\xa0р";
    }
    return '';
}

const numberWithSpaces = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, String.fromCharCode(160));
    return parts.join(".");
}

export default roubles;
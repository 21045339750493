import * as actionTypes from './actionTypes';

export const resetCertificates = () => { return { type: actionTypes.CERTIFICATES_RESET }}

export const loadCertificates = (filter, page, perPage, order) => {
    return {
        filter : filter,
        page : page,
        perPage : perPage,
        order: order,

        type: actionTypes.CERTIFICATES_LOAD_INIT,
    }
}

export const loadStart = (filter, page, perPage, order) => {
    return {
        filter: filter,
        page: page,
        perPage: perPage,
        order: order,

        type: actionTypes.CERTIFICATES_LOAD_START,
    }
}

export const loadSuccess = (certificates, total) => {
    return {
        certificates: certificates,
        total: total,
        
        type: actionTypes.CERTIFICATES_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        
        type: actionTypes.CERTIFICATES_LOAD_FAIL,
    }
}


export const deleteCertificate = (certificateId) => ({ certificateId: certificateId, type: actionTypes.CERTIFICATES_DELETE })
export const deleteCertificateSuccess = (certificateId) => ({ certificateId: certificateId, type: actionTypes.CERTIFICATES_DELETE_SUCCESS })
export const deleteCertificateFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })

export const cancelCertificate = (certificateId) => ({ certificateId: certificateId, type: actionTypes.CERTIFICATES_CANCEL })
export const cancelCertificateSuccess = (certificateId, newStatus) => ({ certificateId: certificateId, newStatus: newStatus, type: actionTypes.CERTIFICATES_CANCEL_SUCCESS })
export const cancelCertificateFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })

export const prolongCertificate = (certificateId) => ({ certificateId: certificateId, type: actionTypes.CERTIFICATES_PROLONG })
export const prolongCertificateSuccess = (certificateId) => ({ certificateId: certificateId, type: actionTypes.CERTIFICATES_PROLONG_SUCCESS })
export const prolongCertificateFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })

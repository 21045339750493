import {put} from 'redux-saga/effects';

import * as actions from '../actions/messages';
import * as dialogActions from '../actions/dialogs';
import { generalError, generalSuccess, generalStart } from '../actions/general';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { messagesUrl, messagesDeleteUrl, messageSaveUrl } from '../../settings/api_urls';

export function* loadMessagesSaga(action) 
{
    yield put(actions.loadStart(action.userId, action.page, action.perPage));

    const getData = {
        user_id: action.userId ?? 0,
        page: action.page ?? 1,
        perpage: action.perPage ?? 20
    }

    try {
        const response = yield tokenizedAxios(messagesUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const messages = response.data.messages;
            const total = response.data.total;
            yield put(actions.loadSuccess(messages, total));
            yield put(dialogActions.newMessagesReduce(response.data.read));
        } else {
            yield put(actions.loadError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* deleteMessageSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(messagesDeleteUrl, 'post', {message_id: action.messageId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Сообщение успешно удалено"));
            yield put(actions.deleteMessageSuccess(action.messageId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* saveMessageSaga(action) 
{
    yield put(actions.saveMessageStart());
    try {
        const dataToSend = {
            text: action.text,
            user_ids: action.userIds,
        };

        //console.log("IDS TO SEND TO", dataToSend.user_id);

        dataToSend.new_api = 1; //New API for backend
        const response = yield tokenizedAxios(messageSaveUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            yield put(actions.saveMessageSuccess());
            //yield put(actions.resetMessages()); //Так нельзя ибо сбрасывается сообщение об успехе
        } else {
            yield put(actions.saveMessageFail(response.data.errorstring, response.data.fields));
        }

    } catch (error) {
        yield put(actions.saveMessageFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", null));
    };
}
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmDialog = (props) => {
  const [open, setOpen] = React.useState(true);
  
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.dialogHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{props.dialogText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={(event) => {props.cancelHandler(); handleClose();}} color="primary">{props.cancelText ?? "Отмена"}</Button>
          <Button onClick={(event) => {props.continueHandler(); handleClose();}} color="primary" autoFocus>{props.okText ?? "Продолжить"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;
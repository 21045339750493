import {takeEvery} from 'redux-saga/effects';

import * as actionTypes from '../actions/actionTypes';
import {logoutSaga, checkAuthTimeoutSaga, authUserSaga, authCheckStateSaga} from './auth';
import {registerUserSaga} from './register';
import {resetSaga} from './reset';
import { loadCertificatesSaga, deleteCertificateSaga, cancelCertificateSaga } from './certificates';
import { loadCarInfoSaga, saveCertificateSaga, loadCertificateSaga } from './certificate';
import { loadUsersSaga, deleteUserSaga, changeCompanySaga } from './users';
import { saveUserSaga, loadUserSaga, changePasswordSaga, loadUserLogSaga } from './user';
import { loadRatesSaga, deleteRateSaga } from './rates';
import { saveRateSaga, loadRateSaga, loadRateInfoSaga } from './rate';
import { loadNewsSaga, deleteNewsSaga } from './news';
import { loadMessagesSaga, deleteMessageSaga, saveMessageSaga } from './messages';
import { loadDialogsSaga, loadNewMessagesSaga } from './dialogs';
import { saveNewsSaga, loadNewsElementSaga } from './new';
import { loadCatalogSaga } from './catalogs';
import { uploadFileSaga } from './files';
import { loadDocumentsSaga } from './documents';
import { calculateCertificateSaga, processCertificateSaga, finishCertificateSaga } from './calculations';
import { prolongCertificateSaga } from './prolong';
import { loadReportsSaga, addReportSaga, deleteReportSaga, loadNewReportsSaga } from './reports';
import { downloadReportSaga, loadReportSaga, saveReportSaga, deleteCertificateFromReportSaga, changeReportStatusSaga } from './report';
import { loadAdminStatisticsSaga, loadCountersSaga, loadCreatedCertificatesSaga, loadNewCertificatesSaga, loadProlongationCertificatesSaga } from './dashboard';

export function* watchAll() {
    yield takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga);
    yield takeEvery(actionTypes.AUTH_USER, authUserSaga);
    yield takeEvery(actionTypes.AUTH_RESET, resetSaga);
    yield takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga);

    yield takeEvery(actionTypes.REGISTER_USER, registerUserSaga);

    yield takeEvery(actionTypes.CERTIFICATES_LOAD_INIT, loadCertificatesSaga);
    yield takeEvery(actionTypes.CERTIFICATES_DELETE, deleteCertificateSaga);
    yield takeEvery(actionTypes.CERTIFICATES_CANCEL, cancelCertificateSaga);
    yield takeEvery(actionTypes.CERTIFICATE_CAR_INFO, loadCarInfoSaga);
    yield takeEvery(actionTypes.CERTIFICATE_SAVE, saveCertificateSaga);
    yield takeEvery(actionTypes.CERTIFICATE_LOAD, loadCertificateSaga);

    yield takeEvery(actionTypes.USERS_LOAD_INIT, loadUsersSaga);
    yield takeEvery(actionTypes.USERS_DELETE, deleteUserSaga);
    yield takeEvery(actionTypes.USERS_COMPANY_UPDATE, changeCompanySaga)
    yield takeEvery(actionTypes.USER_SAVE, saveUserSaga);
    yield takeEvery(actionTypes.USER_LOAD, loadUserSaga);
    yield takeEvery(actionTypes.PASSWORD_CHANGE, changePasswordSaga);
    yield takeEvery(actionTypes.USER_LOAD_LOG, loadUserLogSaga);

    yield takeEvery(actionTypes.RATES_LOAD_INIT, loadRatesSaga);
    yield takeEvery(actionTypes.CHILD_RATE_INIT, loadRatesSaga);
    yield takeEvery(actionTypes.RATES_DELETE, deleteRateSaga);
    yield takeEvery(actionTypes.RATE_SAVE, saveRateSaga);
    yield takeEvery(actionTypes.RATE_LOAD, loadRateSaga);
    yield takeEvery(actionTypes.RATE_INFO_LOAD, loadRateInfoSaga);

    yield takeEvery(actionTypes.NEWS_LOAD_INIT, loadNewsSaga);
    yield takeEvery(actionTypes.NEWS_DELETE, deleteNewsSaga);
    yield takeEvery(actionTypes.NEWS_ELEMENT_SAVE, saveNewsSaga);
    yield takeEvery(actionTypes.NEWS_ELEMENT_LOAD, loadNewsElementSaga);

    yield takeEvery(actionTypes.DIALOGS_LOAD_INIT, loadDialogsSaga);
    yield takeEvery(actionTypes.NEW_MESSAGES_LOAD_INIT, loadNewMessagesSaga);

    yield takeEvery(actionTypes.REPORTS_LOAD_INIT, loadReportsSaga);
    yield takeEvery(actionTypes.REPORTS_DELETE, deleteReportSaga);
    yield takeEvery(actionTypes.REPORTS_ADD, addReportSaga);
    yield takeEvery(actionTypes.REPORT_CHANGE_STATUS, changeReportStatusSaga);
    yield takeEvery(actionTypes.NEW_REPORTS_LOAD_INIT, loadNewReportsSaga);

    yield takeEvery(actionTypes.REPORT_LOAD, loadReportSaga);
    yield takeEvery(actionTypes.REPORT_SAVE, saveReportSaga);
    yield takeEvery(actionTypes.REPORT_DOWNLOAD, downloadReportSaga);
    yield takeEvery(actionTypes.DELETE_CERTIFICATE_FROM_REPORT, deleteCertificateFromReportSaga);

    yield takeEvery(actionTypes.DASHBOARD_COUNTERS, loadCountersSaga);
    yield takeEvery(actionTypes.DASHBOARD_CREATED_CERTIFICATES, loadCreatedCertificatesSaga);
    yield takeEvery(actionTypes.DASHBOARD_NEW_CERTIFICATES, loadNewCertificatesSaga);
    yield takeEvery(actionTypes.DASHBOARD_PROLONG_CERTIFICATES, loadProlongationCertificatesSaga);
    yield takeEvery(actionTypes.DASHBOARD_STATISTICS, loadAdminStatisticsSaga);

    yield takeEvery(actionTypes.MESSAGES_LOAD_INIT, loadMessagesSaga);
    yield takeEvery(actionTypes.MESSAGES_DELETE, deleteMessageSaga);
    yield takeEvery(actionTypes.MESSAGES_SAVE, saveMessageSaga);

    yield takeEvery(actionTypes.CATALOG_LOAD_INIT, loadCatalogSaga);
    yield takeEvery(actionTypes.DOCUMENTS_LOAD_INIT, loadDocumentsSaga);

    yield takeEvery(actionTypes.CERTIFICATE_CALCULATE, calculateCertificateSaga);
    yield takeEvery(actionTypes.CERTIFICATE_PROCESS, processCertificateSaga);
    yield takeEvery(actionTypes.CERTIFICATE_FINISH, finishCertificateSaga);

    yield takeEvery(actionTypes.CERTIFICATES_PROLONG, prolongCertificateSaga);

    yield takeEvery(actionTypes.UPLOAD_FILE, uploadFileSaga);
}
import React, { useState, useEffect, useCallback } from 'react';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    Divider,
    Fade,
    Grid,
    Modal,
    TextField,
    makeStyles,
    Typography,
} from '@material-ui/core';
import InputMask from 'react-input-mask';

import * as actions from '../../../store/actions';

import Spinner from '../../../components/Spinner';

import {companiesBsoNeedsCheque, companiesEbsoNeedsCheque, companiesBsoMandatoryCheque, companiesEbsoNeedNumber} from '../../../settings/companies';
import bigRussianLettersAndDigits from '../../../utils/bigRussianLettersAndDigits';
import isCompanyVisible from '../../../utils/isCompanyVisible';
import { russianLetters } from '../../../settings/masks';

const useStyles = makeStyles(() => ({
    root: {},
    marginBottom: {
      marginBottom: "10px"
    },
    error: {
      color: "#f44336"
    },
    card: {
      width: "330px",
      textAlign: "center",
    },
    button: {
      width: "210px",
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

const FinishBlock = ({ catalogsCompanies, certificate, calculations, ...rest }) => {

  const classes = useStyles();
  const navigate = useNavigate();

  const [bsoNumber, setBsoNumber] = useState(rest.bsoNumber);
  const [bsoCheque, setBsoCheque] = useState(rest.bsoCheque);

  const printDocuments = (certificateId) => {
      rest.onOpenDocumentsWindow(certificateId);
  }

  const newCertificate = () => {
      navigate("/app/certificates/new");
  }

  const processCertificateType = (certificateTypeId) => {

      /*if (certificateTypeId !== 'eosago')
        rest.onSetFinishInfo(rest.companyId, certificateTypeId, '', '', '');
      else 
        rest.onSetFinishInfo(rest.companyId, certificateTypeId, '', '', '');  */

      rest.onSetFinishInfo(rest.companyId, certificateTypeId, '', '', '');

  }

  const processCertificate = (bsoNumber, bsoCheque) => {
        
      const calculation = (calculations && calculations[rest.companyId]);

      if (rest.typeId === 'eosago') {
        rest.onProcessCertificate(certificate.id, rest.companyId, rest.typeId, bsoNumber, bsoCheque, calculation.calculationResult.calc_data);
      } else {
        let valid = true;
        if (rest.typeId === 'bso') {
          if (!bsoNumber) valid = false;
          if (!bsoCheque && companiesBsoMandatoryCheque.includes(rest.companyId*1)) valid = false;
        }
        if (rest.typeId === 'ebso') {
          if (!bsoNumber && companiesEbsoNeedNumber.includes(rest.companyId*1)) valid = false;
          //if (!bsoCheque && companiesBsoMandatoryCheque.includes(rest.companyId*1)) valid = false;
        }
        if (valid) {
          rest.onProcessCertificate(certificate.id, rest.companyId, rest.typeId, bsoNumber, bsoCheque, calculation.calculationResult.calc_data);
        } else {
          rest.onSetFinishInfo(rest.companyId, rest.typeId, bsoNumber, bsoCheque, 'Укажите данные БСО');
        }
      }
  }

  const finishCertificate = (companyId) => {       
    //console.log("FINISH PRESSED", rest.processData);
    rest.onFinishCertificate(certificate.id, companyId, rest.processData.calc_data);
  }

  if (catalogsCompanies && rest.companyId) {

    const company = catalogsCompanies[rest.companyId];
    const cancelButton = <Grid key={"cancel"} item xs={12} lg={12} md={12}><Button className={classes.button} variant="contained" onClick={() => {rest.finishWindow(null)}}>Отмена</Button></Grid>

    let finishBlock = null;

    if (rest.processError || rest.finishError) {
      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}><Alert severity="error">{rest.processError ?? rest.finishError}</Alert></Grid>
        <Grid item xs={12} lg={12} md={12}><Button className={classes.button} variant="contained" onClick={() => {rest.finishWindow(rest.companyId)}}>Попробовать ещё раз</Button></Grid>
        {cancelButton}
      </>
    } else if (rest.isFinishing || rest.isProcessing) {

      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}>
          <Spinner text="Оформляем полис..."></Spinner>
        </Grid>
      </>

    } else if (rest.isFinishSuccess) {
      
      let payButton = null;
      if (rest.completeData && rest.completeData.calc_data.formUrl && rest.completeData.calc_data.formUrl !== "") payButton = <Grid item xs={12} lg={12} md={12}>
          <Button href={rest.completeData.calc_data.formUrl} target="_blank" variant="contained" color="primary">Оплатить полис</Button>
      </Grid>

      let printButton = null;
      if (rest.typeId === 'bso' || rest.typeId === 'ebso') printButton = <Grid item xs={12} lg={12} md={12}>
        <Button variant="contained" color="default" onClick={() => printDocuments(certificate.id)}>Распечатать документы</Button>
      </Grid>

      let eosagoNotice = null;
      if (rest.typeId === 'eosago')
        eosagoNotice = <Alert severity="info">Проверка факта оплаты полиса может занять несколько минут.</Alert>
      if (rest.completeData.price_changed && rest.completeData.price_changed*1 === 1)
        eosagoNotice = <Alert severity="error" style={{marginBottom: "5px"}}>Внимание! Финальная стоимость полиса изменилась на { rest.completeData.cost }Р!</Alert>


      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}>
        <Alert severity="success" style={{marginBottom: "5px"}}>Полис успешно оформлен!</Alert>
        {eosagoNotice}
        </Grid>
        {payButton}
        {printButton}
        <Grid item xs={12} lg={12} md={12}>
          <Button variant="contained" color="default" onClick={() => newCertificate(certificate.id)}>Оформить новый полис</Button>
        </Grid>
      </>
    }
    else if (rest.isProcessSuccess) {
      
      //console.log("PROCESSDATA IS",rest.processData);

      let priceChangedAlert = null;
      if (rest.processData.price_changed && rest.processData.price_changed*1 === 1)
        priceChangedAlert = <Grid item xs={12} lg={12} md={12}><Alert severity="error" style={{marginBottom: "5px"}}>Внимание! Финальная стоимость полиса изменилась!</Alert></Grid>

      let poolAlert = null;
      if (rest.processData.is_pool && rest.processData.is_pool*1 === 1)
        poolAlert = <Grid item xs={12} lg={12} md={12}><Alert severity="error" style={{marginBottom: "5px"}}>Внимание! Договор подлежит перестрахованию!</Alert></Grid>

      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}><Typography>Данные полиса успешно отправлены в страховую компанию.</Typography></Grid>
        {priceChangedAlert}
        {poolAlert}
        <Grid item xs={2} lg={2} md={2}></Grid>
        <Grid item xs={4} lg={4} md={4}><Typography>Стоимость</Typography></Grid>
        <Grid item xs={6} lg={6} md={6}><Typography><b>{rest.processData.cost}</b> Р</Typography></Grid>
        <Grid item xs={2} lg={2} md={2}></Grid>
        <Grid item xs={4} lg={4} md={4}><Typography>Комиссия</Typography></Grid>
        <Grid item xs={6} lg={6} md={6}><Typography><b>{rest.processData.comission}</b> Р</Typography></Grid>

        <Grid item xs={12} lg={12} md={12}>
          <Button variant="contained" color="primary" onClick={() => finishCertificate(company.id)}>Завершить оформление</Button>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          <Button variant="contained" color="default" onClick={() => rest.finishWindow(null)}>Отменить оформление</Button>
        </Grid>
      </>
    }
    else if (rest.typeId === null) 
    {

      const typeButtons = [];
      if (company.eosago*1 === 1 && isCompanyVisible(rest.user, company.id, 'eosago')) typeButtons.push(<Grid key={1} item xs={12} lg={12} md={12}><Button className={classes.button} variant="contained" color="primary" onClick={() => {processCertificateType('eosago')}}>Оформить ЕОСАГО</Button></Grid>);
      if (company.bso*1 === 1 && isCompanyVisible(rest.user, company.id, 'bso'))       typeButtons.push(<Grid key={2} item xs={12} lg={12} md={12}><Button className={classes.button} variant="contained" color="primary" onClick={() => {processCertificateType('bso')}}>Оформить БСО</Button></Grid>);
      if (company.ebso*1 === 1 && isCompanyVisible(rest.user, company.id, 'ebso'))     typeButtons.push(<Grid key={3} item xs={12} lg={12} md={12}><Button className={classes.button} variant="contained" color="primary" onClick={() => {processCertificateType('ebso')}}>Оформить ЕБСО</Button></Grid>);

      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}><Typography color="textSecondary" variant="body1" >Выберите тип полиса</Typography></Grid>
        {typeButtons}
        {cancelButton}
      </>

    } else if ((rest.typeId === 'bso' || rest.typeId === 'ebso') && !rest.isProcessing && !rest.isFinishing) {

      let chequeBlock = null;
      if ((rest.typeId === 'bso' && companiesBsoNeedsCheque.includes(rest.companyId*1) !== false) || (rest.typeId === 'ebso' && companiesEbsoNeedsCheque.includes(rest.companyId*1) !== false)) {
        let helperText = "Серию и номер квитанции разделяйте через пробел";
        if (!companiesBsoMandatoryCheque.includes(rest.companyId*1))
          helperText +=". Если поле не будет заполнено, квитанция будет сформирована автоматически страховой компанией";
        chequeBlock = <Grid item xs={12} lg={12} md={12}>
          <TextField
            fullWidth
            error={false}
            helperText={helperText}
            label="Серия и номер квитанции"
            name="bso_cheque"
            type="text"
            value={bsoCheque}
            onChange={(event) => { setBsoCheque(bigRussianLettersAndDigits(event.target.value)); } }
            variant="outlined"
            placeholder="123 123456"
          />
        </Grid>
      }

      let bsoBlock = null;
      if (rest.typeId === 'bso' || companiesEbsoNeedNumber.includes(rest.companyId*1)) bsoBlock = <Grid item xs={12} lg={12} md={12}>
        <InputMask
          mask={[russianLetters, russianLetters, russianLetters, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
          value={bsoNumber}
          disabled={false}
          name="bso_number"
          onChange={(event) => setBsoNumber(event.target.value.toUpperCase())}
        >
        <TextField
          fullWidth
          error={false}
          helperText={false}
          type="text"
          label="Серия и номер полиса"
          placeholder="PPP 123456"
          required={true}
          value={bsoNumber}
          variant="outlined"
          />
        </InputMask>
      </Grid>

      let enterDataText = <Typography color="textSecondary" variant="body1" >Всё готово для оформления {rest.typeId==='bso' ? 'БСО' : 'ЕБСО'}</Typography>
      if (bsoBlock || chequeBlock) enterDataText = <Typography color="textSecondary" variant="body1" >Введите данные для оформления {rest.typeId==='bso' ? 'БСО' : 'ЕБСО'}</Typography>

      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}>{enterDataText}</Grid>
        {bsoBlock}
        {chequeBlock}
        <Grid item xs={12} lg={12} md={12}>
          { rest.formError ? <Alert className={classes.marginBottom} severity="error">{rest.formError}</Alert> : null }
          <Button className={classes.button} variant="contained" color="primary" onClick={() => {processCertificate(bsoNumber, bsoCheque)}}>Оформить</Button>
        </Grid>
        {cancelButton}
      </>

    } else if (rest.typeId === 'eosago' && !rest.isFinishing && !rest.isProcessing) {

      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}>
          <Typography color="textSecondary" variant="body1" >Всё готово к оформлению ЕОСАГО</Typography>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          { rest.formError ? <Alert className={classes.marginBottom} severity="error">{rest.formError}</Alert> : null }
          <Button className={classes.button} variant="contained" color="primary" onClick={() => {processCertificate('', '')}}>Оформить</Button>
        </Grid>
        {cancelButton}
      </>

    } else {
      finishBlock = <>
        <Grid item xs={12} lg={12} md={12}><Alert severity="error">Ошибка разбора оформления полиса</Alert></Grid>
        {cancelButton}
      </>
    }

    return <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={true}
      onClose={() => rest.finishWindow(null)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      tabIndex={false}
      tab-index={false}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
      <Card
        className={clsx(classes.root/*, className*/)}
      >
        <CardContent className={classes.card}>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Grid container spacing={1} >
              <Grid item xs={12} lg={12} md={12}>
                <Typography color="textPrimary" gutterBottom variant="h3" >
                  Оформление полиса {certificate.id}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={12} md={12}>{rest.getLogoByCompanyId(rest.companyId)}</Grid>
              {finishBlock}
            </Grid>
          </Box>
        </CardContent>
        <Divider />
      </Card>
      </Fade>
    </Modal>
  }

  return null;
}

const mapStateToProps = state => {
    return {
      typeId: state.calculations.typeId,
      bsoNumber: state.calculations.bsoNumber, 
      bsoCheque: state.calculations.bsoCheque, 
      formError: state.calculations.formError,
  
      isProcessSuccess: state.calculations.isProcessSuccess, 
      processError: state.calculations.processError,
      isProcessing: state.calculations.isProcessing,

      isFinishSuccess: state.calculations.isFinishSuccess, 
      finishError: state.calculations.finishError,
      isFinishing: state.calculations.isFinishing,
      
      processData: state.calculations.processData,
      completeData: state.calculations.completeData,

      user: state.auth.user,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        onSetFinishInfo: (companyId, typeId, bsoNumber, bsoCheque, formError) => dispatch(actions.setFinishInfo(companyId, typeId, bsoNumber, bsoCheque, formError)),  
        onProcessCertificate: (certificateId, companyId, typeId, bsoNumber, bsoCheque, calcData) => dispatch(actions.processCertificate(certificateId, companyId, typeId, bsoNumber, bsoCheque, calcData)),
        onFinishCertificate: (certificateId, companyId, processData) => dispatch(actions.finishCertificate(certificateId, companyId, processData)),
        onOpenDocumentsWindow: (certificateId) => dispatch(actions.openDocumentsWindow(certificateId)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(FinishBlock);
import { isObject } from "formik";
import copyObject from '../../../utils/copyObject';

export const prepareRate = (initialRate) => {

    const rate = copyObject(initialRate);

    const fieldsToIgnore = [];

    if (!rate || !isObject(rate)) return {result: "error", errorString: "Вы не указали данные комиссионного вознаграждения"};

    //Создаём массив данных для отправки на сервер. Это все поля формы за исключением некоторых - их обрабатываем отдельно и не включаем в результирующий массив
    const dataToSend = {};

    //console.log("PREPARING RATE START", rate);

    for (const fieldName in rate) {
        switch (fieldName) {

            case "id": dataToSend['rate_id'] = rate.id; break;

            case "rates":
                dataToSend.subrates = [];
                const subrates = rate[fieldName];
                for (const i in subrates) {

                    if (rate.id === 1)
                        dataToSend.subrates.push({
                            id: subrates[i].id,
                            company_id: subrates[i].company_id,
                            region_id: JSON.stringify(subrates[i].region_id),
                            category: JSON.stringify(subrates[i].category),
                            task: JSON.stringify(subrates[i].task),
                            prolong: JSON.stringify(subrates[i].prolong),

                            trailer: subrates[i].trailer,
                            juridical: subrates[i].juridical,
                            drivers: subrates[i].drivers,
                            power: subrates[i].power,
                            kbm: subrates[i].kbm,

                            percent: subrates[i].percent,
                        });
                    else 
                        dataToSend.subrates.push({
                            subrate_id: subrates[i].subrate_id,
                            percent: subrates[i].percent,
                        });
                }
                break;

            case "parent_rates": break;

            default:
                if (!fieldsToIgnore.includes(fieldName))
                    dataToSend[fieldName] = rate[fieldName];
                break;
        }
    }

    //console.log("PREPARING RATE FINISHED", dataToSend);
    
    return dataToSend;
}
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import SmartTable from '../../components/SmartTable';

import { certificateCancelableStatuses, certificatePrintableStatuses } from '../../settings/profins';

import * as actions from '../../store/actions';
import CertificateDocuments from '../certificate/CertificateDocuments';
import ConfirmDialog from '../../components/ConfirmDialog';
import Spinner from '../../components/Spinner';
import Header from '../../components/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  marginTop: {
    marginTop: '20px'
  },
  tooltipLink: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  },
  subText: {
    fontSize: '10px',
    color: 'gray',
    marginTop: '1px',
  },
  text: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  icon: {
    verticalAlign: 'middle',
    display: 'inline-block',
    color: 'gray',
  },
}));

const smartTableHeaders = { 
  //'id':'Номер', 
  'client_name_f':'Страхователь', 
  'created_at':'Создан' 
}

const NewCertificates = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const [confirmBlock, setConfirmBlock] = useState(null);
  const [infoBlock, setInfoBlock] = useState(null);

  const onLoadNewCertificates = props.onLoadNewCertificates;
  const onLoadCatalog = props.onLoadCatalog;
  
  const clickHandler = (certificateId) => {
    navigate("/app/certificates/" + certificateId);
  }

  const messageHandler = (userId) => {
    navigate("/app/messages/" + userId);
  }

  const cancelHandler = (certificateId) => {
    props.onCancelCertificate(certificateId);
  }

  const catalogsGeneral = props.catalogs['general'] ?? false;
  const catalogsCompanies = props.catalogs['companies'] ?? false;
  const catalogsRegions = props.catalogs['regions'] ?? false;

  useEffect(() => {
    if (catalogsGeneral && catalogsCompanies && catalogsRegions && props.newCertificates === null) {
      if (!props.isCertificatesLoading) {
        onLoadNewCertificates()
      }
    }
  }, [onLoadNewCertificates, onLoadCatalog, catalogsGeneral, catalogsCompanies, catalogsRegions, props.newCertificates]);

  const smartTableColumnCreator = (record) => {
    return {
      //'id': record.id,
      'client_name_f': parseInt(record.client_is_jur) === 1 ? record.client_name : (record.client_name_f + " " + record.client_name_i),
      'created_at': moment(record.created_at).format('DD.MM.YYYY')
    };
  };

  const smartTableTooltipData = (record) => {
    return <Box padding={3}>
      <Grid container spacing={2} >
        <Grid item md={8} xs={12} ><b>Регион</b></Grid>
        <Grid item md={8} xs={12} >{props.catalogs['regions'] ? (props.catalogs['regions'][record.client_region_id] ? props.catalogs['regions'][record.client_region_id].name : 'Регион не найден') : 'Неизветно'}</Grid>
        <Grid item md={12} xs={12} className={classes.marginTop}><b>Контакты страхователя</b></Grid>
        <Grid item md={4} xs={12} ><b>Телефон</b></Grid>
        <Grid item md={8} xs={12} >{record.client_phone ?? 'Не указан'}</Grid>
        <Grid item md={4} xs={12} ><b>E-mail</b></Grid>
        <Grid item md={8} xs={12} >{record.client_email ?? 'Не указан'}</Grid>
      </Grid>
    </Box>
  }

  const openConfirmBlock = (onContinue, header, text) => {
    setConfirmBlock({onContinue: onContinue, onCancel: ()=>setConfirmBlock(null), header: header, text: text});
  }

  const smartTableButtonsData = (record) => {

    const links = [];
    if (certificateCancelableStatuses.includes(record.status_id)) links.push(<MenuItem key={4} onClick={(event)=>openConfirmBlock(()=>cancelHandler(record.id), 'Аннулирование полиса '+record.id, 'Вы действительно хотите аннулировать этот полис?')}>Аннулировать</MenuItem>);
    if (certificatePrintableStatuses.includes(record.status_id)) links.push(<MenuItem key={5} onClick={() => props.onOpenDocumentsWindow(record.id)}>Печать документов</MenuItem>);
    links.push(<MenuItem key={6} onClick={(event)=>messageHandler(record.user_id)}>Сообщение агенту</MenuItem>);

    return links;
  }

  let documentsPopup = null;
  if (props.isDocumentsWindowActive && props.documentsCertificateId > 0) documentsPopup = <CertificateDocuments certificateId={props.documentsCertificateId} />

  let table;
  if (props.isCertificatesLoading) table = <Spinner></Spinner>
  else
    table = <SmartTable 
      isLoading={props.isCertificatesLoading}
      tableheaders={smartTableHeaders} 
      rowCreator={smartTableColumnCreator}
      tooltipData={smartTableTooltipData}
      buttonsData={smartTableButtonsData}
      filters={null}
      data={props.newCertificates} 
      total={null}
      onFilter={null} 
      onRowClick={clickHandler}
      error={props.error} 
      filter={null}
      order={null}
      minWidth={"100%"}
      page={1}
      perPage={10}
      massButtons={null}
      infoBlock={infoBlock}
    />;

  let confirmDialog = null;
  if (confirmBlock !== null)
    confirmDialog = <ConfirmDialog
      dialogHeader={confirmBlock.header} 
      dialogText={confirmBlock.text} 
      cancelHandler={confirmBlock.onCancel} 
      continueHandler={confirmBlock.onContinue} 
    />;

  return <>
    <Header title="Недавние расчёты" />
    {table}
    {confirmDialog}
    {documentsPopup}
  </>
};

const mapStateToProps = state => {
  return {
      isCertificatesLoading: state.dashboard.newCertificatesLoading,
      error: state.dashboard.newCertificatesError,
      newCertificates: state.dashboard.newCertificates,

      isCatalogsLoading: state.catalogs.isLoading,
      catalogs: state.catalogs.catalogs,

      isDocumentsWindowActive: state.documents.isActive,
      documentsCertificateId: state.documents.certificateId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadNewCertificates: () => dispatch(actions.loadNewCertificates()),
    onLoadCatalog: (catalogName) => dispatch(actions.loadCatalog(catalogName)),
    onDeleteCertificate: (certificateId) => dispatch(actions.deleteCertificate(certificateId)),
    onProlongCertificate: (certificateId) => dispatch(actions.prolongCertificate(certificateId)),
    onCancelCertificate: (certificateId) => dispatch(actions.cancelCertificate(certificateId)),
    onOpenDocumentsWindow: (certificateId) => dispatch(actions.openDocumentsWindow(certificateId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCertificates);
import React, { useState, useEffect } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { Plus as PlusIcon } from 'react-feather';
import Alert from '@material-ui/lab/Alert';

import ElkTextInput from '../../components/FormElements/ElkTextInput';

const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: "100%"
  },
  userBox: {
    marginTop: "20px"
  },
  marginTop: {
    marginTop: "10px"
  },
  marginBottom: {
    marginBottom: "20px"
  },
  center: {
    textAlign: "center",
  },
  saveSpinner: {
    marginRight: "10px"
  },
  error: {
    color: "#f44336"
  },
}));

const MessageForm = (props) => {

  const [message, setMessage] = useState('');
  const classes = useStyles();

  const saveMessage = (text) => {
    props.onSaveMessage(text);
  }

  useEffect(() => {
    setMessage('');
  },[props.savedId]);

  let saveButton = <Button variant="contained" color="primary" onClick={()=>saveMessage(message)}>Отправить</Button>
  if (props.isSaving) saveButton = <Button variant="contained" color="primary" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Отправляю</Button>

  let saveError = null;
  if (props.saveError) saveError = <Alert severity="error" className={classes.marginBottom}>{props.saveError}</Alert>
  else if (props.savedId) saveError =<Alert severity="success" className={classes.marginBottom}>Сообщение успешно сохранено</Alert>
  
  return <Grid container spacing={2} >
    <ElkTextInput
      label="Текст сообщения"
      width={12}
      name="message" 
      multiline={true}
      placeholder=""
      type="text"
      required={true}
      setFieldValue={()=>{}}
      onValueChange={(newValue) => { setMessage(newValue) }} 
      value={ message }
    />
  
    <Grid item lg={12} md={12} xs={12} className={classes.center} >
      {saveError}
      {saveButton}
    </Grid>
  </Grid>
}
     
export default MessageForm

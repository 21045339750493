import * as actionTypes from './actionTypes';

export const resetCalculations = () => ({ type: actionTypes.CERTIFICATE_RESET_CALCULATIONS });

export const calculateCertificate = (certificateId, companyId, fixedModelId=0) => ({ certificateId: certificateId, companyId: companyId, fixedModelId: fixedModelId, type: actionTypes.CERTIFICATE_CALCULATE })
export const calculateCertificateClear = (certificateId) => ({ certificateId: certificateId, type: actionTypes.CERTIFICATE_CALCULATE_CLEAR })
export const calculateCertificateStart = (certificateId, companyId) => ({ certificateId: certificateId, companyId: companyId, type: actionTypes.CERTIFICATE_CALCULATE_START })
export const calculateCertificateSuccess = (companyId, result) => ({ companyId: companyId, result: result, type: actionTypes.CERTIFICATE_CALCULATE_SUCCESS })
export const calculateCertificateFail = (companyId, errorText, mode, errors, models = null) => ({ companyId: companyId, error: errorText, mode: mode, errors: errors, models: models, type: actionTypes.CERTIFICATE_CALCULATE_FAIL })

export const setFinishInfo = (companyId, typeId, bsoNumber, bsoCheque, formError) => ({ companyId: companyId, typeId: typeId, bsoNumber: bsoNumber, bsoCheque: bsoCheque, formError: formError, type: actionTypes.CERTIFICATE_SET_FINISH_INFO });

export const processCertificate = (certificateId, companyId, typeId, bsoNumber, bsoCheque, calcData) => ({ certificateId: certificateId, companyId: companyId, typeId: typeId, bsoNumber: bsoNumber, bsoCheque: bsoCheque, calcData: calcData, type: actionTypes.CERTIFICATE_PROCESS })
export const processCertificateStart = (certificateId, companyId, typeId, bsoNumber, bsoCheque) => ({ certificateId: certificateId, companyId: companyId, typeId: typeId, bsoNumber: bsoNumber, bsoCheque: bsoCheque, type: actionTypes.CERTIFICATE_PROCESS_START })
export const processCertificateSuccess = (companyId, processResult) => ({ companyId: companyId, processResult: processResult, type: actionTypes.CERTIFICATE_PROCESS_SUCCESS })
export const processCertificateFail = (companyId, errorText, errors) => ({ companyId: companyId, error: errorText, errors: errors, type: actionTypes.CERTIFICATE_PROCESS_FAIL })

export const finishCertificate = (certificateId, companyId, calcData) => ({ certificateId: certificateId, companyId:companyId, calcData: calcData, type: actionTypes.CERTIFICATE_FINISH })
export const finishCertificateStart = (certificateId) => ({ certificateId: certificateId, type: actionTypes.CERTIFICATE_FINISH_START })
export const finishCertificateSuccess = (companyId, completeResult) => ({ companyId: companyId, completeResult: completeResult, type: actionTypes.CERTIFICATE_FINISH_SUCCESS })
export const finishCertificateFail = (companyId, errorText, errors) => ({ companyId: companyId, error: errorText, errors: errors, type: actionTypes.CERTIFICATE_FINISH_FAIL })
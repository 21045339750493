import React from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  FormControlLabel,
  Grid,
  Switch
} from '@material-ui/core';

import makeFirstLetterCapital from '../../../utils/makeFirstLetterCapital'
import getFormError from '../../../utils/getFormError'
import createMaskFromString from '../../../utils/createMaskFromString'

import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import ElkDadataFio from '../../../components/FormElements/ElkDadataFio';
import ElkDateSelector from '../../../components/FormElements/ElkDateSelector';
import ElkSelectInput from '../../../components/FormElements/ElkSelectInput';

import ElkDadataAddress from '../../../components/FormElements/ElkDadataAddress';

const CertificateFormPerson = (props) => {

  //return null;

  const activateFio = (setFieldValue, personVariant, newValue) => {
    let genderValue = certificate[personVariant + "_gender"];
    if (newValue && newValue.data && newValue.data.gender) {
      genderValue = newValue.data.gender === 'FEMALE' ? 2 : 1;
    } else if (newValue && newValue.gender)
      genderValue = newValue.gender;
    const fioValue = newValue.fio ? makeFirstLetterCapital(newValue.fio) : certificate[personVariant + "_fio"];
    setFieldValue(personVariant + "_gender", genderValue);
    setFieldValue(personVariant + "_fio", fioValue);
    props.changeData({ fio: fioValue, data: newValue.data ?? null, gender: genderValue });
  }

  const changeAddress = (addressString, addressData) => {
    props.changeData({ 
      address: addressString, 
      address_data: addressData, 
      city_id: null,
      city_kladr: null,
      city_name: null,
      house_id: null,
      house_kladr: null,
      house_name: null,
      street_id: null,
      street_kladr: null,
      street_name: null,
      apt: null,
    })
  }

  const certificate = props.certificate;
  const personVariant = props.personVariant;
  const documentType = certificate[personVariant + "_document_type"];

  let documentMask = React.useMemo(() => {
    let newDocumentMask = null;
    if (props.catalogsGeneral) {
      const documentInfo = props.catalogsGeneral.document_types[documentType];
      newDocumentMask = documentInfo ? (documentInfo.mask ?? null) : null;
      if (newDocumentMask !== null) newDocumentMask = createMaskFromString(newDocumentMask);
    }
    return newDocumentMask;
  }, [documentType, props.catalogsGeneral]);

  let formBlock = null;
  if ((certificate[personVariant + "_is_jur"] ?? 0)*1 === 0) {

    formBlock = <>

      <Grid item lg={12} md={12} xs={12} >
        <FormControlLabel
          style={{marginLeft:0}}
          control={<Switch checked={certificate[personVariant + "_is_ip"]*1 === 1} onChange={(event, newValue) => { props.changeData({ is_ip: newValue ? 1 : 0 }) }} name={personVariant + "_is_ip"} />}
          label={props.personLabel + " является ИП"}
        />
      </Grid>

      <ElkDadataFio 
        label="ФИО" 
        {...getFormError([personVariant + "_fio", personVariant + "_name_f", personVariant + "_name_i", personVariant + "_name_o", personVariant + "_gender"], props.touched, props.errors, props.certificateErrors)}
        name={personVariant + "_fio"}
        genderName={personVariant + "_gender"}
        onValueChange={(newValue) => { activateFio(props.setFieldValue, personVariant, newValue); }}
        //required={true} 
        value={certificate[personVariant + "_fio"]}
        genderValue={certificate[personVariant + "_gender"]}
      />

      <ElkDateSelector 
        {...getFormError(personVariant + "_birth_date", props.touched, props.errors, props.certificateErrors)}
        label="Дата рождения" 
        name={personVariant + "_birth_date"}
        onValueChange={(newValue) => { props.changeData({ birth_date: newValue }) }} 
        //required={true} 
        value={certificate[personVariant + "_birth_date"]}
        setFieldValue={props.setFieldValue}
      />

      <ElkSelectInput 
        {...getFormError(personVariant + "_document_type", props.touched, props.errors, props.certificateErrors)}
        label="Тип документа"
        name={personVariant + "_document_type"}
        onValueChange={(newValue) => { /*changeDocumentMask(newValue);*/ props.changeData({ document_type: newValue }) }} 
        setFieldValue={props.setFieldValue}
        //required={true} 
        value={certificate[personVariant + "_document_type"]}
        options={ props.personDocumentOptionsList }
      /> 

      <ElkTextInput
        {...getFormError([personVariant + "_document_series", personVariant + "_document_number", personVariant + "_document_series_number"], props.touched, props.errors, props.certificateErrors)}
        label="Серия и номер документа"
        name={personVariant + "_document_series_number"}
        //placeholder=""
        setFieldValue={props.setFieldValue}
        handleBlur={props.handleBlur}
        onValueChange={(newValue) => { props.changeData({ document_series_number: newValue.toUpperCase() }) }} 
        //required={true} 
        mask={documentMask}
        value={certificate[personVariant + "_document_series_number"] ?? ''}
      />

      <ElkDateSelector 
        {...getFormError(personVariant + "_document_date", props.touched, props.errors, props.certificateErrors)}
        label="Дата выдачи документа" 
        name={personVariant + "_document_date"} 
        onValueChange={(newValue) => { props.changeData({ document_date: newValue }) }} 
        //required={true} 
        value={certificate[personVariant + "_document_date"]}
        setFieldValue={props.setFieldValue}
      />

      <ElkTextInput
        {...getFormError(personVariant + "_document_place", props.touched, props.errors, props.certificateErrors)}
        label="Документ выдан"
        name={personVariant + "_document_place"}
        placeholder="ОВД Центрального округа г. Москвы"
        onValueChange={(newValue) => { props.changeData({ document_place: newValue }) }} 
        setFieldValue={props.setFieldValue}
        //required={true} 
        value={certificate[personVariant + "_document_place"] ?? ''}
      />

      <ElkTextInput
        {...getFormError(personVariant + "_document_code", props.touched, props.errors, props.certificateErrors)}
        label="Код подразделения"
        name={personVariant + "_document_code"}
        placeholder="110-440"
        handleBlur={props.handleBlur}
        onValueChange={(newValue) => { props.changeData({ document_code: newValue }) }} 
        setFieldValue={props.setFieldValue}
        //required={true} 
        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
        value={certificate[personVariant + "_document_code"] ?? ''}
      />

      <ElkTextInput
        {...getFormError(personVariant + '_phone', props.touched, props.errors, props.certificateErrors)}
        label="Телефон"
        name={personVariant + "_phone"}
        placeholder="7 (999) 999-9999"
        type="phone"
        onValueChange={(newValue) => { props.changeData({ phone: newValue }) }} 
        //required={true} 
        handleBlur={props.handleBlur}
        setFieldValue={props.setFieldValue}
        value={certificate[personVariant + "_phone"]}
        mask={['7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      />  

      <ElkDadataAddress
        {...getFormError([personVariant + "_address", personVariant + "_city_id", personVariant + "_street_id", personVariant + "_house_id"], props.touched, props.errors, props.certificateErrors)}
        label="Адрес" 
        width={8}
        name={personVariant + "_address"}
        onValueChange={(newValue, addressData) => { changeAddress(newValue, addressData); }} 
        //required={true} 
        handleBlur={props.handleBlur}
        value={certificate[personVariant + "_address"]}
        setFieldValue={props.setFieldValue}
      />

      <ElkTextInput
        {...getFormError(personVariant + '_email', props.touched, props.errors, props.certificateErrors)}
        label="Почта"
        name={personVariant + "_email"}
        type="email"
        placeholder="smirnov@yandex.ru"
        setFieldValue={props.setFieldValue}
        handleBlur={props.handleBlur}
        onValueChange={(newValue) => { props.changeData({ email: newValue }) }} 
        //required={true} 
        value={certificate[personVariant + "_email"]}
      />

    </>

  } else {

    formBlock = <>
      <ElkTextInput
        {...getFormError(personVariant + "_name", props.touched, props.errors, props.certificateErrors)}
        label="Название юрлица"
        name={personVariant + "_name"}
        placeholder="ООО Светлана"
        onValueChange={(newValue) => { props.changeData({ name: newValue }) }} 
        handleBlur={props.handleBlur}
        setFieldValue={props.setFieldValue}
        //required={true} 
        value={certificate[personVariant + "_name"] ?? ''}
      />

      <ElkTextInput
        {...getFormError(personVariant + "_inn", props.touched, props.errors, props.certificateErrors)}
        label="ИНН"
        name={personVariant + "_inn"}
        placeholder="1234567890"
        onValueChange={(newValue) => { props.changeData({ inn: newValue }) }} 
        handleBlur={props.handleBlur}
        setFieldValue={props.setFieldValue}
        //required={true} 
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        value={certificate[personVariant + "_inn"] ?? ''}
      />

      <ElkTextInput
        {...getFormError(personVariant + "_kpp", props.touched, props.errors, props.certificateErrors)}
        label="КПП"
        name={personVariant + "_kpp"}
        placeholder="123456789"
        onValueChange={(newValue) => { props.changeData({ kpp: newValue }) }} 
        handleBlur={props.handleBlur}
        setFieldValue={props.setFieldValue}
        //required={true} 
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        value={certificate[personVariant + "_kpp"] ?? ''}
      />

      <ElkTextInput
        {...getFormError(personVariant + "_ogrn", props.touched, props.errors, props.certificateErrors)}
        label="ОГРН"
        name={personVariant + "_ogrn"}
        placeholder="1234567890123"
        onValueChange={(newValue) => { props.changeData({ ogrn: newValue }) }} 
        handleBlur={props.handleBlur}
        setFieldValue={props.setFieldValue}
        //required={true} 
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        value={certificate[personVariant + "_ogrn"] ?? ''}
      />

      <ElkSelectInput 
        {...getFormError(personVariant + "_form", props.touched, props.errors, props.certificateErrors)}
        label="Организационно-правовая форма"
        name={personVariant + "_form"}
        onValueChange={(newValue) => { props.changeData({ form: newValue }) }} 
        setFieldValue={props.setFieldValue}
        //required={true} 
        value={certificate[personVariant + "_form"]}
        options={ props.juridicalOptionsList }
      /> 

      <ElkTextInput
        {...getFormError(personVariant + "_cert_number", props.touched, props.errors, props.certificateErrors)}
        label="Серия и номер документа о регистрации"
        name={personVariant + "_cert_number"}
        //placeholder=""
        onValueChange={(newValue) => { props.changeData({ cert_number: newValue }) }} 
        handleBlur={props.handleBlur}
        setFieldValue={props.setFieldValue}
        //required={true} 
        //mask={[/\d/, /\d/, /\d/, '.', /\d/]}
        value={certificate[personVariant + "_cert_number"] ?? ''}
      />

      <ElkDateSelector 
        {...getFormError(personVariant + "_cert_date", props.touched, props.errors, props.certificateErrors)}
        label="Дата выдачи документа" 
        name={personVariant + "_cert_date"} 
        onValueChange={(newValue) => { props.changeData({ cert_date: newValue }) }} 
        //required={true} 
        value={certificate[personVariant + "_cert_date"]}
        setFieldValue={props.setFieldValue}
      />

      <ElkDadataAddress
        {...getFormError([personVariant + "_address", personVariant + "_city_id", personVariant + "_street_id", personVariant + "_house_id"], props.touched, props.errors, props.certificateErrors)}
        label="Адрес" 
        width={8}
        name={personVariant + "_address"}
        onValueChange={(newValue, addressData) => { props.changeData({ address: newValue , address_data: addressData }) }} 
        //required={true} 
        value={certificate[personVariant + "_address"]}
        setFieldValue={props.setFieldValue}
        handleBlur={props.handleBlur}
      />

      <ElkTextInput
        {...getFormError(personVariant + '_phone', props.touched, props.errors, props.certificateErrors)}
        label="Телефон"
        name={personVariant + "_phone"}
        placeholder="7 (999) 999-9999"
        type="phone"
        onValueChange={(newValue) => { props.changeData({ phone: newValue }) }} 
        //required={true} 
        handleBlur={props.handleBlur}
        setFieldValue={props.setFieldValue}
        value={certificate[personVariant + "_phone"]}
        mask={['7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      />

      <ElkTextInput
        {...getFormError(personVariant + '_email', props.touched, props.errors, props.certificateErrors)}
        label="Почта"
        name={personVariant + "_email"}
        type="email"
        placeholder="smirnov@yandex.ru"
        setFieldValue={props.setFieldValue}
        handleBlur={props.handleBlur}
        onValueChange={(newValue) => { props.changeData({ email: newValue }) }} 
        //required={true} 
        value={certificate[personVariant + "_email"]}
      />

    </>

  }

  return ( 
    <Grid container spacing={3} >

      <Grid item lg={12} md={12} xs={12} >
        <ToggleButtonGroup
            value={certificate[personVariant + "_is_jur"] ?? 0}
            exclusive
            label="Юрлицо"
            onChange={(event, newValue) => { props.changeData({ is_jur: newValue })}}
        >
          <ToggleButton value={0}>Физическое лицо</ToggleButton>
          <ToggleButton value={1}>Юридическое лицо</ToggleButton>
        </ToggleButtonGroup>
        
      </Grid>

      {formBlock}
      
    </Grid>   
  );
};

export default CertificateFormPerson;

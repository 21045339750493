import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Alert from '@material-ui/lab/Alert';
import SmartTable from '../../../components/SmartTable';
import ErrorBlock from '../../../components/ErrorBlock';

import { CreditCardOutlined, AccountBalanceWalletOutlined } from '@material-ui/icons';

import copyObject from '../../../utils/copyObject';

import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import ElkSelectInput from '../../../components/FormElements/ElkSelectInput';

import Spinner from '../../../components/Spinner';

import { prepareReport } from './ReportController';
import { certificateDisplayPaymentTypeStatuses } from '../../../settings/profins';

import * as actions from '../../../store/actions';
import getFormError from '../../../utils/getFormError';
import createMenuItems from '../../../utils/createMenuItems';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { restorePhone } from '../../../shared/utility';

const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: "100%"
  },
  userBox: {
    marginTop: "20px"
  },
  marginTop: {
    marginTop: "10px"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  subText: {
    fontSize: '10px',
    color: 'gray',
    marginTop: '1px',
  },
  center: {
    textAlign: "center",
  },
  saveSpinner: {
    marginRight: "10px"
  },
  error: {
    color: "#f44336"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const smartTableHeaders = { 
  'id':'#', 
  'agent':'Агент',
  'client_name_f':'Страхователь', 
  'client_phone': 'Телефон',
  'status_id':'Статус', 
  'certificate_company_id':'Компания', 
  'certificate_series':'Серия', 
  'certificate_number':'Номер', 
  'certificate_price':'Cтоимость', 
  'paytype_id':'Тип оплаты',
  'certificate_payed_at':'Оплачен' 
}

const ReportForm = ({ className, report, reportId, ...rest }) => {

  const [confirmBlock, setConfirmBlock] = useState(null);
  const navigate = useNavigate();

  const [statusesOptionsList, setStatusesOptionsList] = useState([<MenuItem key={2} value={2} ></MenuItem>]);

  const reportErrors = {};
  if (rest.errorFields) {
    for (const i in rest.errorFields) 
    reportErrors[i] = rest.errorFields[i];
  }

  const classes = useStyles();

  const curUser = rest.curUser;
  const onLoadCatalog = rest.onLoadCatalog;
  const onLoadReport = rest.onLoadReport;
  const catalogsGeneral = rest.catalogs['general'] ?? false;
  const catalogsRegions = rest.catalogs['regions'] ?? false;
  const catalogsCompanies = rest.catalogs['companies'] ?? false;

  useEffect(() => {
    //console.log("LOAD REPORT", report, reportId, rest.isReportLoading, rest.reportError);
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsRegions) onLoadCatalog('regions')
    else if (!catalogsCompanies) onLoadCatalog('companies')
    else if ((!report || report.id*1 !== reportId*1) && !rest.isReportLoading && !rest.reportError) onLoadReport(reportId, true);

    if (catalogsGeneral) {
      let newStatusesOptionsList = [];
      if (catalogsGeneral) {
        newStatusesOptionsList = createMenuItems(catalogsGeneral['report_statuses']);
      }
      setStatusesOptionsList(newStatusesOptionsList);
    }

  }, [catalogsGeneral, catalogsRegions, catalogsCompanies, rest.reportError, onLoadCatalog, report, reportId, onLoadReport, rest.isReportLoading ]);

  const updateReportData = (updatedFields) => {
    let newReportData = copyObject(report);
    for (const i in updatedFields)
    newReportData[i] = updatedFields[i];
    rest.onUpdateReport(newReportData);
  }

  const changeStatus = (newStatus) => {
    rest.onChangeReportStatus(report.id, newStatus);
  }

  const clickHandler = (certificateId) => {
    navigate("/app/certificates/" + certificateId);
  }

  const deleteHandler = (reportId, certificateId) => {
    rest.onDeleteCertificate(reportId, certificateId);
  }

  const downloadExcel = (reportId) => {
    rest.onDownload(reportId);
  }

  const saveReport = () => {
    const dataToSend = prepareReport(report);
    rest.onSaveReport(dataToSend);
  }

  const yupValidationShape = React.useMemo(() => {
    let yupValidationShape = {
      name: Yup.string().max(255).required('Укажите название отчёта'),
    }
    return yupValidationShape;
  }, []);

  let backdrop = null;
  if (rest.isReportLoading) backdrop = <Backdrop className={classes.backdrop} open={true}><Spinner text="Загружаем отчёт" /></Backdrop>

  //if ((/*!rest.isCatalogsLoading &&*/ !rest.isReportLoading))
  if (!rest.reportError) {
    return <>
      <Formik
        className={clsx(classes.root, className)}
        initialValues={report}
        enableReinitialize={true}
        onSubmit={ (values) => { saveReport();  } }
        validationSchema={ Yup.object().shape(yupValidationShape) }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => { 
          
          let saveButton = <Button variant="contained" color="primary" type="submit">Сохранить</Button>
          if (rest.isSaving) saveButton = <Button variant="contained" color="primary" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Сохраняю</Button>

          let saveError = null;
          if (rest.saveError) saveError = <Alert severity="error" className={classes.marginBottom}>{rest.saveError}</Alert>
          else if (rest.savedId) saveError =<Alert severity="success" className={classes.marginBottom}>Данные об отчёте успешно изменены</Alert>
          else if (touched && errors && Object.keys(errors).length > 0) {
            for (const error in errors) if (touched[error]) {
              saveError = <Alert severity="error" className={classes.marginBottom}>Ошибки в заполнении данных комиссионного вознаграждения</Alert>
              break;
            }
          }



          const smartTableColumnCreator = (record) => {
    
            let priceText = '';
            if (record.certificate_price > 0) {
              const percent = Math.round((record.certificate_comission / record.certificate_price) * 1000);
              priceText = record.certificate_price > 0 ? (<><div>{Number(record.certificate_price).toLocaleString()}</div><div className={classes.subText}>{percent / 10}% - {Number(record.certificate_comission).toLocaleString()}</div></>) : "";
            }
        
            let paymentType = rest.catalogs['companies'][record.certificate_company_id] ? rest.catalogs['companies'][record.certificate_company_id].name : '';
            if (certificateDisplayPaymentTypeStatuses.includes(record.status_id)) {
              if (parseInt(record.certificate_format) === 1) paymentType = <div className={classes.text} style={{verticalAlign: "middle"}}><AccountBalanceWalletOutlined className={classes.icon} fontSize="small" /> {paymentType}</div>
              else paymentType = <div className={classes.text}><CreditCardOutlined className={classes.icon} fontSize="small" /> {paymentType}</div>;
            }
        
            return {
              'id': record.id,
              'agent': (record.agent.name_f??"") + " " + (record.agent.name_i??""),
              'client_name_f': record.client_name_f + " " + record.client_name_i,
              'client_phone': restorePhone(record.client_phone),
              'status_id': rest.catalogs['general']['certificate_statuses'][record.status_id] ? rest.catalogs['general']['certificate_statuses'][record.status_id].name : <ErrorBlock error="НЕИЗВЕСТНЫЙ СТАТУС" />,
              'certificate_company_id': rest.catalogs['companies'][record.certificate_company_id] ? paymentType : '',
              'certificate_series': record.certificate_series,
              'certificate_number': record.certificate_number,
              'certificate_price': priceText,
              'paytype_id': rest.catalogs['general']['payment_types'][record.paytype_id] ? rest.catalogs['general']['payment_types'][record.paytype_id].name : <ErrorBlock error="НЕИЗВЕСТНЫЙ ТИП ОПЛАТЫ" />,
              //'created_at': [moment(record.created_at).format('DD.MM.YYYY HH:mm'), (record.certificate_payed_at !== '0000-00-00 00:00:00' ? <div className={classes.subText}>{'Оплачен ' + moment(record.certificate_payed_at).format('DD.MM.YYYY HH:mm')}</div> : null)]
              'certificate_payed_at': record.certificate_payed_at !== '0000-00-00 00:00:00' ? moment(record.certificate_payed_at).format('DD.MM.YYYY') : null
            };
          };
        
          let confirmDialog = null;
          if (confirmBlock !== null)
            confirmDialog = <ConfirmDialog 
              dialogHeader={confirmBlock.header} 
              dialogText={confirmBlock.text} 
              cancelHandler={confirmBlock.onCancel} 
              continueHandler={confirmBlock.onContinue} 
            />;

          const openConfirmBlock = (onContinue, header, text) => {
            //console.log("OPEN CONFIRM LOG");
            setConfirmBlock({onContinue: onContinue, onCancel: ()=>setConfirmBlock(null), header: header, text: text});
          }
        
          const smartTableButtonsData = (record) => {
            const links = [];
            links.push(<MenuItem key={3} onClick={(event)=>openConfirmBlock(()=>deleteHandler(reportId, record.id), 'Удалить полис ' + record.id + ' из отчёта ' + reportId, 'Вы действительно хотите удалить этот полис из отчёта?')}>Удалить</MenuItem>);
            return links;
          }

          let statusChanger = null;
          if (curUser.access >= 50) 
            statusChanger = <ElkSelectInput 
              label="Статус"
              name="status_id"
              onValueChange={(newValue) => { updateReportData({ status_id: newValue }) }} 
              setFieldValue={setFieldValue}
              value={report ? report.status_id : ''}
              options={ statusesOptionsList }
            />

          let reportSender = null;
          if (report && curUser.access < 50) {
            let reportSenderBlock, reportSenderInfo;
            if (report.status_id === 1) {
              reportSenderBlock = <Button onClick={() => changeStatus(2)} variant="contained" color="primary" type="button">Отправить на оплату</Button>
              reportSenderInfo = <Alert severity="info" className={classes.marginBottom}>Отчёт готов к отправке в Профинс на оплату</Alert>
            } else if (report.status_id === 2) {
              reportSenderBlock = <Button onClick={() => changeStatus(1)} variant="contained" color="primary" type="button">Отменить отправку</Button>
              reportSenderInfo = <Alert severity="warning" className={classes.marginBottom}>Отчёт отправлен в профинс на проверку и оплату</Alert>
            }
            reportSender = <Box className={classes.userBox}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} >
                    <Grid item lg={12} md={12} xs={12} >{reportSenderInfo}</Grid>
                    <Grid item lg={12} md={12} xs={12} >{reportSenderBlock}</Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          }

          const needToGive = report.cash - report.income;
          let text3 = "Комиссия агента";
          let text4 = "Денег на руках агента";
          let text5 = "Агент должен сдать в кассу";
          if (needToGive < 0) text5 = "Вы должны доплатить агенту";
          if (curUser.id === report.user_id) {
            text3 = "Ваша комиссия";
            text4 = "Денег у вас на руках";
            text5 = "Необходимо сдать в кассу";
            if (needToGive < 0) text5 = "Вам должны выдать";
          }

          let agentPhoneBlock = null;
          if (report.user) agentPhoneBlock = <ListItem>
            <ListItemText primary="Телефон агента" />
            <ListItemSecondaryAction><Typography>{ report.user.phone ? restorePhone(report.user.phone) : "Не указан" }</Typography></ListItemSecondaryAction>
          </ListItem>

          let comissionPercent = 0;
          if (report.sum > 0)
            comissionPercent = Math.round((report.income*1 / report.sum*1) * 1000, 1) / 10;

          let reportInfo = null;
          if (report && report.id > 0) {
            reportInfo = <Box className={classes.userBox}>
            <Card>
              <CardHeader
                title="Данные отчёта"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3} >
                  <Grid item lg={4} md={4} xs={12}>
                    <List dense={true}>
                      <ListItem>
                        <ListItemText primary="Полисов в отчёте" />
                        <ListItemSecondaryAction><Typography>{ report.certificates.length }</Typography></ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Итоговая стоимость полисов" />
                        <ListItemSecondaryAction><Typography>{ Number(report.sum).toLocaleString() } Р</Typography></ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={text3} />
                        <ListItemSecondaryAction><Typography>{ Number(report.income).toLocaleString() } Р ({ comissionPercent }%)</Typography></ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={text4} />
                        <ListItemSecondaryAction><Typography>{ Number(report.cash).toLocaleString() } Р</Typography></ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={text5} />
                        <ListItemSecondaryAction><Typography>{ Number(Math.abs(needToGive)).toLocaleString() } Р</Typography></ListItemSecondaryAction>
                      </ListItem>
                      {agentPhoneBlock}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
          }

          return (<form onSubmit={handleSubmit} className={classes.fullWidth}>
            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  //subheader="Введите госномер авто и часть данных будет заполнена автоматически"
                  title="Управление отчётом"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >

                    <ElkTextInput
                      {...getFormError('name', touched, errors, reportErrors)}
                      label="Название отчёта"
                      name="name" 
                      placeholder="Отчёт от 12.05.2021"
                      type="text"
                      onValueChange={(newValue) => { updateReportData({ name: newValue }) }} 
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={report ? report.name : ''}
                    />

                    {statusChanger}

                    <Grid item lg={4} md={4} xs={12} style={{paddingTop: "21px"}} >
                      <Button onClick={() => downloadExcel(reportId)} variant="contained" color="primary" type="button">Выгрузить в Excel</Button>
                    </Grid>

                    <Grid item lg={12} md={12} xs={12} className={classes.center} >
                      {saveError}  
                      {saveButton}
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Box>

            {reportInfo}

            {reportSender}

            <Box mt={3}>
                <SmartTable 
                  isLoading={rest.isReportLoading}
                  tableheaders={smartTableHeaders} 
                  rowCreator={smartTableColumnCreator}
                  tooltipData={null}
                  buttonsData={smartTableButtonsData}
                  filters={{}}
                  data={report.certificates} 
                  total={report.certificates.length}
                  //onFilter={filterHandler} 
                  onRowClick={clickHandler}
                  error={rest.reportError} 
                  //filter={props.filter}
                  //order={props.order}
                  page={1}
                  perPage={99999}
                />
            </Box>
            
            {confirmDialog}
          </form>
        ); }}
      </Formik>
      {backdrop}
    </>
  } else {
    if (rest.reportError) return <Box style={{width:'100%'}} className={classes.userBox}><Alert style={{width:'100%'}} severity="error">{rest.reportError}</Alert></Box>;
    else if (rest.parentError) return <Box style={{width:'100%'}} className={classes.userBox}><Alert style={{width:'100%'}} severity="error">{rest.parentError}</Alert></Box>;
  }
  /*else {
    //if (rest.isCatalogsLoading) return <Spinner text="Загружаем каталоги"></Spinner>;
    if (rest.isReportLoading) return <Spinner text="Загружаем данные полиса"></Spinner>;
    return <Spinner></Spinner>;
  }*/
};

ReportForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    isReportLoading: state.report.isLoading,
    isReportLoaded: state.report.isLoaded,
    reportError: state.report.loadError,
    isReportSaving: state.report.isSaving,
    report: state.report.report,
    saveError: state.report.saveError,
    errorFields: state.report.errorFields,
    isSaving: state.report.isSaving,
    savedId: state.report.savedId,
    curUser: state.auth.user,

    isCatalogsLoading: state.catalogs.isLoading,
    catalogs: state.catalogs.catalogs,

    deletingCertificate: state.report.deletingCertificate,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCatalog: (catalogName, subId=null) => dispatch(actions.loadCatalog(catalogName, subId)),
      onUpdateReport: (newData) => dispatch(actions.updateReport(newData)),
      onSaveReport: (reportToSave) => dispatch(actions.saveReport(reportToSave)),
      onChangeReportStatus: (reportId, newStatusId) => dispatch(actions.changeReportStatus(reportId, newStatusId)),
      onLoadReport: (reportId, isFull) => dispatch(actions.loadReport(reportId, isFull)),
      onDownload: (reportId) => dispatch(actions.downloadReport(reportId)),
      onDeleteCertificate: (reportId, certificateId) => dispatch(actions.deleteCertificateFromReport(reportId, certificateId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportForm);
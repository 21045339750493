const correctCarNumber = (string = '') => {
  // Делаем все буквы большими
  string = string.toUpperCase();
  // Заменяем латинские символы русскими
  const compareList = {
    'A': 'А',
    'B': 'В',
    'C': 'С',
    'E': 'Е',
    'H': 'Н',
    'K': 'К',
    'M': 'М',
    'O': 'О',
    'P': 'Р',
    'T': 'Т',
    'X': 'Х',
  }
  for (const engLetter in compareList)
    string = string.replace(engLetter, compareList[engLetter]);
  return string;
}

export default correctCarNumber;
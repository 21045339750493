import moment from 'moment';

import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    makeStyles,
    MenuItem,
    Switch,
    Typography
  } from '@material-ui/core';

  const useStyles = makeStyles((theme) => ({
    newsDate: {
      marginBottom: '10px',
      fontSize: '10px',
    },
    controlButtons: {
      textAlign: "right",
      marginTop: "5px",
    },
    controlButton: {
      marginLeft: "10px",
    }
  }));

const NewsElement = (props) => {

  const classes = useStyles();

  const newsElement = props.newsElement;

  let controlButtons = [];
  if (props.isAdmin) {
    controlButtons.push(<Button className={classes.controlButton} variant="contained" size="small" key="del" severity="error" onClick={()=>{props.onDelete(newsElement.id)}}>Удалить</Button>)
    controlButtons.push(<Button color="primary" className={classes.controlButton} variant="contained" size="small" key="mor" onClick={()=>{props.onClick(newsElement.id)}}>Редактировать</Button>);
  }

  let shortText = newsElement.short;
  if (shortText.length > 97 || newsElement.text !== "") {
    shortText = shortText.substring(0,97) + "...";
    if (!props.isAdmin)
      controlButtons.push(<Button className={classes.controlButton}variant="contained" size="small" key="mor" onClick={()=>{props.onClick(newsElement.id)}}>Полный текст</Button>);
  }


  return <Grid item lg={4} md={6} xs={12}>
    <Card>
      <CardContent>
        <Grid container>
          <Grid item lg={12} md={12} xs={12}><Typography className={classes.newsDate}>{moment(newsElement.created_at).format("DD.MM.YYYY HH:mm")}</Typography></Grid>
          <Grid item lg={12} md={12} xs={12}><Typography>{shortText}</Typography></Grid>
          <Grid item lg={12} md={12} xs={12} className={classes.controlButtons}>{controlButtons}</Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>

}

export default NewsElement;
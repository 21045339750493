const includeAddressData = (personType, addressData, dataToSend, noNameAdding=false) => {

    if (addressData) {
        if (addressData.apt) dataToSend[personType + "_apt"] = addressData.apt;

        // НОВАЯ ВЕРСИЯ - В УЛИЦУ ПИШЕТСЯ ТОЛЬКО УЛИЦА, В КАЧЕСТВЕ ГОРОДА ПИШЕТСЯ ГОРОД ЛИБО ПОСЕЛЕНИЕ.
        if (addressData.street) {
            if (addressData.street) dataToSend[personType + "_street" + (noNameAdding ? "" : "_name")] = addressData.street;
            if (addressData.street_id) dataToSend[personType + "_street_id"] = addressData.street_id;
            if (addressData.street_kladr) dataToSend[personType + "_street_kladr"] = addressData.street_kladr;
        } else { // Улицы может не оказаться (по умолчанию её нет)
            dataToSend[personType + "_street" + (noNameAdding ? "" : "_name")] = ''; 
            dataToSend[personType + "_street_id"] = '';
            dataToSend[personType + "_street_kladr"] = '';
        }

        if (addressData.settlement) {
            //Доп проверка - если улица НЕ указана, город УКАЗАН и поселение УКАЗАНО, и его тип "мкр", пишем его в качестве улицы
            if (!addressData.street && addressData.city && addressData.settlement_type === "мкр") {
                dataToSend[personType + "_street" + (noNameAdding ? "" : "_name")] = addressData.settlement;
                dataToSend[personType + "_street_id"] = addressData.settlement_id;
                dataToSend[personType + "_street_kladr"] = addressData.settlement_kladr;
                dataToSend[personType + "_city" + (noNameAdding ? "" : "_name")] = addressData.city;
                dataToSend[personType + "_city_id"] = addressData.city_id;
                dataToSend[personType + "_city_kladr"] = addressData.city_kladr;
            } else {
                if (addressData.settlement) dataToSend[personType + "_city" + (noNameAdding ? "" : "_name")] = addressData.settlement;
                if (addressData.settlement_id) dataToSend[personType + "_city_id"] = addressData.settlement_id;
                if (addressData.settlement_kladr) dataToSend[personType + "_city_kladr"] = addressData.settlement_kladr;
            }
        } else if (addressData.city) {
            if (addressData.city) dataToSend[personType + "_city" + (noNameAdding ? "" : "_name")] = addressData.city;
            if (addressData.city_id) dataToSend[personType + "_city_id"] = addressData.city_id;
            if (addressData.city_kladr) dataToSend[personType + "_city_kladr"] = addressData.city_kladr;
        }

        if (addressData.house) dataToSend[personType + "_house" + (noNameAdding ? "" : "_name")] = addressData.house;
        if (addressData.house_id) dataToSend[personType + "_house_id"] = addressData.house_id;
        if (addressData.house_kladr) dataToSend[personType + "_house_kladr"] = addressData.house_kladr;
        if (addressData.postal_code) dataToSend[personType + "_postal_code"] = addressData.postal_code;
    }
}

export default includeAddressData;
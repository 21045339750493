import * as actionTypes from './actionTypes';

export const uploadFile = (fileName, file, additional) => {
    return {
        fileName: fileName,
        additional: additional,
        file: file,
        type: actionTypes.UPLOAD_FILE,
    }
}

export const uploadFileStart = (fileName, additional) => {
    return {
        fileName: fileName,
        additional: additional,
        type: actionTypes.UPLOAD_FILE_START,
    }
}

export const uploadFileSuccess = (fileName, additional) => {
    return {
        fileName: fileName,
        additional: additional,
        type: actionTypes.UPLOAD_FILE_SUCCESS,
    }
}

export const uploadFileFail = (fileName, errorText, additional) => {
    return {
        fileName: fileName,
        additional: additional,
        error: errorText,
        type: actionTypes.UPLOAD_FILE_FAIL,
    }
}
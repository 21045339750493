import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//import MUIRichTextEditor from 'mui-rte'
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Typography
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Alert from '@material-ui/lab/Alert';

import copyObject from '../../../utils/copyObject';
import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import Spinner from '../../../components/Spinner';
import * as actions from '../../../store/actions';
import getFormError from '../../../utils/getFormError';

const useStyles = makeStyles((theme) => ({
  root: {},
  fullWidth: {
    width: "100%"
  },
  userBox: {
    marginTop: "20px"
  },
  marginTop: {
    marginTop: "10px"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  center: {
    textAlign: "center",
  },
  saveSpinner: {
    marginRight: "10px"
  },
  error: {
    color: "#f44336"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const NewsForm = ({ className, news, newsId, ...rest }) => {

  const [cont, setCont] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setCont(editorState);
    updateNewsData({text: JSON.stringify(convertToRaw(cont.getCurrentContent()))});
  };

  const newsErrors = {};
  if (rest.errorFields) {
    for (const i in rest.errorFields) 
    newsErrors[i] = rest.errorFields[i];
  }

  const classes = useStyles();

  const onLoadNews = rest.onLoadNews;

  useEffect(() => {
    if (news.text!=='') setCont(EditorState.createWithContent(convertFromRaw(JSON.parse(news.text))));
  }, [news.id]);

  useEffect(() => {
    if (news.id*1 !== newsId*1 && !rest.isNewsLoading && !rest.newsError) onLoadNews(newsId);
  }, [rest.newsError, news.id, newsId, onLoadNews, rest.isNewsLoading]);

  const updateNewsData = (updatedFields) => {
    let newNewsData = copyObject(news);
    for (const i in updatedFields)
    newNewsData[i] = updatedFields[i];
    rest.onUpdateNews(newNewsData);
  }

  /*const importanceOptionsList = [
    <MenuItem key={"default"} value={"default"} >Обычная</MenuItem>,
    <MenuItem key={"info"} value={"info"} >Информация</MenuItem>,
    <MenuItem key={"success"} value={"success"} >Успех</MenuItem>,
    <MenuItem key={"error"} value={"error"} >Внимание</MenuItem>,
  ]*/

  const saveNews = () => {
    //news.text = JSON.stringify(convertToRaw(cont));
    rest.onSaveNews(news);
  }

  const yupValidationShape = React.useMemo(() => {
    let yupValidationShape = {
      short: Yup.string().max(255).required('Укажите текст новости'),
    }
    return yupValidationShape;
  }, []);

  let backdrop = null;
  if (rest.isNewsLoading) backdrop = <Backdrop className={classes.backdrop} open={true}><Spinner text="Загружаем данные новости" /></Backdrop>

  if (!rest.newsError) {

    let adminPart = null;

    if (rest.curUser.access >= 50) adminPart = <>
      <Formik
        className={clsx(classes.root, className)}
        initialValues={news}
        enableReinitialize={true}
        onSubmit={ (values) => { saveNews();  } }
        validationSchema={ Yup.object().shape(yupValidationShape) }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => { 
          
          let saveButton = <Button variant="contained" color="primary" type="submit">Сохранить</Button>
          if (rest.isSaving) saveButton = <Button variant="contained" color="primary" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Сохраняю</Button>

          let saveError = null;
          if (rest.saveError) saveError = <Alert severity="error" className={classes.marginBottom}>{rest.saveError}</Alert>
          else if (rest.savedId) saveError =<Alert severity="success" className={classes.marginBottom}>Новость успешно сохранена</Alert>
          else if (touched && errors && Object.keys(errors).length > 0) {
            for (const error in errors) if (touched[error]) {
              saveError = <Alert severity="error" className={classes.marginBottom}>Ошибки в заполнении данных новости</Alert>
              break;
            }
          }

          return (<form onSubmit={handleSubmit} className={classes.fullWidth}>
            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  title="Управление новостью"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >

                    <ElkTextInput
                      {...getFormError('short', touched, errors, newsErrors)}
                      label="Краткий текст (до 255 символов)"
                      width={12}
                      name="short" 
                      multiline={true}
                      placeholder=""
                      type="text"
                      onValueChange={(newValue) => { updateNewsData({ short: newValue }) }} 
                      //required={true} 
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={news.short ?? ''}
                    />

                    <Grid item xs={12}>
                      <Typography>Полный текст новости:</Typography>
                      <Editor
                        editorState={cont}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={onEditorStateChange}
                      />
                    </Grid>

                    {/*<Grid item lg={12} md={12} xs={12} style={{marginBottom:"20px"}}>
                      <MUIRichTextEditor 
                        onChange={(data) => updateNewsData({ text: convertToHTML(data.getCurrentContent()) }) }
                        value={defaultState}
                        label="Начните вводить текст новости"></MUIRichTextEditor>
                    </Grid>*/}

                    {/*<ElkSelectInput 
                      {...getFormError("importance", touched, errors, newsErrors)}
                      label="Важность"
                      name={"importance"}
                      onValueChange={(newValue) => { updateNewsData({ importance: newValue }) }} 
                      setFieldValue={setFieldValue}
                      value={news.importance ?? 'info'}
                      handleBlur={handleBlur}
                      options={ importanceOptionsList }
                    /> */}

                    <Grid item lg={12} md={12} xs={12} >
                      <FormControlLabel
                        style={{marginLeft:0}}
                        control={<Switch checked={news.active === 1 ? true : false} onChange={(event, newValue) => { updateNewsData({ active: newValue ? 1 : 0 }) }} name={"active"} />}
                        label={"Новость активна"}
                      />
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>
            </Box>

            <Box className={classes.userBox}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} xs={12} className={classes.center} >
                      {saveError}
                      {saveButton}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </form>
        ) }}
      </Formik>
      {backdrop}
    </>

    return <>
     <Box className={classes.fullWidth}>
      <Card>
        <CardHeader
          title={news.short}
        />
        <Divider />
        <CardContent  className={classes.fullWidth} >
          <Typography dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(cont.getCurrentContent())) }}></Typography>
        </CardContent>
      </Card>
    </Box>
     {adminPart}
    </>

  } else {
    if (rest.newsError) return <Box style={{width:'100%'}} className={classes.userBox}><Alert style={{width:'100%'}} severity="error">{rest.newsError}</Alert></Box>;
  }
};

NewsForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    isNewsLoading: state.new.isLoading,
    isNewsLoaded: state.new.isLoaded,
    newsError: state.new.loadError,
    isNewsSaving: state.new.isSaving,
    news: state.new.news,
    saveError: state.new.saveError,
    errorFields: state.new.errorFields,
    isSaving: state.new.isSaving,
    savedId: state.new.savedId,
    curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onUpdateNews: (newData) => dispatch(actions.updateNews(newData)),
      onSaveNews: (newsToSave) => dispatch(actions.saveNews(newsToSave)),
      onLoadNews: (newsId) => dispatch(actions.loadNewsElement(newsId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsForm);

import * as actionTypes from './actionTypes';

export const loadCatalog = (catalogType, subId = null) => {
    //console.log("SUBID IS", subId);
    return {
        catalogType : catalogType,
        subId: subId,
        type: actionTypes.CATALOG_LOAD_INIT,
    }
}

export const loadStart = (catalogType) => {
    return {
        catalogType: catalogType,
        type: actionTypes.CATALOG_LOAD_START,
    }
}

export const loadSuccess = (changedCatalogs) => {
    return {
        changedCatalogs: changedCatalogs,
        type: actionTypes.CATALOG_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        type: actionTypes.CATALOG_LOAD_FAIL,
    }
}
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialState = {
    isLoading: false,
    users: null,
    total: null,
    error: null,
    additionalData: null,

    filter: {},
    page: 1,
    perPage: 20,
    order: {id: 'DESC'},
}

const reducer = (state = initialState, action) => {

    const newUsers = [];

    switch (action.type) {

        case actionTypes.USERS_RESET:
        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.USERS_LOAD_START:   
            const finalNewState = {isLoading: true};
            if (action.filter !== null) finalNewState.filter = action.filter;
            if (action.page !== null) finalNewState.page = action.page;
            if (action.perPage !== null) finalNewState.perPage = action.perPage;
            if (action.order !== null) finalNewState.order = action.order;
            finalNewState.additionalData = action.additionalData;
            return updateState(state, finalNewState);

        case actionTypes.USERS_LOAD_SUCCESS: return updateState(state, {isLoading: false, users: action.users, total: action.total * 1});
        case actionTypes.USERS_LOAD_FAIL:    return updateState(state, {isLoading: false, users: null, total: null, error: action.error });

        case actionTypes.USERS_DELETE_SUCCESS: 
            for (const i in state.users)
                if (state.users[i].id !== action.userId)
                newUsers.push(state.users[i]);

            return updateState(state, {isLoading: false, users: newUsers, total: state.total - 1});

        case actionTypes.USERS_COMPANY_UPDATE_START: 
            return updateState(state, {isLoading: false, users: updateDataCompany(state.users, action.userIds, action.companyId, action.policyType, 'updating'), total: state.total - 1});
        
        case actionTypes.USERS_COMPANY_UPDATE_SUCCESS: 
            return updateState(state, {isLoading: false, users: updateDataCompany(state.users, action.userIds, action.companyId, action.policyType, action.newValue), total: state.total - 1});

        case actionTypes.USERS_COMPANY_UPDATE_FAIL: 
            return updateState(state, {isLoading: false, users: updateDataCompany(state.users, action.userIds, action.companyId, action.policyType, 'fail'), total: state.total - 1});

        default: return state;
    }
}

const updateDataCompany = (users, userIds, companyId, policyType, newValue) => {
    const newUsers = [];
    
    for (const i in users) {
        if ( userIds.includes(users[i].id) ) {
            const updatedUser = copyObject(users[i]);
            //console.log("UPDATING USER", updatedUser);
            if (!updatedUser.data_company[companyId]) 
                updatedUser.data_company[companyId] = {};
            updatedUser.data_company[companyId][policyType] = newValue;
            newUsers.push(updatedUser);
        }
        else 
            newUsers.push(users[i]);
    }
    return newUsers;
}

export default reducer;
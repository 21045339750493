import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialProlong = {
    prev_number: '',
    inn: '',
    birth_date: '',
    company_id: null, 
    car_number_type: 1,
    car_body_number: '',
    car_vin_number: '',
    car_chass_number: '',
    fio: '',
    document: '',
    typeId: 0,
};

const initialState = {
    isProlonging: false,
    prolongError: null,
    prolongResult: null,
    prolongData: initialProlong,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.UPDATE_PROLONG_DATA: 
            const newUpdatedData = copyObject(state.prolongData);
            for (const i in action.updatedData) newUpdatedData[i] = action.updatedData[i];
            return updateState(state, {prolongData: newUpdatedData});

        case actionTypes.RESET_PROLONG_DATA: return updateState(state, initialState);

        case actionTypes.CERTIFICATES_PROLONG_START: return updateState(state,   {isProlonging: true,  prolongError: null, prolongResult: null});
        case actionTypes.CERTIFICATES_PROLONG_SUCCESS: return updateState(state, {isProlonging: false, prolongResult: action.certificateId });
        case actionTypes.CERTIFICATES_PROLONG_FAIL: return updateState(state,    {isProlonging: false, prolongError: action.error });

        default: return state;
    }
}

export default reducer;
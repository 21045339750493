import * as React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import IconButton from '@material-ui/core/IconButton';
import { SvgIcon } from '@material-ui/core'
import { Search as SearchIcon, Calendar as CalendarIcon, HelpCircle as HelpIcon, MoreVertical as MoreIcon } from 'react-feather';
import MenuItem from '@material-ui/core/MenuItem'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'

const MenuButton = (props) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

    /*<MenuItem onClick={popupState.close}>Cake</MenuItem>
        <MenuItem onClick={popupState.close}>Death</MenuItem>*/

  return (
    <div>
      <IconButton size="small" {...bindTrigger(popupState)} >
        <MoreIcon />
      </IconButton>
      <Menu
        {...bindMenu(popupState)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {props.buttonsData}
      </Menu>
    </div>
  )
}

export default MenuButton
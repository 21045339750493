import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialUser = {

    id: 0,
    jur_type: 0,
    fio: '',
    city: '',
    city_id: '',
    phone: '',
    email: '',
    rate_id: 0,
    birth_date: '',
    birth_place_id: '',
    passport_series_number: '',
    passport_date: '',
    passport_issued: '',
    passport_code: '',
    passport_photo1: '',
    passport_photo2: '',
    register_index: '',
    register_address: '',
    inn: '',
    inn_photo: '',
    snils: '',
    snils_photo: '',

    oldpassword: '',
    password: '',
    repassword: '',
};

const initialState = {
    isLoading: false,
    isLoaded: false,
    loadError: false,
    user: initialUser,

    isSaving: false,
    savedId: false,
    savedRateId: false,
    saveError: null,
    errorFields: null,

    isPasswordChanging: false,
    passwordChangeError: null,
    passwordChangeSuccess: null,

    isLogLoading: false,
    isLogLoaded: false,
    log: null,
    logError: null,
    logTotal: null,
    logPage: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.USER_INITIALIZE: 
            return updateState(state, {
                isLoading: false, 
                isLoaded: false, 
                user: initialUser, 
                loadError: null, 
                errorFields: null,

                isLogLoading: false,
                isLogLoaded: false,
                log: null,
                logError: null,
                logTotal: null,

                isSaving: false,
                savedId: false,
                savedRateId: false,
                saveError: null,
            });

        case actionTypes.USER_UPDATE:
            const updatedUser = copyObject(state.user);
            for (const i in action.data)
            updatedUser[i] = action.data[i];
            return updateState(state, { user: updatedUser });

        case actionTypes.USER_SAVE_START: return updateState(state, {isSaving: true, saveError: null, errorFields: null});
        case actionTypes.USER_SAVE_SUCCESS: return updateState(state, {isSaving: false, savedId: action.userId, savedRateId: action.savedRateId });
        case actionTypes.USER_SAVE_FAIL: return updateState(state, {isSaving: false, saveError: action.error, errorFields: action.fields });

        case actionTypes.PASSWORD_CHANGE_START: return updateState(state, {isPasswordChanging: true, passwordChangeError: null, passwordChangeSuccess: null});
        case actionTypes.PASSWORD_CHANGE_SUCCESS: return updateState(state, {isPasswordChanging: false, passwordChangeError: null, passwordChangeSuccess: true});
        case actionTypes.PASSWORD_CHANGE_FAIL: return updateState(state, {isPasswordChanging: false, passwordChangeError: action.error, passwordChangeSuccess: null});

        case actionTypes.USER_LOAD_START: return updateState(state, {isLoading: true, isLoaded: false, loadError: null, errorFields: null, log: null, logError: null, logTotal: null, isLogLoaded: false});
        case actionTypes.USER_LOAD_SUCCESS: return updateState(state, {isLoading: false, isLoaded: true, user: action.user });
        case actionTypes.USER_LOAD_FAIL: return updateState(state, {isLoading: false, isLoaded: false, loadError: action.error });

        case actionTypes.USER_LOAD_LOG_START: return updateState(state, {isLogLoading: true, logPage: action.page, isLogLoaded: false, logError: null, errorFields: null});
        case actionTypes.USER_LOAD_LOG_SUCCESS: return updateState(state, {isLogLoading: false, isLogLoaded: true, log: action.log, logTotal: action.total });
        case actionTypes.USER_LOAD_LOG_FAIL: return updateState(state, {isLogLoading: false, isLogLoaded: false, logError: action.error });

        default: return state;
    }
}

export default reducer;
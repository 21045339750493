import * as actionTypes from './actionTypes';

export const resetUsers = () => { return { type: actionTypes.USERS_RESET }}

export const loadUsers = (filter, page, perPage, order, additionalData = null) => {
    return {
        filter : filter,
        page : page,
        perPage : perPage,
        order: order,
        additionalData: additionalData,

        type: actionTypes.USERS_LOAD_INIT,
    }
}

export const loadStart = (filter, page, perPage, order, additionalData = null) => {
    return {
        filter: filter,
        page: page,
        perPage: perPage,
        order: order,
        additionalData: additionalData,

        type: actionTypes.USERS_LOAD_START,
    }
}

export const loadSuccess = (users, total) => {
    return {
        users: users,
        total: total,
        
        type: actionTypes.USERS_LOAD_SUCCESS,
    }
}

export const loadError = (error) => {
    return {
        error: error,
        
        type: actionTypes.USERS_LOAD_FAIL,
    }
}


export const deleteUser = (userId) => ({ userId: userId, type: actionTypes.USERS_DELETE })
export const deleteUserSuccess = (userId) => ({ userId: userId, type: actionTypes.USERS_DELETE_SUCCESS })
export const deleteUserFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })

export const changeCompanyData = (companyId, policyType, selectedIds, newValue) => ({ companyId: companyId, policyType: policyType, userIds: selectedIds, newValue: newValue, type: actionTypes.USERS_COMPANY_UPDATE })
export const changeCompanyDataStart = (companyId, policyType, selectedIds) => ({ companyId: companyId, policyType: policyType, userIds: selectedIds, type: actionTypes.USERS_COMPANY_UPDATE_START })
export const changeCompanyDataSuccess = (companyId, policyType, selectedIds, newValue) => ({ companyId: companyId, policyType: policyType, userIds: selectedIds, newValue: newValue, type: actionTypes.USERS_COMPANY_UPDATE_SUCCESS })
export const changeCompanyDataFail = (companyId, policyType, selectedIds) => ({ companyId: companyId, policyType: policyType, userIds: selectedIds, type: actionTypes.USERS_COMPANY_UPDATE_FAIL })
import React, { useEffect, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from '../../../components/Page';
import SmartTable from '../../../components/SmartTable';
//import Toolbar from './Toolbar';

import {UserPlus} from 'react-feather';

import Spinner from '../../../components/Spinner';
import ErrorBlock from '../../../components/ErrorBlock';
import getFio from '../../../utils/getFio';
import { userDeleteableStauses } from '../../../settings/profins';

import * as actions from '../../../store/actions';
//import MassMessageBlock from './MassMessageBlock';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { getSmartTableSelectedIds } from '../../../shared/utility';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    //paddingTop: theme.spacing(3)
  },
  marginTop: {
    marginTop: '20px'
  },
  chipMargin: {
    marginBottom: '2px'
  },
  tooltipLink: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  }
}));

const UserCompany = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const [confirmBlock, setConfirmBlock] = useState(null);
  const [filterUsers, setFilterUsers] = useState({});
  const [hideSravni, setHideSravni] = useState(false);

  const onLoadUsers = props.onLoadUsers;
  const onLoadCatalog = props.onLoadCatalog;
  //const onLoadСompanyData = props.onLoadCompanyData;
  const currentFilter = props.filter;

  const filterHandler = useCallback((filter, page, perPage, order) => {
    onLoadUsers(filter, page, perPage, order);
  }, [onLoadUsers]);

  const variants = {
    'eosago': {id: 'eosago', name: 'ЕОСАГО'},
    'bso': {id: 'bso',name: 'БСО'},
    'ebso': {id: 'ebso',name: 'ЕБСО'},
  }

  const catalogsGeneral = props.catalogs['general'] ?? false;
  const catalogsCompanies = props.catalogs['companies'] ?? false;
  //const companyData = props.companyData;

  //console.log("CHECK", props.users, props.additionalData, props.isUsersLoading, props.error);
  //console.log("RESULT", (props.users === null || props.additionalData !== 'company') && !props.isUsersLoading && !props.error);

  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsCompanies) onLoadCatalog('companies')
    else if ((props.users === null || props.additionalData !== 'company') && !props.isUsersLoading && !props.error) filterHandler(currentFilter, 1, 5000, {id: "DESC"})
    //else if (!props.isCompanyDataLoading && !props.companyDataError && !companyData) onLoadСompanyData({}, 1, 100, {'name':'asc'});
  }, [filterHandler, onLoadCatalog, catalogsGeneral, currentFilter, props.users, props.error, catalogsCompanies, props.additionalData, props.isUsersLoading]);
  
  if (Object.keys(filterUsers).length === 0 && props.users) {
    const userFilterOptions = {};
    for (const i in props.users)
      userFilterOptions[props.users[i].id] = {id: props.users[i].id, name: props.users[i].name_f + ' ' + props.users[i].name_i};
    setFilterUsers(userFilterOptions);
  }
    
  if (props.users || props.error) {

    const smartTableHeaders = { 
      'id':'Номер', 
      'name':'Агент', 
    }

    for (const i in catalogsCompanies) if (!hideSravni || i < 10) {
      smartTableHeaders['company_' + catalogsCompanies[i].id] = catalogsCompanies[i].name;
    }

    const smartTableColumnCreator = (record) => {

      const smartTableColumnCreatorData = {
        'id': record.id,
        'name': record.name_f + " " + record.name_i,
      }

      for (const i in catalogsCompanies) if (!hideSravni || i < 10) {
        const checkboxes = [];
        for (const t in variants) {
          if (parseInt(catalogsCompanies[i][t]) === 1) {
            //console.log("DATA COMPANY",i , t, props.additionalData, record.data_company);
            const curValue = (record.data_company[i] && record.data_company[i][t]) ? record.data_company[i][t] : 0;
            let chip;
            if (curValue === "updating") chip = <span key={t}><Chip className={classes.chipMargin} variant="outlined" label="..." color="primary" size="small" /></span>
            else if (curValue === "fail") chip = <span key={t}><Chip className={classes.chipMargin} variant="outlined" label="Ошибка" color="default" size="small" /></span>
            else chip = <span key={t} onClick={()=>changeCompanyData(catalogsCompanies[i].id, t, getSmartTableSelectedIds('userCompany') ? getSmartTableSelectedIds('userCompany').split(",") : [record.id], parseInt(curValue) === 1 ? 0 : 1)} color={"primary"}><Chip className={classes.chipMargin} variant={parseInt(curValue) === 1 ? "default" : "outlined"} label={variants[t].name} color="primary" size="small" /></span>
            checkboxes.push(chip);
            //checkboxes.push(<span key='eosago' onClick={()=>changeCompanyData(catalogsCompanies[i].id, 'eosago', [record.id], (record.data_company[i] && record.data_company[i]['eosago']) ? 0 : 1)} color={"primary"}><Chip variant={(record.data_company[i] && record.data_company[i]['eosago']) ? "default" : "outlined"} label="ЕОСАГО" color="primary" /></span>);            
          }

          //if (parseInt(catalogsCompanies[i].eosago) === 1) checkboxes.push(<span key='eosago' onClick={()=>changeCompanyData(catalogsCompanies[i].id, 'eosago', [record.id], (record.data_company[i] && record.data_company[i]['eosago']) ? 0 : 1)} color={"primary"}><Chip variant={(record.data_company[i] && record.data_company[i]['eosago']) ? "default" : "outlined"} label="ЕОСАГО" color="primary" /></span>);
          //if (parseInt(catalogsCompanies[i].bso) === 1) checkboxes.push(<span key='bso' onClick={()=>changeCompanyData(catalogsCompanies[i].id, 'bso', [record.id], (record.data_company[i] && record.data_company[i]['bso']) ? 0 : 1)} color={"primary"}><Chip variant={(record.data_company[i] && record.data_company[i]['bso']) ? "default" : "outlined"} label="БСО" color="primary"/></span>);
          //if (parseInt(catalogsCompanies[i].ebso) === 1) checkboxes.push(<span key='ebso' onClick={()=>changeCompanyData(catalogsCompanies[i].id, 'ebso', [record.id], (record.data_company[i] && record.data_company[i]['ebso']) ? 0 : 1)} color={"primary"}><Chip variant={(record.data_company[i] && record.data_company[i]['ebso']) ? "default" : "outlined"} label="ЕБСО" color="primary"/></span>);
        }
        smartTableColumnCreatorData['company_' + catalogsCompanies[i].id] = checkboxes;
      }

      return smartTableColumnCreatorData;
    };

    

    const changeCompanyData = (companyId, type, selectedIds, newValue) => {
      //console.log("CHECK", companyId, type, selectedIds, newValue);
      props.onChangeCompanyData(companyId, type, selectedIds, newValue);
      /*const dataToAdd = {};
      selectedIds.forEach( (item, index) => {
        for (const i in props.users) {
          if (props.users[i].id*1 === item*1)
            dataToAdd[item] = props.users[i].name_f + " " + props.users[i].name_i;
        }
      });
      props.addMassMessageUsers(dataToAdd);*/
    }

    const openConfirmBlock = (onContinue, header, text) => {
      setConfirmBlock({onContinue: onContinue, onCancel: ()=>setConfirmBlock(null), header: header, text: text});
    }

    const smartTableFilters = {
      //'search': { type: 'search', width: 2, name: "Поиск агента", placeholder: "Поиск по ФИО агента" },
      'user_id': { type: 'multiselectSearch', width: 2, name: "Поиск агента", placeholder: "Поиск по ФИО агента", options: filterUsers },
      'company_id': { type: 'multiselect', name: "Страховая компания", options: props.catalogs['companies'] },
      'company_type': { type: 'select', name: "Тип оформления", options: variants },
    }

    /*const smartTableTooltipData = (record) => {
      return <Box padding={3}>
        <Grid container spacing={2} >
          <Grid item md={4} xs={12} ><b>Агент</b></Grid>
          <Grid item md={8} xs={12} >{record.agent ? getFio(record.agent) : 'Неизвестно'}</Grid>
          <Grid item md={4} xs={12} ><b>Регион</b></Grid>
          <Grid item md={8} xs={12} >{props.catalogs['regions'] ? (props.catalogs['regions'][record.client_region_id] ? props.catalogs['regions'][record.client_region_id].name : 'Регион не найден') : 'Неизветно'}</Grid>
          <Grid item md={12} xs={12} className={classes.marginTop}><b>Контакты страхователя</b></Grid>
          <Grid item md={4} xs={12} ><b>Телефон</b></Grid>
          <Grid item md={8} xs={12} >{record.client_phone ?? 'Не указан'}</Grid>
          <Grid item md={4} xs={12} ><b>E-mail</b></Grid>
          <Grid item md={8} xs={12} >{record.client_email ?? 'Не указан'}</Grid>
        </Grid>
      </Box>
    }*/

    const smartTableButtonsData = (record) => {

      const links = [];
      //links.push(<MenuItem key={1} onClick={(event)=>clickHandler(record.id)}>Редактировать</MenuItem>);
      //if (certificateContinueableStauses.includes(record.status_id)) links.push(<MenuItem key={2} onClick={(event)=>calculateHandler(record.id)}>Рассчитать</MenuItem>);
      //if (userDeleteableStauses.includes(record.status_id)) links.push(<MenuItem key={3} onClick={(event)=>deleteHandler(record.id)}>Удалить</MenuItem>);
      //if (props.curUser.access >= 50 && record.id*1 !== props.curUser.id*1) links.push(<MenuItem key={2} onClick={(event)=>openConfirmBlock(()=>deleteHandler(record.id), 'Удаление агента '+record.name_f+' '+record.name_i, 'Вы действительно хотите удалить этого агента?')}>Удалить</MenuItem>);
      //if (props.curUser.access >= 50 && record.id*1 !== props.curUser.id*1) links.push(<MenuItem key={3} onClick={(event)=>navigate('/app/messages/'+record.id)}>Отправить сообщение</MenuItem>);

      return links;
    }

    /*const deleteRecepicient = (userId) => {
      props.removeMassMessageUsers([userId]);
    }*/

    let smartTableMassButtons = null;
    //let massMessageBlock = null;
    let table = <Spinner></Spinner>;
    if (props.isCatalogsLoading) table = <Spinner text="Загружаем каталоги"></Spinner>
    else {

      if (props.curUser.access >= 50) {
        smartTableMassButtons = [
          {
            name: "Завершить массовое обновление", 
            onClick: (selectedIds) => console.log(selectedIds),
            icon: <UserPlus />
          }
        ];
        //massMessageBlock = <MassMessageBlock removeUserHandler={deleteRecepicient} massMessageData={props.massMessageIds} />
      }

      //console.log("DRAWING", props.users, props.total);

      table = <SmartTable 
        tableName="userCompany"
        isScrollable={true}
        isLoading={props.isUsersLoading}
        tableheaders={smartTableHeaders} 
        rowCreator={smartTableColumnCreator}
        tooltipData={null}
        buttonsData={smartTableButtonsData}
        filters={smartTableFilters}
        data={(props.users && props.additionalData === 'company') ? props.users : null} 
        total={props.total}
        onFilter={filterHandler} 
        onRowClick={null}
        massButtons={smartTableMassButtons}
        error={props.error} 
        filter={props.filter}
        order={props.order}
        page={props.page}
        perPage={props.perPage}
      />;
    }

    const totalBox = <Box className={classes.userBox} mt={3}>
    <Card>
      <CardHeader
        title="Настройки отображения"
      />
      <Divider />
      <CardContent>
        <Box
          alignItems="center"
          display="flex"
          ml={-1}
        >
          <Checkbox
            checked={hideSravni}
            color="primary"
            onChange={()=>setHideSravni(!hideSravni)}
            label="Скрыть компании Сравни.ру"
          />
          <Typography>Скрыть компании Сравни.ру</Typography>
        </Box>
      </CardContent>
    </Card>
  </Box>

    let confirmDialog = null;
    if (confirmBlock !== null)
      confirmDialog = <ConfirmDialog 
        dialogHeader={confirmBlock.header} 
        dialogText={confirmBlock.text} 
        cancelHandler={confirmBlock.onCancel} 
        continueHandler={confirmBlock.onContinue} 
      />;

    return (
      <Page
        className={classes.root}
        title="Users"
      >
        <Container maxWidth={false}>
          {totalBox}
          {/*massMessageBlock*/}
          {/*<Toolbar className={classes.marginTop} />*/}
          <Box mt={3}>
            {table}
            {confirmDialog}
          </Box>
        </Container>
      </Page>
    );

  } else {
    return <Page className={classes.root} title="Users"><Container maxWidth={false}>Загрузка...</Container></Page>
  }
};

const mapStateToProps = state => {
  return {
      isUsersLoading: state.users.isLoading,
      error: state.users.error,
      users: state.users.users,
      additionalData: state.users.additionalData,
      total: state.users.total,
      page: state.users.page,
      perPage: state.users.perPage,
      filter: state.users.filter,
      order: state.users.order,

      massMessageIds: state.messages.massMessageIds,

      rates: state.rates.rates,
      isRatesLoading: state.rates.isLoading,
      ratesError: state.rates.error,

      isCatalogsLoading: state.catalogs.isLoading,
      catalogs: state.catalogs.catalogs,

      curUser: state.auth.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadUsers: (filter, page, perPage, order) => dispatch(actions.loadUsers(filter, page, perPage, order, 'company')),
      onLoadRates: (filter, page, perPage, order) => dispatch(actions.loadRates(filter, page, perPage, order)),
      onLoadCatalog: (catalogName) => dispatch(actions.loadCatalog(catalogName)),
      onDeleteUser: (userId) => dispatch(actions.deleteUser(userId)),
      onChangeCompanyData: (companyId, type, selectedIds, newValue) => dispatch(actions.changeCompanyData(companyId, type, selectedIds, newValue)),

      addMassMessageUsers: (userIds) => dispatch(actions.addMassMessageUsers(userIds)),
      removeMassMessageUsers: (userIds) => dispatch(actions.removeMassMessageUsers(userIds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCompany);
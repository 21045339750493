import { isObject } from "formik";
import moment from 'moment';

import explodeSeriesNumber from '../../../utils/explodeSeriesNumber';
import copyObject from '../../../utils/copyObject';
import correctCarNumber from "../../../utils/correctCarNumber";
import onlyDigitsAndLetters from "../../../utils/onlyDigitsAndLetters";
import includeAddressData from "../../../utils/includeAddressData";
import { fillFioFields } from "../../../shared/utility";

const personCopyFieldsList = [
    'address', 'apt', 'birth_date', 'city_id', 'city_kladr', 'city_name', 'data', 'document_date', 'document_number', 'document_place', 'document_series', 'document_type', 'fio', 'gender',
    'country_id', 'house_id', 'house_kladr', 'house_name', 'is_jur', 'name_f', 'name_i', 'name_o', 'postal_code', 'street_id', 'street_kladr', 'street_name',
    'address_data', 'document_series_number', 'document_code', 'email', 'phone',
    //JUR
    'name', 'is_ip', 'form', 'name', 'inn', 'kpp', 'ogrn', 'cert_number', 'cert_date'
];

export const isPersonsSame = (certificate) => {
    for (const i in personCopyFieldsList) 
        if (certificate['client_' + personCopyFieldsList[i]] !== certificate['owner_' + personCopyFieldsList[i]]) return false;
    return true;
}

const parsePerson = (personData, fioField) => {
    let finalData = {};
    if (personData) {
        if (personData.name) finalData.name_i = personData.name;
        if (personData.surname) finalData.name_f = personData.surname;
        if (personData.patronymic) finalData.name_o = personData.patronymic;
    } else {
        finalData = fillFioFields(finalData, fioField);
        /*const fioFieldData = fioField.trim().split(' ');
        if (fioFieldData[0]) finalData.name_f = fioFieldData[0];
        if (fioFieldData[1]) finalData.name_i = fioFieldData[1];
        if (fioFieldData[2]) finalData.name_o = fioFieldData[2];*/
    }
    return finalData;
}

export const prepareCertificate = (initialCertificate, prolongMode = false) => {

    // Сначала пробуем дадату, и, если там данных нет, просто разбиваем фио по пробелу
    const includePersonData = (personType, personData, fioField, dataToSend) => {
        const person = parsePerson(personData, fioField);
        if (person.name_i) dataToSend[personType + "_name_i"] = person.name_i;
        if (person.name_f) dataToSend[personType + "_name_f"] = person.name_f;
        if (person.name_o) dataToSend[personType + "_name_o"] = person.name_o;
    }

    const certificate = copyObject(initialCertificate);

    const fieldsToIgnore = ['client_address_data', 'owner_address_data', 'client_data', 'owner_data', 'client_document_series', 'client_document_number', 'owner_document_series', 'owner_document_number'];

    if (!certificate || !isObject(certificate)) return {result: "error", errorString: "Вы не указали данные полиса"};

    //Создаём массив данных для отправки на сервер. Это все поля формы за исключением некоторых - их обрабатываем отдельно и не включаем в результирующий массив
    const dataToSend = {};

    // Если стоит галочка "совпадает", то дублируем данные в финальный массив
    if (certificate.owner_is_client) {
        for (const i in personCopyFieldsList) {
            const fieldName = "client_" + personCopyFieldsList[i];
            if (typeof certificate[fieldName] !== 'undefined') certificate['owner_' + personCopyFieldsList[i]] = certificate[fieldName];
        }
    }

    let exploded = null;
    for (const fieldName in certificate) {
        switch (fieldName) {

            case "id": dataToSend['certificate_id'] = (prolongMode ? 0 : certificate.id); break;

            case "car_number": dataToSend['car_number'] = onlyDigitsAndLetters(certificate[fieldName]); break;

            case "client_document_series_number": 
                exploded=explodeSeriesNumber(certificate[fieldName]); 
                dataToSend['client_document_series']=exploded.series; 
                dataToSend['client_document_number']=exploded.number;  
                break;
            case "owner_document_series_number": 
                exploded=explodeSeriesNumber(certificate[fieldName]); 
                dataToSend['owner_document_series']=exploded.series; 
                dataToSend['owner_document_number']=exploded.number;  
                break;

            case "drivers":
                dataToSend.drivers = [];
                const driversList = certificate[fieldName];
                for (const i in driversList) {
                    const driverData = {
                        birth_date: driversList[i].birth_date,
                        document_from: driversList[i].document_from,
                        document_series: '',
                        document_number: '',
                        document_type: driversList[i].document_type,
                        experience_from: driversList[i].experience_from,
                        country_code: driversList[i].country_code,
                        fio: driversList[i].fio,
                        gender: driversList[i].gender,
                    };

                    const driverDocumentData = (driversList[i].document_series_number ?? '').trim().split(' ');
                    driverData.document_series = driverDocumentData[0] ?? '';
                    driverData.document_number = driverDocumentData[1] ?? '';

                    const driverFioData = parsePerson(driversList[i].fio_data, driversList[i].fio ?? '');
                    if (driverFioData.name_i) driverData.name_i = driverFioData.name_i;
                    if (driverFioData.name_f) driverData.name_f = driverFioData.name_f;
                    if (driverFioData.name_o) driverData.name_o = driverFioData.name_o;

                    const prevDriverDocumentData = (driversList[i].prev_document_series_number ?? '').trim().split(' ');
                    driverData.prev_document_series = prevDriverDocumentData[0] ?? '';
                    driverData.prev_document_number = prevDriverDocumentData[1] ?? '';

                    const prevDriverFioData = parsePerson(driversList[i].prev_fio_data, driversList[i].prev_fio ?? '');
                    if (prevDriverFioData.name_i) driverData.prev_name_i = prevDriverFioData.name_i;
                    if (prevDriverFioData.name_f) driverData.prev_name_f = prevDriverFioData.name_f;
                    if (prevDriverFioData.name_o) driverData.prev_name_o = prevDriverFioData.name_o;

                    dataToSend.drivers.push(driverData);
                }
                break;

            case "periods":
                dataToSend.periods = [];
                const periodsList = certificate[fieldName];
                for (const i in periodsList) {
                    const periodData = {
                        start: periodsList[i].start,
                        end: moment(periodsList[i].start,"DD.MM.YYYY").add(periodsList[i].months, 'M').format('YYYY-MM-DD'),
                    };
                    dataToSend.periods.push(periodData);
                }
                break;

            case 'client_city_id': case 'client_city_kladr': case 'client_city_name': 
            case 'client_house_id': case 'client_house_kladr': case 'client_house_name': 
            case 'client_street_id': case 'client_street_kladr': case 'client_street_name':
            case 'owner_city_id': case 'owner_city_kladr': case 'owner_city_name': 
            case 'owner_house_id': case 'owner_house_kladr': case 'owner_house_name': 
            case 'owner_street_id': case 'owner_street_kladr': case 'owner_street_name':
            case 'client_apt': case 'owner_apt':
                if (certificate[fieldName] !== null) dataToSend[fieldName] = certificate[fieldName];
                break;

            default:
                if (!fieldsToIgnore.includes(fieldName))
                    dataToSend[fieldName] = certificate[fieldName];
                break;
        }
    }

    if (certificate.client_address_data) includeAddressData('client', certificate.client_address_data, dataToSend);
    if (certificate.owner_address_data) includeAddressData('owner', certificate.owner_address_data, dataToSend);

    if (certificate.client_data || certificate.client_fio !== "") includePersonData('client', certificate.coient_data, certificate.client_fio, dataToSend);
    if (certificate.owner_data || certificate.owner_fio !== "") includePersonData('owner', certificate.owner_data, certificate.owner_fio, dataToSend);

    return dataToSend;
}
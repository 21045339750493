import React from 'react';
import { useState, useCallback } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';

import { token, addressUrl } from '../../settings/dadata';

export default function ElkDadataAddress(props) {

    const [options, setOptions] = useState();
    const [optionsData, setOptionsData] = useState([]);
    const [value, setValue] = useState();
    const [error, setError] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const getPredictions = React.useMemo(
        () =>
            throttle((request, callback) => {
            
            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: request})
            }
            
            try {
                fetch(addressUrl, requestOptions)
                .then(response => response.json())
                .then(result => { callback(result.suggestions ?? null, null); })
                .catch(error => { console.log("ERROR IS", error); callback(null, error); });
            } catch(error) {
                console.log("GLOBAL ERROR IS", error);
            }

            }, 1000),
        [],
    );

    const checkAddressData = useCallback((value) => {
        let addressData = null;
        if (optionsData[value]) {
            addressData = {

                city: optionsData[value].city_with_type,
                city_id: optionsData[value].city_fias_id,
                city_kladr: optionsData[value].city_kladr_id,

                house: optionsData[value].house,
                house_id: optionsData[value].house_fias_id,
                house_kladr: optionsData[value].house_kladr_id,

                region: optionsData[value].region_with_type,
                region_id: optionsData[value].region_fias_id,
                region_kladr: optionsData[value].region_kladr_id,

                settlement: optionsData[value].settlement_with_type,
                settlement_id: optionsData[value].settlement_fias_id,
                settlement_type: optionsData[value].settlement_type,
                settlement_kladr: optionsData[value].settlement_kladr_id,

                area: optionsData[value].area_with_type,
                area_id: optionsData[value].area_fias_id,
                area_kladr: optionsData[value].area_kladr_id,

                street: optionsData[value].street_with_type,
                street_id: optionsData[value].street_fias_id,
                street_kladr: optionsData[value].street_kladr_id,

                apt: optionsData[value].flat,
                postal_code: optionsData[value].postal_code,
            }
        }
        return addressData;
    }, [optionsData]);

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        getPredictions( inputValue, (results, error) => {

            if (active) {
                let newOptions = [];
                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    const newOptionsData = {};
                    for (const i in results) {
                        newOptions.push(results[i].value);
                        newOptionsData[results[i].value] = results[i].data;
                    }
                    setOptionsData(newOptionsData);
                }
                //console.log("NEW OPTIONS", newOptions);
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };

    }, [value, inputValue, getPredictions]);

    const onAddressSelected = (value) => {
        // Меняем что-либо только если значение изменилось иначе сбрасывается значение разбора при "табе" по полю
        //if (value !== props.value) { 
            const addressData = checkAddressData(value);
            //console.log("GO CHANGE", addressData);

            if (addressData)
                props.onValueChange( value, addressData );
        //}
    }

    //console.log("PRE FINAL OPTIONS ARE", options);

    let finalOptions = options;
    if (!finalOptions) finalOptions = [props.value];
    else if (finalOptions.length === 0) finalOptions.push(props.value);

    //console.log("FINAL OPTIONS ARE", finalOptions);
    //console.log("DADATA ADDRESS", props.value);

    //console.log("FINAL OPTIONS", finalOptions);

    return (
        <Grid item lg={props.width ?? 4} md={props.width ?? 4} xs={12} >
        <Autocomplete
            freeSolo
            fullWidth
            disableClearable
            options={finalOptions}
            value={props.value ?? null}
            filterOptions={(option)=>option} // Нужно чтобы всегда показывать все опции
            onChange={(event, value) => { onAddressSelected(value) }}
            renderInput={(params) => (
            <TextField
                {...params}
                label={props.label}
                error={props.error}
                helperText={props.helperText}
                variant="outlined"
                required={props.required}
                value={props.value ?? null}
                onChange={(event) => { onAddressSelected(event.target.value); props.setFieldValue(props.name, event.target.value); setInputValue(event.target.value) }}
                onBlur={(event) => {props.handleBlur(event); onAddressSelected(event.target.value)}}
                InputProps={{ ...params.InputProps, type: 'search' }}
            />
            )}
        />
        </Grid>
    );
    //(event) => props.onValueChange(event.target.value)
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  makeStyles,
  Switch
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ruLocale from "date-fns/locale/ru";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Plus as PlusIcon } from 'react-feather';
import Alert from '@material-ui/lab/Alert';

import makeFirstLetterCapital from '../../../utils/makeFirstLetterCapital'

import copyObject from '../../../utils/copyObject';
import createMenuItems from '../../../utils/createMenuItems';

import dateValidation from '../../../utils/yup/dateValidation';

import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import ElkDateSelector from '../../../components/FormElements/ElkDateSelector';
import ElkDadataFio from '../../../components/FormElements/ElkDadataFio';
import ElkDadataAddress from '../../../components/FormElements/ElkDadataAddress';
import ElkSearchInput from '../../../components/FormElements/ElkSearchInput';

import Spinner from '../../../components/Spinner';

import { prepareUser } from './UserController';

import * as actions from '../../../store/actions';
import getFormError from '../../../utils/getFormError';
import onlyDigits from '../../../utils/onlyDigits';

const useStyles = makeStyles((theme) => ({
  root: {},
  pointer: {
    cursor: "pointer"
  },
  userBox: {
    marginTop: "20px"
  },
  marginTop: {
    marginTop: "10px"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  marginRight: {
    marginRight: "10px"
  },
  center: {
    textAlign: "center",
  },
  saveSpinner: {
    marginRight: "10px"
  },
  error: {
    color: "#f44336"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const textBlocks = {
  header1: {
    own: "Базовые данные",
    external: "Данные агента",
  },
  header2: {
    own: "Паспортные данные",
    external: "Паспорт агента",
  },
}

const UserForm = ({ className, user, rates, gowno, userId, ...rest }) => {

  const [filterRatesOptionsArray, setFilterRatesOptionsArray] = useState([{id: 0, name: ""}]);
  const [createLinkedRate, setCreateLinkedRate] = useState(false);

  const textBlockType = rest.curUser.id === userId ? "own" : "external";

  //const rates = {};
  //for (const i in rawRates) rates[rawRates[i].id] = rawRates[i];

  const userErrors = {};
  if (rest.errorFields) {
    for (const i in rest.errorFields) 
    userErrors[i] = rest.errorFields[i];
  }

  const classes = useStyles();
  const navigate = useNavigate();

  const onLoadCatalog = rest.onLoadCatalog;
  const onLoadUser = rest.onLoadUser;
  const onLoadRates = rest.onLoadRates;
  const catalogsGeneral = rest.catalogs['general'] ?? false;
  const catalogsRegions = rest.catalogs['regions'] ?? false;
  useEffect(() => {
    //console.log("ON LOAD USER", user.id, userId, rest.isUserLoading);
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsRegions) onLoadCatalog('regions')
    else if (user.id*1 !== userId*1 && !rest.isUserLoading && !rest.userError) {
      //console.log("ON LOAD USER", userId);
      onLoadUser(userId);
    }
    else if (!rates) onLoadRates({}, 1, 1000, {});

    //Заполняем каталоги в опции
    if (rates) {
      const newFilterRatesOptionsArray = [{id: 0, name: "Не указан"}];
      for (const i in rates)
        newFilterRatesOptionsArray.push({id: rates[i].id, name: rates[i].name});
      setFilterRatesOptionsArray(newFilterRatesOptionsArray);
    }

  }, [catalogsGeneral, catalogsRegions, onLoadCatalog, user.id, userId, rest.userError, onLoadUser, rest.isUserLoading, rates, onLoadRates]);

  const editRate = (rateId) => {
    navigate("/app/rates/" + rateId);
  }

  const updateUserData = (updatedFields) => {
    //console.log("On update user", updatedFields);
    let newUserData = copyObject(user);
    for (const i in updatedFields)
    newUserData[i] = updatedFields[i];
    rest.onUpdateUser(newUserData);
  }

  const changeAddress = (addressString, addressData) => {
    updateUserData({ 
      city: addressString, 
      address_data: addressData, 
      city_id: null,
    })
  }

  const changeRegisterAddress = (addressString, addressData) => {
    updateUserData({ 
      register_address: addressString, 
      register_address_data: addressData, 
      register_city_id: null,
      register_city: null,
      register_house_id: null,
      register_house: null,
      register_street_id: null,
      register_street: null,
      register_apt: null,
    })
  }

  const uploadFile = (type, event) => {
    rest.onUploadFile(type, event.target.files[0], {type:'user', id: user.id});
  }

  const activateFio = (setFieldValue, newValue) => {
    let genderValue = user.gender;
    if (newValue && newValue.data && newValue.data.gender) {
      genderValue = newValue.data.gender === 'FEMALE' ? 2 : 1;
    } else if (newValue && newValue.gender)
      genderValue = newValue.gender;
    const fioValue = newValue.fio ? makeFirstLetterCapital(newValue.fio) : user.fio;
    setFieldValue("gender", genderValue);
    setFieldValue("fio", fioValue);
    updateUserData({ fio: fioValue, data: newValue.data ?? null, gender: genderValue });
  }

  const changePassword = () => {
    rest.onChangePassword(user.id, user.oldpassword, user.password, user.repassword);
  }
  
  const saveUser = (createLinkedRate) => {
    const dataToSend = prepareUser(user);
    rest.onSaveUser(dataToSend, createLinkedRate);
  }

  const yupValidationShape = React.useMemo(() => {

    let yupValidationShape = {

      fio: Yup.string().max(255).required('Пожалуйста, укажите ФИО').test('2 parts', 'Укажите фамилию, имя и отчество (если есть)', function(value) {
        return value && (value.trim().split(" ").length >= 2);
      }),
      email: Yup.string().email('Укажите корректный E-mail').max(255).required('Пожалуйста, укажите E-mail клиента'),
      phone: Yup.string().required('Пожалуйста, укажите телефон клиента').test('phone', 'Укажите корректный телефон', function(value) {
        return onlyDigits(value).length === 11;
      }),

      birth_date: dateValidation(true, 'past', 100, 18, "Пожалуйста, укажите дату рождения"),
      city: Yup.string().required("Пожалуйста, укажите город работы агента").test('city', 'Выберите город работы агента из списка', function(value) {
        
        return (user.address_data || user.city_id) ? true : false;
      }),

      passport_series_number: Yup.string().max(255).required('Укажите серию и номер паспорта').test('2 parts', 'Укажите серию и номер паспорта через пробел', function(value) {
        return value && (value.trim().split(" ").length >= 2);
      }),
      passport_date: dateValidation(true, 'past', 100, 0, "Пожалуйста, укажите дату выдачи документа"),
      passport_issued: Yup.string().max(255).required('Укажите место выдачи документа'),
      passport_code: Yup.string().max(255).required('Укажите код подразделения'),
      register_address: Yup.string().max(255).required('Укажите адрес').test('chosen','Выберите адрес из выпадающего списка', function(value) {
        //console.log("DATA", user.register_address_data, user);
        return (user.register_address_data || user.register_house) ? true : false;
      }),

      password: Yup.string().max(255).test('password-exist', 'Пожалуйста, укажите пароль для агента (минимум 8 символов)', function(value) {
        return ((userId!=="new" && !value) || (value && value.length >= 8));
      }),
      repassword: Yup.string().max(255).test('passwords-match', 'Пароль и подтверждение пароля должны совпадать', function(value) {
        return this.parent.password === value;
      }),

      //TODO Passport scan documents check

      //inn: Yup.string().max(255).required('Укажите ИНН'),
      //snils: Yup.string().max(255).required('Укажите СНИЛС'),
      //card: Yup.string().max(255).required('Укажите номер карты для вывода средств'),
    }

    return yupValidationShape;
  }, [user.city_id, user.register_address_data, user.address_data, userId, user.register_house_id]);

  let backdrop = null;
  //if (rest.isCatalogsLoading) return <Spinner text="Загружаем каталоги"></Spinner>;
  if (rest.isUserLoading) backdrop = <Backdrop className={classes.backdrop} open={true}><Spinner text="Загружаем данные полиса" /></Backdrop>

  //if ((/*!rest.isCatalogsLoading &&*/ !rest.isUserLoading))
  if (!rest.userError)
    return <>
      <Formik
        className={clsx(classes.root, className)}
        initialValues={user}
        //enableReinitialize={rest.isUserLoaded}
        enableReinitialize={true}
        onSubmit={ (values) => { saveUser(createLinkedRate);  } }
        validationSchema={ Yup.object().shape(yupValidationShape) }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => { 
          
          //console.log("TOUCHED ERRORS", touched, errors, values, user);
          //console.log("TOUCHED ERRORS 2", touched, values, user);
          //console.log("REFRESH", userId);

          let newPasswordBlock = null;
          let changePasswordBlock = null;
          if (userId !== rest.curUser.id) {
            newPasswordBlock = <Box className={classes.userBox}>
              <Card>
                <CardHeader 
                  title="Пароль агента" 
                  subheader={userId==="new" ? "" : "Если не хотите менять пароль пользователя, оставьте эти поля пустыми"}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <ElkTextInput
                      {...getFormError("password", touched, errors, userErrors)}
                      label="Пароль"
                      name="password"
                      type="password"
                      placeholder=""
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ password: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      value={user.password ?? ''}
                    />
                    <ElkTextInput
                      {...getFormError("repassword", touched, errors, userErrors)}
                      label="Повторный ввод пароля"
                      name="repassword"
                      type="password"
                      placeholder=""
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ repassword: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      value={user.repassword ?? ''}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          } else {

            let passwordChangeResult = null;
            if (rest.passwordChangeSuccess) 
              passwordChangeResult = <Grid item lg={12} md={12} xs={12} className={classes.center} ><Alert severity="success" className={classes.marginBottom}>Пароль успешно изменён</Alert></Grid>
            else if (rest.passwordChangeError)
              passwordChangeResult = <Grid item lg={12} md={12} xs={12} className={classes.center} ><Alert severity="error" className={classes.marginBottom}>{rest.passwordChangeError}</Alert></Grid>

            let changePasswordButton = <Button variant="contained" color="default" onClick={changePassword}>Изменить пароль</Button>;
            if (rest.isPasswordChanging) changePasswordButton = <Button variant="contained" color="default" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Меняем пароль</Button>

            changePasswordBlock = <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  title="Изменение пароля"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <ElkTextInput
                      {...getFormError("oldpassword", touched, errors, userErrors)}
                      label="Старый пароль"
                      name="oldpassword"
                      placeholder=""
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ oldpassword: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      value={user.oldpassword ?? ''}
                    />
                    <ElkTextInput
                      {...getFormError("password", touched, errors, userErrors)}
                      label="Новый пароль"
                      name="password"
                      placeholder=""
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ password: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      value={user.password ?? ''}
                    />
                    <ElkTextInput
                      {...getFormError("repassword", touched, errors, userErrors)}
                      label="Повторный ввод пароля"
                      name="repassword"
                      placeholder=""
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ repassword: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      value={user.repassword ?? ''}
                    />
                    {passwordChangeResult}
                    <Grid item lg={12} md={12} xs={12} className={classes.center} >
                      {changePasswordButton}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          }

          let saveButton = <Button variant="contained" color="primary" type="submit">Сохранить</Button>
          if (rest.isSaving) saveButton = <Button variant="contained" color="primary" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Сохраняю</Button>

          let saveError = null;
          if (rates && rest.savedId && rest.savedId === user.id) {
            let editLink = null;

            /*console.log("CHECK1", rest.curUser);
            console.log("CHECK2", rates);
            console.log("CHECK3", user.rate_id);
            console.log("CHECK4", rates[user.rate_id]);*/

            if (rest.curUser.access*1 >= 50 || rates[user.rate_id].user_id*1 === rest.curUser.id*1)
              editLink = <Link className={classes.pointer} onClick={()=>editRate(rest.savedRateId)}>Отредактировать комиссионное вознаграждение агента</Link>
            saveError = <><Alert severity="success" className={classes.marginBottom}>Агент успешно сохранён! {editLink}</Alert></>
          }
          else if (rest.saveError) saveError = <Alert severity="error" className={classes.marginBottom}>{rest.saveError}</Alert>
          else if (touched && errors && Object.keys(errors).length > 0) {
            for (const error in errors) if (touched[error]) {
              saveError = <Alert severity="error" className={classes.marginBottom}>Ошибки в заполнении данных агента</Alert>
              break;
            }
          }

          //console.log("FILE 1 LOAD RESULt",rest.files);

          let passportPhoto1 = null;
          let passportPhoto2 = null;
          if (rest.files.passport_photo1) {
            const file = rest.files.passport_photo1;
            if (file.loading) passportPhoto1 = <Spinner text="Загружаю документ"></Spinner>
            else if (file.error) passportPhoto1 = <Alert className={classes.marginBottom} severity="error">{file.error}</Alert>
            else if (file.success) passportPhoto1 = <Alert className={classes.marginBottom} severity="success">{user.passport_photo1 ? "Новый документ загружен" : "Документ загружен"}</Alert>
            else passportPhoto1 = <Alert className={classes.marginBottom} severity="success">Неизвестный статус</Alert>
          }
          else if (user.passport_photo1) passportPhoto1 = <Alert className={classes.marginBottom} severity="info">Документ загружен</Alert>
          if (rest.files.passport_photo2) {
            const file = rest.files.passport_photo2;
            if (file.loading) passportPhoto2 = <Spinner text="Загружаю документ"></Spinner>
            else if (file.error) passportPhoto2 = <Alert className={classes.marginBottom} severity="error">{file.error}</Alert>
            else if (file.success) passportPhoto2 = <Alert className={classes.marginBottom} severity="success">{user.passport_photo2 ? "Новый документ загружен" : "Документ загружен"}</Alert>
            else passportPhoto2 = <Alert className={classes.marginBottom} severity="success">Неизвестный статус</Alert>
          }
          else if (user.passport_photo2) passportPhoto2 = <Alert className={classes.marginBottom} severity="info">Документ загружен</Alert>

          let documentsBlock = <Grid item lg={12} md={12} xs={12} className={classes.center}><Alert severity="info">Загрузка документов будет доступна после создания агента</Alert></Grid>;
          if (user.id > 0) {
            documentsBlock = <>
              <Grid item lg={4} md={4} xs={12} className={classes.center}>
                {passportPhoto1}
                <Button variant="outlined" component="label" >
                  Загрузить первый разворот паспорта
                  <input type="file" hidden onChange={(event) => uploadFile("passport_photo1", event)} />
                </Button>
              </Grid>

              <Grid item lg={4} md={4} xs={12} className={classes.center}>
                {passportPhoto2}
                <Button variant="outlined" component="label" >
                  Загрузить страницу прописки паспорта
                  <input type="file" hidden onChange={(event) => uploadFile("passport_photo2", event)} />
                </Button>
              </Grid>
            </>
          }

          let buttonsBlock = null;
          if (user.id > 0) {
            buttonsBlock = <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  title="Дополнительно"
                />
                <Divider />
                <CardContent>
                  <Grid item lg={4} md={4} xs={12} className={classes.center}>
                    <Button variant="outlined" component="label" onClick={(event)=>navigate('/app/messages/'+user.id)} >
                      Отправить сообщение
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          }

          let linkedRateButton = null;
          if (rates && rates[user.rate_id] && rates[user.rate_id].linked_id*1 === 0 && (rest.curUser.access*1 >= 50 || rates[user.rate_id].user_id*1 === rest.curUser.id*1))
            linkedRateButton = <Grid item lg={4} md={4} xs={12} >
              <FormControlLabel
                style={{marginLeft:0}}
                control={<Switch checked={createLinkedRate} onChange={(event, newValue) => { setCreateLinkedRate(newValue) }} name="createLinkedRate" />}
                label="Создать связное комиссионное вознаграждение"
              />
            </Grid>

          let editRateButton = null;
          if (rates && rates[user.rate_id] && rates[user.rate_id].linked_id > 0) {
          if (user.rate_id*1 > 0 && (rest.curUser.access >= 50 || (rates[user.rate_id].user_id*1 === rest.curUser.id)))
            editRateButton = <Grid item lg={4} md={4} xs={12} >
              <Button onClick={()=>editRate(user.rate_id)} variant="contained" color="default" type="button">Просмотр и редактирование КВ</Button>
            </Grid>
          }

          let tariffBlock = null;
          if (rest.curUser.id !== userId) 
            tariffBlock = <Box className={classes.userBox}>
            <Card>
              <CardHeader
                subheader="Выберите комиссионное вознаграждение для агента"
                title="Комиссионное вознаграждение"
              />
              <Divider />
              <CardContent>
                <Grid container spacing={3} >
                  <ElkSearchInput 
                    {...getFormError('rate_id', touched, errors, userErrors)}
                    label="Комиссионное вознаграждение"
                    name="rate_id"
                    onValueChange={(newValue) => { updateUserData({ rate_id: newValue }) }} 
                    //required={true} 
                    setFieldValue={setFieldValue}
                    value={user.rate_id}
                    options={ filterRatesOptionsArray }
                  />  
                  {linkedRateButton}
                  {editRateButton}
                </Grid>
              </CardContent>
            </Card>
          </Box>

          return (<form onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  title={textBlocks.header1[textBlockType]}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >

                    <ElkDadataFio 
                      label="ФИО" 
                      {...getFormError(["fio", "gender"], touched, errors, userErrors)}
                      name="fio"
                      genderName="gender"
                      onValueChange={(newValue) => { activateFio(setFieldValue, newValue); }}
                      //required={true} 
                      value={user.fio}
                      genderValue={user.gender}
                    />

                    <ElkDateSelector 
                      {...getFormError("birth_date", touched, errors, userErrors)}
                      label="Дата рождения" 
                      name="birth_date"
                      onValueChange={(newValue) => { updateUserData({ birth_date: newValue }) }} 
                      //required={true} 
                      value={user.birth_date}
                      setFieldValue={setFieldValue}
                    />

                    <ElkTextInput
                      {...getFormError('phone', touched, errors, userErrors)}
                      label="Телефон"
                      name="phone" 
                      placeholder="7 (999) 999-9999"
                      type="phone"
                      onValueChange={(newValue) => { updateUserData({ phone: newValue }) }} 
                      //required={true} 
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={user.phone ?? ''}
                      mask={['7', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    />

                    <ElkTextInput
                      {...getFormError('email', touched, errors, userErrors)}
                      label="Почта"
                      name="email" 
                      type="email"
                      placeholder="smirnov@yandex.ru"
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ email: newValue }) }} 
                      //required={true} 
                      value={user.email ?? ''}
                    />

                    <ElkDadataAddress
                      {...getFormError(["city","city_id"], touched, errors, userErrors)}
                      label="Город работы" 
                      mode="city"
                      width={4}
                      name="city"
                      onValueChange={(newValue, addressData) => { changeAddress(newValue, addressData); }} 
                      //required={true} 
                      handleBlur={handleBlur}
                      value={user.city ?? ''}
                      setFieldValue={setFieldValue}
                    />

                  </Grid>
                </CardContent>
              </Card>
            </Box>

            {tariffBlock}
            
            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  title={textBlocks.header2[textBlockType]}
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <ElkTextInput
                      {...getFormError(["passport_series", "passport_number", "passport_series_number"], touched, errors, userErrors)}
                      label="Серия и номер документа"
                      name={"passport_series_number"}
                      //placeholder=""
                      setFieldValue={setFieldValue}
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ passport_series_number: newValue }) }} 
                      //required={true} 
                      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                      value={user.passport_series_number ?? ''}
                    />

                    <ElkDateSelector 
                      {...getFormError("passport_date", touched, errors, userErrors)}
                      label="Дата выдачи документа" 
                      name={"passport_date"} 
                      onValueChange={(newValue) => { updateUserData({ passport_date: newValue }) }} 
                      //required={true} 
                      value={user.passport_date ?? ''}
                      setFieldValue={setFieldValue}
                    />

                    <ElkTextInput
                      {...getFormError("passport_issued", touched, errors, userErrors)}
                      label="Документ выдан"
                      name={"passport_issued"}
                      placeholder="ОВД Центрального округа г. Москвы"
                      onValueChange={(newValue) => { updateUserData({ passport_issued: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      value={user.passport_issued ?? ''}
                    />

                    <ElkTextInput
                      {...getFormError("passport_code", touched, errors, userErrors)}
                      label="Код подразделения"
                      name={"passport_code"}
                      placeholder="110-440"
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ passport_code: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                      value={user.passport_code ?? ''}
                    />

                    {<ElkDadataAddress
                      {...getFormError(["register_address", "register_city_id", "register_street_id", "register_house_id", "register_apt"], touched, errors, userErrors)}
                      label="Адрес прописки" 
                      width={8}
                      name="register_address"
                      onValueChange={(newValue, addressData) => { changeRegisterAddress(newValue, addressData); }} 
                      //required={true} 
                      handleBlur={handleBlur}
                      value={user.register_address ?? ''}
                      setFieldValue={setFieldValue}
                    />}

                  </Grid>
                </CardContent>
              </Card>
            </Box>

            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  //subheader="Введите госномер авто и часть данных будет заполнена автоматически"
                  title="Другие документы"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <ElkTextInput
                      {...getFormError("inn", touched, errors, userErrors)}
                      label="ИНН"
                      name="inn"
                      placeholder="1234567890"
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ inn: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                      value={user.inn ?? ''}
                    />

                    <ElkTextInput
                      {...getFormError("snils", touched, errors, userErrors)}
                      label="СНИЛС"
                      name="snils"
                      placeholder=""
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ snils: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/]}
                      value={user.snils ?? ''}
                    />

                    <ElkTextInput
                      {...getFormError("card", touched, errors, userErrors)}
                      label="Карта для вывода средств"
                      name="card"
                      placeholder=""
                      handleBlur={handleBlur}
                      onValueChange={(newValue) => { updateUserData({ card: newValue }) }} 
                      setFieldValue={setFieldValue}
                      //required={true} 
                      mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                      value={user.card ?? ''}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Box>

            {newPasswordBlock}
            
            <Box className={classes.userBox}>
              <Card>
                <CardHeader
                  subheader="Укажите фото документа и оно будет привязано и сохранено автоматически"
                  title="Загрузка документов"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    {documentsBlock}
                  </Grid>
                </CardContent>
              </Card>
            </Box>

            {changePasswordBlock}

            {buttonsBlock}
            
            <Box className={classes.userBox}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} xs={12} className={classes.center} >
                      {saveError}
                      {saveButton}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>

            </MuiPickersUtilsProvider>
          </form>
        ); }}
      </Formik>
      {backdrop}
    </>
  else 
    return <Box style={{width:'100%'}} className={classes.userBox}><Alert style={{width:'100%'}} severity="error">{rest.userError}</Alert></Box>;
  /*else {
    //if (rest.isCatalogsLoading) return <Spinner text="Загружаем каталоги"></Spinner>;
    if (rest.isUserLoading) return <Spinner text="Загружаем данные полиса"></Spinner>;
    return <Spinner></Spinner>;
  }*/
};

UserForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    isUserLoading: state.user.isLoading,
    isUserLoaded: state.user.isLoaded,
    isUserSaving: state.user.isSaving,
    user: state.user.user,
    userError: state.user.loadError,
    saveError: state.user.saveError,
    errorFields: state.user.errorFields,
    isSaving: state.user.isSaving,
    savedId: state.user.savedId,
    savedRateId: state.user.savedRateId,
    rates: state.rates.rates,

    files: state.files.files,
    
    curUser: state.auth.user,

    isPasswordChanging: state.user.isPasswordChanging,
    passwordChangeError: state.user.passwordChangeError,
    passwordChangeSuccess: state.user.passwordChangeSuccess,
    
    isCatalogsLoading: state.catalogs.isLoading,
    catalogs: state.catalogs.catalogs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCatalog: (catalogName, subId=null) => dispatch(actions.loadCatalog(catalogName, subId)),
      onLoadRates: (filter, page, perPage, order) => dispatch(actions.loadRates(filter, page, perPage, order)),
      onUpdateUser: (newUserData) => dispatch(actions.updateUser(newUserData)),
      onUploadFile: (fileName, file, additional) => dispatch(actions.uploadFile(fileName, file, additional)),
      onSaveUser: (userToSave, createLinkedRate) => dispatch(actions.saveUser(userToSave, createLinkedRate)),
      onLoadUser: (userId) => dispatch(actions.loadUser(userId)),
      onChangePassword: (userId, oldPassword, newPassword, reNewPassword) => dispatch(actions.changePassword(userId, oldPassword, newPassword, reNewPassword)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);

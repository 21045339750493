import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import UserLog from './UserLog';

import parseUrl from '../../../utils/parseUrl';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const UserLogPage = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userId = parseUrl(3);

  let topMenu = null;
  if (props.curUser.access === 100) {
    topMenu = <>
      <Button onClick={()=>{navigate("/app/users/" + userId)}} color="default">Управление агентом</Button>
      <Button color="primary" variant="outlined">История изменений</Button>
    </>
  }

  return (
    <Page
      className={classes.root}
      title="Управление пользователем"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          {topMenu}
          <UserLog userId={userId} />
        </Grid>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    curUser: state.auth.user,
  }
}

export default connect(mapStateToProps)(UserLogPage);
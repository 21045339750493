import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Page from '../../../components/Page';
import SmartTable from '../../../components/SmartTable';
import CertificateDocuments from '../CertificateDocuments/index';
import ReportsWindow from '../ReportsWindow';

import { CreditCardOutlined, AccountBalanceWalletOutlined } from '@material-ui/icons';

import Spinner from '../../../components/Spinner';
import ErrorBlock from '../../../components/ErrorBlock';
import getFio from '../../../utils/getFio';
import { certificateContinueableStauses, certificateDeleteableStauses, certificateCancelableStatuses, certificateProlongableStatuses, certificatePrintableStatuses } from '../../../settings/profins';

import * as actions from '../../../store/actions';
import tokenizedAxios from '../../../utils/tokenizedAxios';
import { usersAutocompleteUrl } from '../../../settings/api_urls';
import { certificateDisplayPaymentTypeStatuses } from '../../../settings/profins';
import ConfirmDialog from '../../../components/ConfirmDialog';
import copyObject from '../../../utils/copyObject';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  marginTop: {
    marginTop: '20px'
  },
  tooltipLink: {
    cursor: 'pointer',
    color: 'black',
    textDecoration: 'none',
  },
  subText: {
    fontSize: '10px',
    color: 'gray',
    marginTop: '1px',
  },
  text: {
    verticalAlign: 'middle',
    display: 'inline-block',
  },
  icon: {
    verticalAlign: 'middle',
    display: 'inline-block',
    color: 'gray',
  },
}));

const smartTableHeaders = { 
  'id':'Номер', 
  'agent':'Агент',
  'client_name_f':'Страхователь', 
  'status_id':'Статус', 
  'certificate_company_id':'Компания', 
  'certificate_number':'Номер', 
  'certificate_price':'Cтоимость', 
  'report_id':'Отчёт',
  'certificate_processed_at':'Дата' 
}

const CertificateListView = (props) => {

  const classes = useStyles();
  const navigate = useNavigate();
  const [confirmBlock, setConfirmBlock] = useState(null);
  const [infoBlock, setInfoBlock] = useState(null);

  const onLoadCertificates = props.onLoadCertificates;
  const onLoadCatalog = props.onLoadCatalog;
  const currentFilter = props.filter;

  const filterHandler = useCallback((filter, page, perPage, order) => {
    onLoadCertificates(filter, page, perPage, order);
  }, [onLoadCertificates]);

  const clickHandler = (certificateId) => {
    navigate("/app/certificates/" + certificateId);
  }

  const messageHandler = (userId) => {
    navigate("/app/messages/" + userId);
  }

  async function searchAgentFunc (request, callback) {
    const response = await tokenizedAxios(usersAutocompleteUrl, 'get', {search: request});
    const optionsData = [];
    let error = null;
    if (response.data.result === "ok") {
        const users = response.data.users;
        for (const i in response.data.users) 
            optionsData.push({id: users[i].id, value: users[i].name_f + " " + users[i].name_i});
    } else {
        error = response.errorstring;
    }

    callback(optionsData, error);
  }

  const calculateHandler = (certificateId) => {
    navigate("/app/certificates/" + certificateId + "/calculate");
  }

  const prolongHandler = (certificateId) => {
    navigate("/app/certificates/" + certificateId + "/prolong");
  }

  const deleteHandler = (certificateId) => {
    props.onDeleteCertificate(certificateId);
  }

  const cancelHandler = (certificateId) => {
    props.onCancelCertificate(certificateId);
  }

  const createInfoBlock = (selectedIds) => {
    if (!selectedIds || selectedIds.length === 0) return;
    let count = 0;
    let sum = 0;
    let comission = 0;
    
    for (const i in props.certificates) {
      const certificate = props.certificates[i];
      if (selectedIds.includes(certificate.id)) {
        count ++;
        sum += certificate.certificate_price;
        comission += certificate.certificate_comission;
      }
    }
    setInfoBlock(<Grid style={{marginTop: "10px"}} container justify="center" alignItems="center"><Typography>Полисов: <b>{count}</b>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Сумма: <b>{Math.floor(sum)}</b>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Комиссия: <b>{Math.floor(comission)}</b></Typography></Grid>);
  }

  const addToReportHandler = (certificateIds) => {
      props.onOpenReportsWindow(certificateIds);
  }

  const catalogsGeneral = props.catalogs['general'] ?? false;
  const catalogsCompanies = props.catalogs['companies'] ?? false;
  const catalogsRegions = props.catalogs['regions'] ?? false;

  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsCompanies) onLoadCatalog('companies')
    else if (!catalogsRegions) onLoadCatalog('regions')
    else if (props.certificates === null) filterHandler(currentFilter, 1, 20, {certificate_processed_at: "DESC"})
  }, [filterHandler, onLoadCatalog, catalogsGeneral, catalogsCompanies, catalogsRegions, currentFilter, props.certificates]);

  const onCloseReportsWindow = props.onCloseReportsWindow;
  useEffect(() => {
    if (props.isReportsWindowActive && props.redirectToReportId) {
      onCloseReportsWindow();
      navigate("/app/reports/" + props.redirectToReportId);
    }
  }, [props.isReportsWindowActive, props.redirectToReportId, onCloseReportsWindow, navigate]);

  const smartTableColumnCreator = (record) => {
    
    let priceText = '';
    if (record.certificate_price > 0) {
      let comissionText;
      if (record.is_pool)
        comissionText = <div className={classes.subText}>0 руб.<br/>(Перестр.)</div>;
      else {
        const percent = Math.round((record.certificate_comission / record.certificate_price) * 1000);
        comissionText = <div className={classes.subText}>{percent / 10}% - {record.certificate_comission}</div>;
      }
      priceText = record.certificate_price > 0 ? (<><div>{record.certificate_price}</div>{ comissionText }</>) : "";
    }

    let paymentType = props.catalogs['companies'][record.certificate_company_id] ? props.catalogs['companies'][record.certificate_company_id].name : '';
    if (certificateDisplayPaymentTypeStatuses.includes(record.status_id)) {
      if (parseInt(record.certificate_format) === 1 || parseInt(record.certificate_format) === 2) paymentType = <div className={classes.text} style={{verticalAlign: "middle"}}><AccountBalanceWalletOutlined className={classes.icon} fontSize="small" /> {paymentType}</div>
      else paymentType = <div className={classes.text}><CreditCardOutlined className={classes.icon} fontSize="small" /> {paymentType}</div>;
    }

    return {
      'id': record.id,
      'agent': (record.agent.name_f??"") + " " + (record.agent.name_i??""),
      'client_name_f': parseInt(record.client_is_jur) === 1 ? record.client_name : (record.client_name_f + " " + record.client_name_i),
      'status_id': props.catalogs['general']['certificate_statuses'][record.status_id] ? props.catalogs['general']['certificate_statuses'][record.status_id].name : <ErrorBlock error="НЕИЗВЕСТНЫЙ СТАТУС" />,
      'certificate_company_id': props.catalogs['companies'][record.certificate_company_id] ? paymentType : '',
      'certificate_number': record.certificate_series + " " + record.certificate_number,
      'certificate_price': priceText,
      'report_id': record.report_id > 0 ? record.report_id : "",
      'created_at': [moment(record.created_at).format('DD.MM.YYYY HH:mm'), (record.certificate_payed_at !== '0000-00-00 00:00:00' ? <div className={classes.subText}>{'Оплачен ' + moment(record.certificate_payed_at).format('DD.MM.YYYY HH:mm')}</div> : null)]
    };
  };

  const smartTableMassButtons = [
    {name: "Добавить в отчёт", onClick: (selectedIds)=>addToReportHandler(selectedIds) }
  ]

  const companiesForFilter = copyObject(props.catalogs['companies']);
  companiesForFilter[-1] = { name:"Все компании WEB", id:-1 };
  //companiesForFilter.push();
  const smartTableFilters = {
    'search': { type: 'search', width: 2, name: "Поиск по полисам (минимум 2 символа)", placeholder: "Поиск по ФИО, телефону или e-mail" },
    'created_at': { type: 'dateRange', name: "Дата создания" },
    'company_id': { type: 'multiselect', name: "Страховая компания", options: companiesForFilter },
    'status_id': {type: 'multiselect', name: "Статус", options: props.catalogs['general'] ? props.catalogs['general']['certificate_statuses'] : null},
    'region_id': {type: 'multiselectSearch', name: "Регион", options: props.catalogs['regions']},
    'user_id': {type: 'multiselectServerSearch', name: "Агент", searchFunc: (request, callback) => searchAgentFunc(request,callback)},
    'format_id': {type: 'select', name: "Формат", options: props.catalogs['general'] ? props.catalogs['general']['formats'] : null},
  }

  const smartTableTooltipData = (record) => {
    return <Box padding={3}>
      <Grid container spacing={2} >
        <Grid item md={4} xs={12} ><b>Агент</b></Grid>
        <Grid item md={8} xs={12} >{record.agent ? getFio(record.agent) : 'Неизвестно'}</Grid>
        <Grid item md={4} xs={12} ><b>Регион</b></Grid>
        <Grid item md={8} xs={12} >{props.catalogs['regions'] ? (props.catalogs['regions'][record.client_region_id] ? props.catalogs['regions'][record.client_region_id].name : 'Регион не найден') : 'Неизветно'}</Grid>
        <Grid item md={12} xs={12} className={classes.marginTop}><b>Контакты страхователя</b></Grid>
        <Grid item md={4} xs={12} ><b>Телефон</b></Grid>
        <Grid item md={8} xs={12} >{record.client_phone ?? 'Не указан'}</Grid>
        <Grid item md={4} xs={12} ><b>E-mail</b></Grid>
        <Grid item md={8} xs={12} >{record.client_email ?? 'Не указан'}</Grid>
      </Grid>
    </Box>
  }

  const openConfirmBlock = (onContinue, header, text) => {
    setConfirmBlock({onContinue: onContinue, onCancel: ()=>setConfirmBlock(null), header: header, text: text});
  }

  const smartTableButtonsData = (record) => {

    const links = [];
    if (certificateContinueableStauses.includes(record.status_id)) links.push(<MenuItem key={1} onClick={(event)=>clickHandler(record.id)}>Продолжить оформление</MenuItem>);
    if (certificateContinueableStauses.includes(record.status_id)) links.push(<MenuItem key={2} onClick={(event)=>calculateHandler(record.id)}>Рассчитать</MenuItem>);
    if (certificateDeleteableStauses.includes(record.status_id)) links.push(<MenuItem key={3} onClick={(event)=>openConfirmBlock(()=>deleteHandler(record.id), 'Удаление полиса '+record.id, 'Вы действительно хотите удалить этот полис?')}>Удалить</MenuItem>);
    if (certificateCancelableStatuses.includes(record.status_id)) links.push(<MenuItem key={4} onClick={(event)=>openConfirmBlock(()=>cancelHandler(record.id), 'Аннулирование полиса '+record.id, 'Вы действительно хотите аннулировать этот полис?')}>Аннулировать</MenuItem>);
    if (certificateProlongableStatuses.includes(record.status_id)) links.push(<MenuItem key={5} onClick={(event)=>prolongHandler(record.id)}>Пролонгировать</MenuItem>);
    if (certificatePrintableStatuses.includes(record.status_id)) links.push(<MenuItem key={7} onClick={() => props.onOpenDocumentsWindow(record.id)}>Печать документов</MenuItem>);
    links.push(<MenuItem key={6} onClick={(event)=>messageHandler(record.user_id)}>Сообщение агенту</MenuItem>);

    return links;
  }

  let documentsPopup = null;
  if (props.isDocumentsWindowActive && props.documentsCertificateId > 0) documentsPopup = <CertificateDocuments certificateId={props.documentsCertificateId} />

  let reportsPopup = null;
  if (props.isReportsWindowActive && props.reportWindowCertificateIds && props.reportWindowCertificateIds.length > 0) 
    reportsPopup = <ReportsWindow certificateIds={props.reportWindowCertificateIds} />

  let table = <Spinner></Spinner>;
  if (props.isCatalogsLoading) table = <Spinner text="Загружаем каталоги"></Spinner>
  //else if (props.isCertificatesLoading) table = <Spinner text="Загружаем полисы"></Spinner>
  else //if (props.certificates)
    table = <SmartTable 
      isLoading={props.isCertificatesLoading}
      tableheaders={smartTableHeaders} 
      rowCreator={smartTableColumnCreator}
      tooltipData={smartTableTooltipData}
      buttonsData={smartTableButtonsData}
      filters={smartTableFilters}
      data={props.certificates} 
      total={props.total}
      onFilter={filterHandler} 
      onRowClick={clickHandler}
      error={props.error} 
      filter={props.filter}
      order={props.order}
      minWidth={1600}
      page={props.page}
      perPage={props.perPage}
      massButtons={smartTableMassButtons}
      infoBlock={infoBlock}
      onChangeSelectedIds={(selectedIds) => createInfoBlock(selectedIds)}
    />;

  let confirmDialog = null;
  if (confirmBlock !== null)
    confirmDialog = <ConfirmDialog 
      dialogHeader={confirmBlock.header} 
      dialogText={confirmBlock.text} 
      cancelHandler={confirmBlock.onCancel} 
      continueHandler={confirmBlock.onContinue} 
    />;

  return (
    <Page
      className={classes.root}
      title="Customers"
    >
      <Container maxWidth={false}>
        {/*<Toolbar />*/}
        <Box mt={3}>
          {table}
          {confirmDialog}
          {documentsPopup}
          {reportsPopup}
        </Box>
      </Container>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
      isCertificatesLoading: state.certificates.isLoading,
      error: state.certificates.error,
      certificates: state.certificates.certificates,
      total: state.certificates.total,
      page: state.certificates.page,
      perPage: state.certificates.perPage,
      filter: state.certificates.filter,
      order: state.certificates.order,

      isCatalogsLoading: state.catalogs.isLoading,
      catalogs: state.catalogs.catalogs,

      isReportsWindowActive: state.reports.isReportsWindowOpened,
      redirectToReportId: state.reports.goToReportId,
      reportWindowCertificateIds: state.reports.reportWindowCertificateIds,

      /*reports: state.reports.available,
      reportsSuccess: state.reports.availableSuccess,
      reportsError: state.reports.availableError,
      addCertificatesError: state.reports.error,
      addCertificatesSuccess: state.reports.success,*/

      isDocumentsWindowActive: state.documents.isActive,
      documentsCertificateId: state.documents.certificateId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCertificates: (filter, page, perPage, order) => dispatch(actions.loadCertificates(filter, page, perPage, order)),
      onLoadCatalog: (catalogName) => dispatch(actions.loadCatalog(catalogName)),
      onDeleteCertificate: (certificateId) => dispatch(actions.deleteCertificate(certificateId)),
      onProlongCertificate: (certificateId) => dispatch(actions.prolongCertificate(certificateId)),
      onCancelCertificate: (certificateId) => dispatch(actions.cancelCertificate(certificateId)),

      onGeneralStart: () => dispatch(actions.generalStart()),
      onGeneralError: (error) => dispatch(actions.generalError(error)),
      onGeneralSuccess: (text) => dispatch(actions.generalSuccess(text)),

      onOpenDocumentsWindow: (certificateId) => dispatch(actions.openDocumentsWindow(certificateId)),

      onOpenReportsWindow: (certificateIds) => dispatch(actions.openReportsWindow(certificateIds)),
      onCloseReportsWindow: () => dispatch(actions.closeReportsWindow()),
      //onAddCertificatesToReport: (reportId, certificateIds) => dispatch(actions.addCertificatesToReport(reportId, certificateIds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificateListView);
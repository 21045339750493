import {put} from 'redux-saga/effects';

import * as actions from '../actions/report';
import * as reportsActions from '../actions/reports';
import { generalError, generalSuccess, generalStart } from '../actions/general';

import tokenizedAxios from '../../utils/tokenizedAxios';

import { reportSaveUrl, reportLoadUrl, reportDownloadUrl, deleteCertificateFromReportUrl, changeCertificateStatusUrl } from '../../settings/api_urls';

//import parseServerReport from './sagasUtils/parseServerReport';

export function* loadReportSaga(action, isFull = false) 
{
    if (action.reportId === 0 || action.reportId === 'new') { // Новый отчёт
        yield put(actions.initializeReport());
    } else {
        yield put(actions.loadReportStart(action.reportId));
        try {
            const response = yield tokenizedAxios(reportLoadUrl, 'get', { report_id: action.reportId, is_full: isFull, combine_fields: 0 });
            if (response.data.result === 'ok') {
                const report = response.data.report;
                //const finalReport = parseServerReport(report);
                const finalReport = report;
                yield put(actions.loadReportSuccess({report: finalReport }));
            } else {
                yield put(actions.loadReportFail(response.data.errorstring));
            }
        } catch (error) {
            yield put(actions.loadReportFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз."));
        };
    }
}

export function* saveReportSaga(action) 
{
    yield put(actions.saveReportStart());

    try {
        const dataToSend = action.data;
        dataToSend.new_api = 1; //New API for backend
        const response = yield tokenizedAxios(reportSaveUrl, 'post', dataToSend);

        if (response.data.result === 'ok') {
            const reportId = response.data.id;
            yield put(actions.saveReportSuccess({
                id: reportId,
            }));
            yield put(reportsActions.resetReports());
        } else {
            yield put(actions.saveReportFail(response.data.errorstring, response.data.fields));
        }

    } catch (error) {
        yield put(actions.saveReportFail("Ошибка соединения с сервером: "+(error.message??"Неизвестная ошибка")+". Пожалуйста, повторите попытку сохранения или обратитесь в техподдержку", null));
    };
}

export function* downloadReportSaga(action) 
{
    yield put(actions.downloadReportStart(action.reportId));
    try {
        const response = yield tokenizedAxios(reportDownloadUrl, 'get', { report_id: action.reportId });
        
        if (response.data.result === 'ok') {
            const report = response.data.file;
            yield put(actions.downloadReportSuccess({report: report }));
            window.open('/' + report, "_blank");
        } else {
            yield put(actions.downloadReportFail(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.downloadReportFail("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз."));
    };
}

export function* deleteCertificateFromReportSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(deleteCertificateFromReportUrl, 'post', {report_id: action.reportId, certificate_id: action.certificateId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Полис " + action.certificateId + " успешно удалён из отчёта " + action.reportId));
            yield put(actions.deleteCertificateFromReportSuccess(action.certificateId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}

export function* changeReportStatusSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(changeCertificateStatusUrl, 'post', {report_id: action.reportId, new_status_id: action.newStatusId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Статус полиса успешно изменён"));
            yield put(actions.changeReportStatusSuccess(response.data.status_id));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
import {put} from 'redux-saga/effects';

import * as actions from '../actions/rates';
import { generalError, generalSuccess, generalStart } from '../actions/general';
import tokenizedAxios from '../../utils/tokenizedAxios';

import { ratesUrl, ratesDeleteUrl } from '../../settings/api_urls';

export function* loadRatesSaga(action) 
{
    yield put(actions.loadStart(action.filter, action.page, action.perPage, action.order, action.mode));

    const getData = {
        ...action.filter,
        format: 1,
        fill_agent_data: 1,
        page: action.page ?? 1,
        perpage: action.perPage ?? 20,
        order: action.order ?? { "id": "DESC" },
    }

    try {
        const response = yield tokenizedAxios(ratesUrl, 'get', getData);
        if (response.data.result === 'ok') {
            const rates = {};
            for (const i in response.data.rates) rates[response.data.rates[i].id] = response.data.rates[i];
            const total = response.data.total;
            yield put(actions.loadSuccess(rates, total, action.mode));
        } else {
            yield put(actions.loadError(response.data.errorstring, action.mode));
        }
    } catch (error) {
        yield put(actions.loadError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку.", action.mode));
    };
}

export function* deleteRateSaga(action)
{
    yield put(generalStart());

    try {
        const response = yield tokenizedAxios(ratesDeleteUrl, 'post', {rate_id: action.rateId});
        if (response.data.result === 'ok') {
            yield put(generalSuccess("Комиссионного вознаграждение " + action.rateId + " успешно удалёно"));
            yield put(actions.deleteRateSuccess(action.rateId));
        } else {
            yield put(generalError(response.data.errorstring));
        }
    } catch (error) {
        yield put(generalError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
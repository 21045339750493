export const updateState = (oldObject, updatedValues) => {

    return {
        ...oldObject,
        ...updatedValues
    }

};

export function restorePhone(rawPhone) {
    if (rawPhone) {
        const phone = rawPhone.toString();
        return "+" + phone[0] + ' ' + phone[1] + phone[2] + phone[3] + ' ' + phone[4] + phone[5] + phone[6] + ' ' + phone[7] + phone[8] + phone[9] + phone[10];
    } else
        return "";
}

//Получает выбранные строки в SmartTable. Использовать только если таблица одна на странице
export function getSmartTableSelectedIds(tableName) {
    const selectedIds = localStorage.getItem('smartTableSelectedIds_' + tableName);
    return selectedIds;
}

export function fillFioFields(data, fioField) {
    const fioFieldData = fioField.trim().split(' ');
    if (fioFieldData[0]) data.name_f = fioFieldData[0];
    if (fioFieldData[1]) data.name_i = fioFieldData[1];
    if (fioFieldData[2]) data.name_o = fioFieldData[2];
    if (fioFieldData[3]) data.name_o += (" " + fioFieldData[3]);
    return data;
}
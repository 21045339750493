import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyObject from '../../utils/copyObject';

const initialNewsElement = {
    id: 0,
    short: '',
    text: '',
    active: 0,
    importance: 'default',
};

const initialState = {
    isLoading: false,
    isLoaded: false,
    loadError: false,
    news: initialNewsElement,

    isSaving: false,
    savedId: false,
    saveError: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.NEWS_ELEMENT_INITIALIZE: 
            return updateState(state, {
                isLoading: false, 
                isLoaded: false, 
                news: initialNewsElement, 
                loadError: null, 
                isSaving: false,
                savedId: false,
                saveError: null,
            });

        case actionTypes.NEWS_ELEMENT_UPDATE:
            const updatedNewsElement = copyObject(state.news);
            for (const i in action.data)
            updatedNewsElement[i] = action.data[i];
            return updateState(state, { news: updatedNewsElement });

        case actionTypes.NEWS_ELEMENT_SAVE_START: return updateState(state, {isSaving: true, saveError: null, errorFields: null});
        case actionTypes.NEWS_ELEMENT_SAVE_SUCCESS: return updateState(state, {isSaving: false, savedId: action.newsId });
        case actionTypes.NEWS_ELEMENT_SAVE_FAIL: return updateState(state, {isSaving: false, saveError: action.error, errorFields: action.fields });

        case actionTypes.NEWS_ELEMENT_LOAD_START: return updateState(state, {isLoading: true, isLoaded: false, loadError: null, errorFields: null, isSaving: false, savedId: false, saveError: null });
        case actionTypes.NEWS_ELEMENT_LOAD_SUCCESS: return updateState(state, {isLoading: false, isLoaded: true, news: action.news });
        case actionTypes.NEWS_ELEMENT_LOAD_FAIL: return updateState(state, {isLoading: false, isLoaded: false, loadError: action.error });

        default: return state;
    }
}

export default reducer;
import * as actionTypes from './actionTypes';

export const resetRates = () => { return { type: actionTypes.RATES_RESET }}

export const loadRates = (filter, page, perPage, order, mode="rates") => {
    
    if (mode==="rates")
        return {
            filter : filter,
            page : page,
            perPage : perPage,
            order: order,
            mode: mode,
            type: actionTypes.RATES_LOAD_INIT,
        }
    else if (mode==="child")
        return {
            filter : filter,
            page : page,
            perPage : perPage,
            order: order,
            mode: mode,
            type: actionTypes.CHILD_RATE_INIT,
        }
}

export const loadStart = (filter, page, perPage, order, mode="rates") => {
    if (mode==="rates")
        return {
            filter: filter,
            page: page,
            perPage: perPage,
            order: order,
            mode: mode,
            type: actionTypes.RATES_LOAD_START,
        }
    else if (mode==="child")
        return {
            mode: mode,
            type: actionTypes.CHILD_RATE_START,
        }
}

export const loadSuccess = (rates, total, mode="rates") => {
    if (mode==="rates")
        return {
            rates: rates,
            total: total,
            mode: mode,
            type: actionTypes.RATES_LOAD_SUCCESS,
        }
    else if (mode==="child")
        return {
            rates: rates,
            mode: mode,
            type: actionTypes.CHILD_RATE_SUCCESS,
        }
}

export const loadError = (error, mode="rates") => {
    if (mode==="rates")
        return {
            error: error,
            mode: mode,
            type: actionTypes.RATES_LOAD_FAIL,
        }
    else if (mode==="child")
        return {
            error: error,
            mode: mode,
            type: actionTypes.CHILD_RATE_FAIL,
        }
}


export const deleteRate = (rateId) => ({ rateId: rateId, type: actionTypes.RATES_DELETE })
export const deleteRateSuccess = (rateId) => ({ rateId: rateId, type: actionTypes.RATES_DELETE_SUCCESS })
export const deleteRateFail = (errorText) => ({ text: errorText, type: actionTypes.GENERAL_ERROR })
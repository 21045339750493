import React from 'react';
import { connect } from 'react-redux';

import Messages from './Messages';
import Dialogs from './Dialogs';

const MessagesIndex = (props) => {
  const isAdmin = props.curUser.access >= 50;

  if (isAdmin) return <Dialogs />
  else return <Messages forcedUserId={0} />
};

const mapStateToProps = state => {
  return {
      curUser: state.auth.user,
  }
}

export default connect(mapStateToProps)(MessagesIndex);
import {put} from 'redux-saga/effects';
import axios from 'axios';

import * as actions from '../actions/reset';
import { apiUrl } from '../../settings/base';
import { resetUrl } from '../../settings/api_urls';

export function* resetSaga(action)
{
    yield put(actions.resetStart());

    const resetData = {
        email: action.email,
    }

    try {
        const response = yield axios.post(apiUrl + resetUrl, resetData);
        if (response.data.result === 'ok') {
            yield put(actions.resetSuccess(response.data));
        } else {
            yield put(actions.resetError(response.data.errorstring));
        }
    } catch (error) {
        yield put(actions.resetError("Ошибка соединения с сервером. Пожалуйста, обновите страницу и попробуйте ещё раз или обратитесь в техподдержку."));
    };
}
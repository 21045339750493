import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';

const initialState = {
    isLoading: false,
    news: null,
    total: null,
    error: null,

    filter: {},
    page: 1,
    perPage: 20,
    order: {id: 'DESC'},
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.NEWS_RESET:
        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.NEWS_LOAD_START:
            const finalNewState = {isLoading: true};
            if (action.filter !== null) finalNewState.filter = action.filter;
            if (action.page !== null) finalNewState.page = action.page;
            if (action.perPage !== null) finalNewState.perPage = action.perPage;
            if (action.order !== null) finalNewState.order = action.order;
            return updateState(state, finalNewState);

        case actionTypes.NEWS_LOAD_SUCCESS: return updateState(state, {isLoading: false, news: action.news, total: action.total * 1});
        case actionTypes.NEWS_LOAD_FAIL:    return updateState(state, {isLoading: false, news: null, total: null, error: action.error });

        case actionTypes.NEWS_DELETE_SUCCESS: 
            const newNews = [];
            for (const i in state.news)
                if (state.news[i].id !== action.newsId)
                newNews.push(state.news[i]);

            return updateState(state, {isLoading: false, news: newNews, total: state.total - 1});

        default: return state;
    }
}

export default reducer;
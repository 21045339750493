import React from 'react';
import { useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { token, fioUrl } from '../../settings/dadata';

export default function FreeSolo(props) {

    const [options, setOptions] = useState([]);
    const [optionsData, setOptionsData] = useState([]);
    const [value, setValue] = useState('');
    const [error, setError] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const getPredictions = React.useMemo(
        () =>
            throttle((request, callback) => {
            
            const requestOptions = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify({query: request})
            }
            
            try {
                fetch(fioUrl, requestOptions)
                .then(response => response.json())
                .then(result => { callback(result.suggestions ?? null, null); })
                .catch(error => { callback(null, error); });
            } catch(error) {
                console.log("GLOBAL ERROR IS", error);
            }

            }, 1000),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        getPredictions( inputValue, (results, error) => {

            if (active) {
                let newOptions = [];
                if (value) {
                    newOptions = [value];
            }

            if (results) {
                const newOptionsData = {};
                for (const i in results) {
                    newOptions.push(results[i].value);
                    newOptionsData[results[i].value] = results[i].data;
                    //newOptionsData[results[i].value] = results[i].data.gender ? (results[i].data.gender === 'FEMALE' ? 2 : 1) : null;
                }
                setOptionsData(newOptionsData);
            }

            setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };

    }, [value, inputValue, getPredictions]);

//console.log("FIO CHECKER", props.helperText);

  return (
    <Grid item lg={props.fullWidth ? 12 : 8} md={props.fullWidth ? 12 : 8} xs={12} >
        <Grid container>
            <Grid item lg={8} md={8} xs={12} >
                <Autocomplete
                    freeSolo
                    fullWidth
                    disableClearable
                    options={options}
                    onChange={(event, value) => { props.onValueChange({fio: value, data: optionsData[value] ?? null }); }}
                    filterOptions={(option)=>option} // Нужно чтобы всегда показывать все опции
                    value={props.value ?? ""}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        onBlur={props.handleBlur}
                        name={props.name}
                        error={props.error}
                        helperText={props.helperText}
                        label={props.label}
                        variant="outlined"
                        required={props.required}
                        value={props.value ?? ""}
                        onChange={(event) => { props.onValueChange({fio: event.target.value}); setInputValue(event.target.value) }}
                        InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                    )}
                />
            </Grid>
            <Grid item lg={4} md={4} xs={12} style={{padding:4, textAlign:"center"}} >
                <ToggleButtonGroup
                        value={props.genderValue ?? null}
                        exclusive
                        required
                        onChange={(event, newValue) => { props.onValueChange({ gender: newValue })}}
                    >
                        <ToggleButton value={1}>Мужской</ToggleButton>
                        <ToggleButton value={2}>Женский</ToggleButton>
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    </Grid>
  );
  //(event) => props.onValueChange(event.target.value)
}
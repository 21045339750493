import * as actionTypes from './actionTypes';

export const generalStart = () => {
    return {
        type: actionTypes.GENERAL_START,
    }
}

export const generalSuccess = (text) => {
    return {
        type: actionTypes.GENERAL_SUCCESS,
        text: text
    }
}

export const generalError = (error) => {
    return {
        type: actionTypes.GENERAL_ERROR,
        error: error,
    }
}


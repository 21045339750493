import React, { useState } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Switch,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import createMenuItems from '../../../utils/createMenuItems';
import makeFirstLetterCapital from '../../../utils/makeFirstLetterCapital'
import getFormError from '../../../utils/getFormError'
import createMaskFromString from '../../../utils/createMaskFromString'

import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import ElkDadataFio from '../../../components/FormElements/ElkDadataFio';
import ElkDateSelector from '../../../components/FormElements/ElkDateSelector';
import ElkSelectInput from '../../../components/FormElements/ElkSelectInput';
import ElkSearchInput from '../../../components/FormElements/ElkSearchInput';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  right: {
    textAlign: "right",
  }
}));

const CertificateFormDriver = (props) => {

  const shouldShowPrevDocuments = (props.driver.prev_name_f??"") + (props.driver.prev_name_i??"") + (props.driver.prev_name_o??"") + (props.driver.prev_document_series??"") + (props.driver.prev_document_number??"") !== ""

  //console.log("SHOULD PREV", "" + props.driver.prev_name_f + props.driver.prev_name_i + props.driver.prev_name_o + props.driver.prev_document_series + props.driver.prev_document_number);

  const classes = useStyles();
  const [prevDocuments, setPrevDocuments] = useState(shouldShowPrevDocuments);

  let driverDocumentOptionsList = [];
  if (props.catalogsGeneral) {
    driverDocumentOptionsList = createMenuItems(Object.values(props.catalogsGeneral['document_types']).filter(documentType => documentType.type && documentType.type === 'driver'));
  }
  
  const activateDriverFio = (newValue) => {
    let genderValue = props.driver.gender;
    if (newValue && newValue.data && newValue.data.gender) {
      genderValue = newValue.data.gender === 'FEMALE' ? 2 : 1;
    } else if (newValue && newValue.gender)
      genderValue = newValue.gender;
    const fioValue = newValue.fio ? makeFirstLetterCapital(newValue.fio) : props.driver.fio;
    props.setFieldValue("driver_gender_"+props.driverId, genderValue);
    props.setFieldValue("driver_fio_"+props.driverId, fioValue);
    props.changeDriverData(props.driverId, { fio: fioValue, fio_data: newValue.data, gender: genderValue });
  }

  const activatePrevDriverFio = (newValue) => {
    let genderValue = props.driver.prev_gender;
    if (newValue && newValue.data && newValue.data.gender) {
      genderValue = newValue.data.gender === 'FEMALE' ? 2 : 1;
    } else if (newValue && newValue.gender)
      genderValue = newValue.gender;
    const fioValue = newValue.fio !== null ? makeFirstLetterCapital(newValue.fio) : props.driver.prev_fio;
    props.setFieldValue("prev_driver_gender_"+props.driverId, genderValue);
    props.setFieldValue("prev_driver_fio_"+props.driverId, fioValue);
    props.changeDriverData(props.driverId, { prev_fio: fioValue, prev_fio_data: newValue.data, prev_gender: genderValue });
  }

  let prevDocumentBlock = null;
  if (prevDocuments) {
    prevDocumentBlock = <>
      <ElkDadataFio 
        {...getFormError(["prev_fio", "prev_name_f", "prev_name_i", "prev_name_o", "prev_gender","prev_driver_fio_"+props.driverId], props.touched, props.errors, props.driverErrors)}
        label="Предыдущее ФИО" 
        name={"prev_driver_fio_"+props.driverId}
        genderName={"prev_driver_gender_"+props.driverId}
        onValueChange={(newValue) => { activatePrevDriverFio(newValue); }} 
        fullWidth={false}
        value={props.driver.prev_fio}
        genderValue={props.driver.prev_gender*1}
      />
      <ElkTextInput
        {...getFormError(["prev_document_number", "prev_document_series_number_"+props.driverId], props.touched, props.errors, props.driverErrors)}
        label="Серия и номер старого ВУ"
        name={"prev_document_series_number_"+props.driverId}
        placeholder="1234 5678901"
        setFieldValue={props.setFieldValue}
        handleBlur={props.handleBlur}
        helperText="Обязательно разделяйте серию и номер пробелом"
        onValueChange={(newValue) => { props.changeDriverData(props.driverId, { prev_document_series_number: newValue.toUpperCase() }) }} 
        //mask={[/\d/, /\d/, /[а-я0-9]/i, /[а-я0-9]/i, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
        value={props.driver.prev_document_series_number}
      />
    </>
  }

  let documentMask = React.useMemo(() => {
    let newDocumentMask = null;
    if (props.catalogsGeneral) {
      const documentInfo = props.catalogsGeneral.document_types[props.driver.document_type];
      newDocumentMask = documentInfo ? (documentInfo.mask ?? null) : null;
      if (newDocumentMask !== null) newDocumentMask = createMaskFromString(newDocumentMask);
    }
    return newDocumentMask;
  }, [props.driver.document_type, props.catalogsGeneral]);

  let documentCountry = null;
  if (props.driver.document_type*1 === 25)
    documentCountry = <ElkSearchInput 
      {...getFormError('country_code', props.touched, props.errors, props.driverErrors)}
      label="Страна выдачи ВУ"
      name="country_code" 
      onValueChange={(newValue) => { props.changeDriverData(props.driverId, { country_code: newValue }) }} 
      setFieldValue={props.setFieldValue}
      value={props.driver.country_code}
      options={ props.filterCountriesOptionsArray }
    />

  return ( 
    <Grid item lg={12} md={12} xs={12} >
      <Paper elevation={5} className={classes.paper}>
        <Grid container spacing={2} >

          <Grid item lg={9} md={8} xs={5} >
            <Typography variant="h5" gutterBottom>Водитель {props.driverId*1 + 1}</Typography>
          </Grid>
          <Grid item lg={3} md={4} xs={7} className={classes.right} >
            <Typography gutterBottom><Button startIcon={<DeleteIcon />} onClick={() => props.removeDriver(props.driverId)}>Удалить</Button></Typography>
          </Grid>

          <ElkDadataFio 
            {...getFormError(["fio", "name_f", "name_i", "name_o", "gender","driver_fio_"+props.driverId], props.touched, props.errors, props.driverErrors)}
            label="ФИО" 
            name={"driver_fio_"+props.driverId}
            genderName={"driver_gender_"+props.driverId}
            onValueChange={(newValue) => { activateDriverFio(newValue); }} 
            //required={true} 
            fullWidth={false}
            value={props.driver.fio}
            genderValue={props.driver.gender*1}
          />
          
          <ElkDateSelector 
            {...getFormError(["birth_date", ], props.touched, props.errors, props.driverErrors)}
            label="Дата рождения" 
            name={"birth_date_"+props.driverId}
            onValueChange={(newValue) => { props.changeDriverData(props.driverId, { birth_date: newValue }) }} 
            //required={true} 
            //value={prepareDate(props.driver.birth_date)}
            value={props.driver.birth_date}
            setFieldValue={props.setFieldValue}
          />

          <ElkSelectInput 
            {...getFormError(["document_type", "document_type_"+props.driverId], props.touched, props.errors, props.driverErrors)}
            label="Тип документа"
            name={"document_type_"+props.driverId}
            onValueChange={(newValue) => { props.changeDriverData(props.driverId, { document_type: newValue }) }} 
            //required={true} 
            setFieldValue={props.setFieldValue}
            value={props.driver.document_type}
            options={ driverDocumentOptionsList }
          /> 

          {documentCountry}

          <ElkTextInput
            {...getFormError(["document_number", "document_series_number_"+props.driverId], props.touched, props.errors, props.driverErrors)}
            label="Серия и номер документа"
            name={"document_series_number_"+props.driverId}
            //placeholder=""
            setFieldValue={props.setFieldValue}
            handleBlur={props.handleBlur}
            onValueChange={(newValue) => { props.changeDriverData(props.driverId, { document_series_number: newValue.toUpperCase() }) }} 
            //required={true} 
            mask={documentMask}
            value={props.driver.document_series_number}
          />

          <ElkDateSelector 
            {...getFormError(["document_from", "document_from_"+props.driverId], props.touched, props.errors, props.driverErrors)}
            label="Дата выдачи документа" 
            name={"document_from_"+props.driverId}
            onValueChange={(newValue) => { props.changeDriverData(props.driverId, { document_from: newValue }) }} 
            //required={true} 
            //value={prepareDate(props.driver.document_from)}
            value={props.driver.document_from}
            setFieldValue={props.setFieldValue}
          />

          <ElkDateSelector 
            {...getFormError(["experience_from", "experience_from_"+props.driverId], props.touched, props.errors, props.driverErrors)}
            label="Дата выдачи первого ВУ" 
            name={"experience_from_"+props.driverId}
            onValueChange={(newValue) => { props.changeDriverData(props.driverId, { experience_from: newValue }) }} 
            //required={true} 
            //value={prepareDate(props.driver.experience_from)}
            value={props.driver.experience_from}
            setFieldValue={props.setFieldValue}
          />

          <Grid item lg={4} md={4} xs={12} >
            <FormControlLabel
              style={{marginLeft:0}}
              control={<Switch checked={prevDocuments === true} onChange={(event, newValue) => { setPrevDocuments(newValue) }} name={"prevDocument" + (props.driverId*1 + 1)} />}
              label="Указать данные предыдущего ВУ"
            />
          </Grid>

          {prevDocumentBlock}

        </Grid>
      </Paper> 
    </Grid>
  );
};


export default CertificateFormDriver;

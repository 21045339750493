import React from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core';
import Spinner from '../../components/Spinner';
import Alert from '@material-ui/lab/Alert';
import draftToHtml from 'draftjs-to-html';
import Header from '../../components/Header';

const useStyles = makeStyles(({
  root: {
    //height: '100%'
  },
  image: {
    height: 48,
    width: 48
  }
}));

const RecentNews = ({ className, ...rest }) => {
  const classes = useStyles();

  const newsBlocks = [];

  if (rest.error) {
    newsBlocks.push(<Alert key="error" severity="error">{rest.error}</Alert>)
  }
  else if (rest.isLoading)
    newsBlocks.push(<Spinner key="loading" text="Загружаем новости" />)
  else {
    for (const i in rest.news) {

      const element = rest.news[i];
      let shortText = element.short;
      let fullText = null;
      if (element.text != "")
        fullText = <Grid item xs={12}><Typography dangerouslySetInnerHTML={{ __html: draftToHtml(JSON.parse(element.text)) }}></Typography></Grid>
      let moreButton = null;
      /*if (shortText.length > 197 || element.text !== "") {
        if (shortText.length > 197) shortText = shortText.substring(0,197) + "...";
        moreButton = <Link to={"/app/news/" + element.id}><Button size="small">Подробнее</Button></Link>;
      }*/

      newsBlocks.push(<ListItem divider={i < rest.news.length - 1} key={i} >
          <Grid container>
            <Grid item xs={12}>
              <ListItemText
                primary={shortText}
                //secondary={fullText}
                style={{width:"100%", fontWeight:"bold", marginBottom: "10px"}}
              />
            </Grid>
            {fullText}
            <Grid item xs={12} style={{textAlign:"right"}}>
              {moreButton}
            </Grid>
          </Grid>
        </ListItem>
      );

    }
  }

  return <>
    <Header title="Последние новости" />
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <List>{newsBlocks.length > 0 ? newsBlocks : <ListItem><Grid container><Grid item xs={12}><Typography>Новостей нет</Typography></Grid></Grid></ListItem>}</List>
    </Card>
  </>;
};

RecentNews.propTypes = {
  className: PropTypes.string
};

export default RecentNews;

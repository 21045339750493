import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';
import copyArray from '../../utils/copyArray';

const initialState = {
    isLoading: false,
    dialogs: null,
    total: null,
    error: null,

    filter: {},
    page: 1,
    perPage: 10000,

    isNewMessagesLoading: false,
    newMessages: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.DIALOGS_RESET:
        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.DIALOGS_LOAD_START:
            const finalNewState = {isLoading: true};
            if (action.filter !== null) finalNewState.filter = action.filter;
            if (action.page !== null) finalNewState.page = action.page;
            if (action.perPage !== null) finalNewState.perPage = action.perPage;
            return updateState(state, finalNewState);

        case actionTypes.DIALOGS_LOAD_SUCCESS: 
            let newDialogs;
            if (state.dialogs && state.page > 1) newDialogs = copyArray(state.dialogs);
            else newDialogs = [];
            for (const i in action.dialogs)
                newDialogs.push(action.dialogs[i]);

            return updateState(state, {isLoading: false, dialogs: newDialogs, total: action.total * 1});

        case actionTypes.DIALOGS_LOAD_FAIL:return updateState(state, {isLoading: false, dialogs: null, total: null, error: action.error });

        case actionTypes.NEW_MESSAGES_LOAD_START: return updateState(state, {isNewMessagesLoading: true});
        case actionTypes.NEW_MESSAGES_LOAD_SUCCESS: return updateState(state, {isNewMessagesLoading: false, newMessages: action.newMessages});
        case actionTypes.NEW_MESSAGES_REDUCE: return updateState(state, {newMessages: state.newMessages - action.amount*1});

        default: return state;
    }
}

export default reducer;
import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';

const initialState = {
    counters: null,
    countersLoading: false,
    countersError: null,

    prolongationCertificates: null,
    prolongationCertificatesLoading: false,
    prolongationCertificatesError: null,

    statistics: null,
    statisticsLoading: false,
    statisticsError: null,

    createdCertificates: null,
    createdCertificatesLoading: false,
    createdCertificatesError: null,

    newCertificates: null,
    newCertificatesLoading: false,
    newCertificatesError: null,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.DASHBOARD_COUNTERS_START:   return updateState(state, {countersLoading: true});
        case actionTypes.DASHBOARD_COUNTERS_SUCCESS: return updateState(state, {countersLoading: false, counters: action.counters});
        case actionTypes.DASHBOARD_COUNTERS_FAIL:    return updateState(state, {countersLoading: false, counters: null, countersError: action.error });

        case actionTypes.DASHBOARD_PROLONG_CERTIFICATES_START:   return updateState(state, {prolongationCertificatesLoading: true});
        case actionTypes.DASHBOARD_PROLONG_CERTIFICATES_SUCCESS: return updateState(state, {prolongationCertificatesLoading: false, prolongationCertificates: action.certificates});
        case actionTypes.DASHBOARD_PROLONG_CERTIFICATES_FAIL:    return updateState(state, {prolongationCertificatesLoading: false, prolongationCertificates: null, prolongationCertificatesError: action.error });

        case actionTypes.DASHBOARD_STATISTICS_START:   return updateState(state, {statisticsLoading: true});
        case actionTypes.DASHBOARD_STATISTICS_SUCCESS: return updateState(state, {statisticsLoading: false, statistics: action.statistics});
        case actionTypes.DASHBOARD_STATISTICS_FAIL:    return updateState(state, {statisticsLoading: false, statistics: null, statisticsError: action.error });

        case actionTypes.DASHBOARD_CREATED_CERTIFICATES_START:   return updateState(state, {createdCertificatesLoading: true});
        case actionTypes.DASHBOARD_CREATED_CERTIFICATES_SUCCESS: return updateState(state, {createdCertificatesLoading: false, createdCertificates: action.certificates});
        case actionTypes.DASHBOARD_CREATED_CERTIFICATES_FAIL:    return updateState(state, {createdCertificatesLoading: false, createdCertificates: null, createdCertificatesError: action.error });

        case actionTypes.DASHBOARD_NEW_CERTIFICATES_START:   return updateState(state, {newCertificatesLoading: true});
        case actionTypes.DASHBOARD_NEW_CERTIFICATES_SUCCESS: return updateState(state, {newCertificatesLoading: false, newCertificates: action.certificates});
        case actionTypes.DASHBOARD_NEW_CERTIFICATES_FAIL:    return updateState(state, {newCertificatesLoading: false, newCertificates: null, newCertificatesError: action.error });

        default: return state;
    }
}

export default reducer;
import * as actionTypes from './actionTypes';

export const resetStart = () => {
    return { type: actionTypes.AUTH_RESET_START }
}

export const resetSuccess = (data) => {
    return {
        userId: data.userId,
        type: actionTypes.AUTH_RESET_SUCCESS
    }
}

export const resetError = (error) => {
    return {
        type: actionTypes.AUTH_RESET_FAIL,
        error: error,
    }
}

export const reset = (email) => {
    return {
        type: actionTypes.AUTH_RESET,
        email: email,
    }
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Switch,
  Typography
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ruLocale from "date-fns/locale/ru";
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Plus as PlusIcon } from 'react-feather';
import Alert from '@material-ui/lab/Alert';

import copyObject from '../../../utils/copyObject';
import copyArray from '../../../utils/copyArray';
import clearSpaces from '../../../utils/clearSpaces';
import correctCarNumber from '../../../utils/correctCarNumber';
import createMenuItems from '../../../utils/createMenuItems';
import bigRussianLettersAndDigits from '../../../utils/bigRussianLettersAndDigits';

import dateValidation from '../../../utils/yup/dateValidation';
import oneOfValidation from '../../../utils/yup/oneOfValidation';
import carIdValidation from '../../../utils/yup/carIdValidation';

import ElkTextInput from '../../../components/FormElements/ElkTextInput';
import ElkDateSelector from '../../../components/FormElements/ElkDateSelector';
import ElkSearchInput from '../../../components/FormElements/ElkSearchInput';
import ElkSelectInput from '../../../components/FormElements/ElkSelectInput';
import ProfinsCarNumber from '../../../components/FormElements/ProfinsCarNumber';

import CertificateDocuments from '../CertificateDocuments';

import Spinner from '../../../components/Spinner';

import CertificateFormPerson from './CertificateFormPerson';
import CertificateFormDriver from './CertificateFormDriver';
import CertificateFormPeriod from './CertificateFormPeriod';
import { prepareCertificate } from './CertificateController';
import BackupBlock from './BackupBlock';

import * as actions from '../../../store/actions';
import getFormError from '../../../utils/getFormError';

import { carNumberLetters, russianLetterOrNumber } from '../../../settings/masks';
import { certificatePrintableStatuses } from '../../../settings/profins';
import onlyDigits from '../../../utils/onlyDigits';
import onlyDigitsAndLetters from '../../../utils/onlyDigitsAndLetters';
import createMaskFromString from '../../../utils/createMaskFromString'

const useStyles = makeStyles((theme) => ({
  root: {},
  certificateBox: {
    marginTop: "20px"
  },
  marginBottom: {
    marginBottom: "10px"
  },
  center: {
    textAlign: "center",
  },
  saveSpinner: {
    marginRight: "10px"
  },
  marginRight: {
    marginRight: "20px"
  },
  error: {
    color: "#f44336"
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  greenCorridor: {
    backgroundColor: "#edf7ed",
    //color: "#FFFFFF",
  }
}));

const CertificateForm = ({ className, certificate, certificateId, prolongMode, ...rest }) => {

  const [errorLogOpened, setErrorLogOpened] = useState(false);

  const [filterBrandsOptionsArray, setFilterBrandsOptionsArray] = useState([{id: 0, name: ""}]);
  const [filterCountriesOptionsArray, setFilterCountriesOptionsArray] = useState([{id: 0, name: ""}]);
  const [filterModelsOptionsArray, setFilterModelsOptionsArray] = useState([{id: 0, name: ""}]);

  //const [companyOptionsList, setCompanyOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [excompanyOptionsList, setExcompanyOptionsList] = useState([{id: 0, name: ""}]);
  const [carTaskOptionsList, setCarTaskOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [carTypesOptionsList, setCarTypesOptionsList] = useState([<MenuItem key={2} value={2} ></MenuItem>]);
  const [carDocumentTypeOptionsList, setCarDocumentTypeOptionsList] = useState([<MenuItem key={11} value={11} ></MenuItem>]);
  const [typeOptionsList, setTypeOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [periodOptionsList, setPeriodOptionsList] = useState([<MenuItem key={12} value={12} ></MenuItem>]);
  const [personDocumentOptionsList, setPersonDocumentOptionsList] = useState([<MenuItem key={1} value={1} ></MenuItem>]);
  const [juridicalOptionsList, setJuridicalOptionsList] = useState([<MenuItem key={2} value={2} ></MenuItem>]);

  const certificateErrors = {};
  if (rest.errorFields) {
    for (const i in rest.errorFields) 
      certificateErrors[i] = rest.errorFields[i];
  }

  const carNumber = certificate.car_number;
  const carVinNumber = certificate.car_vin_number;
  const onLoadCarInfo = rest.onLoadCarInfo;
  useEffect(() => {
    return; //TODO Activate back
    if (carVinNumber === '') {
      let clearedNumber = clearSpaces(carNumber);
      clearedNumber = clearedNumber.replace(/_/ug,'');
      if (clearedNumber.length === 9) {
        onLoadCarInfo(clearedNumber);
      }
    }
  },[onLoadCarInfo, carNumber, carVinNumber]);

  const classes = useStyles();
  const navigate = useNavigate();

  const onLoadCatalog = rest.onLoadCatalog;
  const onLoadCertificate = rest.onLoadCertificate;
  const catalogsGeneral = rest.catalogs['general'] ?? false;
  const catalogsRegions = rest.catalogs['regions'] ?? false;
  const catalogBrands = rest.catalogs['brands'] ?? false;
  const catalogCountries = rest.catalogs['countries'] ?? false;
  //const catalogsCompanies = rest.catalogs['companies'] ?? false;
  const catalogsExcompanies = rest.catalogs['excompanies'] ?? false;
  useEffect(() => {
    if (!catalogsGeneral) onLoadCatalog('general')
    else if (!catalogsRegions) onLoadCatalog('regions')
    //else if (!catalogsCompanies) onLoadCatalog('companies')
    else if (!catalogsExcompanies) onLoadCatalog('excompanies')
    else if (!catalogBrands) onLoadCatalog('brands')
    else if (!catalogCountries) onLoadCatalog('countries')
    else if (certificate.id*1 !== certificateId*1 && !rest.isCertificateLoading && !rest.certificateError) {
      onLoadCertificate(certificateId, prolongMode);
    }

    //Заполняем каталоги в опции
    if (catalogBrands) {
      const newFilterBrandsOptionsArray = [];
      if (catalogBrands)
        for (const i in catalogBrands)
          newFilterBrandsOptionsArray.push({id: i, name: catalogBrands[i].name});
      setFilterBrandsOptionsArray(newFilterBrandsOptionsArray);
    }
    //Заполняем каталоги в опции
    if (catalogCountries) {
      const newFilterCountriesOptionsArray = [];
      if (catalogCountries)
        for (const i in catalogCountries)
          newFilterCountriesOptionsArray.push({id: i, name: catalogCountries[i].name});
      setFilterCountriesOptionsArray(newFilterCountriesOptionsArray);
    }
    if (catalogsGeneral) {
      let newCarTaskOptionsList = [];
      let newCarTypesOptionsList = [];
      let newCarDocumentTypeOptionsList = [];
      let newTypeOptionsList = [];
      let newPeriodOptionsList = [];
      let newPersonDocumentOptionsList = [];
      let newJuridicalOptionsList = [];
      if (catalogsGeneral) {
        newCarTaskOptionsList = createMenuItems(catalogsGeneral['car_tasks']);
        newCarTypesOptionsList = createMenuItems(catalogsGeneral['car_types']);
        newCarDocumentTypeOptionsList = createMenuItems(Object.values(catalogsGeneral['document_types']).filter(documentType => documentType.type && documentType.type === 'car'));
        newPersonDocumentOptionsList = createMenuItems(Object.values(catalogsGeneral['document_types']).filter(documentType => documentType.type && documentType.type === 'person'));
        newTypeOptionsList = createMenuItems(catalogsGeneral['periods']);
        newPeriodOptionsList = createMenuItems(Object.values(catalogsGeneral['periods_options']).filter(option => option.type && option.type*1 === certificate.period*1));
        newJuridicalOptionsList = createMenuItems(catalogsGeneral['jur_forms']);
      }
      setCarTaskOptionsList(newCarTaskOptionsList);
      setCarDocumentTypeOptionsList(newCarDocumentTypeOptionsList);
      setCarTypesOptionsList(newCarTypesOptionsList);
      setPersonDocumentOptionsList(newPersonDocumentOptionsList);
      setTypeOptionsList(newTypeOptionsList);
      setPeriodOptionsList(newPeriodOptionsList);
      setJuridicalOptionsList(newJuridicalOptionsList);
    }
    /*if (catalogsCompanies) {
      let newCompanyOptionsList = [];
      newCompanyOptionsList = createMenuItems(catalogsCompanies, <MenuItem key={0} value={0} >Не указана</MenuItem>);
      newCompanyOptionsList.push(<MenuItem key={9999} value={9999} >Иная компания</MenuItem>)
      setCompanyOptionsList(newCompanyOptionsList);
    }*/
    if (catalogsExcompanies) {
      const newExcompanyOptionsList = [{id: 0, name: "Не указана"}];
      if (catalogsExcompanies)
        for (const i in catalogsExcompanies)
          newExcompanyOptionsList.push({id: catalogsExcompanies[i].id, name: catalogsExcompanies[i].name});
      setExcompanyOptionsList(newExcompanyOptionsList);
    }

  }, [catalogsGeneral, catalogsRegions/*, catalogsCompanies*/, catalogsExcompanies, catalogBrands, catalogCountries, onLoadCatalog, rest.certificateError, certificate.id, certificateId, onLoadCertificate, certificate.period, rest.isCertificateLoading]);

  const clearLocalCopy = () => { localStorage.removeItem('certificateBackup'); }
  const storeLocalCopy = (certificateToStore) => { localStorage.setItem('certificateBackup', JSON.stringify(certificateToStore)); }
  const resetBackup = () => { clearLocalCopy(); onLoadCertificate(0); }

  const toggleErrorOpened = () => {
    setErrorLogOpened(errorLogOpened === true ? false : true);
  }

  const catalogModels = rest.catalogs['models'] ?? false;
  useEffect(() => {
    if (certificate.car_brand_id > 0) {
      if (!catalogModels || !catalogModels[certificate.car_brand_id]) 
        onLoadCatalog('models', certificate.car_brand_id)
    }
    if (catalogModels) {
      const newFilterModelsOptionsArray = [{id: 0, name: ""}];
      if (catalogModels && catalogModels[certificate.car_brand_id])
        for (const i in catalogModels[certificate.car_brand_id])
          newFilterModelsOptionsArray.push({id: i, name: catalogModels[certificate.car_brand_id][i].name});
      setFilterModelsOptionsArray(newFilterModelsOptionsArray);
    }
  }, [catalogModels, certificate.car_brand_id, onLoadCatalog]);

  const onRedirectToCalculate = rest.onRedirectToCalculate;
  useEffect(() => {
    if (rest.savedId && rest.savedId.id*1 > 0) {
      onRedirectToCalculate();
      clearLocalCopy();
      navigate("/app/certificates/" + rest.savedId.id + "/preview");
    }
  }, [rest.savedId, navigate, certificateId, onRedirectToCalculate]);

  function parseNumber (enteredNumber) {
    enteredNumber = correctCarNumber(enteredNumber);
    updateCertificateData({ car_number: enteredNumber });
  }

  const updateCertificateData = (updatedFields) => {
    let newCertificateData = copyObject(certificate);
    for (const i in updatedFields)
      newCertificateData[i] = updatedFields[i];
    if (certificateId === 0 || certificateId === "new") storeLocalCopy(newCertificateData);
    rest.onUpdateCertificate(newCertificateData);
  }

  const handleCarBrandSelected = (newBrandId) => {
    if (newBrandId !== certificate.car_brand_id) {
      updateCertificateData({ car_brand_id: newBrandId, car_model_id: null });
    }
  }

  const changePersonData = (personVariant, dataToChange) => {
    const certificateUpdatedFields = {};
    for (const i in dataToChange)
      certificateUpdatedFields[personVariant + "_" + i] = dataToChange[i];
    updateCertificateData(certificateUpdatedFields);
  }

  const changeDriverData = (driverId, updatedFields) => {
    let newDriverData = copyArray(certificate.drivers);
    for (const i in updatedFields)
      newDriverData[driverId][i] = updatedFields[i];
    updateCertificateData({drivers: newDriverData});
  }

  const removeDriver = (driverId) => {
    let newDriverData = copyArray(certificate.drivers);
    newDriverData.splice(driverId,1);
    updateCertificateData({drivers: newDriverData});
  }

  const addDriver = (type = null) => {
    const newDriver = {
      fio: '',
      gender: 0,
      name_f: '',
      name_i: '',
      name_o: '',
      birth_date: '',
      document_type: 3,
      document: '',
      document_from: '',
      experience_from: '',
      country_code: '',
    }
    if (type != null) {
      newDriver.fio = certificate[type + "_fio"];
      newDriver.gender = certificate[type + "_gender"];
      newDriver.name_f = certificate[type + "_name_f"];
      newDriver.name_i = certificate[type + "_name_i"];
      newDriver.name_o = certificate[type + "_name_o"];
      newDriver.birth_date = certificate[type + "_birth_date"];
      newDriver.country_code = certificate[type + "_country_code"];
    }
    const certificateDrivers = certificate.drivers ?? [];
    certificateDrivers.push(newDriver);
    updateCertificateData({drivers: certificateDrivers});
  }


  const changePeriodData = (periodId, updatedFields) => {
    let newPeriodData = copyArray(certificate.periods);
    for (const i in updatedFields)
      newPeriodData[periodId][i] = updatedFields[i];
    updateCertificateData({periods: newPeriodData});
  }

  const changeStartPeriod = (minDate) => {
    let newPeriodData = copyArray(certificate.periods);
    if (newPeriodData.length > 0) newPeriodData[0].start = minDate;
    updateCertificateData({ date_start: minDate, periods: newPeriodData });
  }

  const removePeriod = (periodId) => {
    let newPeriodData = copyArray(certificate.periods);
    newPeriodData.splice(periodId,1);
    updateCertificateData({periods: newPeriodData});
  }

  const addPeriod = () => {
    const certificatePeriods = certificate.periods ?? [];
    let start = '';
    if (certificatePeriods.length === 0) start = certificate.date_start;
    const newPeriod = {
      start: start.toString(),
      months: 3,
    }
    if (certificatePeriods.length < 3) {
      certificatePeriods.push(newPeriod);
      updateCertificateData({periods: certificatePeriods});
    }
  }

  
  const saveCertificate = (prolongMode) => {
    const dataToSend = prepareCertificate(certificate, prolongMode);
    rest.onSaveCertificate(dataToSend);
  }

  const clientIsJur = certificate.client_is_jur;
  const ownerIsJur = certificate.owner_is_jur;
  const yupValidationShape = React.useMemo(() => {

    // GENERAL VALIDATION
    let yupValidationShape = {
      car_number: Yup.string()/*.required("Пожалуйста, укажите госномер авто")*/.test('full', 'Укажите корректный госномер', function(value) {
        return value !== null && value !== false && (clearSpaces(bigRussianLettersAndDigits(value)).length === 0 || clearSpaces(bigRussianLettersAndDigits(value)).length > 6);
      }),
      car_brand_id: oneOfValidation(filterBrandsOptionsArray, true, 'id', 'Выберите марку авто'),
      car_model_id: oneOfValidation(filterModelsOptionsArray, true, 'id', 'Выберите модель авто'),
      car_year: Yup.string().required('Пожалуйста, укажите год выпуска авто').test('year', 'Укажите корректный год выпуска авто', function(value) {
        const correctedYear = onlyDigits(value);
        const curYear = (new Date()).getFullYear();
        if (correctedYear < curYear - 200 || correctedYear > curYear) return false;
        return true;
      }),
      car_power: Yup.string().required('Пожалуйста, укажите мощность').test('power', 'Укажите корректную мощность авто. Например, 90.5', function(value) {
        if (isNaN(value)) return false;
        //if (value < 10 || value > 300) return false;
        return true;
      }),
      ...carIdValidation(certificate.car_number_type),
      car_task_id: oneOfValidation(catalogsGeneral['car_tasks'], true, 'id', 'Выберите цель использования авто'),
      car_document_type: oneOfValidation(catalogsGeneral['document_types'], true, 'id', 'Выберите тип документа на авто'),
      car_document_number: Yup.string().max(255).required('Укажите номер документа на авто').test('carDocument', 'Укажите корректный номер документа на авто', function(value) {
        return onlyDigitsAndLetters(value).length === 10 || onlyDigitsAndLetters(value).length === 15;
      }),
      car_document_date: dateValidation(true, 'past', 50, 0, "Пожалуйста, укажите дату выдачи документа на авто"),

      period: oneOfValidation(catalogsGeneral['periods'], true, 'id', 'Укажите тип договора'),
      season_months: oneOfValidation(catalogsGeneral['periods_options'], true, 'id', 'Укажите срок действия договора'),
      date_start: dateValidation(true, 'none', 0, 0, "Укажите дату начала действия договора"),
    }

    for (const personTypeId in ['client', 'owner']) {
      const personType = personTypeId*1 === 0 ? 'client' : 'owner';

      // Если страхователь сопадает с собственником, то пропускаем все проверки страхователя
      if (personType === 'client' || !certificate.owner_is_client) {

        // GENERAL PERSON VALIDATION //
        yupValidationShape[personType + "_address"] = Yup.string().max(255).required('Укажите адрес').test('chosen','Выберите адрес из выпадающего списка', function(value) {
          //console.log("CERTIFICATE IS", certificate);
          if (personType === 'client') return (certificate.client_address_data || certificate.client_house_name) ? true : false;
          if (personType === 'owner') return (certificate.owner_address_data || certificate.owner_house_name) ? true : false;
        });

        yupValidationShape[personType + "_email"] = Yup.string().email('Укажите корректный E-mail').max(255).required('Пожалуйста, укажите E-mail');
        yupValidationShape[personType + "_phone"] = Yup.string().required('Пожалуйста, укажите телефон').test('phone', 'Укажите корректный телефон', function(value) {
          return onlyDigits(value).length === 11;
        });

        if ((personType === 'client' && clientIsJur*1 === 0) || (personType === 'owner' && ownerIsJur*1 === 0)) {
          yupValidationShape[personType + "_is_ip"] = Yup.number().oneOf([0,1],"Ошибка указания ИП");
          yupValidationShape[personType + "_fio"] = Yup.string().max(255).required('Пожалуйста, укажите ФИО').test('2 parts', 'Укажите фамилию, имя и отчество (если есть)', function(value) {
            return value && (value.trim().split(" ").length >= 2);
          });
          yupValidationShape[personType + "_gender"] = Yup.number().oneOf([1,2],"Укажите пол");
          yupValidationShape[personType + "_birth_date"] = dateValidation(true, 'past', 100, 0, "Пожалуйста, укажите дату рождения");
          yupValidationShape[personType + "_document_type"] = oneOfValidation(catalogsGeneral['document_types'], true, 'id', 'Выберите тип документа');
          yupValidationShape[personType + "_document_series_number"] = Yup.string().max(255).required('Укажите серию и номер документа');
          
          // NEW CERT ADDITIONAL VALIDATION //
          if (certificate.prolong_company_id === 0) {
            yupValidationShape[personType + "_document_date"] = dateValidation(true, 'past', 100, 0, "Пожалуйста, укажите дату выдачи документа");
            yupValidationShape[personType + "_document_place"] = Yup.string().max(255).required('Укажите место выдачи документа');
            yupValidationShape[personType + "_document_code"] = Yup.string().max(255).required('Укажите код подразделения');
          }

        } else {
          yupValidationShape[personType + "_name"] = Yup.string().max(255).required('Укажите наименование юрлица');
          yupValidationShape[personType + "_inn"] = Yup.string().max(255).required('Пожалуйста, укажите ИНН').test('size', 'Номер ИНН некорректен', (value) => onlyDigits(value).length === 10)
          yupValidationShape[personType + "_kpp"] = Yup.string().max(255).required('Пожалуйста, укажите КПП').test('size', 'Номер КПП некорректен', (value) => onlyDigits(value).length === 9)
          yupValidationShape[personType + "_ogrn"] = Yup.string().max(255).required('Пожалуйста, укажите ОГРН').test('size', 'Номер ОГРН некорректен', (value) => onlyDigits(value).length === 13)
          yupValidationShape[personType + "_form"] = oneOfValidation(catalogsGeneral['jur_forms'], true, 'id', 'Выберите организационно-правовую форму юрлица из списка')
          yupValidationShape[personType + "_cert_number"] = Yup.string().max(255).required('Укажите серию и номер свидетельства о регистрации юрлица');
          yupValidationShape[personType + "_cert_date"] = dateValidation(true, 'past', 100, 0, "Пожалуйста, укажите дату выдачи свидетельства о регистрации юрлица");

          // NEW CERT ADDITIONAL VALIDATION //
          if (certificate.prolong_company_id === 0) {
            //NONE
          }

        }
      }
    }

    /*if (certificate.car_year*1>1000 && certificate.car_year*1 < (new Date()).getFullYear() - 3) {
      yupValidationShape.car_diagnost_number = Yup.string().max(255).required('Авто старше 3 лет, указание диагностической карты обязательно').test('size', 'Номер диагностической карты некорректен', (value) => onlyDigits(value).length === 15)
      yupValidationShape.car_diagnost_date = dateValidation(true, 'future', 0, 0, 'Авто старше 3 лет, указание срока окончания действия диагностической карты обязательно');
    }*/

    if (certificate.car_category_id === "C") yupValidationShape.car_max_weight = Yup.number().required('Для категории C обязательно указание максимально разрешённой массы');
    if (certificate.car_category_id === "D") yupValidationShape.car_seats = Yup.number().required('Для категории D обязательно указание числа мест');

    // NEW CERT ADDITIONAL VALIDATION //
    if (certificate.prolong_company_id === 0) {
      
    }

    return yupValidationShape;
  }, [filterBrandsOptionsArray, filterModelsOptionsArray, certificate.prolong_company_id, certificate.car_number_type, certificate.car_year, catalogsGeneral, clientIsJur, ownerIsJur, certificate.client_address_data, certificate.owner_address_data, certificate.client_house_name, certificate.owner_house_name, certificate.owner_is_client, certificate.car_category_id]);

  let backdrop = null;
  //if (rest.isCatalogsLoading) return <Spinner text="Загружаем каталоги"></Spinner>;
  if (rest.isCertificateLoading) backdrop = <Backdrop className={classes.backdrop} open={true}><Spinner text="Загружаем данные полиса" /></Backdrop>

  let documentsPopup = null;
  if (rest.isDocumentsWindowActive && certificate.id > 0) documentsPopup = <CertificateDocuments certificateId={certificate.id} />

  const carDocumentType = certificate.car_document_type;
  let carDocumentMask = React.useMemo(() => {
    let newDocumentMask = null;
    if (catalogsGeneral) {
      const documentInfo = catalogsGeneral.document_types[carDocumentType];
      newDocumentMask = documentInfo ? (documentInfo.mask ?? null) : null;
      if (newDocumentMask !== null) newDocumentMask = createMaskFromString(newDocumentMask);
    }
    return newDocumentMask;
  }, [carDocumentType, catalogsGeneral]);

  //console.log("CAR DOC MASK IS", carDocumentMask);

  //if ((/*!rest.isCatalogsLoading &&*/ !rest.isCertificateLoading))

  if (!rest.certificateError)
    return <>
      <Formik
        className={clsx(classes.root, className)}
        initialValues={certificate}
        enableReinitialize={rest.isCertificateLoaded }
        onSubmit={ (values) => { saveCertificate(prolongMode);  } }
        validationSchema={ Yup.object().shape(yupValidationShape) }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => { 
          
          //console.log("TOUCHED ERRORS", rest.isCertificateLoaded ? "DA" : "NET" /*, touched*/, errors, values, certificate);
          //console.log("FORMIK START");

          const driversBlocks = [];
          for (const i in certificate.drivers) {
            //console.log("CREATING DRIVER ",i);
            driversBlocks.push(
              <CertificateFormDriver 
                driverErrors={certificateErrors.drivers ? (certificateErrors.drivers[i] ?? {}) : {}}
                key={i} 
                catalogsGeneral={catalogsGeneral}
                filterCountriesOptionsArray={filterCountriesOptionsArray}
                driver={certificate.drivers[i]} 
                driverId={i} 
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                changeDriverData={(driverId, dataToChange) => changeDriverData(driverId, dataToChange) } 
                removeDriver={(driverId) => removeDriver(driverId) }
                certificateErrors={certificateErrors}
              />
            );
          }

          const periodsBlocks = [];
          for (const i in certificate.periods) {
            periodsBlocks.push(
              <CertificateFormPeriod 
                periodErrors={certificateErrors.periods ? (certificateErrors.periods[i] ?? {}) : {}}
                key={i} 
                catalogsGeneral={catalogsGeneral}
                period={certificate.periods[i]} 
                periodId={i} 
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                changePeriodData={(periodId, dataToChange) => changePeriodData(periodId, dataToChange) } 
                removePeriod={(periodId) => removePeriod(periodId) }
                certificateErrors={certificateErrors}
              />
            );
          }

          let periodsError = null;
          if (certificateErrors.periods) 
            periodsError = <Grid item lg={12} md={12} xs={12} ><Alert severity="error">{certificateErrors.periods}</Alert></Grid>

          let ownerBlock = null;
          if (!certificate.owner_is_client) {
            ownerBlock = <><Divider/><CardContent><CertificateFormPerson
              certificate={certificate}
              personVariant="owner"
              personDocumentOptionsList={personDocumentOptionsList}
              juridicalOptionsList={juridicalOptionsList}
              personLabel="Собственник"
              catalogsGeneral={ catalogsGeneral }
              changeData={ (dataToChange) => changePersonData("owner", dataToChange) }
              touched={touched}
              errors={errors}
              certificateErrors={certificateErrors}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
            /></CardContent></>
          }

          let carInfoHelperText = null;
          let carInfoErrorText = null;
          if (rest.isCarInfoLoading) carInfoHelperText = 'Загружаем данные об авто...';
          else if (rest.carInfoError) carInfoErrorText = rest.carInfoError;
          else if (rest.carInfoSuccess) carInfoHelperText = rest.carInfoSuccess;

          //console.log("DATE END TEST1", certificate.season_months > 0);
          //console.log("DATE END TEST2", catalogsGeneral['periods_options'] && catalogsGeneral['periods_options'][certificate.season_months]);
          //console.log("DATE END TEST3", certificate.date_start, moment(certificate.date_start, "YYYY-MM-DD", true),  moment(certificate.date_start, "YYYY-MM-DD", true).isValid());

          let dateEndText = "Дата окончания будет расчитана после указания даты начала";
          if (certificate.season_months > 0 && catalogsGeneral['periods_options'] && catalogsGeneral['periods_options'][certificate.season_months] && certificate.date_start && moment(certificate.date_start, "DD.MM.YYYY", true).isValid()) {
            let endDate = moment(certificate.date_start, 'DD.MM.YYYY');
            const periodInfo = catalogsGeneral['periods_options'][certificate.season_months];
            if (endDate.isValid()) {
              if (periodInfo.days && periodInfo.days > 0) endDate.add(periodInfo.days, 'days');
              if (periodInfo.months && periodInfo.months > 0) endDate.add(periodInfo.months, 'months');
              dateEndText = "Дата окончания: " + endDate.format("DD.MM.YYYY");
            }
          }

          let sameAsOwnerButton = null;
          if (certificate.owner_is_client*1 === 0) sameAsOwnerButton = <Grid item lg={3} md={4} xs={12} >
            <Button variant="contained" color="default" startIcon={<PlusIcon />} onClick={() => addDriver("owner")}>
              Добавить собственника
            </Button>
          </Grid>

          let driverAddButtons = null;
          let driversContent = null;
          if (!certificate.no_drivers_limit) {
            driverAddButtons = <>
              <Grid item lg={3} md={4} xs={12} >
                <Button variant="contained" color="default" startIcon={<PlusIcon />} onClick={() => addDriver("client")}>
                  Добавить страхователя
                </Button>
              </Grid>
              { sameAsOwnerButton }
              <Grid item lg={3} md={4} xs={12} >
                <Button variant="contained" color="default" startIcon={<PlusIcon />} onClick={() => addDriver()}>
                  Добавить водителя
                </Button>
              </Grid>
            </>
            driversContent = <>
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    {driversBlocks}    
                  </Grid>
                </CardContent>
            </>
          }

          let periodsContent = null;
          if (periodsBlocks.length > 0)
            periodsContent = <>
                <Divider />
                <CardContent style={{width:"100%"}}>
                  <Grid container spacing={3} >
                    {periodsBlocks}
                  </Grid>
                </CardContent>
            </>

          let printButton = null;
          if (certificatePrintableStatuses.includes(certificate.status_id)) 
            printButton = <Button onClick={() => rest.onOpenDocumentsWindow(certificate.id)} className={classes.marginRight} variant="contained" color="default" type="button">Печать документов</Button>

          let saveButton = <Button variant="contained" color="primary" type="submit">Сохранить полис</Button>
          if (rest.isSaving) saveButton = <Button variant="contained" color="primary" disabled={true}><CircularProgress size="24px" className={classes.saveSpinner} /> Сохраняю полис</Button>

          let driversError = null;
          if (certificateErrors.no_drivers_limit) driversError = <Grid item lg={12} md={12} xs={12} ><Alert severity="error">{certificateErrors.no_drivers_limit}</Alert></Grid>

          let saveError = null;
          let saveErrorLog = null;
          if (rest.savedId) saveError = <><Alert severity="success" className={classes.marginBottom}>Полис успешно сохранён, переходим на расчёт</Alert></>
          else if (rest.saveError) {
            const saveErrorLogItems = [];
            if (rest.errorFields) {
              for (const errorFieldName in rest.errorFields)
              saveErrorLogItems.push(<Chip style={{marginBottom:"5px"}} label={errorFieldName +": " + rest.errorFields[errorFieldName]} />);
            }
            if (saveErrorLogItems.length > 0) {
              if (errorLogOpened) saveErrorLog = <Alert className={classes.marginBottom} severity="info">{saveErrorLogItems}</Alert>
              const actionButton = <Button color="inherit" size="small" onClick={()=>toggleErrorOpened()}>Список полей</Button>
              saveError = <Alert severity="error" action={actionButton} className={classes.marginBottom} >{rest.saveError}</Alert>
            } else {
              saveError = <Alert severity="error" className={classes.marginBottom} >{rest.saveError}</Alert>
            }
          }
          else if (touched && errors && Object.keys(errors).length > 0) {
            for (const error in errors) if (touched[error]) {
              saveError = <Alert severity="error" className={classes.marginBottom}>Ошибки в заполнении сертификата</Alert>
              break;
            }
          }

          let categoryIdError = null;
          if (certificateErrors.car_category_id) categoryIdError = <FormHelperText className={classes.error}>{certificateErrors.car_category_id}</FormHelperText>

          let maxWeightBlock = null;
          if (certificate.car_category_id === "C") {
            maxWeightBlock = <ElkTextInput
              {...getFormError('car_max_weight', touched, errors, certificateErrors)}
              label="Максимально разрешённая масса (тонн)"
              name="car_max_weight"
              placeholder="4.5"
              setFieldValue={setFieldValue}
              onValueChange={(newValue) => { updateCertificateData({ car_max_weight: newValue }) }} 
              handleBlur={handleBlur}
              //required={true} 
              value={certificate.car_max_weight ?? ''}
            />
          }

          let maxPlacesBlock = null;
          if (certificate.car_category_id === "D") {
            maxPlacesBlock = <ElkTextInput
              {...getFormError('car_seats', touched, errors, certificateErrors)}
              label="Количество мест"
              name="car_seats"
              placeholder="20"
              setFieldValue={setFieldValue}
              onValueChange={(newValue) => { updateCertificateData({ car_seats: newValue }) }} 
              handleBlur={handleBlur}
              //required={true} 
              value={certificate.car_seats ?? ''}
            />
          }

          let carNumberMask;
          if (certificate.car_category_id !== "A")
            carNumberMask = [carNumberLetters, /\d/, /\d/, /\d/, carNumberLetters, carNumberLetters, ' ', /\d/, /\d/, /\d/];
          else 
            carNumberMask = null;

          //console.log("FORMIK TICK " + (Date.now() % 1000) / 1000);

          return (<form onSubmit={handleSubmit}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            <BackupBlock certificateId={certificateId} resetBackup={resetBackup} />
            <Box>
              <Card className={classes.greenCorridor}>
                <CardHeader
                  subheader="Введите номер предыдущего договора для получения более выгодной ставки от Некоторых компаний. Если вы пролонгируете полис Альфа-страхование не заполняйте это поле!"
                  title="Предыдущий полис"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <ElkTextInput
                      {...getFormError('green_coridor_number', touched, errors, certificateErrors, "Серия полиса заполняется русскими буквами")}
                      label="Номер предыдущего договора"
                      name="green_coridor_number"
                      placeholder="РРР 1112223344"
                      setFieldValue={setFieldValue}
                      onValueChange={(newValue) => { updateCertificateData({ green_coridor_number: newValue }) }} 
                      handleBlur={handleBlur}
                      mask={[russianLetterOrNumber, russianLetterOrNumber, russianLetterOrNumber, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                      //required={true} 
                      value={certificate.green_coridor_number ?? ''}
                    /> 
                    <ElkSearchInput 
                      {...getFormError('excompany_id', touched, errors, certificateErrors)}
                      label="Предыдущая компания"
                      name="excompany_id"
                      onValueChange={(newValue) => { updateCertificateData({ excompany_id: newValue }) }} 
                      setFieldValue={setFieldValue}
                      value={certificate.excompany_id}
                      options={ excompanyOptionsList }
                    />
                    {/*<Grid item xs={12}>
                      <Typography style={{marginLeft:"20px", color:"#546e7a"}}>
                        <div style={{marginBottom:"5px"}}><b>Условия активации "Зелёного коридора" для Альфа-страхование</b></div>
                        <ul>
                          <li>Предыдущий полис из другой ск</li>
                          <li>Нет разрыва в сроках страхования, новый полис должен вступить в силу ровно на следующий день.</li>
                          <li>Кбм от 0.9 и ниже</li>
                          <li>Только ЕОСАГО</li>
                        </ul>
                      </Typography>
                    </Grid>*/}
                  </Grid>
                </CardContent>
              </Card>
            </Box>
            <Box className={classes.certificateBox}>
              <Card>
                <CardHeader
                  subheader="Введите госномер авто и часть данных будет заполнена автоматически"
                  title="Данные по автомобилю"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >

                    <ElkTextInput
                      error={Boolean((carInfoErrorText || touched.car_number) && (carInfoErrorText || certificateErrors.car_number || errors.car_number))}
                      helperText={(carInfoErrorText || carInfoHelperText || touched.car_number) && ( certificateErrors.car_number || errors.car_number || carInfoErrorText || carInfoHelperText)}
                      label="Госномер"
                      placeholder="A123BC 777"
                      name="car_number"
                      setFieldValue={setFieldValue}
                      onValueChange={(newValue) => { parseNumber(newValue); }} 
                      handleBlur={handleBlur}
                      onBlur={handleBlur}
                      //required={true} 
                      //mask={[carNumberLetters, /\d/, /\d/, /\d/, carNumberLetters, carNumberLetters, ' ', /\d/, /\d/, /\d/]}
                      mask={carNumberMask}
                      value={certificate.car_number ?? ''}
                    />

                    <ElkSearchInput 
                      {...getFormError('car_brand_id', touched, errors, certificateErrors)}
                      label="Марка авто"
                      name="car_brand_id" 
                      onValueChange={(newValue) => { handleCarBrandSelected(newValue) }} 
                      //required={true} 
                      setFieldValue={setFieldValue}
                      value={certificate.car_brand_id}
                      options={ filterBrandsOptionsArray }
                    />

                    <ElkSearchInput 
                      {...getFormError('car_model_id', touched, errors, certificateErrors)}
                      label="Модель авто"
                      name="car_model_id" 
                      onValueChange={(newValue) => { updateCertificateData({ car_model_id: newValue }) }} 
                      //required={true} 
                      setFieldValue={setFieldValue}
                      value={certificate.car_model_id}
                      options={ filterModelsOptionsArray }
                    /> 
                    
                    <Grid item lg={4} md={4} xs={12} >
                      <ToggleButtonGroup
                        value={certificate.car_category_id}
                        exclusive
                        label="Категория ТС"
                        
                        onChange={(event, newValue) => { updateCertificateData({ car_category_id: newValue })}}
                        aria-label="Категория"
                      >
                        <ToggleButton value="A" aria-label="A">A</ToggleButton>
                        <ToggleButton value="B" aria-label="B">B</ToggleButton>
                        <ToggleButton value="C" aria-label="C">C</ToggleButton>
                        <ToggleButton value="D" aria-label="D">D</ToggleButton>
                      </ToggleButtonGroup>
                      {categoryIdError}
                    </Grid>

                    <ElkSelectInput 
                      {...getFormError('car_type_id', touched, errors, certificateErrors)}
                      label="Тип авто"
                      name="car_type_id"
                      onValueChange={(newValue) => { updateCertificateData({ car_type_id: newValue }) }} 
                      //required={true} 
                      setFieldValue={setFieldValue}
                      value={certificate.car_type_id}
                      options={ carTypesOptionsList }
                    />

                    <ElkTextInput
                      {...getFormError('car_year', touched, errors, certificateErrors)}
                      label="Год выпуска"
                      name="car_year"
                      placeholder="2000"
                      setFieldValue={setFieldValue}
                      onValueChange={(newValue) => { updateCertificateData({ car_year: newValue }) }} 
                      handleBlur={handleBlur}
                      //required={true} 
                      mask={[/\d/, /\d/, /\d/, /\d/]}
                      value={certificate.car_year ?? ''}
                    />

                    <ElkTextInput
                      {...getFormError('car_power', touched, errors, certificateErrors)}
                      label="Мощность (л.с.)"
                      name="car_power" 
                      placeholder="130.5"
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      onValueChange={(newValue) => { updateCertificateData({ car_power: newValue }) }} 
                      //required={true} 
                      value={certificate.car_power ?? ''}
                    />

                    <ProfinsCarNumber 
                      errorText={
                        (certificate.car_number_type*1 === 1 && touched.car_vin_number && (certificateErrors.car_vin_number || errors.car_vin_number)) || 
                        (certificate.car_number_type*1 === 2 && touched.car_body_number && (certificateErrors.car_body_number || errors.car_body_number)) || 
                        (certificate.car_number_type*1 === 3 && touched.car_chass_number && (certificateErrors.car_chass_number || errors.car_chass_number))
                      }
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      onValueChange={(newValues) => { updateCertificateData(newValues) }} 
                      car_body_number={certificate.car_body_number}
                      car_vin_number={certificate.car_vin_number}
                      car_chass_number={certificate.car_chass_number}
                      car_number_type={certificate.car_number_type}
                    />

                    <ElkSelectInput 
                      {...getFormError('car_task_id', touched, errors, certificateErrors)}
                      label="Цель использования"
                      name="car_task_id"
                      onValueChange={(newValue) => { updateCertificateData({ car_task_id: newValue }) }} 
                      //required={true} 
                      setFieldValue={setFieldValue}
                      value={certificate.car_task_id}
                      options={ carTaskOptionsList }
                    /> 

                    {maxWeightBlock}
                    {maxPlacesBlock}

                    <Grid item lg={4} md={4} xs={12} >
                      <FormControlLabel
                        style={{marginLeft:0}}
                        control={<Switch checked={certificate.car_trailer*1 === 1} onChange={(event, newValue) => { updateCertificateData({ car_trailer: newValue ? 1 : 0 }) }} name="car_trailer" />}
                        label="Авто используется с прицепом"
                      />
                    </Grid>

                  </Grid>
                </CardContent>

                <Divider />
                <CardHeader
                  subheader="Документ на ТС"
                  //title="Данные по автомобилю"
                />
                <Divider />

                <CardContent>
                  <Grid container spacing={3} >

                    <ElkSelectInput 
                      {...getFormError('car_document_type', touched, errors, certificateErrors)}
                      label="Тип документа"
                      name="car_document_type" 
                      onValueChange={(newValue) => { updateCertificateData({ car_document_type: newValue }) }} 
                      //required={true} 
                      setFieldValue={setFieldValue}
                      value={certificate.car_document_type}
                      options={ carDocumentTypeOptionsList }
                    /> 

                    <ElkTextInput
                      {...getFormError('car_document_number', touched, errors, certificateErrors)}
                      label="Номер документа"
                      name="car_document_number" 
                      //placeholder={carDocumentPlaceholder}
                      placeholder=""
                      onValueChange={(newValue) => { updateCertificateData({ car_document_number: newValue.toUpperCase() }) }} 
                      //required={true} 
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={certificate.car_document_number ?? ''}
                      mask={carDocumentMask}
                      //mask={[russianLetterOrNumber, russianLetterOrNumber, russianLetterOrNumber, russianLetterOrNumber, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                    />

                    <ElkDateSelector 
                      {...getFormError('car_document_date', touched, errors, certificateErrors)}
                      label="Дата выдачи" 
                      name="car_document_date"
                      setFieldValue={setFieldValue}
                      onValueChange={(newValue) => { updateCertificateData({ car_document_date: newValue }) }} 
                      //required={true} 
                      value={certificate.car_document_date}
                    />

                    {/*<ElkTextInput
                      {...getFormError('car_diagnost_number', touched, errors, certificateErrors, "Оставьте пустым если техосмотр не требуется")}
                      label="Номер диагностической карты"
                      name="car_diagnost_number"
                      placeholder="77ТК 123456"
                      onValueChange={(newValue) => { updateCertificateData({ car_diagnost_number: newValue }) }} 
                      //required={true} 
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      value={certificate.car_diagnost_number ?? ''}
                      mask={[ /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/ ]}
                    />

                    <ElkDateSelector 
                      {...getFormError('car_diagnost_date', touched, errors, certificateErrors)}
                      label="Карта действительна до"
                      name="car_diagnost_date"
                      setFieldValue={setFieldValue}
                      onValueChange={(newValue) => { updateCertificateData({ car_diagnost_date: newValue }) }} 
                      //required={true} 
                      value={certificate.car_diagnost_date}
                    />*/}

                  </Grid>
                </CardContent>

              </Card>
            </Box>

            <Box className={classes.certificateBox}>
              <Card>
                <CardHeader
                  subheader="Если страховка будет использоваться не весь год, укажите периоды использования"
                  title="Срок действия полиса"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <ElkSelectInput 
                        {...getFormError('period', touched, errors, certificateErrors)}
                        label="Тип договора"
                        name="period"
                        onValueChange={(newValue) => { updateCertificateData({ period: newValue }) }} 
                        //required={true} 
                        setFieldValue={setFieldValue}
                        value={certificate.period ?? ''}
                        options={ typeOptionsList }
                    /> 
                    <ElkSelectInput 
                        {...getFormError('season_months', touched, errors, certificateErrors)}
                        label="Срок действия договора"
                        name="season_months"
                        onValueChange={(newValue) => { updateCertificateData({ season_months: newValue }) }} 
                        //required={true} 
                        setFieldValue={setFieldValue}
                        value={certificate.season_months ?? ''}
                        options={ periodOptionsList }
                    /> 
                    <ElkDateSelector 
                      {...getFormError('date_start', touched, errors, certificateErrors, dateEndText)}
                      label="Дата начала действия договора"
                      format="yyyy-MM-dd"
                      name="date_start"
                      setFieldValue={setFieldValue}
                      onValueChange={(newValue) => { changeStartPeriod(newValue); }} 
                      //required={true} 
                      value={certificate.date_start}
                    />
                  </Grid>
                </CardContent>
                {/*<CardContent>
                  <Grid container spacing={3} >
                    {periodsContent}
                    {periodsError}
                    <Grid item lg={12} md={12} xs={12} >
                      <Button variant="contained" color="default" startIcon={<PlusIcon />} onClick={() => addPeriod()}>
                        Добавить период
                      </Button>
                    </Grid>
                  </Grid>
                  </CardContent>*/}
              </Card>
            </Box>

            <Box className={classes.certificateBox}>
              <Card>
                <CardHeader
                  //subheader="Введите госномер авто и часть данных будет заполнена автоматически"
                  title="Страхователь"
                />
                <Divider />
                <CardContent>
                  <CertificateFormPerson
                    certificate={certificate}
                    personVariant="client"
                    personDocumentOptionsList={personDocumentOptionsList}
                    juridicalOptionsList={juridicalOptionsList}
                    personLabel="Клиент"
                    catalogsGeneral={ catalogsGeneral }
                    changeData={ (dataToChange) => changePersonData("client", dataToChange) }
                    touched={touched}
                    handleBlur={handleBlur}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    certificateErrors={certificateErrors}
                  />
                </CardContent>
              </Card>
            </Box>

            <Box className={classes.certificateBox}>
              <Card>
                <CardHeader
                  //subheader="Введите госномер авто и часть данных будет заполнена автоматически"
                  title="Собственник"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} >
                    <Grid item lg={12} md={12} xs={12} >
                      <FormControlLabel
                        style={{marginLeft:0}}
                        control={<Switch checked={certificate.owner_is_client*1 === 1} onChange={(event, newValue) => { updateCertificateData({ owner_is_client: newValue }) }} name="" />}
                        label="Собственник совпадает со страхователем"
                      />
                    </Grid>
                  </Grid> 
                </CardContent>
                
                {ownerBlock}

              </Card>
            </Box>

            <Box className={classes.certificateBox}>
              <Card>
                <CardHeader
                  //subheader="Введите госномер авто и часть данных будет заполнена автоматически"
                  title="Данные о водителях"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} xs={12} >
                      <FormControlLabel
                        style={{marginLeft:0}}
                        control={<Switch checked={certificate.no_drivers_limit*1 === 1} onChange={(event, newValue) => { updateCertificateData({ no_drivers_limit: newValue ? 1 : 0 }) }} name="" />}
                        label="Без ограничения на число водителей"
                      />
                    </Grid>
                    {driversError}
                    {driverAddButtons}
                  </Grid>
                </CardContent>
                {driversContent}
              </Card>
            </Box>

            <Box className={classes.certificateBox}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} xs={12} className={classes.center} >
                      {saveError}
                      {saveErrorLog}
                      {printButton}
                      {saveButton}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>

            </MuiPickersUtilsProvider>
          </form>
        ); }}
      </Formik>
      {backdrop}
      {documentsPopup}
    </>
  else 
    return <Box style={{width:'100%'}} className={classes.userBox}><Alert style={{width:'100%'}} severity="error">{rest.certificateError}</Alert></Box>;
  /*else {
    //if (rest.isCatalogsLoading) return <Spinner text="Загружаем каталоги"></Spinner>;
    if (rest.isCertificateLoading) return <Spinner text="Загружаем данные полиса"></Spinner>;
    return <Spinner></Spinner>;
  }*/
};

CertificateForm.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  return {
    isCertificateLoading: state.certificate.isLoading,
    isCertificateLoaded: state.certificate.isLoaded,
    isCertificateSaving: state.certificate.isSaving,
    certificate: state.certificate.certificate,
    certificateError: state.certificate.loadError,
    saveError: state.certificate.saveError,
    errorFields: state.certificate.errorFields,
    isSaving: state.certificate.isSaving,
    savedId: state.certificate.savedId,

    isCarInfoLoading: state.certificate.isCarInfoLoading,
    carInfoError: state.certificate.carInfoError,
    carInfoSuccess: state.certificate.carInfoSuccess,
    
    isCatalogsLoading: state.catalogs.isLoading,
    catalogs: state.catalogs.catalogs,

    isDocumentsWindowActive: state.documents.isActive,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onLoadCarInfo: (carNumber) => dispatch(actions.loadCarInfo(carNumber)),
      onLoadCatalog: (catalogName, subId=null) => dispatch(actions.loadCatalog(catalogName, subId)),
      onUpdateCertificate: (newCertificateData) => dispatch(actions.updateCertificate(newCertificateData)),
      onSaveCertificate: (certificateToSave) => dispatch(actions.saveCertificate(certificateToSave)),
      onLoadCertificate: (certificateId, prolongMode = false) => dispatch(actions.loadCertificate(certificateId, prolongMode)),

      onRedirectToCalculate: () => dispatch(actions.redirectToCalculate()),
      //onResetCalculations: () => dispatch(actions.redirectToCalculate()),
      
      onOpenDocumentsWindow: (certificateId) => dispatch(actions.openDocumentsWindow(certificateId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CertificateForm);

import {
    Grid,
    TextField,
  } from '@material-ui/core';

const ElkSelectInput = (props) => {

    return <Grid item lg={props.width ?? 4} md={props.width ?? 4} xs={12} >
        <TextField
            fullWidth
            error={props.error}
            helperText={props.helperText}
            select
            name={props.name}
            variant="outlined"
            required={props.required}
            handleBlur={props.handleBlur}
            label={props.label}
            SelectProps={{
                multiple: props.multiple ?? false,
                value: props.value ?? '',
                onChange: (event, index, values) => { props.setFieldValue(props.name, event.target.value); props.onValueChange(event.target.value ?? null) }
            }}
        >
        {props.options}
        </TextField>
    </Grid>
}

export default ElkSelectInput;
const bigRussianLettersAndDigits = (initialString) => {
    
    if (!initialString) return '';

    // To upperCase
    let finalString = initialString.toUpperCase();

    //Changing capital latin to capital russian if possible, removing letters which are not possible to convert //TODO
    finalString = finalString.replace(/[^А-Я0-9 ]+/g, '');
    return finalString;
}

export default bigRussianLettersAndDigits;
import * as actionTypes from '../actions/actionTypes';

import { updateState } from '../../shared/utility';

const initialState = {
    token: null,
    isLocalTokenChecked: false,
    user: null,
    error: null,
    loading: false,
    redirectPath: "/",

    rate: null,
    rateError: null,
    rateLoading: false,
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return updateState(state, {error:null, loading: true});
        case actionTypes.AUTH_FAIL: return updateState(state, {loading: false, error: action.error, isLocalTokenChecked: true});
        case actionTypes.AUTH_SUCCESS: return updateState(state, {loading: false, token: action.token, user: action.user, isLocalTokenChecked: true});
        case actionTypes.AUTH_LOGOUT: return updateState(state, {token: null, user: null, isLocalTokenChecked: true});
        case actionTypes.AUTH_SET_REDIRECT_PATH: return updateState(state, {redirectPath: action.path});

        case actionTypes.USER_RATE_START: return updateState(state, {rateError:null, rateLoading: true, parentRate: null});
        case actionTypes.USER_RATE_FAIL: return updateState(state, {rateLoading: false, rateError: action.error });
        case actionTypes.USER_RATE_SUCCESS: return updateState(state, {rateLoading: false, rateError: null, rate: action.rate });

        default: return state;
    }
}

export default reducer;
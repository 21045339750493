
import * as Yup from 'yup';
import moment from 'moment'

const dateValidation = (required=true, mode='past', maxPastYears=100, minPastYears=0, noneText="Поле обязательно для заполнения") => {

    var dateChecker = Yup.string();

    if (required) dateChecker = dateChecker.required(noneText).test('correct', 'Пожалуйста, укажите корректную дату', function(value) {
        const dateObject = moment(value, 'DD.MM.YYYY');
        return dateObject.isValid();
    });

    if (mode==='past') {
        dateChecker = dateChecker.test('onlyPast', 'Дата не может быть в будущем времени', function(value) {
            const dateObject = moment(value, 'DD.MM.YYYY');
            return dateObject.isValid() && dateObject < moment();
        });  
    } else if (mode==='future') {
        dateChecker = dateChecker.test('onlyFuture', 'Дата должна быть в будущем времени', function(value) {
            const dateObject = moment(value, 'DD.MM.YYYY');
            //console.log("DATE CHECK", value, dateObject, moment(), dateObject > moment());
            return dateObject.isValid() && dateObject > moment();
        });
    }

    if (maxPastYears > 0) dateChecker = dateChecker.test('maxPastYears', 'Слишком ранняя дата. Возможно, вы ошиблись при вводе даты.', function(value) {
        const dateObject = moment(value, 'DD.MM.YYYY');
        const past = moment();
        //past.setFullYear(past.getFullYear() - maxPastYears);
        past.subtract(maxPastYears, 'years');
        return dateObject.isValid() && dateObject > past;
    });

    if (minPastYears > 0) dateChecker = dateChecker.test('maxPastYears', 'Дата не может быть ранее чем '+minPastYears+' лет. Возможно, вы ошиблись при вводе даты.', function(value) {
        const dateObject = moment(value, 'DD.MM.YYYY');
        const earlyPast = moment();
        //earlyPast.setFullYear(earlyPast.getFullYear() - minPastYears);
        earlyPast.subtract(minPastYears, 'years');
        //console.log("DATE CHECK", dateObject, earlyPast);
        return dateObject.isValid() && dateObject < earlyPast;
    });

    return dateChecker;
}

export default dateValidation;
import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';
import { ruRU } from '@material-ui/core/locale';

import purple from '@material-ui/core/colors/purple';
import indigo from '@material-ui/core/colors/indigo';

const theme = (overloadColors) => {
  return createMuiTheme({
    palette: {
      background: {
        dark: overloadColors.background ?? '#F4F6F8',
        default: colors.common.white,
        paper: colors.common.white
      },
      primary: {
        main: overloadColors.primary ?? "#34878e" //indigo[700]
      },
      secondary: {
        main: overloadColors.secondary ?? "#34878e" //indigo[500]
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600]
      }
    },
    shadows,
    typography
  }, ruRU)
};

export default theme;

import * as actionTypes from '../actions/actionTypes';
import { updateState } from '../../shared/utility';

const initialState = {
    calculations: null,
    certificateId: null,

    companyId: null,
    typeId: null,
    bsoNumber: '',
    bsoCheque: '',
    formError: '',

    isProcessing: false,
    isProcessSuccess: null,
    processError: null,

    isFinishing: false,
    isFinishSuccess: null,
    finishError: null,

    processData: null,
    completeData: null,
}

const copyCalculationsObject = (calculations) => {
    const newCalculations = {};
    for (const i in calculations)
        newCalculations[i] = calculations[i];
    return newCalculations;
}

const reducer = (state = initialState, action) => {

    let newCalculations = null;
    switch (action.type) {

        case actionTypes.AUTH_LOGOUT:
            return updateState(state, initialState);

        case actionTypes.CERTIFICATE_CALCULATE_CLEAR: 
            return updateState(state, {calculations: null});

        case actionTypes.CERTIFICATE_CALCULATE_START:   
            newCalculations = copyCalculationsObject(state.calculations);
            if (!newCalculations[action.companyId]) newCalculations[action.companyId] = {isCalculating: true, calculationError: false, calculationMode: false, calculationResult: false, models: null }
            else {
                newCalculations[action.companyId].isCalculating = true;
                newCalculations[action.companyId].calculationError = false;
                newCalculations[action.companyId].calculationMode = false;
                newCalculations[action.companyId].calculationResult = false;
            }
            return updateState(state, {calculations: newCalculations, certificateId: action.certificateId});

        case actionTypes.CERTIFICATE_CALCULATE_SUCCESS: 
            newCalculations = copyCalculationsObject(state.calculations);
            newCalculations[action.companyId].isCalculating = false;
            newCalculations[action.companyId].calculationResult = action.result;
            return updateState(state, {calculations: newCalculations});

        case actionTypes.CERTIFICATE_CALCULATE_FAIL:
            newCalculations = copyCalculationsObject(state.calculations);
            if (newCalculations[action.companyId]) {
                newCalculations[action.companyId].isCalculating = false;
                newCalculations[action.companyId].calculationError = action.error;
                newCalculations[action.companyId].calculationMode = action.mode;
                newCalculations[action.companyId].calculationErrors = action.errors;
                newCalculations[action.companyId].models = action.models ?? null;
            }
            return updateState(state, {calculations: newCalculations});

        //------------------------------------------------------------------------------

        case actionTypes.CERTIFICATE_SET_FINISH_INFO:
            return updateState(state, {
                companyId: action.companyId, 
                typeId: action.typeId, 
                bsoNumber: action.bsoNumber, 
                bsoCheque: action.bsoCheque,
                formError: action.formError,

                finishError: null,
                isFinishing: false,
                isFinishSuccess: false,

                processError: null,
                isProcessing: false,
                isProcessSuccess: false,

                processData: null,
                completeData: null,
            });

        case actionTypes.CERTIFICATE_FINISH_START:  
            return updateState(state, {
                isFinishSuccess: null, 
                finishError: null,
                isFinishing: true,
                formError: '',
            });

        case actionTypes.CERTIFICATE_FINISH_SUCCESS: 
            return updateState(state, {isFinishing: false, finishError: null, isFinishSuccess: true, completeData: action.completeResult});

        case actionTypes.CERTIFICATE_RESET_CALCULATIONS:
            return updateState(state, {calculations: null, isFinishing: false, finishError: null, isFinishSuccess: false, completeData: null});

        case actionTypes.CERTIFICATE_FINISH_FAIL:
            return updateState(state, {isFinishing: false, finishError: action.error, isFinishSuccess: false});

        //------------------------------------------------------------------------------

        case actionTypes.CERTIFICATE_SET_PROCESS_INFO:
            return updateState(state, {
                companyId: action.companyId, 
                typeId: action.typeId, 
                bsoNumber: action.bsoNumber, 
                bsoCheque: action.bsoCheque,
                formError: action.formError,

                processError: null,
                isProcessing: false,
                isProcessSuccess: false,
                processData: null,
                completeData: null,
            });

        case actionTypes.CERTIFICATE_PROCESS_START:  
            return updateState(state, {
                isProcessSuccess: null, 
                processError: null,
                isProcessing: true,
                formError: '',
            });

        case actionTypes.CERTIFICATE_PROCESS_SUCCESS: 
            return updateState(state, {isProcessing: false, processError: null, isProcessSuccess: true, processData: action.processResult});

        case actionTypes.CERTIFICATE_PROCESS_FAIL:
            return updateState(state, {isProcessing: false, processError: action.error, isProcessSuccess: false});

        default: return state;
    }
}

export default reducer;
import moment from 'moment';

import {
    Backdrop,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    ListItem,
    ListItemText,
    ListSubheader,
    makeStyles,
    MenuItem,
    Switch,
    Typography
  } from '@material-ui/core';
  import DeleteIcon from '@material-ui/icons/Delete';

  const useStyles = makeStyles((theme) => ({
    newsDate: {
      marginBottom: '10px',
      fontSize: '10px',
    },
    controlButtons: {
      textAlign: "right",
      marginTop: "5px",
    },
    controlButton: {
      marginLeft: "10px",
    }
  }));

const MessageElement = (props) => {

  const classes = useStyles();

  const message = props.message;

  let controlButtons = [];
  if (props.isAdmin || message.user_id === props.curUserId) {
    controlButtons.push(<Button className={classes.controlButton} variant="contained" size="small" key="del" severity="error" onClick={()=>{props.onDelete(message.id)}}>Удалить</Button>)
  }

  //console.log("YEAR", message.user_id*1, props.curUserId*1, moment(message.read_at).year());

  const checkUserId = props.isAdmin ? 0 : props.curUserId*1;
  let readAtBlock = null;
  if (message.user_id*1 === checkUserId && moment(message.read_at).year() > 0)
    readAtBlock = ", прочитано " + moment(message.read_at).format("DD.MM.YYYY в HH:mm")


  return <ListItem
    divider={props.divider ?? false}
    style={{cursor:"pointer"}}
  >
    <ListItemText
      primary={
        <>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            {props.headerTitle} <span style={{fontSize:"10px"}}>{" написал " + moment(message.created_at).format("DD.MM.YYYY в HH:mm")}{readAtBlock}</span>
          </Typography>
        </>
      }
      secondary={ <Typography>{message.text}</Typography> }
    />
    {/*<IconButton
      edge="end"
      size="small"
      onClick={props.onDelete}
    >
      <DeleteIcon />
    </IconButton>*/}
  </ListItem>
}

export default MessageElement;
import React from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from '../../../components/Page';
import RateForm from './RateForm';

import parseUrl from '../../../utils/parseUrl';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const RateFormPage = () => {
  const classes = useStyles();

  const rateId = parseUrl(3);

  return (
    <Page
      className={classes.root}
      title="Управление тарифом"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <RateForm rateId={rateId} />
        </Grid>
      </Container>
    </Page>
  );
};

export default RateFormPage;
